@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;600;700;800;900&family=Lora:wght@400;500&display=swap');

:root{
    --color-slate-50: #f8fafc;
    --color-slate-100: #f1f5f9;
    --color-slate-200: #e2e8f0;
    --color-slate-300: #cbd5e1;
    --color-slate-400: #94a3b8;
    --color-slate-500: #64748b;
    --color-slate-600: #475569;
    --color-slate-700: #334155;
    --color-slate-800: #1e293b;
    --color-slate-900: #0f172a;
    --color-zinc-50: #fafafa;
    --color-zinc-100: #f4f4f5;
    --color-zinc-200: #e4e4e7;
    --color-zinc-300: #d4d4d8;
    --color-zinc-400: #a1a1aa;
    --color-zinc-500: #71717a;
    --color-zinc-600: #52525b;
    --color-zinc-700: #3f3f46;
    --color-zinc-800: #27272a;
    --color-zinc-900: #18181b;
    --color-green-50: #F2FCDF;
    --color-green-100: #E6F8BF;
    --color-green-200: #CBF17A;
    --color-green-300: #B2EA39;
    --color-green-400: #8BC115;
    --color-green-500: #5D810E;
    --color-green-600: #49650B;
    --color-green-700: #384E08;
    --color-green-800: #243305;
    --color-green-900: #141C03;
    --color-emerald-50: #ecfdf5;
    --color-emerald-100: #d1fae5;
    --color-emerald-200: #a7f3d0;
    --color-emerald-300: #6ee7b7;
    --color-emerald-400: #34d399;
    --color-emerald-500: #10b981;
    --color-emerald-600: #059669;
    --color-emerald-700: #047857;
    --color-emerald-800: #065f46;
    --color-emerald-900: #064e3b;
    --color-yellow-50: #FEF4A9;
    --color-yellow-100: #FEF295;
    --color-yellow-200: #FEED6D;
    --color-yellow-300: #FDE744;
    --color-yellow-400: #FDE21C;
    --color-yellow-500: #EED202;
    --color-yellow-600: #C6AE02;
    --color-yellow-700: #9D8B01;
    --color-yellow-800: #756701;
    --color-yellow-900: #4C4301;
    --color-orange-50: #FEF1DF;
    --color-orange-100: #FEE6C9;
    --color-orange-200: #FCCD9C;
    --color-orange-300: #FBB26F;
    --color-orange-400: #FA9443;
    --color-orange-500: #F97316;
    --color-orange-600: #D65406;
    --color-orange-700: #A53B04;
    --color-orange-800: #732603;
    --color-orange-900: #411302;
    --color-blue-50: #E1F1FF;
    --color-blue-100: #B8DDFF;
    --color-blue-200: #67B6FF;
    --color-blue-300: #0074E0;
    --color-blue-400: #005FB8;
    --color-blue-500: #003B71;
    --color-blue-600: #003362;
    --color-blue-700: #002B52;
    --color-blue-800: #002343;
    --color-blue-900: #001B34;
    --color-teal-50: #f0fdfa;
    --color-teal-100: #ccfbf1;
    --color-teal-200: #99f6e4;
    --color-teal-300: #5eead4;
    --color-teal-400: #2dd4bf;
    --color-teal-500: #14b8a6;
    --color-teal-600: #0d9488;
    --color-teal-700: #0f766e;
    --color-teal-800: #115e59;
    --color-teal-900: #134e4a;
    --color-cyan-50: #D6F6FB;
    --color-cyan-100: #C1F1F9;
    --color-cyan-200: #97E8F5;
    --color-cyan-300: #6DDFF0;
    --color-cyan-400: #44D6EC;
    --color-cyan-500: #1ACDE8;
    --color-cyan-600: #13A6BC;
    --color-cyan-700: #0E7D8E;
    --color-cyan-800: #0A545F;
    --color-cyan-900: #052B31;
    --color-sky-50: #f0f9ff;
    --color-sky-100: #e0f2fe;
    --color-sky-200: #bae6fd;
    --color-sky-300: #7dd3fc;
    --color-sky-400: #38bdf8;
    --color-sky-500: #0ea5e9;
    --color-sky-600: #0284c7;
    --color-sky-700: #0369a1;
    --color-sky-800: #075985;
    --color-sky-900: #0c4a6e;
    --color-indigo-50: #eef2ff;
    --color-indigo-100: #e0e7ff;
    --color-indigo-200: #c7d2fe;
    --color-indigo-300: #a5b4fc;
    --color-indigo-400: #818cf8;
    --color-indigo-500: #6366f1;
    --color-indigo-600: #4f46e5;
    --color-indigo-700: #4338ca;
    --color-indigo-800: #3730a3;
    --color-indigo-900: #312e81;
    --color-violet-50: #f5f3ff;
    --color-violet-100: #ede9fe;
    --color-violet-200: #ddd6fe;
    --color-violet-300: #c4b5fd;
    --color-violet-400: #a78bfa;
    --color-violet-500: #8b5cf6;
    --color-violet-600: #7c3aed;
    --color-violet-700: #6d28d9;
    --color-violet-800: #5b21b6;
    --color-violet-900: #4c1d95;
    --color-fuchsia-50: #fdf4ff;
    --color-fuchsia-100: #fae8ff;
    --color-fuchsia-200: #f5d0fe;
    --color-fuchsia-300: #f0abfc;
    --color-fuchsia-400: #e879f9;
    --color-fuchsia-500: #d946ef;
    --color-fuchsia-600: #c026d3;
    --color-fuchsia-700: #a21caf;
    --color-fuchsia-800: #86198f;
    --color-fuchsia-900: #701a75;
    --color-pink-50: #fdf2f8;
    --color-pink-100: #fce7f3;
    --color-pink-200: #fbcfe8;
    --color-pink-300: #f9a8d4;
    --color-pink-400: #f472b6;
    --color-pink-500: #ec4899;
    --color-pink-600: #db2777;
    --color-pink-700: #be185d;
    --color-pink-800: #9d174d;
    --color-pink-900: #831843;
    --color-rose-50: #fff1f2;
    --color-rose-100: #ffe4e6;
    --color-rose-200: #fecdd3;
    --color-rose-300: #fda4af;
    --color-rose-400: #fb7185;
    --color-rose-500: #f43f5e;
    --color-rose-600: #e11d48;
    --color-rose-700: #be123c;
    --color-rose-800: #9f1239;
    --color-rose-900: #881337;
    --color-red-50: #FBCBCB;
    --color-red-100: #F37272;
    --color-red-200: #F04C4C;
    --color-red-300: #ED2727;
    --color-red-400: #D91212;
    --color-red-500: #B30F0F;
    --color-red-600: #9B0D0D;
    --color-red-700: #840B0B;
    --color-red-800: #6C0909;
    --color-red-900: #550707;
    --color-brown-50: #EDE5BF;
    --color-brown-100: #E9DFB3;
    --color-brown-200: #E2D29B;
    --color-brown-300: #DCC384;
    --color-brown-400: #D5B36C;
    --color-brown-500: #CEA154;
    --color-brown-600: #9A6C2C;
    --color-brown-700: #775222;
    --color-brown-800: #382514;
    --color-brown-900: #0B0503;
    --color-magenta-50: #FFE5FF;
    --color-magenta-100: #FFCCFF;
    --color-magenta-200: #FF99FF;
    --color-magenta-300: #FF66FF;
    --color-magenta-400: #FF33FF;
    --color-magenta-500: #FF00FF;
    --color-magenta-600: #D100D1;
    --color-magenta-700: #A300A3;
    --color-magenta-800: #750075;
    --color-magenta-900: #470047;
    --color-purple-50: #F6DDFF;
    --color-purple-100: #EFC1FF;
    --color-purple-200: #E189FF;
    --color-purple-300: #D251FF;
    --color-purple-400: #C319FF;
    --color-purple-500: #A600E0;
    --color-purple-600: #8800B7;
    --color-purple-700: #6A008E;
    --color-purple-800: #4B0066;
    --color-purple-900: #2D003D;
    --color-gray-50: #F6F6F6;
    --color-gray-100: #E0E0E0;
    --color-gray-200: #B5B5B5;
    --color-gray-300: #8A8A8A;
    --color-gray-400: #5E5E5E;
    --color-gray-500: #333333;
    --color-gray-600: #2B2B2B;
    --color-gray-700: #242424;
    --color-gray-800: #1C1C1C;
    --color-gray-900: #141414;
    --color-srm-1: #F8F4B4;
    --color-srm-2: #F9E06C;
    --color-srm-3: #F4CE51;
    --color-srm-4: #F2BE37;
    --color-srm-5: #EDAC1E;
    --color-srm-6: #E59C19;
    --color-srm-7: #DF8F16;
    --color-srm-8: #D68019;
    --color-srm-9: #CF731C;
    --color-srm-10: #BD591B;
    --color-srm-11: #C3621B;
    --color-srm-12: #C86B1B;
    --color-srm-13: #C05727;
    --color-srm-14: #AD4417;
    --color-srm-15: #AE4818;
    --color-srm-16: #AD4417;
    --color-srm-17: #A73D15;
    --color-srm-18: #A23A15;
    --color-srm-19: #9D3414;
    --color-srm-20: #983015;
    --color-srm-21: #932A14;
    --color-srm-22: #8D2615;
    --color-srm-23: #892515;
    --color-srm-24: #832212;
    --color-srm-25: #7D200F;
    --color-srm-26: #771E0E;
    --color-srm-27: #731C0B;
    --color-srm-28: #70180C;
    --color-srm-29: #6A160C;
    --color-srm-30: #67120B;
    --color-srm-31: #63100A;
    --color-srm-32: #5F0E0A;
    --color-srm-33: #5B0B0A;
    --color-srm-34: #58080B;
    --color-srm-35: #4B090B;
    --color-srm-36: #4B090B;
    --color-srm-37: #470D0C;
    --color-srm-38: #400C0E;
    --color-srm-39: #3C0B0E;
    --color-srm-40: #240A0B;
    --color-cider-straw: #F8F4B4;
    --color-cider-yellow: #F9E06C;
    --color-cider-gold: #F4CE51;
    --color-cider-amber: #C3621B;
    --color-cider-copper: #AE4818;
    --color-cider-pink: #FF66FF;
    --color-cider-ruby: #B30F0F;
    --color-cider-purple: #750075;
    --color-cider-garnet: #6C0909;
    --color-fruity-fruity: #FF0000;
    --color-fruity-berry: #990000;
    --color-fruity-apple: #CC0100;
    --color-fruity-stonefruit: #E06666;
    --color-fruity-tropical: #EA9999;
    --color-fruity-melon: #EA9999;
    --color-fruity-citrus: #F4CCCC;
    --color-flower-floral: #FF9902;
    --color-flower-herbaceous: #FFFF04;
    --color-flower-grassy: #92C47C;
    --color-flower-vegetal: #284E13;
    --color-flower-earthy: #7F6001;
    --color-flower-woody: #BF9001;
    --color-flower-spicy: #B45F05;
    --color-flower-sweetaromatic: #741C47;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-transparent: transparent;
    --color-primary: #5D810E;
    --color-secondary: #005FB8;
    --color-info: #0074E0;
    --color-success: #547E11;
    --color-warning: #EED202;
    --color-error: #C93B3B;
    --color-light: #FCFDFD;
    --color-medium: #5E5E5E;
    --color-dark: #333333;
}

*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(0 59 113 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(0 59 113 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}

.container{
    width: 100%;
}

@media (min-width: 640px){

    .container{
        max-width: 640px;
    }
}

@media (min-width: 768px){

    .container{
        max-width: 768px;
    }
}

@media (min-width: 1024px){

    .container{
        max-width: 1024px;
    }
}

@media (min-width: 1280px){

    .container{
        max-width: 1280px;
    }
}

@media (min-width: 1536px){

    .container{
        max-width: 1536px;
    }
}

.sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
}

.pointer-events-none{
    pointer-events: none !important;
}

.visible{
    visibility: visible !important;
}

.fixed{
    position: fixed !important;
}

.absolute{
    position: absolute !important;
}

.relative{
    position: relative !important;
}

.-left-3{
    left: -0.75rem !important;
}

.-right-0{
    right: -0px !important;
}

.-right-0\.5{
    right: -0.125rem !important;
}

.-top-0{
    top: -0px !important;
}

.-top-0\.5{
    top: -0.125rem !important;
}

.bottom-0{
    bottom: 0px !important;
}

.bottom-2{
    bottom: 0.5rem !important;
}

.bottom-4{
    bottom: 1rem !important;
}

.left-0{
    left: 0px !important;
}

.left-4{
    left: 1rem !important;
}

.right-0{
    right: 0px !important;
}

.right-0\.5{
    right: 0.125rem !important;
}

.right-\[-70rem\]{
    right: -70rem !important;
}

.right-\[-72rem\]{
    right: -72rem !important;
}

.top-0{
    top: 0px !important;
}

.top-16{
    top: 4rem !important;
}

.top-8{
    top: 2rem !important;
}

.top-\[-115\%\]{
    top: -115% !important;
}

.top-\[-160\%\]{
    top: -160% !important;
}

.z-0{
    z-index: 0 !important;
}

.z-10{
    z-index: 10 !important;
}

.z-20{
    z-index: 20 !important;
}

.z-\[100\]{
    z-index: 100 !important;
}

.z-\[94\]{
    z-index: 94 !important;
}

.z-\[95\]{
    z-index: 95 !important;
}

.z-\[96\]{
    z-index: 96 !important;
}

.z-\[97\]{
    z-index: 97 !important;
}

.z-\[98\]{
    z-index: 98 !important;
}

.z-\[99999\]{
    z-index: 99999 !important;
}

.z-\[99\]{
    z-index: 99 !important;
}

.col-span-2{
    grid-column: span 2 / span 2 !important;
}

.mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
}

.mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.-ml-0{
    margin-left: -0px !important;
}

.-ml-0\.5{
    margin-left: -0.125rem !important;
}

.-ml-2{
    margin-left: -0.5rem !important;
}

.mb-0{
    margin-bottom: 0px !important;
}

.mb-0\.5{
    margin-bottom: 0.125rem !important;
}

.mb-1{
    margin-bottom: 0.25rem !important;
}

.mb-1\.5{
    margin-bottom: 0.375rem !important;
}

.mb-12{
    margin-bottom: 3rem !important;
}

.mb-2{
    margin-bottom: 0.5rem !important;
}

.mb-4{
    margin-bottom: 1rem !important;
}

.mb-6{
    margin-bottom: 1.5rem !important;
}

.mb-8{
    margin-bottom: 2rem !important;
}

.ml-0{
    margin-left: 0px !important;
}

.ml-1{
    margin-left: 0.25rem !important;
}

.ml-1\.5{
    margin-left: 0.375rem !important;
}

.ml-2{
    margin-left: 0.5rem !important;
}

.ml-4{
    margin-left: 1rem !important;
}

.mr-0{
    margin-right: 0px !important;
}

.mr-12{
    margin-right: 3rem !important;
}

.mr-2{
    margin-right: 0.5rem !important;
}

.mr-3{
    margin-right: 0.75rem !important;
}

.mr-4{
    margin-right: 1rem !important;
}

.mr-6{
    margin-right: 1.5rem !important;
}

.mr-8{
    margin-right: 2rem !important;
}

.mt-10{
    margin-top: 2.5rem !important;
}

.mt-16{
    margin-top: 4rem !important;
}

.mt-2{
    margin-top: 0.5rem !important;
}

.mt-3{
    margin-top: 0.75rem !important;
}

.mt-4{
    margin-top: 1rem !important;
}

.mt-6{
    margin-top: 1.5rem !important;
}

.block{
    display: block !important;
}

.inline-block{
    display: inline-block !important;
}

.inline{
    display: inline !important;
}

.flex{
    display: flex !important;
}

.inline-flex{
    display: inline-flex !important;
}

.\!table{
    display: table !important;
}

.table{
    display: table !important;
}

.grid{
    display: grid !important;
}

.contents{
    display: contents !important;
}

.hidden{
    display: none !important;
}

.h-0{
    height: 0px !important;
}

.h-10{
    height: 2.5rem !important;
}

.h-16{
    height: 4rem !important;
}

.h-20{
    height: 5rem !important;
}

.h-24{
    height: 6rem !important;
}

.h-28{
    height: 7rem !important;
}

.h-3{
    height: 0.75rem !important;
}

.h-32{
    height: 8rem !important;
}

.h-4{
    height: 1rem !important;
}

.h-5{
    height: 1.25rem !important;
}

.h-6{
    height: 1.5rem !important;
}

.h-8{
    height: 2rem !important;
}

.h-80{
    height: 20rem !important;
}

.h-\[10rem\]{
    height: 10rem !important;
}

.h-\[120rem\]{
    height: 120rem !important;
}

.h-\[126rem\]{
    height: 126rem !important;
}

.h-\[15rem\]{
    height: 15rem !important;
}

.h-\[calc\(100vh-3rem\)\]{
    height: calc(100vh - 3rem) !important;
}

.h-auto{
    height: auto !important;
}

.h-full{
    height: 100% !important;
}

.h-screen{
    height: 100vh !important;
}

.max-h-0{
    max-height: 0 !important;
}

.max-h-full{
    max-height: 100% !important;
}

.max-h-screen{
    max-height: 100vh !important;
}

.min-h-12{
    min-height: 3rem !important;
}

.min-h-48{
    min-height: 12rem !important;
}

.min-h-64{
    min-height: 16rem !important;
}

.min-h-72{
    min-height: 18rem !important;
}

.min-h-8{
    min-height: 2rem !important;
}

.min-h-96{
    min-height: 24rem !important;
}

.min-h-full{
    min-height: 100% !important;
}

.min-h-screen{
    min-height: 100vh !important;
}

.min-h-screen-20{
    min-height: 20vh !important;
}

.w-1{
    width: 0.25rem !important;
}

.w-1\/2{
    width: 50% !important;
}

.w-10{
    width: 2.5rem !important;
}

.w-20{
    width: 5rem !important;
}

.w-24{
    width: 6rem !important;
}

.w-3{
    width: 0.75rem !important;
}

.w-36{
    width: 9rem !important;
}

.w-4{
    width: 1rem !important;
}

.w-48{
    width: 12rem !important;
}

.w-6{
    width: 1.5rem !important;
}

.w-64{
    width: 16rem !important;
}

.w-72{
    width: 18rem !important;
}

.w-8{
    width: 2rem !important;
}

.w-\[120rem\]{
    width: 120rem !important;
}

.w-\[126rem\]{
    width: 126rem !important;
}

.w-\[16ch\]{
    width: 16ch !important;
}

.w-auto{
    width: auto !important;
}

.w-fit{
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.w-full{
    width: 100% !important;
}

.w-px{
    width: 1px !important;
}

.min-w-44{
    min-width: 11rem !important;
}

.min-w-48{
    min-width: 12rem !important;
}

.min-w-8{
    min-width: 2rem !important;
}

.min-w-\[24ch\]{
    min-width: 24ch !important;
}

.min-w-full{
    min-width: 100% !important;
}

.max-w-14{
    max-width: 3.5rem !important;
}

.max-w-2xl{
    max-width: 42rem !important;
}

.max-w-\[10ch\]{
    max-width: 10ch !important;
}

.max-w-\[12ch\]{
    max-width: 12ch !important;
}

.max-w-\[16ch\]{
    max-width: 16ch !important;
}

.max-w-\[18ch\]{
    max-width: 18ch !important;
}

.max-w-\[22ch\]{
    max-width: 22ch !important;
}

.max-w-\[24ch\]{
    max-width: 24ch !important;
}

.max-w-\[3ch\]{
    max-width: 3ch !important;
}

.max-w-fit{
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
}

.max-w-sm{
    max-width: 24rem !important;
}

.max-w-xl{
    max-width: 36rem !important;
}

.flex-shrink-0{
    flex-shrink: 0 !important;
}

.shrink-0{
    flex-shrink: 0 !important;
}

.grow{
    flex-grow: 1 !important;
}

.grow-0{
    flex-grow: 0 !important;
}

.-translate-y-1{
    --tw-translate-y: -0.25rem !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rotate-\[-30deg\]{
    --tw-rotate: -30deg !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

@keyframes pulse{

    50%{
        opacity: .5;
    }
}

.animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

@keyframes pulse{

    50%{
        opacity: .5;
    }
}

.animate-pulse-fast{
    animation: pulse 1s ease-in-out infinite !important;
}

.cursor-pointer{
    cursor: pointer !important;
}

.resize{
    resize: both !important;
}

.grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-subgrid{
    grid-template-columns: subgrid !important;
}

.grid-rows-subgrid{
    grid-template-rows: subgrid !important;
}

.flex-row{
    flex-direction: row !important;
}

.flex-row-reverse{
    flex-direction: row-reverse !important;
}

.flex-col{
    flex-direction: column !important;
}

.flex-wrap{
    flex-wrap: wrap !important;
}

.flex-nowrap{
    flex-wrap: nowrap !important;
}

.items-start{
    align-items: flex-start !important;
}

.items-center{
    align-items: center !important;
}

.items-baseline{
    align-items: baseline !important;
}

.justify-start{
    justify-content: flex-start !important;
}

.justify-end{
    justify-content: flex-end !important;
}

.justify-center{
    justify-content: center !important;
}

.justify-between{
    justify-content: space-between !important;
}

.justify-around{
    justify-content: space-around !important;
}

.justify-evenly{
    justify-content: space-evenly !important;
}

.gap-1{
    gap: 0.25rem !important;
}

.gap-1\.5{
    gap: 0.375rem !important;
}

.gap-2{
    gap: 0.5rem !important;
}

.gap-3{
    gap: 0.75rem !important;
}

.gap-4{
    gap: 1rem !important;
}

.gap-5{
    gap: 1.25rem !important;
}

.gap-6{
    gap: 1.5rem !important;
}

.gap-8{
    gap: 2rem !important;
}

.gap-x-2{
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
}

.gap-x-4{
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
}

.gap-x-6{
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
}

.gap-y-2{
    row-gap: 0.5rem !important;
}

.gap-y-3{
    row-gap: 0.75rem !important;
}

.gap-y-4{
    row-gap: 1rem !important;
}

.gap-y-6{
    row-gap: 1.5rem !important;
}

.gap-y-8{
    row-gap: 2rem !important;
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-\[-2px\] > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.self-start{
    align-self: flex-start !important;
}

.overflow-auto{
    overflow: auto !important;
}

.overflow-hidden{
    overflow: hidden !important;
}

.overflow-scroll{
    overflow: scroll !important;
}

.overflow-x-hidden{
    overflow-x: hidden !important;
}

.overflow-x-scroll{
    overflow-x: scroll !important;
}

.truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.text-ellipsis{
    text-overflow: ellipsis !important;
}

.whitespace-nowrap{
    white-space: nowrap !important;
}

.rounded{
    border-radius: 0.25rem !important;
}

.rounded-full{
    border-radius: 9999px !important;
}

.rounded-lg{
    border-radius: 0.5rem !important;
}

.rounded-md{
    border-radius: 0.375rem !important;
}

.rounded-none{
    border-radius: 0px !important;
}

.rounded-sm{
    border-radius: 0.25rem !important;
}

.rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.border{
    border-width: 1px !important;
}

.border-2{
    border-width: 2px !important;
}

.border-px{
    border-width: 1px !important;
}

.border-b-\[1\.5px\]{
    border-bottom-width: 1.5px !important;
}

.border-b-px{
    border-bottom-width: 1px !important;
}

.border-l-\[1\.5px\]{
    border-left-width: 1.5px !important;
}

.border-t-2{
    border-top-width: 2px !important;
}

.border-dashed{
    border-style: dashed !important;
}

.border-none{
    border-style: none !important;
}

.border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgb(224 224 224 / var(--tw-border-opacity)) !important;
}

.border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgb(138 138 138 / var(--tw-border-opacity)) !important;
}

.border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgb(246 246 246 / var(--tw-border-opacity)) !important;
}

.border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgb(230 248 191 / var(--tw-border-opacity)) !important;
}

.border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgb(139 193 21 / var(--tw-border-opacity)) !important;
}

.border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgb(242 252 223 / var(--tw-border-opacity)) !important;
}

.border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgb(217 18 18 / var(--tw-border-opacity)) !important;
}

.border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgb(251 203 203 / var(--tw-border-opacity)) !important;
}

.border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgb(179 15 15 / var(--tw-border-opacity)) !important;
}

.border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgb(253 226 28 / var(--tw-border-opacity)) !important;
}

.border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgb(238 210 2 / var(--tw-border-opacity)) !important;
}

.border-b-gray-50{
    --tw-border-opacity: 1 !important;
    border-bottom-color: rgb(246 246 246 / var(--tw-border-opacity)) !important;
}

.border-t-gray-100{
    --tw-border-opacity: 1 !important;
    border-top-color: rgb(224 224 224 / var(--tw-border-opacity)) !important;
}

.border-t-medium{
    --tw-border-opacity: 1 !important;
    border-top-color: rgb(94 94 94 / var(--tw-border-opacity)) !important;
}

.bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-black\/0{
    background-color: rgb(0 0 0 / 0) !important;
}

.bg-black\/10{
    background-color: rgb(0 0 0 / 0.1) !important;
}

.bg-black\/100{
    background-color: rgb(0 0 0 / 1) !important;
}

.bg-black\/15{
    background-color: rgb(0 0 0 / 0.15) !important;
}

.bg-black\/20{
    background-color: rgb(0 0 0 / 0.2) !important;
}

.bg-black\/25{
    background-color: rgb(0 0 0 / 0.25) !important;
}

.bg-black\/30{
    background-color: rgb(0 0 0 / 0.3) !important;
}

.bg-black\/35{
    background-color: rgb(0 0 0 / 0.35) !important;
}

.bg-black\/40{
    background-color: rgb(0 0 0 / 0.4) !important;
}

.bg-black\/45{
    background-color: rgb(0 0 0 / 0.45) !important;
}

.bg-black\/5{
    background-color: rgb(0 0 0 / 0.05) !important;
}

.bg-black\/50{
    background-color: rgb(0 0 0 / 0.5) !important;
}

.bg-black\/55{
    background-color: rgb(0 0 0 / 0.55) !important;
}

.bg-black\/60{
    background-color: rgb(0 0 0 / 0.6) !important;
}

.bg-black\/65{
    background-color: rgb(0 0 0 / 0.65) !important;
}

.bg-black\/70{
    background-color: rgb(0 0 0 / 0.7) !important;
}

.bg-black\/75{
    background-color: rgb(0 0 0 / 0.75) !important;
}

.bg-black\/80{
    background-color: rgb(0 0 0 / 0.8) !important;
}

.bg-black\/85{
    background-color: rgb(0 0 0 / 0.85) !important;
}

.bg-black\/90{
    background-color: rgb(0 0 0 / 0.9) !important;
}

.bg-black\/95{
    background-color: rgb(0 0 0 / 0.95) !important;
}

.bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(184 221 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-100\/0{
    background-color: rgb(184 221 255 / 0) !important;
}

.bg-blue-100\/10{
    background-color: rgb(184 221 255 / 0.1) !important;
}

.bg-blue-100\/100{
    background-color: rgb(184 221 255 / 1) !important;
}

.bg-blue-100\/15{
    background-color: rgb(184 221 255 / 0.15) !important;
}

.bg-blue-100\/20{
    background-color: rgb(184 221 255 / 0.2) !important;
}

.bg-blue-100\/25{
    background-color: rgb(184 221 255 / 0.25) !important;
}

.bg-blue-100\/30{
    background-color: rgb(184 221 255 / 0.3) !important;
}

.bg-blue-100\/35{
    background-color: rgb(184 221 255 / 0.35) !important;
}

.bg-blue-100\/40{
    background-color: rgb(184 221 255 / 0.4) !important;
}

.bg-blue-100\/45{
    background-color: rgb(184 221 255 / 0.45) !important;
}

.bg-blue-100\/5{
    background-color: rgb(184 221 255 / 0.05) !important;
}

.bg-blue-100\/50{
    background-color: rgb(184 221 255 / 0.5) !important;
}

.bg-blue-100\/55{
    background-color: rgb(184 221 255 / 0.55) !important;
}

.bg-blue-100\/60{
    background-color: rgb(184 221 255 / 0.6) !important;
}

.bg-blue-100\/65{
    background-color: rgb(184 221 255 / 0.65) !important;
}

.bg-blue-100\/70{
    background-color: rgb(184 221 255 / 0.7) !important;
}

.bg-blue-100\/75{
    background-color: rgb(184 221 255 / 0.75) !important;
}

.bg-blue-100\/80{
    background-color: rgb(184 221 255 / 0.8) !important;
}

.bg-blue-100\/85{
    background-color: rgb(184 221 255 / 0.85) !important;
}

.bg-blue-100\/90{
    background-color: rgb(184 221 255 / 0.9) !important;
}

.bg-blue-100\/95{
    background-color: rgb(184 221 255 / 0.95) !important;
}

.bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(103 182 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-200\/0{
    background-color: rgb(103 182 255 / 0) !important;
}

.bg-blue-200\/10{
    background-color: rgb(103 182 255 / 0.1) !important;
}

.bg-blue-200\/100{
    background-color: rgb(103 182 255 / 1) !important;
}

.bg-blue-200\/15{
    background-color: rgb(103 182 255 / 0.15) !important;
}

.bg-blue-200\/20{
    background-color: rgb(103 182 255 / 0.2) !important;
}

.bg-blue-200\/25{
    background-color: rgb(103 182 255 / 0.25) !important;
}

.bg-blue-200\/30{
    background-color: rgb(103 182 255 / 0.3) !important;
}

.bg-blue-200\/35{
    background-color: rgb(103 182 255 / 0.35) !important;
}

.bg-blue-200\/40{
    background-color: rgb(103 182 255 / 0.4) !important;
}

.bg-blue-200\/45{
    background-color: rgb(103 182 255 / 0.45) !important;
}

.bg-blue-200\/5{
    background-color: rgb(103 182 255 / 0.05) !important;
}

.bg-blue-200\/50{
    background-color: rgb(103 182 255 / 0.5) !important;
}

.bg-blue-200\/55{
    background-color: rgb(103 182 255 / 0.55) !important;
}

.bg-blue-200\/60{
    background-color: rgb(103 182 255 / 0.6) !important;
}

.bg-blue-200\/65{
    background-color: rgb(103 182 255 / 0.65) !important;
}

.bg-blue-200\/70{
    background-color: rgb(103 182 255 / 0.7) !important;
}

.bg-blue-200\/75{
    background-color: rgb(103 182 255 / 0.75) !important;
}

.bg-blue-200\/80{
    background-color: rgb(103 182 255 / 0.8) !important;
}

.bg-blue-200\/85{
    background-color: rgb(103 182 255 / 0.85) !important;
}

.bg-blue-200\/90{
    background-color: rgb(103 182 255 / 0.9) !important;
}

.bg-blue-200\/95{
    background-color: rgb(103 182 255 / 0.95) !important;
}

.bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 116 224 / var(--tw-bg-opacity)) !important;
}

.bg-blue-300\/0{
    background-color: rgb(0 116 224 / 0) !important;
}

.bg-blue-300\/10{
    background-color: rgb(0 116 224 / 0.1) !important;
}

.bg-blue-300\/100{
    background-color: rgb(0 116 224 / 1) !important;
}

.bg-blue-300\/15{
    background-color: rgb(0 116 224 / 0.15) !important;
}

.bg-blue-300\/20{
    background-color: rgb(0 116 224 / 0.2) !important;
}

.bg-blue-300\/25{
    background-color: rgb(0 116 224 / 0.25) !important;
}

.bg-blue-300\/30{
    background-color: rgb(0 116 224 / 0.3) !important;
}

.bg-blue-300\/35{
    background-color: rgb(0 116 224 / 0.35) !important;
}

.bg-blue-300\/40{
    background-color: rgb(0 116 224 / 0.4) !important;
}

.bg-blue-300\/45{
    background-color: rgb(0 116 224 / 0.45) !important;
}

.bg-blue-300\/5{
    background-color: rgb(0 116 224 / 0.05) !important;
}

.bg-blue-300\/50{
    background-color: rgb(0 116 224 / 0.5) !important;
}

.bg-blue-300\/55{
    background-color: rgb(0 116 224 / 0.55) !important;
}

.bg-blue-300\/60{
    background-color: rgb(0 116 224 / 0.6) !important;
}

.bg-blue-300\/65{
    background-color: rgb(0 116 224 / 0.65) !important;
}

.bg-blue-300\/70{
    background-color: rgb(0 116 224 / 0.7) !important;
}

.bg-blue-300\/75{
    background-color: rgb(0 116 224 / 0.75) !important;
}

.bg-blue-300\/80{
    background-color: rgb(0 116 224 / 0.8) !important;
}

.bg-blue-300\/85{
    background-color: rgb(0 116 224 / 0.85) !important;
}

.bg-blue-300\/90{
    background-color: rgb(0 116 224 / 0.9) !important;
}

.bg-blue-300\/95{
    background-color: rgb(0 116 224 / 0.95) !important;
}

.bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 95 184 / var(--tw-bg-opacity)) !important;
}

.bg-blue-400\/0{
    background-color: rgb(0 95 184 / 0) !important;
}

.bg-blue-400\/10{
    background-color: rgb(0 95 184 / 0.1) !important;
}

.bg-blue-400\/100{
    background-color: rgb(0 95 184 / 1) !important;
}

.bg-blue-400\/15{
    background-color: rgb(0 95 184 / 0.15) !important;
}

.bg-blue-400\/20{
    background-color: rgb(0 95 184 / 0.2) !important;
}

.bg-blue-400\/25{
    background-color: rgb(0 95 184 / 0.25) !important;
}

.bg-blue-400\/30{
    background-color: rgb(0 95 184 / 0.3) !important;
}

.bg-blue-400\/35{
    background-color: rgb(0 95 184 / 0.35) !important;
}

.bg-blue-400\/40{
    background-color: rgb(0 95 184 / 0.4) !important;
}

.bg-blue-400\/45{
    background-color: rgb(0 95 184 / 0.45) !important;
}

.bg-blue-400\/5{
    background-color: rgb(0 95 184 / 0.05) !important;
}

.bg-blue-400\/50{
    background-color: rgb(0 95 184 / 0.5) !important;
}

.bg-blue-400\/55{
    background-color: rgb(0 95 184 / 0.55) !important;
}

.bg-blue-400\/60{
    background-color: rgb(0 95 184 / 0.6) !important;
}

.bg-blue-400\/65{
    background-color: rgb(0 95 184 / 0.65) !important;
}

.bg-blue-400\/70{
    background-color: rgb(0 95 184 / 0.7) !important;
}

.bg-blue-400\/75{
    background-color: rgb(0 95 184 / 0.75) !important;
}

.bg-blue-400\/80{
    background-color: rgb(0 95 184 / 0.8) !important;
}

.bg-blue-400\/85{
    background-color: rgb(0 95 184 / 0.85) !important;
}

.bg-blue-400\/90{
    background-color: rgb(0 95 184 / 0.9) !important;
}

.bg-blue-400\/95{
    background-color: rgb(0 95 184 / 0.95) !important;
}

.bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(225 241 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-50\/0{
    background-color: rgb(225 241 255 / 0) !important;
}

.bg-blue-50\/10{
    background-color: rgb(225 241 255 / 0.1) !important;
}

.bg-blue-50\/100{
    background-color: rgb(225 241 255 / 1) !important;
}

.bg-blue-50\/15{
    background-color: rgb(225 241 255 / 0.15) !important;
}

.bg-blue-50\/20{
    background-color: rgb(225 241 255 / 0.2) !important;
}

.bg-blue-50\/25{
    background-color: rgb(225 241 255 / 0.25) !important;
}

.bg-blue-50\/30{
    background-color: rgb(225 241 255 / 0.3) !important;
}

.bg-blue-50\/35{
    background-color: rgb(225 241 255 / 0.35) !important;
}

.bg-blue-50\/40{
    background-color: rgb(225 241 255 / 0.4) !important;
}

.bg-blue-50\/45{
    background-color: rgb(225 241 255 / 0.45) !important;
}

.bg-blue-50\/5{
    background-color: rgb(225 241 255 / 0.05) !important;
}

.bg-blue-50\/50{
    background-color: rgb(225 241 255 / 0.5) !important;
}

.bg-blue-50\/55{
    background-color: rgb(225 241 255 / 0.55) !important;
}

.bg-blue-50\/60{
    background-color: rgb(225 241 255 / 0.6) !important;
}

.bg-blue-50\/65{
    background-color: rgb(225 241 255 / 0.65) !important;
}

.bg-blue-50\/70{
    background-color: rgb(225 241 255 / 0.7) !important;
}

.bg-blue-50\/75{
    background-color: rgb(225 241 255 / 0.75) !important;
}

.bg-blue-50\/80{
    background-color: rgb(225 241 255 / 0.8) !important;
}

.bg-blue-50\/85{
    background-color: rgb(225 241 255 / 0.85) !important;
}

.bg-blue-50\/90{
    background-color: rgb(225 241 255 / 0.9) !important;
}

.bg-blue-50\/95{
    background-color: rgb(225 241 255 / 0.95) !important;
}

.bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 59 113 / var(--tw-bg-opacity)) !important;
}

.bg-blue-500\/0{
    background-color: rgb(0 59 113 / 0) !important;
}

.bg-blue-500\/10{
    background-color: rgb(0 59 113 / 0.1) !important;
}

.bg-blue-500\/100{
    background-color: rgb(0 59 113 / 1) !important;
}

.bg-blue-500\/15{
    background-color: rgb(0 59 113 / 0.15) !important;
}

.bg-blue-500\/20{
    background-color: rgb(0 59 113 / 0.2) !important;
}

.bg-blue-500\/25{
    background-color: rgb(0 59 113 / 0.25) !important;
}

.bg-blue-500\/30{
    background-color: rgb(0 59 113 / 0.3) !important;
}

.bg-blue-500\/35{
    background-color: rgb(0 59 113 / 0.35) !important;
}

.bg-blue-500\/40{
    background-color: rgb(0 59 113 / 0.4) !important;
}

.bg-blue-500\/45{
    background-color: rgb(0 59 113 / 0.45) !important;
}

.bg-blue-500\/5{
    background-color: rgb(0 59 113 / 0.05) !important;
}

.bg-blue-500\/50{
    background-color: rgb(0 59 113 / 0.5) !important;
}

.bg-blue-500\/55{
    background-color: rgb(0 59 113 / 0.55) !important;
}

.bg-blue-500\/60{
    background-color: rgb(0 59 113 / 0.6) !important;
}

.bg-blue-500\/65{
    background-color: rgb(0 59 113 / 0.65) !important;
}

.bg-blue-500\/70{
    background-color: rgb(0 59 113 / 0.7) !important;
}

.bg-blue-500\/75{
    background-color: rgb(0 59 113 / 0.75) !important;
}

.bg-blue-500\/80{
    background-color: rgb(0 59 113 / 0.8) !important;
}

.bg-blue-500\/85{
    background-color: rgb(0 59 113 / 0.85) !important;
}

.bg-blue-500\/90{
    background-color: rgb(0 59 113 / 0.9) !important;
}

.bg-blue-500\/95{
    background-color: rgb(0 59 113 / 0.95) !important;
}

.bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 51 98 / var(--tw-bg-opacity)) !important;
}

.bg-blue-600\/0{
    background-color: rgb(0 51 98 / 0) !important;
}

.bg-blue-600\/10{
    background-color: rgb(0 51 98 / 0.1) !important;
}

.bg-blue-600\/100{
    background-color: rgb(0 51 98 / 1) !important;
}

.bg-blue-600\/15{
    background-color: rgb(0 51 98 / 0.15) !important;
}

.bg-blue-600\/20{
    background-color: rgb(0 51 98 / 0.2) !important;
}

.bg-blue-600\/25{
    background-color: rgb(0 51 98 / 0.25) !important;
}

.bg-blue-600\/30{
    background-color: rgb(0 51 98 / 0.3) !important;
}

.bg-blue-600\/35{
    background-color: rgb(0 51 98 / 0.35) !important;
}

.bg-blue-600\/40{
    background-color: rgb(0 51 98 / 0.4) !important;
}

.bg-blue-600\/45{
    background-color: rgb(0 51 98 / 0.45) !important;
}

.bg-blue-600\/5{
    background-color: rgb(0 51 98 / 0.05) !important;
}

.bg-blue-600\/50{
    background-color: rgb(0 51 98 / 0.5) !important;
}

.bg-blue-600\/55{
    background-color: rgb(0 51 98 / 0.55) !important;
}

.bg-blue-600\/60{
    background-color: rgb(0 51 98 / 0.6) !important;
}

.bg-blue-600\/65{
    background-color: rgb(0 51 98 / 0.65) !important;
}

.bg-blue-600\/70{
    background-color: rgb(0 51 98 / 0.7) !important;
}

.bg-blue-600\/75{
    background-color: rgb(0 51 98 / 0.75) !important;
}

.bg-blue-600\/80{
    background-color: rgb(0 51 98 / 0.8) !important;
}

.bg-blue-600\/85{
    background-color: rgb(0 51 98 / 0.85) !important;
}

.bg-blue-600\/90{
    background-color: rgb(0 51 98 / 0.9) !important;
}

.bg-blue-600\/95{
    background-color: rgb(0 51 98 / 0.95) !important;
}

.bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 43 82 / var(--tw-bg-opacity)) !important;
}

.bg-blue-700\/0{
    background-color: rgb(0 43 82 / 0) !important;
}

.bg-blue-700\/10{
    background-color: rgb(0 43 82 / 0.1) !important;
}

.bg-blue-700\/100{
    background-color: rgb(0 43 82 / 1) !important;
}

.bg-blue-700\/15{
    background-color: rgb(0 43 82 / 0.15) !important;
}

.bg-blue-700\/20{
    background-color: rgb(0 43 82 / 0.2) !important;
}

.bg-blue-700\/25{
    background-color: rgb(0 43 82 / 0.25) !important;
}

.bg-blue-700\/30{
    background-color: rgb(0 43 82 / 0.3) !important;
}

.bg-blue-700\/35{
    background-color: rgb(0 43 82 / 0.35) !important;
}

.bg-blue-700\/40{
    background-color: rgb(0 43 82 / 0.4) !important;
}

.bg-blue-700\/45{
    background-color: rgb(0 43 82 / 0.45) !important;
}

.bg-blue-700\/5{
    background-color: rgb(0 43 82 / 0.05) !important;
}

.bg-blue-700\/50{
    background-color: rgb(0 43 82 / 0.5) !important;
}

.bg-blue-700\/55{
    background-color: rgb(0 43 82 / 0.55) !important;
}

.bg-blue-700\/60{
    background-color: rgb(0 43 82 / 0.6) !important;
}

.bg-blue-700\/65{
    background-color: rgb(0 43 82 / 0.65) !important;
}

.bg-blue-700\/70{
    background-color: rgb(0 43 82 / 0.7) !important;
}

.bg-blue-700\/75{
    background-color: rgb(0 43 82 / 0.75) !important;
}

.bg-blue-700\/80{
    background-color: rgb(0 43 82 / 0.8) !important;
}

.bg-blue-700\/85{
    background-color: rgb(0 43 82 / 0.85) !important;
}

.bg-blue-700\/90{
    background-color: rgb(0 43 82 / 0.9) !important;
}

.bg-blue-700\/95{
    background-color: rgb(0 43 82 / 0.95) !important;
}

.bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 35 67 / var(--tw-bg-opacity)) !important;
}

.bg-blue-800\/0{
    background-color: rgb(0 35 67 / 0) !important;
}

.bg-blue-800\/10{
    background-color: rgb(0 35 67 / 0.1) !important;
}

.bg-blue-800\/100{
    background-color: rgb(0 35 67 / 1) !important;
}

.bg-blue-800\/15{
    background-color: rgb(0 35 67 / 0.15) !important;
}

.bg-blue-800\/20{
    background-color: rgb(0 35 67 / 0.2) !important;
}

.bg-blue-800\/25{
    background-color: rgb(0 35 67 / 0.25) !important;
}

.bg-blue-800\/30{
    background-color: rgb(0 35 67 / 0.3) !important;
}

.bg-blue-800\/35{
    background-color: rgb(0 35 67 / 0.35) !important;
}

.bg-blue-800\/40{
    background-color: rgb(0 35 67 / 0.4) !important;
}

.bg-blue-800\/45{
    background-color: rgb(0 35 67 / 0.45) !important;
}

.bg-blue-800\/5{
    background-color: rgb(0 35 67 / 0.05) !important;
}

.bg-blue-800\/50{
    background-color: rgb(0 35 67 / 0.5) !important;
}

.bg-blue-800\/55{
    background-color: rgb(0 35 67 / 0.55) !important;
}

.bg-blue-800\/60{
    background-color: rgb(0 35 67 / 0.6) !important;
}

.bg-blue-800\/65{
    background-color: rgb(0 35 67 / 0.65) !important;
}

.bg-blue-800\/70{
    background-color: rgb(0 35 67 / 0.7) !important;
}

.bg-blue-800\/75{
    background-color: rgb(0 35 67 / 0.75) !important;
}

.bg-blue-800\/80{
    background-color: rgb(0 35 67 / 0.8) !important;
}

.bg-blue-800\/85{
    background-color: rgb(0 35 67 / 0.85) !important;
}

.bg-blue-800\/90{
    background-color: rgb(0 35 67 / 0.9) !important;
}

.bg-blue-800\/95{
    background-color: rgb(0 35 67 / 0.95) !important;
}

.bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 27 52 / var(--tw-bg-opacity)) !important;
}

.bg-blue-900\/0{
    background-color: rgb(0 27 52 / 0) !important;
}

.bg-blue-900\/10{
    background-color: rgb(0 27 52 / 0.1) !important;
}

.bg-blue-900\/100{
    background-color: rgb(0 27 52 / 1) !important;
}

.bg-blue-900\/15{
    background-color: rgb(0 27 52 / 0.15) !important;
}

.bg-blue-900\/20{
    background-color: rgb(0 27 52 / 0.2) !important;
}

.bg-blue-900\/25{
    background-color: rgb(0 27 52 / 0.25) !important;
}

.bg-blue-900\/30{
    background-color: rgb(0 27 52 / 0.3) !important;
}

.bg-blue-900\/35{
    background-color: rgb(0 27 52 / 0.35) !important;
}

.bg-blue-900\/40{
    background-color: rgb(0 27 52 / 0.4) !important;
}

.bg-blue-900\/45{
    background-color: rgb(0 27 52 / 0.45) !important;
}

.bg-blue-900\/5{
    background-color: rgb(0 27 52 / 0.05) !important;
}

.bg-blue-900\/50{
    background-color: rgb(0 27 52 / 0.5) !important;
}

.bg-blue-900\/55{
    background-color: rgb(0 27 52 / 0.55) !important;
}

.bg-blue-900\/60{
    background-color: rgb(0 27 52 / 0.6) !important;
}

.bg-blue-900\/65{
    background-color: rgb(0 27 52 / 0.65) !important;
}

.bg-blue-900\/70{
    background-color: rgb(0 27 52 / 0.7) !important;
}

.bg-blue-900\/75{
    background-color: rgb(0 27 52 / 0.75) !important;
}

.bg-blue-900\/80{
    background-color: rgb(0 27 52 / 0.8) !important;
}

.bg-blue-900\/85{
    background-color: rgb(0 27 52 / 0.85) !important;
}

.bg-blue-900\/90{
    background-color: rgb(0 27 52 / 0.9) !important;
}

.bg-blue-900\/95{
    background-color: rgb(0 27 52 / 0.95) !important;
}

.bg-brown-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(233 223 179 / var(--tw-bg-opacity)) !important;
}

.bg-brown-100\/0{
    background-color: rgb(233 223 179 / 0) !important;
}

.bg-brown-100\/10{
    background-color: rgb(233 223 179 / 0.1) !important;
}

.bg-brown-100\/100{
    background-color: rgb(233 223 179 / 1) !important;
}

.bg-brown-100\/15{
    background-color: rgb(233 223 179 / 0.15) !important;
}

.bg-brown-100\/20{
    background-color: rgb(233 223 179 / 0.2) !important;
}

.bg-brown-100\/25{
    background-color: rgb(233 223 179 / 0.25) !important;
}

.bg-brown-100\/30{
    background-color: rgb(233 223 179 / 0.3) !important;
}

.bg-brown-100\/35{
    background-color: rgb(233 223 179 / 0.35) !important;
}

.bg-brown-100\/40{
    background-color: rgb(233 223 179 / 0.4) !important;
}

.bg-brown-100\/45{
    background-color: rgb(233 223 179 / 0.45) !important;
}

.bg-brown-100\/5{
    background-color: rgb(233 223 179 / 0.05) !important;
}

.bg-brown-100\/50{
    background-color: rgb(233 223 179 / 0.5) !important;
}

.bg-brown-100\/55{
    background-color: rgb(233 223 179 / 0.55) !important;
}

.bg-brown-100\/60{
    background-color: rgb(233 223 179 / 0.6) !important;
}

.bg-brown-100\/65{
    background-color: rgb(233 223 179 / 0.65) !important;
}

.bg-brown-100\/70{
    background-color: rgb(233 223 179 / 0.7) !important;
}

.bg-brown-100\/75{
    background-color: rgb(233 223 179 / 0.75) !important;
}

.bg-brown-100\/80{
    background-color: rgb(233 223 179 / 0.8) !important;
}

.bg-brown-100\/85{
    background-color: rgb(233 223 179 / 0.85) !important;
}

.bg-brown-100\/90{
    background-color: rgb(233 223 179 / 0.9) !important;
}

.bg-brown-100\/95{
    background-color: rgb(233 223 179 / 0.95) !important;
}

.bg-brown-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(226 210 155 / var(--tw-bg-opacity)) !important;
}

.bg-brown-200\/0{
    background-color: rgb(226 210 155 / 0) !important;
}

.bg-brown-200\/10{
    background-color: rgb(226 210 155 / 0.1) !important;
}

.bg-brown-200\/100{
    background-color: rgb(226 210 155 / 1) !important;
}

.bg-brown-200\/15{
    background-color: rgb(226 210 155 / 0.15) !important;
}

.bg-brown-200\/20{
    background-color: rgb(226 210 155 / 0.2) !important;
}

.bg-brown-200\/25{
    background-color: rgb(226 210 155 / 0.25) !important;
}

.bg-brown-200\/30{
    background-color: rgb(226 210 155 / 0.3) !important;
}

.bg-brown-200\/35{
    background-color: rgb(226 210 155 / 0.35) !important;
}

.bg-brown-200\/40{
    background-color: rgb(226 210 155 / 0.4) !important;
}

.bg-brown-200\/45{
    background-color: rgb(226 210 155 / 0.45) !important;
}

.bg-brown-200\/5{
    background-color: rgb(226 210 155 / 0.05) !important;
}

.bg-brown-200\/50{
    background-color: rgb(226 210 155 / 0.5) !important;
}

.bg-brown-200\/55{
    background-color: rgb(226 210 155 / 0.55) !important;
}

.bg-brown-200\/60{
    background-color: rgb(226 210 155 / 0.6) !important;
}

.bg-brown-200\/65{
    background-color: rgb(226 210 155 / 0.65) !important;
}

.bg-brown-200\/70{
    background-color: rgb(226 210 155 / 0.7) !important;
}

.bg-brown-200\/75{
    background-color: rgb(226 210 155 / 0.75) !important;
}

.bg-brown-200\/80{
    background-color: rgb(226 210 155 / 0.8) !important;
}

.bg-brown-200\/85{
    background-color: rgb(226 210 155 / 0.85) !important;
}

.bg-brown-200\/90{
    background-color: rgb(226 210 155 / 0.9) !important;
}

.bg-brown-200\/95{
    background-color: rgb(226 210 155 / 0.95) !important;
}

.bg-brown-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(220 195 132 / var(--tw-bg-opacity)) !important;
}

.bg-brown-300\/0{
    background-color: rgb(220 195 132 / 0) !important;
}

.bg-brown-300\/10{
    background-color: rgb(220 195 132 / 0.1) !important;
}

.bg-brown-300\/100{
    background-color: rgb(220 195 132 / 1) !important;
}

.bg-brown-300\/15{
    background-color: rgb(220 195 132 / 0.15) !important;
}

.bg-brown-300\/20{
    background-color: rgb(220 195 132 / 0.2) !important;
}

.bg-brown-300\/25{
    background-color: rgb(220 195 132 / 0.25) !important;
}

.bg-brown-300\/30{
    background-color: rgb(220 195 132 / 0.3) !important;
}

.bg-brown-300\/35{
    background-color: rgb(220 195 132 / 0.35) !important;
}

.bg-brown-300\/40{
    background-color: rgb(220 195 132 / 0.4) !important;
}

.bg-brown-300\/45{
    background-color: rgb(220 195 132 / 0.45) !important;
}

.bg-brown-300\/5{
    background-color: rgb(220 195 132 / 0.05) !important;
}

.bg-brown-300\/50{
    background-color: rgb(220 195 132 / 0.5) !important;
}

.bg-brown-300\/55{
    background-color: rgb(220 195 132 / 0.55) !important;
}

.bg-brown-300\/60{
    background-color: rgb(220 195 132 / 0.6) !important;
}

.bg-brown-300\/65{
    background-color: rgb(220 195 132 / 0.65) !important;
}

.bg-brown-300\/70{
    background-color: rgb(220 195 132 / 0.7) !important;
}

.bg-brown-300\/75{
    background-color: rgb(220 195 132 / 0.75) !important;
}

.bg-brown-300\/80{
    background-color: rgb(220 195 132 / 0.8) !important;
}

.bg-brown-300\/85{
    background-color: rgb(220 195 132 / 0.85) !important;
}

.bg-brown-300\/90{
    background-color: rgb(220 195 132 / 0.9) !important;
}

.bg-brown-300\/95{
    background-color: rgb(220 195 132 / 0.95) !important;
}

.bg-brown-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(213 179 108 / var(--tw-bg-opacity)) !important;
}

.bg-brown-400\/0{
    background-color: rgb(213 179 108 / 0) !important;
}

.bg-brown-400\/10{
    background-color: rgb(213 179 108 / 0.1) !important;
}

.bg-brown-400\/100{
    background-color: rgb(213 179 108 / 1) !important;
}

.bg-brown-400\/15{
    background-color: rgb(213 179 108 / 0.15) !important;
}

.bg-brown-400\/20{
    background-color: rgb(213 179 108 / 0.2) !important;
}

.bg-brown-400\/25{
    background-color: rgb(213 179 108 / 0.25) !important;
}

.bg-brown-400\/30{
    background-color: rgb(213 179 108 / 0.3) !important;
}

.bg-brown-400\/35{
    background-color: rgb(213 179 108 / 0.35) !important;
}

.bg-brown-400\/40{
    background-color: rgb(213 179 108 / 0.4) !important;
}

.bg-brown-400\/45{
    background-color: rgb(213 179 108 / 0.45) !important;
}

.bg-brown-400\/5{
    background-color: rgb(213 179 108 / 0.05) !important;
}

.bg-brown-400\/50{
    background-color: rgb(213 179 108 / 0.5) !important;
}

.bg-brown-400\/55{
    background-color: rgb(213 179 108 / 0.55) !important;
}

.bg-brown-400\/60{
    background-color: rgb(213 179 108 / 0.6) !important;
}

.bg-brown-400\/65{
    background-color: rgb(213 179 108 / 0.65) !important;
}

.bg-brown-400\/70{
    background-color: rgb(213 179 108 / 0.7) !important;
}

.bg-brown-400\/75{
    background-color: rgb(213 179 108 / 0.75) !important;
}

.bg-brown-400\/80{
    background-color: rgb(213 179 108 / 0.8) !important;
}

.bg-brown-400\/85{
    background-color: rgb(213 179 108 / 0.85) !important;
}

.bg-brown-400\/90{
    background-color: rgb(213 179 108 / 0.9) !important;
}

.bg-brown-400\/95{
    background-color: rgb(213 179 108 / 0.95) !important;
}

.bg-brown-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(237 229 191 / var(--tw-bg-opacity)) !important;
}

.bg-brown-50\/0{
    background-color: rgb(237 229 191 / 0) !important;
}

.bg-brown-50\/10{
    background-color: rgb(237 229 191 / 0.1) !important;
}

.bg-brown-50\/100{
    background-color: rgb(237 229 191 / 1) !important;
}

.bg-brown-50\/15{
    background-color: rgb(237 229 191 / 0.15) !important;
}

.bg-brown-50\/20{
    background-color: rgb(237 229 191 / 0.2) !important;
}

.bg-brown-50\/25{
    background-color: rgb(237 229 191 / 0.25) !important;
}

.bg-brown-50\/30{
    background-color: rgb(237 229 191 / 0.3) !important;
}

.bg-brown-50\/35{
    background-color: rgb(237 229 191 / 0.35) !important;
}

.bg-brown-50\/40{
    background-color: rgb(237 229 191 / 0.4) !important;
}

.bg-brown-50\/45{
    background-color: rgb(237 229 191 / 0.45) !important;
}

.bg-brown-50\/5{
    background-color: rgb(237 229 191 / 0.05) !important;
}

.bg-brown-50\/50{
    background-color: rgb(237 229 191 / 0.5) !important;
}

.bg-brown-50\/55{
    background-color: rgb(237 229 191 / 0.55) !important;
}

.bg-brown-50\/60{
    background-color: rgb(237 229 191 / 0.6) !important;
}

.bg-brown-50\/65{
    background-color: rgb(237 229 191 / 0.65) !important;
}

.bg-brown-50\/70{
    background-color: rgb(237 229 191 / 0.7) !important;
}

.bg-brown-50\/75{
    background-color: rgb(237 229 191 / 0.75) !important;
}

.bg-brown-50\/80{
    background-color: rgb(237 229 191 / 0.8) !important;
}

.bg-brown-50\/85{
    background-color: rgb(237 229 191 / 0.85) !important;
}

.bg-brown-50\/90{
    background-color: rgb(237 229 191 / 0.9) !important;
}

.bg-brown-50\/95{
    background-color: rgb(237 229 191 / 0.95) !important;
}

.bg-brown-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(206 161 84 / var(--tw-bg-opacity)) !important;
}

.bg-brown-500\/0{
    background-color: rgb(206 161 84 / 0) !important;
}

.bg-brown-500\/10{
    background-color: rgb(206 161 84 / 0.1) !important;
}

.bg-brown-500\/100{
    background-color: rgb(206 161 84 / 1) !important;
}

.bg-brown-500\/15{
    background-color: rgb(206 161 84 / 0.15) !important;
}

.bg-brown-500\/20{
    background-color: rgb(206 161 84 / 0.2) !important;
}

.bg-brown-500\/25{
    background-color: rgb(206 161 84 / 0.25) !important;
}

.bg-brown-500\/30{
    background-color: rgb(206 161 84 / 0.3) !important;
}

.bg-brown-500\/35{
    background-color: rgb(206 161 84 / 0.35) !important;
}

.bg-brown-500\/40{
    background-color: rgb(206 161 84 / 0.4) !important;
}

.bg-brown-500\/45{
    background-color: rgb(206 161 84 / 0.45) !important;
}

.bg-brown-500\/5{
    background-color: rgb(206 161 84 / 0.05) !important;
}

.bg-brown-500\/50{
    background-color: rgb(206 161 84 / 0.5) !important;
}

.bg-brown-500\/55{
    background-color: rgb(206 161 84 / 0.55) !important;
}

.bg-brown-500\/60{
    background-color: rgb(206 161 84 / 0.6) !important;
}

.bg-brown-500\/65{
    background-color: rgb(206 161 84 / 0.65) !important;
}

.bg-brown-500\/70{
    background-color: rgb(206 161 84 / 0.7) !important;
}

.bg-brown-500\/75{
    background-color: rgb(206 161 84 / 0.75) !important;
}

.bg-brown-500\/80{
    background-color: rgb(206 161 84 / 0.8) !important;
}

.bg-brown-500\/85{
    background-color: rgb(206 161 84 / 0.85) !important;
}

.bg-brown-500\/90{
    background-color: rgb(206 161 84 / 0.9) !important;
}

.bg-brown-500\/95{
    background-color: rgb(206 161 84 / 0.95) !important;
}

.bg-brown-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(154 108 44 / var(--tw-bg-opacity)) !important;
}

.bg-brown-600\/0{
    background-color: rgb(154 108 44 / 0) !important;
}

.bg-brown-600\/10{
    background-color: rgb(154 108 44 / 0.1) !important;
}

.bg-brown-600\/100{
    background-color: rgb(154 108 44 / 1) !important;
}

.bg-brown-600\/15{
    background-color: rgb(154 108 44 / 0.15) !important;
}

.bg-brown-600\/20{
    background-color: rgb(154 108 44 / 0.2) !important;
}

.bg-brown-600\/25{
    background-color: rgb(154 108 44 / 0.25) !important;
}

.bg-brown-600\/30{
    background-color: rgb(154 108 44 / 0.3) !important;
}

.bg-brown-600\/35{
    background-color: rgb(154 108 44 / 0.35) !important;
}

.bg-brown-600\/40{
    background-color: rgb(154 108 44 / 0.4) !important;
}

.bg-brown-600\/45{
    background-color: rgb(154 108 44 / 0.45) !important;
}

.bg-brown-600\/5{
    background-color: rgb(154 108 44 / 0.05) !important;
}

.bg-brown-600\/50{
    background-color: rgb(154 108 44 / 0.5) !important;
}

.bg-brown-600\/55{
    background-color: rgb(154 108 44 / 0.55) !important;
}

.bg-brown-600\/60{
    background-color: rgb(154 108 44 / 0.6) !important;
}

.bg-brown-600\/65{
    background-color: rgb(154 108 44 / 0.65) !important;
}

.bg-brown-600\/70{
    background-color: rgb(154 108 44 / 0.7) !important;
}

.bg-brown-600\/75{
    background-color: rgb(154 108 44 / 0.75) !important;
}

.bg-brown-600\/80{
    background-color: rgb(154 108 44 / 0.8) !important;
}

.bg-brown-600\/85{
    background-color: rgb(154 108 44 / 0.85) !important;
}

.bg-brown-600\/90{
    background-color: rgb(154 108 44 / 0.9) !important;
}

.bg-brown-600\/95{
    background-color: rgb(154 108 44 / 0.95) !important;
}

.bg-brown-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(119 82 34 / var(--tw-bg-opacity)) !important;
}

.bg-brown-700\/0{
    background-color: rgb(119 82 34 / 0) !important;
}

.bg-brown-700\/10{
    background-color: rgb(119 82 34 / 0.1) !important;
}

.bg-brown-700\/100{
    background-color: rgb(119 82 34 / 1) !important;
}

.bg-brown-700\/15{
    background-color: rgb(119 82 34 / 0.15) !important;
}

.bg-brown-700\/20{
    background-color: rgb(119 82 34 / 0.2) !important;
}

.bg-brown-700\/25{
    background-color: rgb(119 82 34 / 0.25) !important;
}

.bg-brown-700\/30{
    background-color: rgb(119 82 34 / 0.3) !important;
}

.bg-brown-700\/35{
    background-color: rgb(119 82 34 / 0.35) !important;
}

.bg-brown-700\/40{
    background-color: rgb(119 82 34 / 0.4) !important;
}

.bg-brown-700\/45{
    background-color: rgb(119 82 34 / 0.45) !important;
}

.bg-brown-700\/5{
    background-color: rgb(119 82 34 / 0.05) !important;
}

.bg-brown-700\/50{
    background-color: rgb(119 82 34 / 0.5) !important;
}

.bg-brown-700\/55{
    background-color: rgb(119 82 34 / 0.55) !important;
}

.bg-brown-700\/60{
    background-color: rgb(119 82 34 / 0.6) !important;
}

.bg-brown-700\/65{
    background-color: rgb(119 82 34 / 0.65) !important;
}

.bg-brown-700\/70{
    background-color: rgb(119 82 34 / 0.7) !important;
}

.bg-brown-700\/75{
    background-color: rgb(119 82 34 / 0.75) !important;
}

.bg-brown-700\/80{
    background-color: rgb(119 82 34 / 0.8) !important;
}

.bg-brown-700\/85{
    background-color: rgb(119 82 34 / 0.85) !important;
}

.bg-brown-700\/90{
    background-color: rgb(119 82 34 / 0.9) !important;
}

.bg-brown-700\/95{
    background-color: rgb(119 82 34 / 0.95) !important;
}

.bg-brown-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(56 37 20 / var(--tw-bg-opacity)) !important;
}

.bg-brown-800\/0{
    background-color: rgb(56 37 20 / 0) !important;
}

.bg-brown-800\/10{
    background-color: rgb(56 37 20 / 0.1) !important;
}

.bg-brown-800\/100{
    background-color: rgb(56 37 20 / 1) !important;
}

.bg-brown-800\/15{
    background-color: rgb(56 37 20 / 0.15) !important;
}

.bg-brown-800\/20{
    background-color: rgb(56 37 20 / 0.2) !important;
}

.bg-brown-800\/25{
    background-color: rgb(56 37 20 / 0.25) !important;
}

.bg-brown-800\/30{
    background-color: rgb(56 37 20 / 0.3) !important;
}

.bg-brown-800\/35{
    background-color: rgb(56 37 20 / 0.35) !important;
}

.bg-brown-800\/40{
    background-color: rgb(56 37 20 / 0.4) !important;
}

.bg-brown-800\/45{
    background-color: rgb(56 37 20 / 0.45) !important;
}

.bg-brown-800\/5{
    background-color: rgb(56 37 20 / 0.05) !important;
}

.bg-brown-800\/50{
    background-color: rgb(56 37 20 / 0.5) !important;
}

.bg-brown-800\/55{
    background-color: rgb(56 37 20 / 0.55) !important;
}

.bg-brown-800\/60{
    background-color: rgb(56 37 20 / 0.6) !important;
}

.bg-brown-800\/65{
    background-color: rgb(56 37 20 / 0.65) !important;
}

.bg-brown-800\/70{
    background-color: rgb(56 37 20 / 0.7) !important;
}

.bg-brown-800\/75{
    background-color: rgb(56 37 20 / 0.75) !important;
}

.bg-brown-800\/80{
    background-color: rgb(56 37 20 / 0.8) !important;
}

.bg-brown-800\/85{
    background-color: rgb(56 37 20 / 0.85) !important;
}

.bg-brown-800\/90{
    background-color: rgb(56 37 20 / 0.9) !important;
}

.bg-brown-800\/95{
    background-color: rgb(56 37 20 / 0.95) !important;
}

.bg-brown-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(11 5 3 / var(--tw-bg-opacity)) !important;
}

.bg-brown-900\/0{
    background-color: rgb(11 5 3 / 0) !important;
}

.bg-brown-900\/10{
    background-color: rgb(11 5 3 / 0.1) !important;
}

.bg-brown-900\/100{
    background-color: rgb(11 5 3 / 1) !important;
}

.bg-brown-900\/15{
    background-color: rgb(11 5 3 / 0.15) !important;
}

.bg-brown-900\/20{
    background-color: rgb(11 5 3 / 0.2) !important;
}

.bg-brown-900\/25{
    background-color: rgb(11 5 3 / 0.25) !important;
}

.bg-brown-900\/30{
    background-color: rgb(11 5 3 / 0.3) !important;
}

.bg-brown-900\/35{
    background-color: rgb(11 5 3 / 0.35) !important;
}

.bg-brown-900\/40{
    background-color: rgb(11 5 3 / 0.4) !important;
}

.bg-brown-900\/45{
    background-color: rgb(11 5 3 / 0.45) !important;
}

.bg-brown-900\/5{
    background-color: rgb(11 5 3 / 0.05) !important;
}

.bg-brown-900\/50{
    background-color: rgb(11 5 3 / 0.5) !important;
}

.bg-brown-900\/55{
    background-color: rgb(11 5 3 / 0.55) !important;
}

.bg-brown-900\/60{
    background-color: rgb(11 5 3 / 0.6) !important;
}

.bg-brown-900\/65{
    background-color: rgb(11 5 3 / 0.65) !important;
}

.bg-brown-900\/70{
    background-color: rgb(11 5 3 / 0.7) !important;
}

.bg-brown-900\/75{
    background-color: rgb(11 5 3 / 0.75) !important;
}

.bg-brown-900\/80{
    background-color: rgb(11 5 3 / 0.8) !important;
}

.bg-brown-900\/85{
    background-color: rgb(11 5 3 / 0.85) !important;
}

.bg-brown-900\/90{
    background-color: rgb(11 5 3 / 0.9) !important;
}

.bg-brown-900\/95{
    background-color: rgb(11 5 3 / 0.95) !important;
}

.bg-cyan-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(193 241 249 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-100\/0{
    background-color: rgb(193 241 249 / 0) !important;
}

.bg-cyan-100\/10{
    background-color: rgb(193 241 249 / 0.1) !important;
}

.bg-cyan-100\/100{
    background-color: rgb(193 241 249 / 1) !important;
}

.bg-cyan-100\/15{
    background-color: rgb(193 241 249 / 0.15) !important;
}

.bg-cyan-100\/20{
    background-color: rgb(193 241 249 / 0.2) !important;
}

.bg-cyan-100\/25{
    background-color: rgb(193 241 249 / 0.25) !important;
}

.bg-cyan-100\/30{
    background-color: rgb(193 241 249 / 0.3) !important;
}

.bg-cyan-100\/35{
    background-color: rgb(193 241 249 / 0.35) !important;
}

.bg-cyan-100\/40{
    background-color: rgb(193 241 249 / 0.4) !important;
}

.bg-cyan-100\/45{
    background-color: rgb(193 241 249 / 0.45) !important;
}

.bg-cyan-100\/5{
    background-color: rgb(193 241 249 / 0.05) !important;
}

.bg-cyan-100\/50{
    background-color: rgb(193 241 249 / 0.5) !important;
}

.bg-cyan-100\/55{
    background-color: rgb(193 241 249 / 0.55) !important;
}

.bg-cyan-100\/60{
    background-color: rgb(193 241 249 / 0.6) !important;
}

.bg-cyan-100\/65{
    background-color: rgb(193 241 249 / 0.65) !important;
}

.bg-cyan-100\/70{
    background-color: rgb(193 241 249 / 0.7) !important;
}

.bg-cyan-100\/75{
    background-color: rgb(193 241 249 / 0.75) !important;
}

.bg-cyan-100\/80{
    background-color: rgb(193 241 249 / 0.8) !important;
}

.bg-cyan-100\/85{
    background-color: rgb(193 241 249 / 0.85) !important;
}

.bg-cyan-100\/90{
    background-color: rgb(193 241 249 / 0.9) !important;
}

.bg-cyan-100\/95{
    background-color: rgb(193 241 249 / 0.95) !important;
}

.bg-cyan-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(151 232 245 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-200\/0{
    background-color: rgb(151 232 245 / 0) !important;
}

.bg-cyan-200\/10{
    background-color: rgb(151 232 245 / 0.1) !important;
}

.bg-cyan-200\/100{
    background-color: rgb(151 232 245 / 1) !important;
}

.bg-cyan-200\/15{
    background-color: rgb(151 232 245 / 0.15) !important;
}

.bg-cyan-200\/20{
    background-color: rgb(151 232 245 / 0.2) !important;
}

.bg-cyan-200\/25{
    background-color: rgb(151 232 245 / 0.25) !important;
}

.bg-cyan-200\/30{
    background-color: rgb(151 232 245 / 0.3) !important;
}

.bg-cyan-200\/35{
    background-color: rgb(151 232 245 / 0.35) !important;
}

.bg-cyan-200\/40{
    background-color: rgb(151 232 245 / 0.4) !important;
}

.bg-cyan-200\/45{
    background-color: rgb(151 232 245 / 0.45) !important;
}

.bg-cyan-200\/5{
    background-color: rgb(151 232 245 / 0.05) !important;
}

.bg-cyan-200\/50{
    background-color: rgb(151 232 245 / 0.5) !important;
}

.bg-cyan-200\/55{
    background-color: rgb(151 232 245 / 0.55) !important;
}

.bg-cyan-200\/60{
    background-color: rgb(151 232 245 / 0.6) !important;
}

.bg-cyan-200\/65{
    background-color: rgb(151 232 245 / 0.65) !important;
}

.bg-cyan-200\/70{
    background-color: rgb(151 232 245 / 0.7) !important;
}

.bg-cyan-200\/75{
    background-color: rgb(151 232 245 / 0.75) !important;
}

.bg-cyan-200\/80{
    background-color: rgb(151 232 245 / 0.8) !important;
}

.bg-cyan-200\/85{
    background-color: rgb(151 232 245 / 0.85) !important;
}

.bg-cyan-200\/90{
    background-color: rgb(151 232 245 / 0.9) !important;
}

.bg-cyan-200\/95{
    background-color: rgb(151 232 245 / 0.95) !important;
}

.bg-cyan-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(109 223 240 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-300\/0{
    background-color: rgb(109 223 240 / 0) !important;
}

.bg-cyan-300\/10{
    background-color: rgb(109 223 240 / 0.1) !important;
}

.bg-cyan-300\/100{
    background-color: rgb(109 223 240 / 1) !important;
}

.bg-cyan-300\/15{
    background-color: rgb(109 223 240 / 0.15) !important;
}

.bg-cyan-300\/20{
    background-color: rgb(109 223 240 / 0.2) !important;
}

.bg-cyan-300\/25{
    background-color: rgb(109 223 240 / 0.25) !important;
}

.bg-cyan-300\/30{
    background-color: rgb(109 223 240 / 0.3) !important;
}

.bg-cyan-300\/35{
    background-color: rgb(109 223 240 / 0.35) !important;
}

.bg-cyan-300\/40{
    background-color: rgb(109 223 240 / 0.4) !important;
}

.bg-cyan-300\/45{
    background-color: rgb(109 223 240 / 0.45) !important;
}

.bg-cyan-300\/5{
    background-color: rgb(109 223 240 / 0.05) !important;
}

.bg-cyan-300\/50{
    background-color: rgb(109 223 240 / 0.5) !important;
}

.bg-cyan-300\/55{
    background-color: rgb(109 223 240 / 0.55) !important;
}

.bg-cyan-300\/60{
    background-color: rgb(109 223 240 / 0.6) !important;
}

.bg-cyan-300\/65{
    background-color: rgb(109 223 240 / 0.65) !important;
}

.bg-cyan-300\/70{
    background-color: rgb(109 223 240 / 0.7) !important;
}

.bg-cyan-300\/75{
    background-color: rgb(109 223 240 / 0.75) !important;
}

.bg-cyan-300\/80{
    background-color: rgb(109 223 240 / 0.8) !important;
}

.bg-cyan-300\/85{
    background-color: rgb(109 223 240 / 0.85) !important;
}

.bg-cyan-300\/90{
    background-color: rgb(109 223 240 / 0.9) !important;
}

.bg-cyan-300\/95{
    background-color: rgb(109 223 240 / 0.95) !important;
}

.bg-cyan-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(68 214 236 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-400\/0{
    background-color: rgb(68 214 236 / 0) !important;
}

.bg-cyan-400\/10{
    background-color: rgb(68 214 236 / 0.1) !important;
}

.bg-cyan-400\/100{
    background-color: rgb(68 214 236 / 1) !important;
}

.bg-cyan-400\/15{
    background-color: rgb(68 214 236 / 0.15) !important;
}

.bg-cyan-400\/20{
    background-color: rgb(68 214 236 / 0.2) !important;
}

.bg-cyan-400\/25{
    background-color: rgb(68 214 236 / 0.25) !important;
}

.bg-cyan-400\/30{
    background-color: rgb(68 214 236 / 0.3) !important;
}

.bg-cyan-400\/35{
    background-color: rgb(68 214 236 / 0.35) !important;
}

.bg-cyan-400\/40{
    background-color: rgb(68 214 236 / 0.4) !important;
}

.bg-cyan-400\/45{
    background-color: rgb(68 214 236 / 0.45) !important;
}

.bg-cyan-400\/5{
    background-color: rgb(68 214 236 / 0.05) !important;
}

.bg-cyan-400\/50{
    background-color: rgb(68 214 236 / 0.5) !important;
}

.bg-cyan-400\/55{
    background-color: rgb(68 214 236 / 0.55) !important;
}

.bg-cyan-400\/60{
    background-color: rgb(68 214 236 / 0.6) !important;
}

.bg-cyan-400\/65{
    background-color: rgb(68 214 236 / 0.65) !important;
}

.bg-cyan-400\/70{
    background-color: rgb(68 214 236 / 0.7) !important;
}

.bg-cyan-400\/75{
    background-color: rgb(68 214 236 / 0.75) !important;
}

.bg-cyan-400\/80{
    background-color: rgb(68 214 236 / 0.8) !important;
}

.bg-cyan-400\/85{
    background-color: rgb(68 214 236 / 0.85) !important;
}

.bg-cyan-400\/90{
    background-color: rgb(68 214 236 / 0.9) !important;
}

.bg-cyan-400\/95{
    background-color: rgb(68 214 236 / 0.95) !important;
}

.bg-cyan-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(214 246 251 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-50\/0{
    background-color: rgb(214 246 251 / 0) !important;
}

.bg-cyan-50\/10{
    background-color: rgb(214 246 251 / 0.1) !important;
}

.bg-cyan-50\/100{
    background-color: rgb(214 246 251 / 1) !important;
}

.bg-cyan-50\/15{
    background-color: rgb(214 246 251 / 0.15) !important;
}

.bg-cyan-50\/20{
    background-color: rgb(214 246 251 / 0.2) !important;
}

.bg-cyan-50\/25{
    background-color: rgb(214 246 251 / 0.25) !important;
}

.bg-cyan-50\/30{
    background-color: rgb(214 246 251 / 0.3) !important;
}

.bg-cyan-50\/35{
    background-color: rgb(214 246 251 / 0.35) !important;
}

.bg-cyan-50\/40{
    background-color: rgb(214 246 251 / 0.4) !important;
}

.bg-cyan-50\/45{
    background-color: rgb(214 246 251 / 0.45) !important;
}

.bg-cyan-50\/5{
    background-color: rgb(214 246 251 / 0.05) !important;
}

.bg-cyan-50\/50{
    background-color: rgb(214 246 251 / 0.5) !important;
}

.bg-cyan-50\/55{
    background-color: rgb(214 246 251 / 0.55) !important;
}

.bg-cyan-50\/60{
    background-color: rgb(214 246 251 / 0.6) !important;
}

.bg-cyan-50\/65{
    background-color: rgb(214 246 251 / 0.65) !important;
}

.bg-cyan-50\/70{
    background-color: rgb(214 246 251 / 0.7) !important;
}

.bg-cyan-50\/75{
    background-color: rgb(214 246 251 / 0.75) !important;
}

.bg-cyan-50\/80{
    background-color: rgb(214 246 251 / 0.8) !important;
}

.bg-cyan-50\/85{
    background-color: rgb(214 246 251 / 0.85) !important;
}

.bg-cyan-50\/90{
    background-color: rgb(214 246 251 / 0.9) !important;
}

.bg-cyan-50\/95{
    background-color: rgb(214 246 251 / 0.95) !important;
}

.bg-cyan-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(26 205 232 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-500\/0{
    background-color: rgb(26 205 232 / 0) !important;
}

.bg-cyan-500\/10{
    background-color: rgb(26 205 232 / 0.1) !important;
}

.bg-cyan-500\/100{
    background-color: rgb(26 205 232 / 1) !important;
}

.bg-cyan-500\/15{
    background-color: rgb(26 205 232 / 0.15) !important;
}

.bg-cyan-500\/20{
    background-color: rgb(26 205 232 / 0.2) !important;
}

.bg-cyan-500\/25{
    background-color: rgb(26 205 232 / 0.25) !important;
}

.bg-cyan-500\/30{
    background-color: rgb(26 205 232 / 0.3) !important;
}

.bg-cyan-500\/35{
    background-color: rgb(26 205 232 / 0.35) !important;
}

.bg-cyan-500\/40{
    background-color: rgb(26 205 232 / 0.4) !important;
}

.bg-cyan-500\/45{
    background-color: rgb(26 205 232 / 0.45) !important;
}

.bg-cyan-500\/5{
    background-color: rgb(26 205 232 / 0.05) !important;
}

.bg-cyan-500\/50{
    background-color: rgb(26 205 232 / 0.5) !important;
}

.bg-cyan-500\/55{
    background-color: rgb(26 205 232 / 0.55) !important;
}

.bg-cyan-500\/60{
    background-color: rgb(26 205 232 / 0.6) !important;
}

.bg-cyan-500\/65{
    background-color: rgb(26 205 232 / 0.65) !important;
}

.bg-cyan-500\/70{
    background-color: rgb(26 205 232 / 0.7) !important;
}

.bg-cyan-500\/75{
    background-color: rgb(26 205 232 / 0.75) !important;
}

.bg-cyan-500\/80{
    background-color: rgb(26 205 232 / 0.8) !important;
}

.bg-cyan-500\/85{
    background-color: rgb(26 205 232 / 0.85) !important;
}

.bg-cyan-500\/90{
    background-color: rgb(26 205 232 / 0.9) !important;
}

.bg-cyan-500\/95{
    background-color: rgb(26 205 232 / 0.95) !important;
}

.bg-cyan-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(19 166 188 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-600\/0{
    background-color: rgb(19 166 188 / 0) !important;
}

.bg-cyan-600\/10{
    background-color: rgb(19 166 188 / 0.1) !important;
}

.bg-cyan-600\/100{
    background-color: rgb(19 166 188 / 1) !important;
}

.bg-cyan-600\/15{
    background-color: rgb(19 166 188 / 0.15) !important;
}

.bg-cyan-600\/20{
    background-color: rgb(19 166 188 / 0.2) !important;
}

.bg-cyan-600\/25{
    background-color: rgb(19 166 188 / 0.25) !important;
}

.bg-cyan-600\/30{
    background-color: rgb(19 166 188 / 0.3) !important;
}

.bg-cyan-600\/35{
    background-color: rgb(19 166 188 / 0.35) !important;
}

.bg-cyan-600\/40{
    background-color: rgb(19 166 188 / 0.4) !important;
}

.bg-cyan-600\/45{
    background-color: rgb(19 166 188 / 0.45) !important;
}

.bg-cyan-600\/5{
    background-color: rgb(19 166 188 / 0.05) !important;
}

.bg-cyan-600\/50{
    background-color: rgb(19 166 188 / 0.5) !important;
}

.bg-cyan-600\/55{
    background-color: rgb(19 166 188 / 0.55) !important;
}

.bg-cyan-600\/60{
    background-color: rgb(19 166 188 / 0.6) !important;
}

.bg-cyan-600\/65{
    background-color: rgb(19 166 188 / 0.65) !important;
}

.bg-cyan-600\/70{
    background-color: rgb(19 166 188 / 0.7) !important;
}

.bg-cyan-600\/75{
    background-color: rgb(19 166 188 / 0.75) !important;
}

.bg-cyan-600\/80{
    background-color: rgb(19 166 188 / 0.8) !important;
}

.bg-cyan-600\/85{
    background-color: rgb(19 166 188 / 0.85) !important;
}

.bg-cyan-600\/90{
    background-color: rgb(19 166 188 / 0.9) !important;
}

.bg-cyan-600\/95{
    background-color: rgb(19 166 188 / 0.95) !important;
}

.bg-cyan-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(14 125 142 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-700\/0{
    background-color: rgb(14 125 142 / 0) !important;
}

.bg-cyan-700\/10{
    background-color: rgb(14 125 142 / 0.1) !important;
}

.bg-cyan-700\/100{
    background-color: rgb(14 125 142 / 1) !important;
}

.bg-cyan-700\/15{
    background-color: rgb(14 125 142 / 0.15) !important;
}

.bg-cyan-700\/20{
    background-color: rgb(14 125 142 / 0.2) !important;
}

.bg-cyan-700\/25{
    background-color: rgb(14 125 142 / 0.25) !important;
}

.bg-cyan-700\/30{
    background-color: rgb(14 125 142 / 0.3) !important;
}

.bg-cyan-700\/35{
    background-color: rgb(14 125 142 / 0.35) !important;
}

.bg-cyan-700\/40{
    background-color: rgb(14 125 142 / 0.4) !important;
}

.bg-cyan-700\/45{
    background-color: rgb(14 125 142 / 0.45) !important;
}

.bg-cyan-700\/5{
    background-color: rgb(14 125 142 / 0.05) !important;
}

.bg-cyan-700\/50{
    background-color: rgb(14 125 142 / 0.5) !important;
}

.bg-cyan-700\/55{
    background-color: rgb(14 125 142 / 0.55) !important;
}

.bg-cyan-700\/60{
    background-color: rgb(14 125 142 / 0.6) !important;
}

.bg-cyan-700\/65{
    background-color: rgb(14 125 142 / 0.65) !important;
}

.bg-cyan-700\/70{
    background-color: rgb(14 125 142 / 0.7) !important;
}

.bg-cyan-700\/75{
    background-color: rgb(14 125 142 / 0.75) !important;
}

.bg-cyan-700\/80{
    background-color: rgb(14 125 142 / 0.8) !important;
}

.bg-cyan-700\/85{
    background-color: rgb(14 125 142 / 0.85) !important;
}

.bg-cyan-700\/90{
    background-color: rgb(14 125 142 / 0.9) !important;
}

.bg-cyan-700\/95{
    background-color: rgb(14 125 142 / 0.95) !important;
}

.bg-cyan-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(10 84 95 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-800\/0{
    background-color: rgb(10 84 95 / 0) !important;
}

.bg-cyan-800\/10{
    background-color: rgb(10 84 95 / 0.1) !important;
}

.bg-cyan-800\/100{
    background-color: rgb(10 84 95 / 1) !important;
}

.bg-cyan-800\/15{
    background-color: rgb(10 84 95 / 0.15) !important;
}

.bg-cyan-800\/20{
    background-color: rgb(10 84 95 / 0.2) !important;
}

.bg-cyan-800\/25{
    background-color: rgb(10 84 95 / 0.25) !important;
}

.bg-cyan-800\/30{
    background-color: rgb(10 84 95 / 0.3) !important;
}

.bg-cyan-800\/35{
    background-color: rgb(10 84 95 / 0.35) !important;
}

.bg-cyan-800\/40{
    background-color: rgb(10 84 95 / 0.4) !important;
}

.bg-cyan-800\/45{
    background-color: rgb(10 84 95 / 0.45) !important;
}

.bg-cyan-800\/5{
    background-color: rgb(10 84 95 / 0.05) !important;
}

.bg-cyan-800\/50{
    background-color: rgb(10 84 95 / 0.5) !important;
}

.bg-cyan-800\/55{
    background-color: rgb(10 84 95 / 0.55) !important;
}

.bg-cyan-800\/60{
    background-color: rgb(10 84 95 / 0.6) !important;
}

.bg-cyan-800\/65{
    background-color: rgb(10 84 95 / 0.65) !important;
}

.bg-cyan-800\/70{
    background-color: rgb(10 84 95 / 0.7) !important;
}

.bg-cyan-800\/75{
    background-color: rgb(10 84 95 / 0.75) !important;
}

.bg-cyan-800\/80{
    background-color: rgb(10 84 95 / 0.8) !important;
}

.bg-cyan-800\/85{
    background-color: rgb(10 84 95 / 0.85) !important;
}

.bg-cyan-800\/90{
    background-color: rgb(10 84 95 / 0.9) !important;
}

.bg-cyan-800\/95{
    background-color: rgb(10 84 95 / 0.95) !important;
}

.bg-cyan-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(5 43 49 / var(--tw-bg-opacity)) !important;
}

.bg-cyan-900\/0{
    background-color: rgb(5 43 49 / 0) !important;
}

.bg-cyan-900\/10{
    background-color: rgb(5 43 49 / 0.1) !important;
}

.bg-cyan-900\/100{
    background-color: rgb(5 43 49 / 1) !important;
}

.bg-cyan-900\/15{
    background-color: rgb(5 43 49 / 0.15) !important;
}

.bg-cyan-900\/20{
    background-color: rgb(5 43 49 / 0.2) !important;
}

.bg-cyan-900\/25{
    background-color: rgb(5 43 49 / 0.25) !important;
}

.bg-cyan-900\/30{
    background-color: rgb(5 43 49 / 0.3) !important;
}

.bg-cyan-900\/35{
    background-color: rgb(5 43 49 / 0.35) !important;
}

.bg-cyan-900\/40{
    background-color: rgb(5 43 49 / 0.4) !important;
}

.bg-cyan-900\/45{
    background-color: rgb(5 43 49 / 0.45) !important;
}

.bg-cyan-900\/5{
    background-color: rgb(5 43 49 / 0.05) !important;
}

.bg-cyan-900\/50{
    background-color: rgb(5 43 49 / 0.5) !important;
}

.bg-cyan-900\/55{
    background-color: rgb(5 43 49 / 0.55) !important;
}

.bg-cyan-900\/60{
    background-color: rgb(5 43 49 / 0.6) !important;
}

.bg-cyan-900\/65{
    background-color: rgb(5 43 49 / 0.65) !important;
}

.bg-cyan-900\/70{
    background-color: rgb(5 43 49 / 0.7) !important;
}

.bg-cyan-900\/75{
    background-color: rgb(5 43 49 / 0.75) !important;
}

.bg-cyan-900\/80{
    background-color: rgb(5 43 49 / 0.8) !important;
}

.bg-cyan-900\/85{
    background-color: rgb(5 43 49 / 0.85) !important;
}

.bg-cyan-900\/90{
    background-color: rgb(5 43 49 / 0.9) !important;
}

.bg-cyan-900\/95{
    background-color: rgb(5 43 49 / 0.95) !important;
}

.bg-dark{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
}

.bg-dark\/0{
    background-color: rgb(51 51 51 / 0) !important;
}

.bg-dark\/10{
    background-color: rgb(51 51 51 / 0.1) !important;
}

.bg-dark\/100{
    background-color: rgb(51 51 51 / 1) !important;
}

.bg-dark\/15{
    background-color: rgb(51 51 51 / 0.15) !important;
}

.bg-dark\/20{
    background-color: rgb(51 51 51 / 0.2) !important;
}

.bg-dark\/25{
    background-color: rgb(51 51 51 / 0.25) !important;
}

.bg-dark\/30{
    background-color: rgb(51 51 51 / 0.3) !important;
}

.bg-dark\/35{
    background-color: rgb(51 51 51 / 0.35) !important;
}

.bg-dark\/40{
    background-color: rgb(51 51 51 / 0.4) !important;
}

.bg-dark\/45{
    background-color: rgb(51 51 51 / 0.45) !important;
}

.bg-dark\/5{
    background-color: rgb(51 51 51 / 0.05) !important;
}

.bg-dark\/50{
    background-color: rgb(51 51 51 / 0.5) !important;
}

.bg-dark\/55{
    background-color: rgb(51 51 51 / 0.55) !important;
}

.bg-dark\/60{
    background-color: rgb(51 51 51 / 0.6) !important;
}

.bg-dark\/65{
    background-color: rgb(51 51 51 / 0.65) !important;
}

.bg-dark\/70{
    background-color: rgb(51 51 51 / 0.7) !important;
}

.bg-dark\/75{
    background-color: rgb(51 51 51 / 0.75) !important;
}

.bg-dark\/80{
    background-color: rgb(51 51 51 / 0.8) !important;
}

.bg-dark\/85{
    background-color: rgb(51 51 51 / 0.85) !important;
}

.bg-dark\/90{
    background-color: rgb(51 51 51 / 0.9) !important;
}

.bg-dark\/95{
    background-color: rgb(51 51 51 / 0.95) !important;
}

.bg-emerald-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-100\/0{
    background-color: rgb(209 250 229 / 0) !important;
}

.bg-emerald-100\/10{
    background-color: rgb(209 250 229 / 0.1) !important;
}

.bg-emerald-100\/100{
    background-color: rgb(209 250 229 / 1) !important;
}

.bg-emerald-100\/15{
    background-color: rgb(209 250 229 / 0.15) !important;
}

.bg-emerald-100\/20{
    background-color: rgb(209 250 229 / 0.2) !important;
}

.bg-emerald-100\/25{
    background-color: rgb(209 250 229 / 0.25) !important;
}

.bg-emerald-100\/30{
    background-color: rgb(209 250 229 / 0.3) !important;
}

.bg-emerald-100\/35{
    background-color: rgb(209 250 229 / 0.35) !important;
}

.bg-emerald-100\/40{
    background-color: rgb(209 250 229 / 0.4) !important;
}

.bg-emerald-100\/45{
    background-color: rgb(209 250 229 / 0.45) !important;
}

.bg-emerald-100\/5{
    background-color: rgb(209 250 229 / 0.05) !important;
}

.bg-emerald-100\/50{
    background-color: rgb(209 250 229 / 0.5) !important;
}

.bg-emerald-100\/55{
    background-color: rgb(209 250 229 / 0.55) !important;
}

.bg-emerald-100\/60{
    background-color: rgb(209 250 229 / 0.6) !important;
}

.bg-emerald-100\/65{
    background-color: rgb(209 250 229 / 0.65) !important;
}

.bg-emerald-100\/70{
    background-color: rgb(209 250 229 / 0.7) !important;
}

.bg-emerald-100\/75{
    background-color: rgb(209 250 229 / 0.75) !important;
}

.bg-emerald-100\/80{
    background-color: rgb(209 250 229 / 0.8) !important;
}

.bg-emerald-100\/85{
    background-color: rgb(209 250 229 / 0.85) !important;
}

.bg-emerald-100\/90{
    background-color: rgb(209 250 229 / 0.9) !important;
}

.bg-emerald-100\/95{
    background-color: rgb(209 250 229 / 0.95) !important;
}

.bg-emerald-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(167 243 208 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-200\/0{
    background-color: rgb(167 243 208 / 0) !important;
}

.bg-emerald-200\/10{
    background-color: rgb(167 243 208 / 0.1) !important;
}

.bg-emerald-200\/100{
    background-color: rgb(167 243 208 / 1) !important;
}

.bg-emerald-200\/15{
    background-color: rgb(167 243 208 / 0.15) !important;
}

.bg-emerald-200\/20{
    background-color: rgb(167 243 208 / 0.2) !important;
}

.bg-emerald-200\/25{
    background-color: rgb(167 243 208 / 0.25) !important;
}

.bg-emerald-200\/30{
    background-color: rgb(167 243 208 / 0.3) !important;
}

.bg-emerald-200\/35{
    background-color: rgb(167 243 208 / 0.35) !important;
}

.bg-emerald-200\/40{
    background-color: rgb(167 243 208 / 0.4) !important;
}

.bg-emerald-200\/45{
    background-color: rgb(167 243 208 / 0.45) !important;
}

.bg-emerald-200\/5{
    background-color: rgb(167 243 208 / 0.05) !important;
}

.bg-emerald-200\/50{
    background-color: rgb(167 243 208 / 0.5) !important;
}

.bg-emerald-200\/55{
    background-color: rgb(167 243 208 / 0.55) !important;
}

.bg-emerald-200\/60{
    background-color: rgb(167 243 208 / 0.6) !important;
}

.bg-emerald-200\/65{
    background-color: rgb(167 243 208 / 0.65) !important;
}

.bg-emerald-200\/70{
    background-color: rgb(167 243 208 / 0.7) !important;
}

.bg-emerald-200\/75{
    background-color: rgb(167 243 208 / 0.75) !important;
}

.bg-emerald-200\/80{
    background-color: rgb(167 243 208 / 0.8) !important;
}

.bg-emerald-200\/85{
    background-color: rgb(167 243 208 / 0.85) !important;
}

.bg-emerald-200\/90{
    background-color: rgb(167 243 208 / 0.9) !important;
}

.bg-emerald-200\/95{
    background-color: rgb(167 243 208 / 0.95) !important;
}

.bg-emerald-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(110 231 183 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-300\/0{
    background-color: rgb(110 231 183 / 0) !important;
}

.bg-emerald-300\/10{
    background-color: rgb(110 231 183 / 0.1) !important;
}

.bg-emerald-300\/100{
    background-color: rgb(110 231 183 / 1) !important;
}

.bg-emerald-300\/15{
    background-color: rgb(110 231 183 / 0.15) !important;
}

.bg-emerald-300\/20{
    background-color: rgb(110 231 183 / 0.2) !important;
}

.bg-emerald-300\/25{
    background-color: rgb(110 231 183 / 0.25) !important;
}

.bg-emerald-300\/30{
    background-color: rgb(110 231 183 / 0.3) !important;
}

.bg-emerald-300\/35{
    background-color: rgb(110 231 183 / 0.35) !important;
}

.bg-emerald-300\/40{
    background-color: rgb(110 231 183 / 0.4) !important;
}

.bg-emerald-300\/45{
    background-color: rgb(110 231 183 / 0.45) !important;
}

.bg-emerald-300\/5{
    background-color: rgb(110 231 183 / 0.05) !important;
}

.bg-emerald-300\/50{
    background-color: rgb(110 231 183 / 0.5) !important;
}

.bg-emerald-300\/55{
    background-color: rgb(110 231 183 / 0.55) !important;
}

.bg-emerald-300\/60{
    background-color: rgb(110 231 183 / 0.6) !important;
}

.bg-emerald-300\/65{
    background-color: rgb(110 231 183 / 0.65) !important;
}

.bg-emerald-300\/70{
    background-color: rgb(110 231 183 / 0.7) !important;
}

.bg-emerald-300\/75{
    background-color: rgb(110 231 183 / 0.75) !important;
}

.bg-emerald-300\/80{
    background-color: rgb(110 231 183 / 0.8) !important;
}

.bg-emerald-300\/85{
    background-color: rgb(110 231 183 / 0.85) !important;
}

.bg-emerald-300\/90{
    background-color: rgb(110 231 183 / 0.9) !important;
}

.bg-emerald-300\/95{
    background-color: rgb(110 231 183 / 0.95) !important;
}

.bg-emerald-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-400\/0{
    background-color: rgb(52 211 153 / 0) !important;
}

.bg-emerald-400\/10{
    background-color: rgb(52 211 153 / 0.1) !important;
}

.bg-emerald-400\/100{
    background-color: rgb(52 211 153 / 1) !important;
}

.bg-emerald-400\/15{
    background-color: rgb(52 211 153 / 0.15) !important;
}

.bg-emerald-400\/20{
    background-color: rgb(52 211 153 / 0.2) !important;
}

.bg-emerald-400\/25{
    background-color: rgb(52 211 153 / 0.25) !important;
}

.bg-emerald-400\/30{
    background-color: rgb(52 211 153 / 0.3) !important;
}

.bg-emerald-400\/35{
    background-color: rgb(52 211 153 / 0.35) !important;
}

.bg-emerald-400\/40{
    background-color: rgb(52 211 153 / 0.4) !important;
}

.bg-emerald-400\/45{
    background-color: rgb(52 211 153 / 0.45) !important;
}

.bg-emerald-400\/5{
    background-color: rgb(52 211 153 / 0.05) !important;
}

.bg-emerald-400\/50{
    background-color: rgb(52 211 153 / 0.5) !important;
}

.bg-emerald-400\/55{
    background-color: rgb(52 211 153 / 0.55) !important;
}

.bg-emerald-400\/60{
    background-color: rgb(52 211 153 / 0.6) !important;
}

.bg-emerald-400\/65{
    background-color: rgb(52 211 153 / 0.65) !important;
}

.bg-emerald-400\/70{
    background-color: rgb(52 211 153 / 0.7) !important;
}

.bg-emerald-400\/75{
    background-color: rgb(52 211 153 / 0.75) !important;
}

.bg-emerald-400\/80{
    background-color: rgb(52 211 153 / 0.8) !important;
}

.bg-emerald-400\/85{
    background-color: rgb(52 211 153 / 0.85) !important;
}

.bg-emerald-400\/90{
    background-color: rgb(52 211 153 / 0.9) !important;
}

.bg-emerald-400\/95{
    background-color: rgb(52 211 153 / 0.95) !important;
}

.bg-emerald-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(236 253 245 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-50\/0{
    background-color: rgb(236 253 245 / 0) !important;
}

.bg-emerald-50\/10{
    background-color: rgb(236 253 245 / 0.1) !important;
}

.bg-emerald-50\/100{
    background-color: rgb(236 253 245 / 1) !important;
}

.bg-emerald-50\/15{
    background-color: rgb(236 253 245 / 0.15) !important;
}

.bg-emerald-50\/20{
    background-color: rgb(236 253 245 / 0.2) !important;
}

.bg-emerald-50\/25{
    background-color: rgb(236 253 245 / 0.25) !important;
}

.bg-emerald-50\/30{
    background-color: rgb(236 253 245 / 0.3) !important;
}

.bg-emerald-50\/35{
    background-color: rgb(236 253 245 / 0.35) !important;
}

.bg-emerald-50\/40{
    background-color: rgb(236 253 245 / 0.4) !important;
}

.bg-emerald-50\/45{
    background-color: rgb(236 253 245 / 0.45) !important;
}

.bg-emerald-50\/5{
    background-color: rgb(236 253 245 / 0.05) !important;
}

.bg-emerald-50\/50{
    background-color: rgb(236 253 245 / 0.5) !important;
}

.bg-emerald-50\/55{
    background-color: rgb(236 253 245 / 0.55) !important;
}

.bg-emerald-50\/60{
    background-color: rgb(236 253 245 / 0.6) !important;
}

.bg-emerald-50\/65{
    background-color: rgb(236 253 245 / 0.65) !important;
}

.bg-emerald-50\/70{
    background-color: rgb(236 253 245 / 0.7) !important;
}

.bg-emerald-50\/75{
    background-color: rgb(236 253 245 / 0.75) !important;
}

.bg-emerald-50\/80{
    background-color: rgb(236 253 245 / 0.8) !important;
}

.bg-emerald-50\/85{
    background-color: rgb(236 253 245 / 0.85) !important;
}

.bg-emerald-50\/90{
    background-color: rgb(236 253 245 / 0.9) !important;
}

.bg-emerald-50\/95{
    background-color: rgb(236 253 245 / 0.95) !important;
}

.bg-emerald-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-500\/0{
    background-color: rgb(16 185 129 / 0) !important;
}

.bg-emerald-500\/10{
    background-color: rgb(16 185 129 / 0.1) !important;
}

.bg-emerald-500\/100{
    background-color: rgb(16 185 129 / 1) !important;
}

.bg-emerald-500\/15{
    background-color: rgb(16 185 129 / 0.15) !important;
}

.bg-emerald-500\/20{
    background-color: rgb(16 185 129 / 0.2) !important;
}

.bg-emerald-500\/25{
    background-color: rgb(16 185 129 / 0.25) !important;
}

.bg-emerald-500\/30{
    background-color: rgb(16 185 129 / 0.3) !important;
}

.bg-emerald-500\/35{
    background-color: rgb(16 185 129 / 0.35) !important;
}

.bg-emerald-500\/40{
    background-color: rgb(16 185 129 / 0.4) !important;
}

.bg-emerald-500\/45{
    background-color: rgb(16 185 129 / 0.45) !important;
}

.bg-emerald-500\/5{
    background-color: rgb(16 185 129 / 0.05) !important;
}

.bg-emerald-500\/50{
    background-color: rgb(16 185 129 / 0.5) !important;
}

.bg-emerald-500\/55{
    background-color: rgb(16 185 129 / 0.55) !important;
}

.bg-emerald-500\/60{
    background-color: rgb(16 185 129 / 0.6) !important;
}

.bg-emerald-500\/65{
    background-color: rgb(16 185 129 / 0.65) !important;
}

.bg-emerald-500\/70{
    background-color: rgb(16 185 129 / 0.7) !important;
}

.bg-emerald-500\/75{
    background-color: rgb(16 185 129 / 0.75) !important;
}

.bg-emerald-500\/80{
    background-color: rgb(16 185 129 / 0.8) !important;
}

.bg-emerald-500\/85{
    background-color: rgb(16 185 129 / 0.85) !important;
}

.bg-emerald-500\/90{
    background-color: rgb(16 185 129 / 0.9) !important;
}

.bg-emerald-500\/95{
    background-color: rgb(16 185 129 / 0.95) !important;
}

.bg-emerald-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-600\/0{
    background-color: rgb(5 150 105 / 0) !important;
}

.bg-emerald-600\/10{
    background-color: rgb(5 150 105 / 0.1) !important;
}

.bg-emerald-600\/100{
    background-color: rgb(5 150 105 / 1) !important;
}

.bg-emerald-600\/15{
    background-color: rgb(5 150 105 / 0.15) !important;
}

.bg-emerald-600\/20{
    background-color: rgb(5 150 105 / 0.2) !important;
}

.bg-emerald-600\/25{
    background-color: rgb(5 150 105 / 0.25) !important;
}

.bg-emerald-600\/30{
    background-color: rgb(5 150 105 / 0.3) !important;
}

.bg-emerald-600\/35{
    background-color: rgb(5 150 105 / 0.35) !important;
}

.bg-emerald-600\/40{
    background-color: rgb(5 150 105 / 0.4) !important;
}

.bg-emerald-600\/45{
    background-color: rgb(5 150 105 / 0.45) !important;
}

.bg-emerald-600\/5{
    background-color: rgb(5 150 105 / 0.05) !important;
}

.bg-emerald-600\/50{
    background-color: rgb(5 150 105 / 0.5) !important;
}

.bg-emerald-600\/55{
    background-color: rgb(5 150 105 / 0.55) !important;
}

.bg-emerald-600\/60{
    background-color: rgb(5 150 105 / 0.6) !important;
}

.bg-emerald-600\/65{
    background-color: rgb(5 150 105 / 0.65) !important;
}

.bg-emerald-600\/70{
    background-color: rgb(5 150 105 / 0.7) !important;
}

.bg-emerald-600\/75{
    background-color: rgb(5 150 105 / 0.75) !important;
}

.bg-emerald-600\/80{
    background-color: rgb(5 150 105 / 0.8) !important;
}

.bg-emerald-600\/85{
    background-color: rgb(5 150 105 / 0.85) !important;
}

.bg-emerald-600\/90{
    background-color: rgb(5 150 105 / 0.9) !important;
}

.bg-emerald-600\/95{
    background-color: rgb(5 150 105 / 0.95) !important;
}

.bg-emerald-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(4 120 87 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-700\/0{
    background-color: rgb(4 120 87 / 0) !important;
}

.bg-emerald-700\/10{
    background-color: rgb(4 120 87 / 0.1) !important;
}

.bg-emerald-700\/100{
    background-color: rgb(4 120 87 / 1) !important;
}

.bg-emerald-700\/15{
    background-color: rgb(4 120 87 / 0.15) !important;
}

.bg-emerald-700\/20{
    background-color: rgb(4 120 87 / 0.2) !important;
}

.bg-emerald-700\/25{
    background-color: rgb(4 120 87 / 0.25) !important;
}

.bg-emerald-700\/30{
    background-color: rgb(4 120 87 / 0.3) !important;
}

.bg-emerald-700\/35{
    background-color: rgb(4 120 87 / 0.35) !important;
}

.bg-emerald-700\/40{
    background-color: rgb(4 120 87 / 0.4) !important;
}

.bg-emerald-700\/45{
    background-color: rgb(4 120 87 / 0.45) !important;
}

.bg-emerald-700\/5{
    background-color: rgb(4 120 87 / 0.05) !important;
}

.bg-emerald-700\/50{
    background-color: rgb(4 120 87 / 0.5) !important;
}

.bg-emerald-700\/55{
    background-color: rgb(4 120 87 / 0.55) !important;
}

.bg-emerald-700\/60{
    background-color: rgb(4 120 87 / 0.6) !important;
}

.bg-emerald-700\/65{
    background-color: rgb(4 120 87 / 0.65) !important;
}

.bg-emerald-700\/70{
    background-color: rgb(4 120 87 / 0.7) !important;
}

.bg-emerald-700\/75{
    background-color: rgb(4 120 87 / 0.75) !important;
}

.bg-emerald-700\/80{
    background-color: rgb(4 120 87 / 0.8) !important;
}

.bg-emerald-700\/85{
    background-color: rgb(4 120 87 / 0.85) !important;
}

.bg-emerald-700\/90{
    background-color: rgb(4 120 87 / 0.9) !important;
}

.bg-emerald-700\/95{
    background-color: rgb(4 120 87 / 0.95) !important;
}

.bg-emerald-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(6 95 70 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-800\/0{
    background-color: rgb(6 95 70 / 0) !important;
}

.bg-emerald-800\/10{
    background-color: rgb(6 95 70 / 0.1) !important;
}

.bg-emerald-800\/100{
    background-color: rgb(6 95 70 / 1) !important;
}

.bg-emerald-800\/15{
    background-color: rgb(6 95 70 / 0.15) !important;
}

.bg-emerald-800\/20{
    background-color: rgb(6 95 70 / 0.2) !important;
}

.bg-emerald-800\/25{
    background-color: rgb(6 95 70 / 0.25) !important;
}

.bg-emerald-800\/30{
    background-color: rgb(6 95 70 / 0.3) !important;
}

.bg-emerald-800\/35{
    background-color: rgb(6 95 70 / 0.35) !important;
}

.bg-emerald-800\/40{
    background-color: rgb(6 95 70 / 0.4) !important;
}

.bg-emerald-800\/45{
    background-color: rgb(6 95 70 / 0.45) !important;
}

.bg-emerald-800\/5{
    background-color: rgb(6 95 70 / 0.05) !important;
}

.bg-emerald-800\/50{
    background-color: rgb(6 95 70 / 0.5) !important;
}

.bg-emerald-800\/55{
    background-color: rgb(6 95 70 / 0.55) !important;
}

.bg-emerald-800\/60{
    background-color: rgb(6 95 70 / 0.6) !important;
}

.bg-emerald-800\/65{
    background-color: rgb(6 95 70 / 0.65) !important;
}

.bg-emerald-800\/70{
    background-color: rgb(6 95 70 / 0.7) !important;
}

.bg-emerald-800\/75{
    background-color: rgb(6 95 70 / 0.75) !important;
}

.bg-emerald-800\/80{
    background-color: rgb(6 95 70 / 0.8) !important;
}

.bg-emerald-800\/85{
    background-color: rgb(6 95 70 / 0.85) !important;
}

.bg-emerald-800\/90{
    background-color: rgb(6 95 70 / 0.9) !important;
}

.bg-emerald-800\/95{
    background-color: rgb(6 95 70 / 0.95) !important;
}

.bg-emerald-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(6 78 59 / var(--tw-bg-opacity)) !important;
}

.bg-emerald-900\/0{
    background-color: rgb(6 78 59 / 0) !important;
}

.bg-emerald-900\/10{
    background-color: rgb(6 78 59 / 0.1) !important;
}

.bg-emerald-900\/100{
    background-color: rgb(6 78 59 / 1) !important;
}

.bg-emerald-900\/15{
    background-color: rgb(6 78 59 / 0.15) !important;
}

.bg-emerald-900\/20{
    background-color: rgb(6 78 59 / 0.2) !important;
}

.bg-emerald-900\/25{
    background-color: rgb(6 78 59 / 0.25) !important;
}

.bg-emerald-900\/30{
    background-color: rgb(6 78 59 / 0.3) !important;
}

.bg-emerald-900\/35{
    background-color: rgb(6 78 59 / 0.35) !important;
}

.bg-emerald-900\/40{
    background-color: rgb(6 78 59 / 0.4) !important;
}

.bg-emerald-900\/45{
    background-color: rgb(6 78 59 / 0.45) !important;
}

.bg-emerald-900\/5{
    background-color: rgb(6 78 59 / 0.05) !important;
}

.bg-emerald-900\/50{
    background-color: rgb(6 78 59 / 0.5) !important;
}

.bg-emerald-900\/55{
    background-color: rgb(6 78 59 / 0.55) !important;
}

.bg-emerald-900\/60{
    background-color: rgb(6 78 59 / 0.6) !important;
}

.bg-emerald-900\/65{
    background-color: rgb(6 78 59 / 0.65) !important;
}

.bg-emerald-900\/70{
    background-color: rgb(6 78 59 / 0.7) !important;
}

.bg-emerald-900\/75{
    background-color: rgb(6 78 59 / 0.75) !important;
}

.bg-emerald-900\/80{
    background-color: rgb(6 78 59 / 0.8) !important;
}

.bg-emerald-900\/85{
    background-color: rgb(6 78 59 / 0.85) !important;
}

.bg-emerald-900\/90{
    background-color: rgb(6 78 59 / 0.9) !important;
}

.bg-emerald-900\/95{
    background-color: rgb(6 78 59 / 0.95) !important;
}

.bg-error{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(201 59 59 / var(--tw-bg-opacity)) !important;
}

.bg-error\/0{
    background-color: rgb(201 59 59 / 0) !important;
}

.bg-error\/10{
    background-color: rgb(201 59 59 / 0.1) !important;
}

.bg-error\/100{
    background-color: rgb(201 59 59 / 1) !important;
}

.bg-error\/15{
    background-color: rgb(201 59 59 / 0.15) !important;
}

.bg-error\/20{
    background-color: rgb(201 59 59 / 0.2) !important;
}

.bg-error\/25{
    background-color: rgb(201 59 59 / 0.25) !important;
}

.bg-error\/30{
    background-color: rgb(201 59 59 / 0.3) !important;
}

.bg-error\/35{
    background-color: rgb(201 59 59 / 0.35) !important;
}

.bg-error\/40{
    background-color: rgb(201 59 59 / 0.4) !important;
}

.bg-error\/45{
    background-color: rgb(201 59 59 / 0.45) !important;
}

.bg-error\/5{
    background-color: rgb(201 59 59 / 0.05) !important;
}

.bg-error\/50{
    background-color: rgb(201 59 59 / 0.5) !important;
}

.bg-error\/55{
    background-color: rgb(201 59 59 / 0.55) !important;
}

.bg-error\/60{
    background-color: rgb(201 59 59 / 0.6) !important;
}

.bg-error\/65{
    background-color: rgb(201 59 59 / 0.65) !important;
}

.bg-error\/70{
    background-color: rgb(201 59 59 / 0.7) !important;
}

.bg-error\/75{
    background-color: rgb(201 59 59 / 0.75) !important;
}

.bg-error\/80{
    background-color: rgb(201 59 59 / 0.8) !important;
}

.bg-error\/85{
    background-color: rgb(201 59 59 / 0.85) !important;
}

.bg-error\/90{
    background-color: rgb(201 59 59 / 0.9) !important;
}

.bg-error\/95{
    background-color: rgb(201 59 59 / 0.95) !important;
}

.bg-flower-cider-amber{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(195 98 27 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-amber\/0{
    background-color: rgb(195 98 27 / 0) !important;
}

.bg-flower-cider-amber\/10{
    background-color: rgb(195 98 27 / 0.1) !important;
}

.bg-flower-cider-amber\/100{
    background-color: rgb(195 98 27 / 1) !important;
}

.bg-flower-cider-amber\/15{
    background-color: rgb(195 98 27 / 0.15) !important;
}

.bg-flower-cider-amber\/20{
    background-color: rgb(195 98 27 / 0.2) !important;
}

.bg-flower-cider-amber\/25{
    background-color: rgb(195 98 27 / 0.25) !important;
}

.bg-flower-cider-amber\/30{
    background-color: rgb(195 98 27 / 0.3) !important;
}

.bg-flower-cider-amber\/35{
    background-color: rgb(195 98 27 / 0.35) !important;
}

.bg-flower-cider-amber\/40{
    background-color: rgb(195 98 27 / 0.4) !important;
}

.bg-flower-cider-amber\/45{
    background-color: rgb(195 98 27 / 0.45) !important;
}

.bg-flower-cider-amber\/5{
    background-color: rgb(195 98 27 / 0.05) !important;
}

.bg-flower-cider-amber\/50{
    background-color: rgb(195 98 27 / 0.5) !important;
}

.bg-flower-cider-amber\/55{
    background-color: rgb(195 98 27 / 0.55) !important;
}

.bg-flower-cider-amber\/60{
    background-color: rgb(195 98 27 / 0.6) !important;
}

.bg-flower-cider-amber\/65{
    background-color: rgb(195 98 27 / 0.65) !important;
}

.bg-flower-cider-amber\/70{
    background-color: rgb(195 98 27 / 0.7) !important;
}

.bg-flower-cider-amber\/75{
    background-color: rgb(195 98 27 / 0.75) !important;
}

.bg-flower-cider-amber\/80{
    background-color: rgb(195 98 27 / 0.8) !important;
}

.bg-flower-cider-amber\/85{
    background-color: rgb(195 98 27 / 0.85) !important;
}

.bg-flower-cider-amber\/90{
    background-color: rgb(195 98 27 / 0.9) !important;
}

.bg-flower-cider-amber\/95{
    background-color: rgb(195 98 27 / 0.95) !important;
}

.bg-flower-cider-copper{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(174 72 24 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-copper\/0{
    background-color: rgb(174 72 24 / 0) !important;
}

.bg-flower-cider-copper\/10{
    background-color: rgb(174 72 24 / 0.1) !important;
}

.bg-flower-cider-copper\/100{
    background-color: rgb(174 72 24 / 1) !important;
}

.bg-flower-cider-copper\/15{
    background-color: rgb(174 72 24 / 0.15) !important;
}

.bg-flower-cider-copper\/20{
    background-color: rgb(174 72 24 / 0.2) !important;
}

.bg-flower-cider-copper\/25{
    background-color: rgb(174 72 24 / 0.25) !important;
}

.bg-flower-cider-copper\/30{
    background-color: rgb(174 72 24 / 0.3) !important;
}

.bg-flower-cider-copper\/35{
    background-color: rgb(174 72 24 / 0.35) !important;
}

.bg-flower-cider-copper\/40{
    background-color: rgb(174 72 24 / 0.4) !important;
}

.bg-flower-cider-copper\/45{
    background-color: rgb(174 72 24 / 0.45) !important;
}

.bg-flower-cider-copper\/5{
    background-color: rgb(174 72 24 / 0.05) !important;
}

.bg-flower-cider-copper\/50{
    background-color: rgb(174 72 24 / 0.5) !important;
}

.bg-flower-cider-copper\/55{
    background-color: rgb(174 72 24 / 0.55) !important;
}

.bg-flower-cider-copper\/60{
    background-color: rgb(174 72 24 / 0.6) !important;
}

.bg-flower-cider-copper\/65{
    background-color: rgb(174 72 24 / 0.65) !important;
}

.bg-flower-cider-copper\/70{
    background-color: rgb(174 72 24 / 0.7) !important;
}

.bg-flower-cider-copper\/75{
    background-color: rgb(174 72 24 / 0.75) !important;
}

.bg-flower-cider-copper\/80{
    background-color: rgb(174 72 24 / 0.8) !important;
}

.bg-flower-cider-copper\/85{
    background-color: rgb(174 72 24 / 0.85) !important;
}

.bg-flower-cider-copper\/90{
    background-color: rgb(174 72 24 / 0.9) !important;
}

.bg-flower-cider-copper\/95{
    background-color: rgb(174 72 24 / 0.95) !important;
}

.bg-flower-cider-garnet{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(108 9 9 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-garnet\/0{
    background-color: rgb(108 9 9 / 0) !important;
}

.bg-flower-cider-garnet\/10{
    background-color: rgb(108 9 9 / 0.1) !important;
}

.bg-flower-cider-garnet\/100{
    background-color: rgb(108 9 9 / 1) !important;
}

.bg-flower-cider-garnet\/15{
    background-color: rgb(108 9 9 / 0.15) !important;
}

.bg-flower-cider-garnet\/20{
    background-color: rgb(108 9 9 / 0.2) !important;
}

.bg-flower-cider-garnet\/25{
    background-color: rgb(108 9 9 / 0.25) !important;
}

.bg-flower-cider-garnet\/30{
    background-color: rgb(108 9 9 / 0.3) !important;
}

.bg-flower-cider-garnet\/35{
    background-color: rgb(108 9 9 / 0.35) !important;
}

.bg-flower-cider-garnet\/40{
    background-color: rgb(108 9 9 / 0.4) !important;
}

.bg-flower-cider-garnet\/45{
    background-color: rgb(108 9 9 / 0.45) !important;
}

.bg-flower-cider-garnet\/5{
    background-color: rgb(108 9 9 / 0.05) !important;
}

.bg-flower-cider-garnet\/50{
    background-color: rgb(108 9 9 / 0.5) !important;
}

.bg-flower-cider-garnet\/55{
    background-color: rgb(108 9 9 / 0.55) !important;
}

.bg-flower-cider-garnet\/60{
    background-color: rgb(108 9 9 / 0.6) !important;
}

.bg-flower-cider-garnet\/65{
    background-color: rgb(108 9 9 / 0.65) !important;
}

.bg-flower-cider-garnet\/70{
    background-color: rgb(108 9 9 / 0.7) !important;
}

.bg-flower-cider-garnet\/75{
    background-color: rgb(108 9 9 / 0.75) !important;
}

.bg-flower-cider-garnet\/80{
    background-color: rgb(108 9 9 / 0.8) !important;
}

.bg-flower-cider-garnet\/85{
    background-color: rgb(108 9 9 / 0.85) !important;
}

.bg-flower-cider-garnet\/90{
    background-color: rgb(108 9 9 / 0.9) !important;
}

.bg-flower-cider-garnet\/95{
    background-color: rgb(108 9 9 / 0.95) !important;
}

.bg-flower-cider-gold{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 206 81 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-gold\/0{
    background-color: rgb(244 206 81 / 0) !important;
}

.bg-flower-cider-gold\/10{
    background-color: rgb(244 206 81 / 0.1) !important;
}

.bg-flower-cider-gold\/100{
    background-color: rgb(244 206 81 / 1) !important;
}

.bg-flower-cider-gold\/15{
    background-color: rgb(244 206 81 / 0.15) !important;
}

.bg-flower-cider-gold\/20{
    background-color: rgb(244 206 81 / 0.2) !important;
}

.bg-flower-cider-gold\/25{
    background-color: rgb(244 206 81 / 0.25) !important;
}

.bg-flower-cider-gold\/30{
    background-color: rgb(244 206 81 / 0.3) !important;
}

.bg-flower-cider-gold\/35{
    background-color: rgb(244 206 81 / 0.35) !important;
}

.bg-flower-cider-gold\/40{
    background-color: rgb(244 206 81 / 0.4) !important;
}

.bg-flower-cider-gold\/45{
    background-color: rgb(244 206 81 / 0.45) !important;
}

.bg-flower-cider-gold\/5{
    background-color: rgb(244 206 81 / 0.05) !important;
}

.bg-flower-cider-gold\/50{
    background-color: rgb(244 206 81 / 0.5) !important;
}

.bg-flower-cider-gold\/55{
    background-color: rgb(244 206 81 / 0.55) !important;
}

.bg-flower-cider-gold\/60{
    background-color: rgb(244 206 81 / 0.6) !important;
}

.bg-flower-cider-gold\/65{
    background-color: rgb(244 206 81 / 0.65) !important;
}

.bg-flower-cider-gold\/70{
    background-color: rgb(244 206 81 / 0.7) !important;
}

.bg-flower-cider-gold\/75{
    background-color: rgb(244 206 81 / 0.75) !important;
}

.bg-flower-cider-gold\/80{
    background-color: rgb(244 206 81 / 0.8) !important;
}

.bg-flower-cider-gold\/85{
    background-color: rgb(244 206 81 / 0.85) !important;
}

.bg-flower-cider-gold\/90{
    background-color: rgb(244 206 81 / 0.9) !important;
}

.bg-flower-cider-gold\/95{
    background-color: rgb(244 206 81 / 0.95) !important;
}

.bg-flower-cider-pink{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 102 255 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-pink\/0{
    background-color: rgb(255 102 255 / 0) !important;
}

.bg-flower-cider-pink\/10{
    background-color: rgb(255 102 255 / 0.1) !important;
}

.bg-flower-cider-pink\/100{
    background-color: rgb(255 102 255 / 1) !important;
}

.bg-flower-cider-pink\/15{
    background-color: rgb(255 102 255 / 0.15) !important;
}

.bg-flower-cider-pink\/20{
    background-color: rgb(255 102 255 / 0.2) !important;
}

.bg-flower-cider-pink\/25{
    background-color: rgb(255 102 255 / 0.25) !important;
}

.bg-flower-cider-pink\/30{
    background-color: rgb(255 102 255 / 0.3) !important;
}

.bg-flower-cider-pink\/35{
    background-color: rgb(255 102 255 / 0.35) !important;
}

.bg-flower-cider-pink\/40{
    background-color: rgb(255 102 255 / 0.4) !important;
}

.bg-flower-cider-pink\/45{
    background-color: rgb(255 102 255 / 0.45) !important;
}

.bg-flower-cider-pink\/5{
    background-color: rgb(255 102 255 / 0.05) !important;
}

.bg-flower-cider-pink\/50{
    background-color: rgb(255 102 255 / 0.5) !important;
}

.bg-flower-cider-pink\/55{
    background-color: rgb(255 102 255 / 0.55) !important;
}

.bg-flower-cider-pink\/60{
    background-color: rgb(255 102 255 / 0.6) !important;
}

.bg-flower-cider-pink\/65{
    background-color: rgb(255 102 255 / 0.65) !important;
}

.bg-flower-cider-pink\/70{
    background-color: rgb(255 102 255 / 0.7) !important;
}

.bg-flower-cider-pink\/75{
    background-color: rgb(255 102 255 / 0.75) !important;
}

.bg-flower-cider-pink\/80{
    background-color: rgb(255 102 255 / 0.8) !important;
}

.bg-flower-cider-pink\/85{
    background-color: rgb(255 102 255 / 0.85) !important;
}

.bg-flower-cider-pink\/90{
    background-color: rgb(255 102 255 / 0.9) !important;
}

.bg-flower-cider-pink\/95{
    background-color: rgb(255 102 255 / 0.95) !important;
}

.bg-flower-cider-purple{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(117 0 117 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-purple\/0{
    background-color: rgb(117 0 117 / 0) !important;
}

.bg-flower-cider-purple\/10{
    background-color: rgb(117 0 117 / 0.1) !important;
}

.bg-flower-cider-purple\/100{
    background-color: rgb(117 0 117 / 1) !important;
}

.bg-flower-cider-purple\/15{
    background-color: rgb(117 0 117 / 0.15) !important;
}

.bg-flower-cider-purple\/20{
    background-color: rgb(117 0 117 / 0.2) !important;
}

.bg-flower-cider-purple\/25{
    background-color: rgb(117 0 117 / 0.25) !important;
}

.bg-flower-cider-purple\/30{
    background-color: rgb(117 0 117 / 0.3) !important;
}

.bg-flower-cider-purple\/35{
    background-color: rgb(117 0 117 / 0.35) !important;
}

.bg-flower-cider-purple\/40{
    background-color: rgb(117 0 117 / 0.4) !important;
}

.bg-flower-cider-purple\/45{
    background-color: rgb(117 0 117 / 0.45) !important;
}

.bg-flower-cider-purple\/5{
    background-color: rgb(117 0 117 / 0.05) !important;
}

.bg-flower-cider-purple\/50{
    background-color: rgb(117 0 117 / 0.5) !important;
}

.bg-flower-cider-purple\/55{
    background-color: rgb(117 0 117 / 0.55) !important;
}

.bg-flower-cider-purple\/60{
    background-color: rgb(117 0 117 / 0.6) !important;
}

.bg-flower-cider-purple\/65{
    background-color: rgb(117 0 117 / 0.65) !important;
}

.bg-flower-cider-purple\/70{
    background-color: rgb(117 0 117 / 0.7) !important;
}

.bg-flower-cider-purple\/75{
    background-color: rgb(117 0 117 / 0.75) !important;
}

.bg-flower-cider-purple\/80{
    background-color: rgb(117 0 117 / 0.8) !important;
}

.bg-flower-cider-purple\/85{
    background-color: rgb(117 0 117 / 0.85) !important;
}

.bg-flower-cider-purple\/90{
    background-color: rgb(117 0 117 / 0.9) !important;
}

.bg-flower-cider-purple\/95{
    background-color: rgb(117 0 117 / 0.95) !important;
}

.bg-flower-cider-ruby{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(179 15 15 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-ruby\/0{
    background-color: rgb(179 15 15 / 0) !important;
}

.bg-flower-cider-ruby\/10{
    background-color: rgb(179 15 15 / 0.1) !important;
}

.bg-flower-cider-ruby\/100{
    background-color: rgb(179 15 15 / 1) !important;
}

.bg-flower-cider-ruby\/15{
    background-color: rgb(179 15 15 / 0.15) !important;
}

.bg-flower-cider-ruby\/20{
    background-color: rgb(179 15 15 / 0.2) !important;
}

.bg-flower-cider-ruby\/25{
    background-color: rgb(179 15 15 / 0.25) !important;
}

.bg-flower-cider-ruby\/30{
    background-color: rgb(179 15 15 / 0.3) !important;
}

.bg-flower-cider-ruby\/35{
    background-color: rgb(179 15 15 / 0.35) !important;
}

.bg-flower-cider-ruby\/40{
    background-color: rgb(179 15 15 / 0.4) !important;
}

.bg-flower-cider-ruby\/45{
    background-color: rgb(179 15 15 / 0.45) !important;
}

.bg-flower-cider-ruby\/5{
    background-color: rgb(179 15 15 / 0.05) !important;
}

.bg-flower-cider-ruby\/50{
    background-color: rgb(179 15 15 / 0.5) !important;
}

.bg-flower-cider-ruby\/55{
    background-color: rgb(179 15 15 / 0.55) !important;
}

.bg-flower-cider-ruby\/60{
    background-color: rgb(179 15 15 / 0.6) !important;
}

.bg-flower-cider-ruby\/65{
    background-color: rgb(179 15 15 / 0.65) !important;
}

.bg-flower-cider-ruby\/70{
    background-color: rgb(179 15 15 / 0.7) !important;
}

.bg-flower-cider-ruby\/75{
    background-color: rgb(179 15 15 / 0.75) !important;
}

.bg-flower-cider-ruby\/80{
    background-color: rgb(179 15 15 / 0.8) !important;
}

.bg-flower-cider-ruby\/85{
    background-color: rgb(179 15 15 / 0.85) !important;
}

.bg-flower-cider-ruby\/90{
    background-color: rgb(179 15 15 / 0.9) !important;
}

.bg-flower-cider-ruby\/95{
    background-color: rgb(179 15 15 / 0.95) !important;
}

.bg-flower-cider-straw{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 244 180 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-straw\/0{
    background-color: rgb(248 244 180 / 0) !important;
}

.bg-flower-cider-straw\/10{
    background-color: rgb(248 244 180 / 0.1) !important;
}

.bg-flower-cider-straw\/100{
    background-color: rgb(248 244 180 / 1) !important;
}

.bg-flower-cider-straw\/15{
    background-color: rgb(248 244 180 / 0.15) !important;
}

.bg-flower-cider-straw\/20{
    background-color: rgb(248 244 180 / 0.2) !important;
}

.bg-flower-cider-straw\/25{
    background-color: rgb(248 244 180 / 0.25) !important;
}

.bg-flower-cider-straw\/30{
    background-color: rgb(248 244 180 / 0.3) !important;
}

.bg-flower-cider-straw\/35{
    background-color: rgb(248 244 180 / 0.35) !important;
}

.bg-flower-cider-straw\/40{
    background-color: rgb(248 244 180 / 0.4) !important;
}

.bg-flower-cider-straw\/45{
    background-color: rgb(248 244 180 / 0.45) !important;
}

.bg-flower-cider-straw\/5{
    background-color: rgb(248 244 180 / 0.05) !important;
}

.bg-flower-cider-straw\/50{
    background-color: rgb(248 244 180 / 0.5) !important;
}

.bg-flower-cider-straw\/55{
    background-color: rgb(248 244 180 / 0.55) !important;
}

.bg-flower-cider-straw\/60{
    background-color: rgb(248 244 180 / 0.6) !important;
}

.bg-flower-cider-straw\/65{
    background-color: rgb(248 244 180 / 0.65) !important;
}

.bg-flower-cider-straw\/70{
    background-color: rgb(248 244 180 / 0.7) !important;
}

.bg-flower-cider-straw\/75{
    background-color: rgb(248 244 180 / 0.75) !important;
}

.bg-flower-cider-straw\/80{
    background-color: rgb(248 244 180 / 0.8) !important;
}

.bg-flower-cider-straw\/85{
    background-color: rgb(248 244 180 / 0.85) !important;
}

.bg-flower-cider-straw\/90{
    background-color: rgb(248 244 180 / 0.9) !important;
}

.bg-flower-cider-straw\/95{
    background-color: rgb(248 244 180 / 0.95) !important;
}

.bg-flower-cider-yellow{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 224 108 / var(--tw-bg-opacity)) !important;
}

.bg-flower-cider-yellow\/0{
    background-color: rgb(249 224 108 / 0) !important;
}

.bg-flower-cider-yellow\/10{
    background-color: rgb(249 224 108 / 0.1) !important;
}

.bg-flower-cider-yellow\/100{
    background-color: rgb(249 224 108 / 1) !important;
}

.bg-flower-cider-yellow\/15{
    background-color: rgb(249 224 108 / 0.15) !important;
}

.bg-flower-cider-yellow\/20{
    background-color: rgb(249 224 108 / 0.2) !important;
}

.bg-flower-cider-yellow\/25{
    background-color: rgb(249 224 108 / 0.25) !important;
}

.bg-flower-cider-yellow\/30{
    background-color: rgb(249 224 108 / 0.3) !important;
}

.bg-flower-cider-yellow\/35{
    background-color: rgb(249 224 108 / 0.35) !important;
}

.bg-flower-cider-yellow\/40{
    background-color: rgb(249 224 108 / 0.4) !important;
}

.bg-flower-cider-yellow\/45{
    background-color: rgb(249 224 108 / 0.45) !important;
}

.bg-flower-cider-yellow\/5{
    background-color: rgb(249 224 108 / 0.05) !important;
}

.bg-flower-cider-yellow\/50{
    background-color: rgb(249 224 108 / 0.5) !important;
}

.bg-flower-cider-yellow\/55{
    background-color: rgb(249 224 108 / 0.55) !important;
}

.bg-flower-cider-yellow\/60{
    background-color: rgb(249 224 108 / 0.6) !important;
}

.bg-flower-cider-yellow\/65{
    background-color: rgb(249 224 108 / 0.65) !important;
}

.bg-flower-cider-yellow\/70{
    background-color: rgb(249 224 108 / 0.7) !important;
}

.bg-flower-cider-yellow\/75{
    background-color: rgb(249 224 108 / 0.75) !important;
}

.bg-flower-cider-yellow\/80{
    background-color: rgb(249 224 108 / 0.8) !important;
}

.bg-flower-cider-yellow\/85{
    background-color: rgb(249 224 108 / 0.85) !important;
}

.bg-flower-cider-yellow\/90{
    background-color: rgb(249 224 108 / 0.9) !important;
}

.bg-flower-cider-yellow\/95{
    background-color: rgb(249 224 108 / 0.95) !important;
}

.bg-flower-earthy{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(127 96 1 / var(--tw-bg-opacity)) !important;
}

.bg-flower-earthy\/0{
    background-color: rgb(127 96 1 / 0) !important;
}

.bg-flower-earthy\/10{
    background-color: rgb(127 96 1 / 0.1) !important;
}

.bg-flower-earthy\/100{
    background-color: rgb(127 96 1 / 1) !important;
}

.bg-flower-earthy\/15{
    background-color: rgb(127 96 1 / 0.15) !important;
}

.bg-flower-earthy\/20{
    background-color: rgb(127 96 1 / 0.2) !important;
}

.bg-flower-earthy\/25{
    background-color: rgb(127 96 1 / 0.25) !important;
}

.bg-flower-earthy\/30{
    background-color: rgb(127 96 1 / 0.3) !important;
}

.bg-flower-earthy\/35{
    background-color: rgb(127 96 1 / 0.35) !important;
}

.bg-flower-earthy\/40{
    background-color: rgb(127 96 1 / 0.4) !important;
}

.bg-flower-earthy\/45{
    background-color: rgb(127 96 1 / 0.45) !important;
}

.bg-flower-earthy\/5{
    background-color: rgb(127 96 1 / 0.05) !important;
}

.bg-flower-earthy\/50{
    background-color: rgb(127 96 1 / 0.5) !important;
}

.bg-flower-earthy\/55{
    background-color: rgb(127 96 1 / 0.55) !important;
}

.bg-flower-earthy\/60{
    background-color: rgb(127 96 1 / 0.6) !important;
}

.bg-flower-earthy\/65{
    background-color: rgb(127 96 1 / 0.65) !important;
}

.bg-flower-earthy\/70{
    background-color: rgb(127 96 1 / 0.7) !important;
}

.bg-flower-earthy\/75{
    background-color: rgb(127 96 1 / 0.75) !important;
}

.bg-flower-earthy\/80{
    background-color: rgb(127 96 1 / 0.8) !important;
}

.bg-flower-earthy\/85{
    background-color: rgb(127 96 1 / 0.85) !important;
}

.bg-flower-earthy\/90{
    background-color: rgb(127 96 1 / 0.9) !important;
}

.bg-flower-earthy\/95{
    background-color: rgb(127 96 1 / 0.95) !important;
}

.bg-flower-floral{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 153 2 / var(--tw-bg-opacity)) !important;
}

.bg-flower-floral\/0{
    background-color: rgb(255 153 2 / 0) !important;
}

.bg-flower-floral\/10{
    background-color: rgb(255 153 2 / 0.1) !important;
}

.bg-flower-floral\/100{
    background-color: rgb(255 153 2 / 1) !important;
}

.bg-flower-floral\/15{
    background-color: rgb(255 153 2 / 0.15) !important;
}

.bg-flower-floral\/20{
    background-color: rgb(255 153 2 / 0.2) !important;
}

.bg-flower-floral\/25{
    background-color: rgb(255 153 2 / 0.25) !important;
}

.bg-flower-floral\/30{
    background-color: rgb(255 153 2 / 0.3) !important;
}

.bg-flower-floral\/35{
    background-color: rgb(255 153 2 / 0.35) !important;
}

.bg-flower-floral\/40{
    background-color: rgb(255 153 2 / 0.4) !important;
}

.bg-flower-floral\/45{
    background-color: rgb(255 153 2 / 0.45) !important;
}

.bg-flower-floral\/5{
    background-color: rgb(255 153 2 / 0.05) !important;
}

.bg-flower-floral\/50{
    background-color: rgb(255 153 2 / 0.5) !important;
}

.bg-flower-floral\/55{
    background-color: rgb(255 153 2 / 0.55) !important;
}

.bg-flower-floral\/60{
    background-color: rgb(255 153 2 / 0.6) !important;
}

.bg-flower-floral\/65{
    background-color: rgb(255 153 2 / 0.65) !important;
}

.bg-flower-floral\/70{
    background-color: rgb(255 153 2 / 0.7) !important;
}

.bg-flower-floral\/75{
    background-color: rgb(255 153 2 / 0.75) !important;
}

.bg-flower-floral\/80{
    background-color: rgb(255 153 2 / 0.8) !important;
}

.bg-flower-floral\/85{
    background-color: rgb(255 153 2 / 0.85) !important;
}

.bg-flower-floral\/90{
    background-color: rgb(255 153 2 / 0.9) !important;
}

.bg-flower-floral\/95{
    background-color: rgb(255 153 2 / 0.95) !important;
}

.bg-flower-fruity-apple{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(204 1 0 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-apple\/0{
    background-color: rgb(204 1 0 / 0) !important;
}

.bg-flower-fruity-apple\/10{
    background-color: rgb(204 1 0 / 0.1) !important;
}

.bg-flower-fruity-apple\/100{
    background-color: rgb(204 1 0 / 1) !important;
}

.bg-flower-fruity-apple\/15{
    background-color: rgb(204 1 0 / 0.15) !important;
}

.bg-flower-fruity-apple\/20{
    background-color: rgb(204 1 0 / 0.2) !important;
}

.bg-flower-fruity-apple\/25{
    background-color: rgb(204 1 0 / 0.25) !important;
}

.bg-flower-fruity-apple\/30{
    background-color: rgb(204 1 0 / 0.3) !important;
}

.bg-flower-fruity-apple\/35{
    background-color: rgb(204 1 0 / 0.35) !important;
}

.bg-flower-fruity-apple\/40{
    background-color: rgb(204 1 0 / 0.4) !important;
}

.bg-flower-fruity-apple\/45{
    background-color: rgb(204 1 0 / 0.45) !important;
}

.bg-flower-fruity-apple\/5{
    background-color: rgb(204 1 0 / 0.05) !important;
}

.bg-flower-fruity-apple\/50{
    background-color: rgb(204 1 0 / 0.5) !important;
}

.bg-flower-fruity-apple\/55{
    background-color: rgb(204 1 0 / 0.55) !important;
}

.bg-flower-fruity-apple\/60{
    background-color: rgb(204 1 0 / 0.6) !important;
}

.bg-flower-fruity-apple\/65{
    background-color: rgb(204 1 0 / 0.65) !important;
}

.bg-flower-fruity-apple\/70{
    background-color: rgb(204 1 0 / 0.7) !important;
}

.bg-flower-fruity-apple\/75{
    background-color: rgb(204 1 0 / 0.75) !important;
}

.bg-flower-fruity-apple\/80{
    background-color: rgb(204 1 0 / 0.8) !important;
}

.bg-flower-fruity-apple\/85{
    background-color: rgb(204 1 0 / 0.85) !important;
}

.bg-flower-fruity-apple\/90{
    background-color: rgb(204 1 0 / 0.9) !important;
}

.bg-flower-fruity-apple\/95{
    background-color: rgb(204 1 0 / 0.95) !important;
}

.bg-flower-fruity-berry{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(153 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-berry\/0{
    background-color: rgb(153 0 0 / 0) !important;
}

.bg-flower-fruity-berry\/10{
    background-color: rgb(153 0 0 / 0.1) !important;
}

.bg-flower-fruity-berry\/100{
    background-color: rgb(153 0 0 / 1) !important;
}

.bg-flower-fruity-berry\/15{
    background-color: rgb(153 0 0 / 0.15) !important;
}

.bg-flower-fruity-berry\/20{
    background-color: rgb(153 0 0 / 0.2) !important;
}

.bg-flower-fruity-berry\/25{
    background-color: rgb(153 0 0 / 0.25) !important;
}

.bg-flower-fruity-berry\/30{
    background-color: rgb(153 0 0 / 0.3) !important;
}

.bg-flower-fruity-berry\/35{
    background-color: rgb(153 0 0 / 0.35) !important;
}

.bg-flower-fruity-berry\/40{
    background-color: rgb(153 0 0 / 0.4) !important;
}

.bg-flower-fruity-berry\/45{
    background-color: rgb(153 0 0 / 0.45) !important;
}

.bg-flower-fruity-berry\/5{
    background-color: rgb(153 0 0 / 0.05) !important;
}

.bg-flower-fruity-berry\/50{
    background-color: rgb(153 0 0 / 0.5) !important;
}

.bg-flower-fruity-berry\/55{
    background-color: rgb(153 0 0 / 0.55) !important;
}

.bg-flower-fruity-berry\/60{
    background-color: rgb(153 0 0 / 0.6) !important;
}

.bg-flower-fruity-berry\/65{
    background-color: rgb(153 0 0 / 0.65) !important;
}

.bg-flower-fruity-berry\/70{
    background-color: rgb(153 0 0 / 0.7) !important;
}

.bg-flower-fruity-berry\/75{
    background-color: rgb(153 0 0 / 0.75) !important;
}

.bg-flower-fruity-berry\/80{
    background-color: rgb(153 0 0 / 0.8) !important;
}

.bg-flower-fruity-berry\/85{
    background-color: rgb(153 0 0 / 0.85) !important;
}

.bg-flower-fruity-berry\/90{
    background-color: rgb(153 0 0 / 0.9) !important;
}

.bg-flower-fruity-berry\/95{
    background-color: rgb(153 0 0 / 0.95) !important;
}

.bg-flower-fruity-citrus{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 204 204 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-citrus\/0{
    background-color: rgb(244 204 204 / 0) !important;
}

.bg-flower-fruity-citrus\/10{
    background-color: rgb(244 204 204 / 0.1) !important;
}

.bg-flower-fruity-citrus\/100{
    background-color: rgb(244 204 204 / 1) !important;
}

.bg-flower-fruity-citrus\/15{
    background-color: rgb(244 204 204 / 0.15) !important;
}

.bg-flower-fruity-citrus\/20{
    background-color: rgb(244 204 204 / 0.2) !important;
}

.bg-flower-fruity-citrus\/25{
    background-color: rgb(244 204 204 / 0.25) !important;
}

.bg-flower-fruity-citrus\/30{
    background-color: rgb(244 204 204 / 0.3) !important;
}

.bg-flower-fruity-citrus\/35{
    background-color: rgb(244 204 204 / 0.35) !important;
}

.bg-flower-fruity-citrus\/40{
    background-color: rgb(244 204 204 / 0.4) !important;
}

.bg-flower-fruity-citrus\/45{
    background-color: rgb(244 204 204 / 0.45) !important;
}

.bg-flower-fruity-citrus\/5{
    background-color: rgb(244 204 204 / 0.05) !important;
}

.bg-flower-fruity-citrus\/50{
    background-color: rgb(244 204 204 / 0.5) !important;
}

.bg-flower-fruity-citrus\/55{
    background-color: rgb(244 204 204 / 0.55) !important;
}

.bg-flower-fruity-citrus\/60{
    background-color: rgb(244 204 204 / 0.6) !important;
}

.bg-flower-fruity-citrus\/65{
    background-color: rgb(244 204 204 / 0.65) !important;
}

.bg-flower-fruity-citrus\/70{
    background-color: rgb(244 204 204 / 0.7) !important;
}

.bg-flower-fruity-citrus\/75{
    background-color: rgb(244 204 204 / 0.75) !important;
}

.bg-flower-fruity-citrus\/80{
    background-color: rgb(244 204 204 / 0.8) !important;
}

.bg-flower-fruity-citrus\/85{
    background-color: rgb(244 204 204 / 0.85) !important;
}

.bg-flower-fruity-citrus\/90{
    background-color: rgb(244 204 204 / 0.9) !important;
}

.bg-flower-fruity-citrus\/95{
    background-color: rgb(244 204 204 / 0.95) !important;
}

.bg-flower-fruity-fruity{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-fruity\/0{
    background-color: rgb(255 0 0 / 0) !important;
}

.bg-flower-fruity-fruity\/10{
    background-color: rgb(255 0 0 / 0.1) !important;
}

.bg-flower-fruity-fruity\/100{
    background-color: rgb(255 0 0 / 1) !important;
}

.bg-flower-fruity-fruity\/15{
    background-color: rgb(255 0 0 / 0.15) !important;
}

.bg-flower-fruity-fruity\/20{
    background-color: rgb(255 0 0 / 0.2) !important;
}

.bg-flower-fruity-fruity\/25{
    background-color: rgb(255 0 0 / 0.25) !important;
}

.bg-flower-fruity-fruity\/30{
    background-color: rgb(255 0 0 / 0.3) !important;
}

.bg-flower-fruity-fruity\/35{
    background-color: rgb(255 0 0 / 0.35) !important;
}

.bg-flower-fruity-fruity\/40{
    background-color: rgb(255 0 0 / 0.4) !important;
}

.bg-flower-fruity-fruity\/45{
    background-color: rgb(255 0 0 / 0.45) !important;
}

.bg-flower-fruity-fruity\/5{
    background-color: rgb(255 0 0 / 0.05) !important;
}

.bg-flower-fruity-fruity\/50{
    background-color: rgb(255 0 0 / 0.5) !important;
}

.bg-flower-fruity-fruity\/55{
    background-color: rgb(255 0 0 / 0.55) !important;
}

.bg-flower-fruity-fruity\/60{
    background-color: rgb(255 0 0 / 0.6) !important;
}

.bg-flower-fruity-fruity\/65{
    background-color: rgb(255 0 0 / 0.65) !important;
}

.bg-flower-fruity-fruity\/70{
    background-color: rgb(255 0 0 / 0.7) !important;
}

.bg-flower-fruity-fruity\/75{
    background-color: rgb(255 0 0 / 0.75) !important;
}

.bg-flower-fruity-fruity\/80{
    background-color: rgb(255 0 0 / 0.8) !important;
}

.bg-flower-fruity-fruity\/85{
    background-color: rgb(255 0 0 / 0.85) !important;
}

.bg-flower-fruity-fruity\/90{
    background-color: rgb(255 0 0 / 0.9) !important;
}

.bg-flower-fruity-fruity\/95{
    background-color: rgb(255 0 0 / 0.95) !important;
}

.bg-flower-fruity-melon{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(234 153 153 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-melon\/0{
    background-color: rgb(234 153 153 / 0) !important;
}

.bg-flower-fruity-melon\/10{
    background-color: rgb(234 153 153 / 0.1) !important;
}

.bg-flower-fruity-melon\/100{
    background-color: rgb(234 153 153 / 1) !important;
}

.bg-flower-fruity-melon\/15{
    background-color: rgb(234 153 153 / 0.15) !important;
}

.bg-flower-fruity-melon\/20{
    background-color: rgb(234 153 153 / 0.2) !important;
}

.bg-flower-fruity-melon\/25{
    background-color: rgb(234 153 153 / 0.25) !important;
}

.bg-flower-fruity-melon\/30{
    background-color: rgb(234 153 153 / 0.3) !important;
}

.bg-flower-fruity-melon\/35{
    background-color: rgb(234 153 153 / 0.35) !important;
}

.bg-flower-fruity-melon\/40{
    background-color: rgb(234 153 153 / 0.4) !important;
}

.bg-flower-fruity-melon\/45{
    background-color: rgb(234 153 153 / 0.45) !important;
}

.bg-flower-fruity-melon\/5{
    background-color: rgb(234 153 153 / 0.05) !important;
}

.bg-flower-fruity-melon\/50{
    background-color: rgb(234 153 153 / 0.5) !important;
}

.bg-flower-fruity-melon\/55{
    background-color: rgb(234 153 153 / 0.55) !important;
}

.bg-flower-fruity-melon\/60{
    background-color: rgb(234 153 153 / 0.6) !important;
}

.bg-flower-fruity-melon\/65{
    background-color: rgb(234 153 153 / 0.65) !important;
}

.bg-flower-fruity-melon\/70{
    background-color: rgb(234 153 153 / 0.7) !important;
}

.bg-flower-fruity-melon\/75{
    background-color: rgb(234 153 153 / 0.75) !important;
}

.bg-flower-fruity-melon\/80{
    background-color: rgb(234 153 153 / 0.8) !important;
}

.bg-flower-fruity-melon\/85{
    background-color: rgb(234 153 153 / 0.85) !important;
}

.bg-flower-fruity-melon\/90{
    background-color: rgb(234 153 153 / 0.9) !important;
}

.bg-flower-fruity-melon\/95{
    background-color: rgb(234 153 153 / 0.95) !important;
}

.bg-flower-fruity-stonefruit{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 102 102 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-stonefruit\/0{
    background-color: rgb(224 102 102 / 0) !important;
}

.bg-flower-fruity-stonefruit\/10{
    background-color: rgb(224 102 102 / 0.1) !important;
}

.bg-flower-fruity-stonefruit\/100{
    background-color: rgb(224 102 102 / 1) !important;
}

.bg-flower-fruity-stonefruit\/15{
    background-color: rgb(224 102 102 / 0.15) !important;
}

.bg-flower-fruity-stonefruit\/20{
    background-color: rgb(224 102 102 / 0.2) !important;
}

.bg-flower-fruity-stonefruit\/25{
    background-color: rgb(224 102 102 / 0.25) !important;
}

.bg-flower-fruity-stonefruit\/30{
    background-color: rgb(224 102 102 / 0.3) !important;
}

.bg-flower-fruity-stonefruit\/35{
    background-color: rgb(224 102 102 / 0.35) !important;
}

.bg-flower-fruity-stonefruit\/40{
    background-color: rgb(224 102 102 / 0.4) !important;
}

.bg-flower-fruity-stonefruit\/45{
    background-color: rgb(224 102 102 / 0.45) !important;
}

.bg-flower-fruity-stonefruit\/5{
    background-color: rgb(224 102 102 / 0.05) !important;
}

.bg-flower-fruity-stonefruit\/50{
    background-color: rgb(224 102 102 / 0.5) !important;
}

.bg-flower-fruity-stonefruit\/55{
    background-color: rgb(224 102 102 / 0.55) !important;
}

.bg-flower-fruity-stonefruit\/60{
    background-color: rgb(224 102 102 / 0.6) !important;
}

.bg-flower-fruity-stonefruit\/65{
    background-color: rgb(224 102 102 / 0.65) !important;
}

.bg-flower-fruity-stonefruit\/70{
    background-color: rgb(224 102 102 / 0.7) !important;
}

.bg-flower-fruity-stonefruit\/75{
    background-color: rgb(224 102 102 / 0.75) !important;
}

.bg-flower-fruity-stonefruit\/80{
    background-color: rgb(224 102 102 / 0.8) !important;
}

.bg-flower-fruity-stonefruit\/85{
    background-color: rgb(224 102 102 / 0.85) !important;
}

.bg-flower-fruity-stonefruit\/90{
    background-color: rgb(224 102 102 / 0.9) !important;
}

.bg-flower-fruity-stonefruit\/95{
    background-color: rgb(224 102 102 / 0.95) !important;
}

.bg-flower-fruity-tropical{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(234 153 153 / var(--tw-bg-opacity)) !important;
}

.bg-flower-fruity-tropical\/0{
    background-color: rgb(234 153 153 / 0) !important;
}

.bg-flower-fruity-tropical\/10{
    background-color: rgb(234 153 153 / 0.1) !important;
}

.bg-flower-fruity-tropical\/100{
    background-color: rgb(234 153 153 / 1) !important;
}

.bg-flower-fruity-tropical\/15{
    background-color: rgb(234 153 153 / 0.15) !important;
}

.bg-flower-fruity-tropical\/20{
    background-color: rgb(234 153 153 / 0.2) !important;
}

.bg-flower-fruity-tropical\/25{
    background-color: rgb(234 153 153 / 0.25) !important;
}

.bg-flower-fruity-tropical\/30{
    background-color: rgb(234 153 153 / 0.3) !important;
}

.bg-flower-fruity-tropical\/35{
    background-color: rgb(234 153 153 / 0.35) !important;
}

.bg-flower-fruity-tropical\/40{
    background-color: rgb(234 153 153 / 0.4) !important;
}

.bg-flower-fruity-tropical\/45{
    background-color: rgb(234 153 153 / 0.45) !important;
}

.bg-flower-fruity-tropical\/5{
    background-color: rgb(234 153 153 / 0.05) !important;
}

.bg-flower-fruity-tropical\/50{
    background-color: rgb(234 153 153 / 0.5) !important;
}

.bg-flower-fruity-tropical\/55{
    background-color: rgb(234 153 153 / 0.55) !important;
}

.bg-flower-fruity-tropical\/60{
    background-color: rgb(234 153 153 / 0.6) !important;
}

.bg-flower-fruity-tropical\/65{
    background-color: rgb(234 153 153 / 0.65) !important;
}

.bg-flower-fruity-tropical\/70{
    background-color: rgb(234 153 153 / 0.7) !important;
}

.bg-flower-fruity-tropical\/75{
    background-color: rgb(234 153 153 / 0.75) !important;
}

.bg-flower-fruity-tropical\/80{
    background-color: rgb(234 153 153 / 0.8) !important;
}

.bg-flower-fruity-tropical\/85{
    background-color: rgb(234 153 153 / 0.85) !important;
}

.bg-flower-fruity-tropical\/90{
    background-color: rgb(234 153 153 / 0.9) !important;
}

.bg-flower-fruity-tropical\/95{
    background-color: rgb(234 153 153 / 0.95) !important;
}

.bg-flower-grassy{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(146 196 124 / var(--tw-bg-opacity)) !important;
}

.bg-flower-grassy\/0{
    background-color: rgb(146 196 124 / 0) !important;
}

.bg-flower-grassy\/10{
    background-color: rgb(146 196 124 / 0.1) !important;
}

.bg-flower-grassy\/100{
    background-color: rgb(146 196 124 / 1) !important;
}

.bg-flower-grassy\/15{
    background-color: rgb(146 196 124 / 0.15) !important;
}

.bg-flower-grassy\/20{
    background-color: rgb(146 196 124 / 0.2) !important;
}

.bg-flower-grassy\/25{
    background-color: rgb(146 196 124 / 0.25) !important;
}

.bg-flower-grassy\/30{
    background-color: rgb(146 196 124 / 0.3) !important;
}

.bg-flower-grassy\/35{
    background-color: rgb(146 196 124 / 0.35) !important;
}

.bg-flower-grassy\/40{
    background-color: rgb(146 196 124 / 0.4) !important;
}

.bg-flower-grassy\/45{
    background-color: rgb(146 196 124 / 0.45) !important;
}

.bg-flower-grassy\/5{
    background-color: rgb(146 196 124 / 0.05) !important;
}

.bg-flower-grassy\/50{
    background-color: rgb(146 196 124 / 0.5) !important;
}

.bg-flower-grassy\/55{
    background-color: rgb(146 196 124 / 0.55) !important;
}

.bg-flower-grassy\/60{
    background-color: rgb(146 196 124 / 0.6) !important;
}

.bg-flower-grassy\/65{
    background-color: rgb(146 196 124 / 0.65) !important;
}

.bg-flower-grassy\/70{
    background-color: rgb(146 196 124 / 0.7) !important;
}

.bg-flower-grassy\/75{
    background-color: rgb(146 196 124 / 0.75) !important;
}

.bg-flower-grassy\/80{
    background-color: rgb(146 196 124 / 0.8) !important;
}

.bg-flower-grassy\/85{
    background-color: rgb(146 196 124 / 0.85) !important;
}

.bg-flower-grassy\/90{
    background-color: rgb(146 196 124 / 0.9) !important;
}

.bg-flower-grassy\/95{
    background-color: rgb(146 196 124 / 0.95) !important;
}

.bg-flower-herbaceous{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 4 / var(--tw-bg-opacity)) !important;
}

.bg-flower-herbaceous\/0{
    background-color: rgb(255 255 4 / 0) !important;
}

.bg-flower-herbaceous\/10{
    background-color: rgb(255 255 4 / 0.1) !important;
}

.bg-flower-herbaceous\/100{
    background-color: rgb(255 255 4 / 1) !important;
}

.bg-flower-herbaceous\/15{
    background-color: rgb(255 255 4 / 0.15) !important;
}

.bg-flower-herbaceous\/20{
    background-color: rgb(255 255 4 / 0.2) !important;
}

.bg-flower-herbaceous\/25{
    background-color: rgb(255 255 4 / 0.25) !important;
}

.bg-flower-herbaceous\/30{
    background-color: rgb(255 255 4 / 0.3) !important;
}

.bg-flower-herbaceous\/35{
    background-color: rgb(255 255 4 / 0.35) !important;
}

.bg-flower-herbaceous\/40{
    background-color: rgb(255 255 4 / 0.4) !important;
}

.bg-flower-herbaceous\/45{
    background-color: rgb(255 255 4 / 0.45) !important;
}

.bg-flower-herbaceous\/5{
    background-color: rgb(255 255 4 / 0.05) !important;
}

.bg-flower-herbaceous\/50{
    background-color: rgb(255 255 4 / 0.5) !important;
}

.bg-flower-herbaceous\/55{
    background-color: rgb(255 255 4 / 0.55) !important;
}

.bg-flower-herbaceous\/60{
    background-color: rgb(255 255 4 / 0.6) !important;
}

.bg-flower-herbaceous\/65{
    background-color: rgb(255 255 4 / 0.65) !important;
}

.bg-flower-herbaceous\/70{
    background-color: rgb(255 255 4 / 0.7) !important;
}

.bg-flower-herbaceous\/75{
    background-color: rgb(255 255 4 / 0.75) !important;
}

.bg-flower-herbaceous\/80{
    background-color: rgb(255 255 4 / 0.8) !important;
}

.bg-flower-herbaceous\/85{
    background-color: rgb(255 255 4 / 0.85) !important;
}

.bg-flower-herbaceous\/90{
    background-color: rgb(255 255 4 / 0.9) !important;
}

.bg-flower-herbaceous\/95{
    background-color: rgb(255 255 4 / 0.95) !important;
}

.bg-flower-spicy{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(180 95 5 / var(--tw-bg-opacity)) !important;
}

.bg-flower-spicy\/0{
    background-color: rgb(180 95 5 / 0) !important;
}

.bg-flower-spicy\/10{
    background-color: rgb(180 95 5 / 0.1) !important;
}

.bg-flower-spicy\/100{
    background-color: rgb(180 95 5 / 1) !important;
}

.bg-flower-spicy\/15{
    background-color: rgb(180 95 5 / 0.15) !important;
}

.bg-flower-spicy\/20{
    background-color: rgb(180 95 5 / 0.2) !important;
}

.bg-flower-spicy\/25{
    background-color: rgb(180 95 5 / 0.25) !important;
}

.bg-flower-spicy\/30{
    background-color: rgb(180 95 5 / 0.3) !important;
}

.bg-flower-spicy\/35{
    background-color: rgb(180 95 5 / 0.35) !important;
}

.bg-flower-spicy\/40{
    background-color: rgb(180 95 5 / 0.4) !important;
}

.bg-flower-spicy\/45{
    background-color: rgb(180 95 5 / 0.45) !important;
}

.bg-flower-spicy\/5{
    background-color: rgb(180 95 5 / 0.05) !important;
}

.bg-flower-spicy\/50{
    background-color: rgb(180 95 5 / 0.5) !important;
}

.bg-flower-spicy\/55{
    background-color: rgb(180 95 5 / 0.55) !important;
}

.bg-flower-spicy\/60{
    background-color: rgb(180 95 5 / 0.6) !important;
}

.bg-flower-spicy\/65{
    background-color: rgb(180 95 5 / 0.65) !important;
}

.bg-flower-spicy\/70{
    background-color: rgb(180 95 5 / 0.7) !important;
}

.bg-flower-spicy\/75{
    background-color: rgb(180 95 5 / 0.75) !important;
}

.bg-flower-spicy\/80{
    background-color: rgb(180 95 5 / 0.8) !important;
}

.bg-flower-spicy\/85{
    background-color: rgb(180 95 5 / 0.85) !important;
}

.bg-flower-spicy\/90{
    background-color: rgb(180 95 5 / 0.9) !important;
}

.bg-flower-spicy\/95{
    background-color: rgb(180 95 5 / 0.95) !important;
}

.bg-flower-sweetaromatic{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(116 28 71 / var(--tw-bg-opacity)) !important;
}

.bg-flower-sweetaromatic\/0{
    background-color: rgb(116 28 71 / 0) !important;
}

.bg-flower-sweetaromatic\/10{
    background-color: rgb(116 28 71 / 0.1) !important;
}

.bg-flower-sweetaromatic\/100{
    background-color: rgb(116 28 71 / 1) !important;
}

.bg-flower-sweetaromatic\/15{
    background-color: rgb(116 28 71 / 0.15) !important;
}

.bg-flower-sweetaromatic\/20{
    background-color: rgb(116 28 71 / 0.2) !important;
}

.bg-flower-sweetaromatic\/25{
    background-color: rgb(116 28 71 / 0.25) !important;
}

.bg-flower-sweetaromatic\/30{
    background-color: rgb(116 28 71 / 0.3) !important;
}

.bg-flower-sweetaromatic\/35{
    background-color: rgb(116 28 71 / 0.35) !important;
}

.bg-flower-sweetaromatic\/40{
    background-color: rgb(116 28 71 / 0.4) !important;
}

.bg-flower-sweetaromatic\/45{
    background-color: rgb(116 28 71 / 0.45) !important;
}

.bg-flower-sweetaromatic\/5{
    background-color: rgb(116 28 71 / 0.05) !important;
}

.bg-flower-sweetaromatic\/50{
    background-color: rgb(116 28 71 / 0.5) !important;
}

.bg-flower-sweetaromatic\/55{
    background-color: rgb(116 28 71 / 0.55) !important;
}

.bg-flower-sweetaromatic\/60{
    background-color: rgb(116 28 71 / 0.6) !important;
}

.bg-flower-sweetaromatic\/65{
    background-color: rgb(116 28 71 / 0.65) !important;
}

.bg-flower-sweetaromatic\/70{
    background-color: rgb(116 28 71 / 0.7) !important;
}

.bg-flower-sweetaromatic\/75{
    background-color: rgb(116 28 71 / 0.75) !important;
}

.bg-flower-sweetaromatic\/80{
    background-color: rgb(116 28 71 / 0.8) !important;
}

.bg-flower-sweetaromatic\/85{
    background-color: rgb(116 28 71 / 0.85) !important;
}

.bg-flower-sweetaromatic\/90{
    background-color: rgb(116 28 71 / 0.9) !important;
}

.bg-flower-sweetaromatic\/95{
    background-color: rgb(116 28 71 / 0.95) !important;
}

.bg-flower-vegetal{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(40 78 19 / var(--tw-bg-opacity)) !important;
}

.bg-flower-vegetal\/0{
    background-color: rgb(40 78 19 / 0) !important;
}

.bg-flower-vegetal\/10{
    background-color: rgb(40 78 19 / 0.1) !important;
}

.bg-flower-vegetal\/100{
    background-color: rgb(40 78 19 / 1) !important;
}

.bg-flower-vegetal\/15{
    background-color: rgb(40 78 19 / 0.15) !important;
}

.bg-flower-vegetal\/20{
    background-color: rgb(40 78 19 / 0.2) !important;
}

.bg-flower-vegetal\/25{
    background-color: rgb(40 78 19 / 0.25) !important;
}

.bg-flower-vegetal\/30{
    background-color: rgb(40 78 19 / 0.3) !important;
}

.bg-flower-vegetal\/35{
    background-color: rgb(40 78 19 / 0.35) !important;
}

.bg-flower-vegetal\/40{
    background-color: rgb(40 78 19 / 0.4) !important;
}

.bg-flower-vegetal\/45{
    background-color: rgb(40 78 19 / 0.45) !important;
}

.bg-flower-vegetal\/5{
    background-color: rgb(40 78 19 / 0.05) !important;
}

.bg-flower-vegetal\/50{
    background-color: rgb(40 78 19 / 0.5) !important;
}

.bg-flower-vegetal\/55{
    background-color: rgb(40 78 19 / 0.55) !important;
}

.bg-flower-vegetal\/60{
    background-color: rgb(40 78 19 / 0.6) !important;
}

.bg-flower-vegetal\/65{
    background-color: rgb(40 78 19 / 0.65) !important;
}

.bg-flower-vegetal\/70{
    background-color: rgb(40 78 19 / 0.7) !important;
}

.bg-flower-vegetal\/75{
    background-color: rgb(40 78 19 / 0.75) !important;
}

.bg-flower-vegetal\/80{
    background-color: rgb(40 78 19 / 0.8) !important;
}

.bg-flower-vegetal\/85{
    background-color: rgb(40 78 19 / 0.85) !important;
}

.bg-flower-vegetal\/90{
    background-color: rgb(40 78 19 / 0.9) !important;
}

.bg-flower-vegetal\/95{
    background-color: rgb(40 78 19 / 0.95) !important;
}

.bg-flower-woody{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(191 144 1 / var(--tw-bg-opacity)) !important;
}

.bg-flower-woody\/0{
    background-color: rgb(191 144 1 / 0) !important;
}

.bg-flower-woody\/10{
    background-color: rgb(191 144 1 / 0.1) !important;
}

.bg-flower-woody\/100{
    background-color: rgb(191 144 1 / 1) !important;
}

.bg-flower-woody\/15{
    background-color: rgb(191 144 1 / 0.15) !important;
}

.bg-flower-woody\/20{
    background-color: rgb(191 144 1 / 0.2) !important;
}

.bg-flower-woody\/25{
    background-color: rgb(191 144 1 / 0.25) !important;
}

.bg-flower-woody\/30{
    background-color: rgb(191 144 1 / 0.3) !important;
}

.bg-flower-woody\/35{
    background-color: rgb(191 144 1 / 0.35) !important;
}

.bg-flower-woody\/40{
    background-color: rgb(191 144 1 / 0.4) !important;
}

.bg-flower-woody\/45{
    background-color: rgb(191 144 1 / 0.45) !important;
}

.bg-flower-woody\/5{
    background-color: rgb(191 144 1 / 0.05) !important;
}

.bg-flower-woody\/50{
    background-color: rgb(191 144 1 / 0.5) !important;
}

.bg-flower-woody\/55{
    background-color: rgb(191 144 1 / 0.55) !important;
}

.bg-flower-woody\/60{
    background-color: rgb(191 144 1 / 0.6) !important;
}

.bg-flower-woody\/65{
    background-color: rgb(191 144 1 / 0.65) !important;
}

.bg-flower-woody\/70{
    background-color: rgb(191 144 1 / 0.7) !important;
}

.bg-flower-woody\/75{
    background-color: rgb(191 144 1 / 0.75) !important;
}

.bg-flower-woody\/80{
    background-color: rgb(191 144 1 / 0.8) !important;
}

.bg-flower-woody\/85{
    background-color: rgb(191 144 1 / 0.85) !important;
}

.bg-flower-woody\/90{
    background-color: rgb(191 144 1 / 0.9) !important;
}

.bg-flower-woody\/95{
    background-color: rgb(191 144 1 / 0.95) !important;
}

.bg-fuchsia-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(250 232 255 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-100\/0{
    background-color: rgb(250 232 255 / 0) !important;
}

.bg-fuchsia-100\/10{
    background-color: rgb(250 232 255 / 0.1) !important;
}

.bg-fuchsia-100\/100{
    background-color: rgb(250 232 255 / 1) !important;
}

.bg-fuchsia-100\/15{
    background-color: rgb(250 232 255 / 0.15) !important;
}

.bg-fuchsia-100\/20{
    background-color: rgb(250 232 255 / 0.2) !important;
}

.bg-fuchsia-100\/25{
    background-color: rgb(250 232 255 / 0.25) !important;
}

.bg-fuchsia-100\/30{
    background-color: rgb(250 232 255 / 0.3) !important;
}

.bg-fuchsia-100\/35{
    background-color: rgb(250 232 255 / 0.35) !important;
}

.bg-fuchsia-100\/40{
    background-color: rgb(250 232 255 / 0.4) !important;
}

.bg-fuchsia-100\/45{
    background-color: rgb(250 232 255 / 0.45) !important;
}

.bg-fuchsia-100\/5{
    background-color: rgb(250 232 255 / 0.05) !important;
}

.bg-fuchsia-100\/50{
    background-color: rgb(250 232 255 / 0.5) !important;
}

.bg-fuchsia-100\/55{
    background-color: rgb(250 232 255 / 0.55) !important;
}

.bg-fuchsia-100\/60{
    background-color: rgb(250 232 255 / 0.6) !important;
}

.bg-fuchsia-100\/65{
    background-color: rgb(250 232 255 / 0.65) !important;
}

.bg-fuchsia-100\/70{
    background-color: rgb(250 232 255 / 0.7) !important;
}

.bg-fuchsia-100\/75{
    background-color: rgb(250 232 255 / 0.75) !important;
}

.bg-fuchsia-100\/80{
    background-color: rgb(250 232 255 / 0.8) !important;
}

.bg-fuchsia-100\/85{
    background-color: rgb(250 232 255 / 0.85) !important;
}

.bg-fuchsia-100\/90{
    background-color: rgb(250 232 255 / 0.9) !important;
}

.bg-fuchsia-100\/95{
    background-color: rgb(250 232 255 / 0.95) !important;
}

.bg-fuchsia-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(245 208 254 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-200\/0{
    background-color: rgb(245 208 254 / 0) !important;
}

.bg-fuchsia-200\/10{
    background-color: rgb(245 208 254 / 0.1) !important;
}

.bg-fuchsia-200\/100{
    background-color: rgb(245 208 254 / 1) !important;
}

.bg-fuchsia-200\/15{
    background-color: rgb(245 208 254 / 0.15) !important;
}

.bg-fuchsia-200\/20{
    background-color: rgb(245 208 254 / 0.2) !important;
}

.bg-fuchsia-200\/25{
    background-color: rgb(245 208 254 / 0.25) !important;
}

.bg-fuchsia-200\/30{
    background-color: rgb(245 208 254 / 0.3) !important;
}

.bg-fuchsia-200\/35{
    background-color: rgb(245 208 254 / 0.35) !important;
}

.bg-fuchsia-200\/40{
    background-color: rgb(245 208 254 / 0.4) !important;
}

.bg-fuchsia-200\/45{
    background-color: rgb(245 208 254 / 0.45) !important;
}

.bg-fuchsia-200\/5{
    background-color: rgb(245 208 254 / 0.05) !important;
}

.bg-fuchsia-200\/50{
    background-color: rgb(245 208 254 / 0.5) !important;
}

.bg-fuchsia-200\/55{
    background-color: rgb(245 208 254 / 0.55) !important;
}

.bg-fuchsia-200\/60{
    background-color: rgb(245 208 254 / 0.6) !important;
}

.bg-fuchsia-200\/65{
    background-color: rgb(245 208 254 / 0.65) !important;
}

.bg-fuchsia-200\/70{
    background-color: rgb(245 208 254 / 0.7) !important;
}

.bg-fuchsia-200\/75{
    background-color: rgb(245 208 254 / 0.75) !important;
}

.bg-fuchsia-200\/80{
    background-color: rgb(245 208 254 / 0.8) !important;
}

.bg-fuchsia-200\/85{
    background-color: rgb(245 208 254 / 0.85) !important;
}

.bg-fuchsia-200\/90{
    background-color: rgb(245 208 254 / 0.9) !important;
}

.bg-fuchsia-200\/95{
    background-color: rgb(245 208 254 / 0.95) !important;
}

.bg-fuchsia-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 171 252 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-300\/0{
    background-color: rgb(240 171 252 / 0) !important;
}

.bg-fuchsia-300\/10{
    background-color: rgb(240 171 252 / 0.1) !important;
}

.bg-fuchsia-300\/100{
    background-color: rgb(240 171 252 / 1) !important;
}

.bg-fuchsia-300\/15{
    background-color: rgb(240 171 252 / 0.15) !important;
}

.bg-fuchsia-300\/20{
    background-color: rgb(240 171 252 / 0.2) !important;
}

.bg-fuchsia-300\/25{
    background-color: rgb(240 171 252 / 0.25) !important;
}

.bg-fuchsia-300\/30{
    background-color: rgb(240 171 252 / 0.3) !important;
}

.bg-fuchsia-300\/35{
    background-color: rgb(240 171 252 / 0.35) !important;
}

.bg-fuchsia-300\/40{
    background-color: rgb(240 171 252 / 0.4) !important;
}

.bg-fuchsia-300\/45{
    background-color: rgb(240 171 252 / 0.45) !important;
}

.bg-fuchsia-300\/5{
    background-color: rgb(240 171 252 / 0.05) !important;
}

.bg-fuchsia-300\/50{
    background-color: rgb(240 171 252 / 0.5) !important;
}

.bg-fuchsia-300\/55{
    background-color: rgb(240 171 252 / 0.55) !important;
}

.bg-fuchsia-300\/60{
    background-color: rgb(240 171 252 / 0.6) !important;
}

.bg-fuchsia-300\/65{
    background-color: rgb(240 171 252 / 0.65) !important;
}

.bg-fuchsia-300\/70{
    background-color: rgb(240 171 252 / 0.7) !important;
}

.bg-fuchsia-300\/75{
    background-color: rgb(240 171 252 / 0.75) !important;
}

.bg-fuchsia-300\/80{
    background-color: rgb(240 171 252 / 0.8) !important;
}

.bg-fuchsia-300\/85{
    background-color: rgb(240 171 252 / 0.85) !important;
}

.bg-fuchsia-300\/90{
    background-color: rgb(240 171 252 / 0.9) !important;
}

.bg-fuchsia-300\/95{
    background-color: rgb(240 171 252 / 0.95) !important;
}

.bg-fuchsia-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(232 121 249 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-400\/0{
    background-color: rgb(232 121 249 / 0) !important;
}

.bg-fuchsia-400\/10{
    background-color: rgb(232 121 249 / 0.1) !important;
}

.bg-fuchsia-400\/100{
    background-color: rgb(232 121 249 / 1) !important;
}

.bg-fuchsia-400\/15{
    background-color: rgb(232 121 249 / 0.15) !important;
}

.bg-fuchsia-400\/20{
    background-color: rgb(232 121 249 / 0.2) !important;
}

.bg-fuchsia-400\/25{
    background-color: rgb(232 121 249 / 0.25) !important;
}

.bg-fuchsia-400\/30{
    background-color: rgb(232 121 249 / 0.3) !important;
}

.bg-fuchsia-400\/35{
    background-color: rgb(232 121 249 / 0.35) !important;
}

.bg-fuchsia-400\/40{
    background-color: rgb(232 121 249 / 0.4) !important;
}

.bg-fuchsia-400\/45{
    background-color: rgb(232 121 249 / 0.45) !important;
}

.bg-fuchsia-400\/5{
    background-color: rgb(232 121 249 / 0.05) !important;
}

.bg-fuchsia-400\/50{
    background-color: rgb(232 121 249 / 0.5) !important;
}

.bg-fuchsia-400\/55{
    background-color: rgb(232 121 249 / 0.55) !important;
}

.bg-fuchsia-400\/60{
    background-color: rgb(232 121 249 / 0.6) !important;
}

.bg-fuchsia-400\/65{
    background-color: rgb(232 121 249 / 0.65) !important;
}

.bg-fuchsia-400\/70{
    background-color: rgb(232 121 249 / 0.7) !important;
}

.bg-fuchsia-400\/75{
    background-color: rgb(232 121 249 / 0.75) !important;
}

.bg-fuchsia-400\/80{
    background-color: rgb(232 121 249 / 0.8) !important;
}

.bg-fuchsia-400\/85{
    background-color: rgb(232 121 249 / 0.85) !important;
}

.bg-fuchsia-400\/90{
    background-color: rgb(232 121 249 / 0.9) !important;
}

.bg-fuchsia-400\/95{
    background-color: rgb(232 121 249 / 0.95) !important;
}

.bg-fuchsia-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 244 255 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-50\/0{
    background-color: rgb(253 244 255 / 0) !important;
}

.bg-fuchsia-50\/10{
    background-color: rgb(253 244 255 / 0.1) !important;
}

.bg-fuchsia-50\/100{
    background-color: rgb(253 244 255 / 1) !important;
}

.bg-fuchsia-50\/15{
    background-color: rgb(253 244 255 / 0.15) !important;
}

.bg-fuchsia-50\/20{
    background-color: rgb(253 244 255 / 0.2) !important;
}

.bg-fuchsia-50\/25{
    background-color: rgb(253 244 255 / 0.25) !important;
}

.bg-fuchsia-50\/30{
    background-color: rgb(253 244 255 / 0.3) !important;
}

.bg-fuchsia-50\/35{
    background-color: rgb(253 244 255 / 0.35) !important;
}

.bg-fuchsia-50\/40{
    background-color: rgb(253 244 255 / 0.4) !important;
}

.bg-fuchsia-50\/45{
    background-color: rgb(253 244 255 / 0.45) !important;
}

.bg-fuchsia-50\/5{
    background-color: rgb(253 244 255 / 0.05) !important;
}

.bg-fuchsia-50\/50{
    background-color: rgb(253 244 255 / 0.5) !important;
}

.bg-fuchsia-50\/55{
    background-color: rgb(253 244 255 / 0.55) !important;
}

.bg-fuchsia-50\/60{
    background-color: rgb(253 244 255 / 0.6) !important;
}

.bg-fuchsia-50\/65{
    background-color: rgb(253 244 255 / 0.65) !important;
}

.bg-fuchsia-50\/70{
    background-color: rgb(253 244 255 / 0.7) !important;
}

.bg-fuchsia-50\/75{
    background-color: rgb(253 244 255 / 0.75) !important;
}

.bg-fuchsia-50\/80{
    background-color: rgb(253 244 255 / 0.8) !important;
}

.bg-fuchsia-50\/85{
    background-color: rgb(253 244 255 / 0.85) !important;
}

.bg-fuchsia-50\/90{
    background-color: rgb(253 244 255 / 0.9) !important;
}

.bg-fuchsia-50\/95{
    background-color: rgb(253 244 255 / 0.95) !important;
}

.bg-fuchsia-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(217 70 239 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-500\/0{
    background-color: rgb(217 70 239 / 0) !important;
}

.bg-fuchsia-500\/10{
    background-color: rgb(217 70 239 / 0.1) !important;
}

.bg-fuchsia-500\/100{
    background-color: rgb(217 70 239 / 1) !important;
}

.bg-fuchsia-500\/15{
    background-color: rgb(217 70 239 / 0.15) !important;
}

.bg-fuchsia-500\/20{
    background-color: rgb(217 70 239 / 0.2) !important;
}

.bg-fuchsia-500\/25{
    background-color: rgb(217 70 239 / 0.25) !important;
}

.bg-fuchsia-500\/30{
    background-color: rgb(217 70 239 / 0.3) !important;
}

.bg-fuchsia-500\/35{
    background-color: rgb(217 70 239 / 0.35) !important;
}

.bg-fuchsia-500\/40{
    background-color: rgb(217 70 239 / 0.4) !important;
}

.bg-fuchsia-500\/45{
    background-color: rgb(217 70 239 / 0.45) !important;
}

.bg-fuchsia-500\/5{
    background-color: rgb(217 70 239 / 0.05) !important;
}

.bg-fuchsia-500\/50{
    background-color: rgb(217 70 239 / 0.5) !important;
}

.bg-fuchsia-500\/55{
    background-color: rgb(217 70 239 / 0.55) !important;
}

.bg-fuchsia-500\/60{
    background-color: rgb(217 70 239 / 0.6) !important;
}

.bg-fuchsia-500\/65{
    background-color: rgb(217 70 239 / 0.65) !important;
}

.bg-fuchsia-500\/70{
    background-color: rgb(217 70 239 / 0.7) !important;
}

.bg-fuchsia-500\/75{
    background-color: rgb(217 70 239 / 0.75) !important;
}

.bg-fuchsia-500\/80{
    background-color: rgb(217 70 239 / 0.8) !important;
}

.bg-fuchsia-500\/85{
    background-color: rgb(217 70 239 / 0.85) !important;
}

.bg-fuchsia-500\/90{
    background-color: rgb(217 70 239 / 0.9) !important;
}

.bg-fuchsia-500\/95{
    background-color: rgb(217 70 239 / 0.95) !important;
}

.bg-fuchsia-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(192 38 211 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-600\/0{
    background-color: rgb(192 38 211 / 0) !important;
}

.bg-fuchsia-600\/10{
    background-color: rgb(192 38 211 / 0.1) !important;
}

.bg-fuchsia-600\/100{
    background-color: rgb(192 38 211 / 1) !important;
}

.bg-fuchsia-600\/15{
    background-color: rgb(192 38 211 / 0.15) !important;
}

.bg-fuchsia-600\/20{
    background-color: rgb(192 38 211 / 0.2) !important;
}

.bg-fuchsia-600\/25{
    background-color: rgb(192 38 211 / 0.25) !important;
}

.bg-fuchsia-600\/30{
    background-color: rgb(192 38 211 / 0.3) !important;
}

.bg-fuchsia-600\/35{
    background-color: rgb(192 38 211 / 0.35) !important;
}

.bg-fuchsia-600\/40{
    background-color: rgb(192 38 211 / 0.4) !important;
}

.bg-fuchsia-600\/45{
    background-color: rgb(192 38 211 / 0.45) !important;
}

.bg-fuchsia-600\/5{
    background-color: rgb(192 38 211 / 0.05) !important;
}

.bg-fuchsia-600\/50{
    background-color: rgb(192 38 211 / 0.5) !important;
}

.bg-fuchsia-600\/55{
    background-color: rgb(192 38 211 / 0.55) !important;
}

.bg-fuchsia-600\/60{
    background-color: rgb(192 38 211 / 0.6) !important;
}

.bg-fuchsia-600\/65{
    background-color: rgb(192 38 211 / 0.65) !important;
}

.bg-fuchsia-600\/70{
    background-color: rgb(192 38 211 / 0.7) !important;
}

.bg-fuchsia-600\/75{
    background-color: rgb(192 38 211 / 0.75) !important;
}

.bg-fuchsia-600\/80{
    background-color: rgb(192 38 211 / 0.8) !important;
}

.bg-fuchsia-600\/85{
    background-color: rgb(192 38 211 / 0.85) !important;
}

.bg-fuchsia-600\/90{
    background-color: rgb(192 38 211 / 0.9) !important;
}

.bg-fuchsia-600\/95{
    background-color: rgb(192 38 211 / 0.95) !important;
}

.bg-fuchsia-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(162 28 175 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-700\/0{
    background-color: rgb(162 28 175 / 0) !important;
}

.bg-fuchsia-700\/10{
    background-color: rgb(162 28 175 / 0.1) !important;
}

.bg-fuchsia-700\/100{
    background-color: rgb(162 28 175 / 1) !important;
}

.bg-fuchsia-700\/15{
    background-color: rgb(162 28 175 / 0.15) !important;
}

.bg-fuchsia-700\/20{
    background-color: rgb(162 28 175 / 0.2) !important;
}

.bg-fuchsia-700\/25{
    background-color: rgb(162 28 175 / 0.25) !important;
}

.bg-fuchsia-700\/30{
    background-color: rgb(162 28 175 / 0.3) !important;
}

.bg-fuchsia-700\/35{
    background-color: rgb(162 28 175 / 0.35) !important;
}

.bg-fuchsia-700\/40{
    background-color: rgb(162 28 175 / 0.4) !important;
}

.bg-fuchsia-700\/45{
    background-color: rgb(162 28 175 / 0.45) !important;
}

.bg-fuchsia-700\/5{
    background-color: rgb(162 28 175 / 0.05) !important;
}

.bg-fuchsia-700\/50{
    background-color: rgb(162 28 175 / 0.5) !important;
}

.bg-fuchsia-700\/55{
    background-color: rgb(162 28 175 / 0.55) !important;
}

.bg-fuchsia-700\/60{
    background-color: rgb(162 28 175 / 0.6) !important;
}

.bg-fuchsia-700\/65{
    background-color: rgb(162 28 175 / 0.65) !important;
}

.bg-fuchsia-700\/70{
    background-color: rgb(162 28 175 / 0.7) !important;
}

.bg-fuchsia-700\/75{
    background-color: rgb(162 28 175 / 0.75) !important;
}

.bg-fuchsia-700\/80{
    background-color: rgb(162 28 175 / 0.8) !important;
}

.bg-fuchsia-700\/85{
    background-color: rgb(162 28 175 / 0.85) !important;
}

.bg-fuchsia-700\/90{
    background-color: rgb(162 28 175 / 0.9) !important;
}

.bg-fuchsia-700\/95{
    background-color: rgb(162 28 175 / 0.95) !important;
}

.bg-fuchsia-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(134 25 143 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-800\/0{
    background-color: rgb(134 25 143 / 0) !important;
}

.bg-fuchsia-800\/10{
    background-color: rgb(134 25 143 / 0.1) !important;
}

.bg-fuchsia-800\/100{
    background-color: rgb(134 25 143 / 1) !important;
}

.bg-fuchsia-800\/15{
    background-color: rgb(134 25 143 / 0.15) !important;
}

.bg-fuchsia-800\/20{
    background-color: rgb(134 25 143 / 0.2) !important;
}

.bg-fuchsia-800\/25{
    background-color: rgb(134 25 143 / 0.25) !important;
}

.bg-fuchsia-800\/30{
    background-color: rgb(134 25 143 / 0.3) !important;
}

.bg-fuchsia-800\/35{
    background-color: rgb(134 25 143 / 0.35) !important;
}

.bg-fuchsia-800\/40{
    background-color: rgb(134 25 143 / 0.4) !important;
}

.bg-fuchsia-800\/45{
    background-color: rgb(134 25 143 / 0.45) !important;
}

.bg-fuchsia-800\/5{
    background-color: rgb(134 25 143 / 0.05) !important;
}

.bg-fuchsia-800\/50{
    background-color: rgb(134 25 143 / 0.5) !important;
}

.bg-fuchsia-800\/55{
    background-color: rgb(134 25 143 / 0.55) !important;
}

.bg-fuchsia-800\/60{
    background-color: rgb(134 25 143 / 0.6) !important;
}

.bg-fuchsia-800\/65{
    background-color: rgb(134 25 143 / 0.65) !important;
}

.bg-fuchsia-800\/70{
    background-color: rgb(134 25 143 / 0.7) !important;
}

.bg-fuchsia-800\/75{
    background-color: rgb(134 25 143 / 0.75) !important;
}

.bg-fuchsia-800\/80{
    background-color: rgb(134 25 143 / 0.8) !important;
}

.bg-fuchsia-800\/85{
    background-color: rgb(134 25 143 / 0.85) !important;
}

.bg-fuchsia-800\/90{
    background-color: rgb(134 25 143 / 0.9) !important;
}

.bg-fuchsia-800\/95{
    background-color: rgb(134 25 143 / 0.95) !important;
}

.bg-fuchsia-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(112 26 117 / var(--tw-bg-opacity)) !important;
}

.bg-fuchsia-900\/0{
    background-color: rgb(112 26 117 / 0) !important;
}

.bg-fuchsia-900\/10{
    background-color: rgb(112 26 117 / 0.1) !important;
}

.bg-fuchsia-900\/100{
    background-color: rgb(112 26 117 / 1) !important;
}

.bg-fuchsia-900\/15{
    background-color: rgb(112 26 117 / 0.15) !important;
}

.bg-fuchsia-900\/20{
    background-color: rgb(112 26 117 / 0.2) !important;
}

.bg-fuchsia-900\/25{
    background-color: rgb(112 26 117 / 0.25) !important;
}

.bg-fuchsia-900\/30{
    background-color: rgb(112 26 117 / 0.3) !important;
}

.bg-fuchsia-900\/35{
    background-color: rgb(112 26 117 / 0.35) !important;
}

.bg-fuchsia-900\/40{
    background-color: rgb(112 26 117 / 0.4) !important;
}

.bg-fuchsia-900\/45{
    background-color: rgb(112 26 117 / 0.45) !important;
}

.bg-fuchsia-900\/5{
    background-color: rgb(112 26 117 / 0.05) !important;
}

.bg-fuchsia-900\/50{
    background-color: rgb(112 26 117 / 0.5) !important;
}

.bg-fuchsia-900\/55{
    background-color: rgb(112 26 117 / 0.55) !important;
}

.bg-fuchsia-900\/60{
    background-color: rgb(112 26 117 / 0.6) !important;
}

.bg-fuchsia-900\/65{
    background-color: rgb(112 26 117 / 0.65) !important;
}

.bg-fuchsia-900\/70{
    background-color: rgb(112 26 117 / 0.7) !important;
}

.bg-fuchsia-900\/75{
    background-color: rgb(112 26 117 / 0.75) !important;
}

.bg-fuchsia-900\/80{
    background-color: rgb(112 26 117 / 0.8) !important;
}

.bg-fuchsia-900\/85{
    background-color: rgb(112 26 117 / 0.85) !important;
}

.bg-fuchsia-900\/90{
    background-color: rgb(112 26 117 / 0.9) !important;
}

.bg-fuchsia-900\/95{
    background-color: rgb(112 26 117 / 0.95) !important;
}

.bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 224 224 / var(--tw-bg-opacity)) !important;
}

.bg-gray-100\/0{
    background-color: rgb(224 224 224 / 0) !important;
}

.bg-gray-100\/10{
    background-color: rgb(224 224 224 / 0.1) !important;
}

.bg-gray-100\/100{
    background-color: rgb(224 224 224 / 1) !important;
}

.bg-gray-100\/15{
    background-color: rgb(224 224 224 / 0.15) !important;
}

.bg-gray-100\/20{
    background-color: rgb(224 224 224 / 0.2) !important;
}

.bg-gray-100\/25{
    background-color: rgb(224 224 224 / 0.25) !important;
}

.bg-gray-100\/30{
    background-color: rgb(224 224 224 / 0.3) !important;
}

.bg-gray-100\/35{
    background-color: rgb(224 224 224 / 0.35) !important;
}

.bg-gray-100\/40{
    background-color: rgb(224 224 224 / 0.4) !important;
}

.bg-gray-100\/45{
    background-color: rgb(224 224 224 / 0.45) !important;
}

.bg-gray-100\/5{
    background-color: rgb(224 224 224 / 0.05) !important;
}

.bg-gray-100\/50{
    background-color: rgb(224 224 224 / 0.5) !important;
}

.bg-gray-100\/55{
    background-color: rgb(224 224 224 / 0.55) !important;
}

.bg-gray-100\/60{
    background-color: rgb(224 224 224 / 0.6) !important;
}

.bg-gray-100\/65{
    background-color: rgb(224 224 224 / 0.65) !important;
}

.bg-gray-100\/70{
    background-color: rgb(224 224 224 / 0.7) !important;
}

.bg-gray-100\/75{
    background-color: rgb(224 224 224 / 0.75) !important;
}

.bg-gray-100\/80{
    background-color: rgb(224 224 224 / 0.8) !important;
}

.bg-gray-100\/85{
    background-color: rgb(224 224 224 / 0.85) !important;
}

.bg-gray-100\/90{
    background-color: rgb(224 224 224 / 0.9) !important;
}

.bg-gray-100\/95{
    background-color: rgb(224 224 224 / 0.95) !important;
}

.bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(181 181 181 / var(--tw-bg-opacity)) !important;
}

.bg-gray-200\/0{
    background-color: rgb(181 181 181 / 0) !important;
}

.bg-gray-200\/10{
    background-color: rgb(181 181 181 / 0.1) !important;
}

.bg-gray-200\/100{
    background-color: rgb(181 181 181 / 1) !important;
}

.bg-gray-200\/15{
    background-color: rgb(181 181 181 / 0.15) !important;
}

.bg-gray-200\/20{
    background-color: rgb(181 181 181 / 0.2) !important;
}

.bg-gray-200\/25{
    background-color: rgb(181 181 181 / 0.25) !important;
}

.bg-gray-200\/30{
    background-color: rgb(181 181 181 / 0.3) !important;
}

.bg-gray-200\/35{
    background-color: rgb(181 181 181 / 0.35) !important;
}

.bg-gray-200\/40{
    background-color: rgb(181 181 181 / 0.4) !important;
}

.bg-gray-200\/45{
    background-color: rgb(181 181 181 / 0.45) !important;
}

.bg-gray-200\/5{
    background-color: rgb(181 181 181 / 0.05) !important;
}

.bg-gray-200\/50{
    background-color: rgb(181 181 181 / 0.5) !important;
}

.bg-gray-200\/55{
    background-color: rgb(181 181 181 / 0.55) !important;
}

.bg-gray-200\/60{
    background-color: rgb(181 181 181 / 0.6) !important;
}

.bg-gray-200\/65{
    background-color: rgb(181 181 181 / 0.65) !important;
}

.bg-gray-200\/70{
    background-color: rgb(181 181 181 / 0.7) !important;
}

.bg-gray-200\/75{
    background-color: rgb(181 181 181 / 0.75) !important;
}

.bg-gray-200\/80{
    background-color: rgb(181 181 181 / 0.8) !important;
}

.bg-gray-200\/85{
    background-color: rgb(181 181 181 / 0.85) !important;
}

.bg-gray-200\/90{
    background-color: rgb(181 181 181 / 0.9) !important;
}

.bg-gray-200\/95{
    background-color: rgb(181 181 181 / 0.95) !important;
}

.bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(138 138 138 / var(--tw-bg-opacity)) !important;
}

.bg-gray-300\/0{
    background-color: rgb(138 138 138 / 0) !important;
}

.bg-gray-300\/10{
    background-color: rgb(138 138 138 / 0.1) !important;
}

.bg-gray-300\/100{
    background-color: rgb(138 138 138 / 1) !important;
}

.bg-gray-300\/15{
    background-color: rgb(138 138 138 / 0.15) !important;
}

.bg-gray-300\/20{
    background-color: rgb(138 138 138 / 0.2) !important;
}

.bg-gray-300\/25{
    background-color: rgb(138 138 138 / 0.25) !important;
}

.bg-gray-300\/30{
    background-color: rgb(138 138 138 / 0.3) !important;
}

.bg-gray-300\/35{
    background-color: rgb(138 138 138 / 0.35) !important;
}

.bg-gray-300\/40{
    background-color: rgb(138 138 138 / 0.4) !important;
}

.bg-gray-300\/45{
    background-color: rgb(138 138 138 / 0.45) !important;
}

.bg-gray-300\/5{
    background-color: rgb(138 138 138 / 0.05) !important;
}

.bg-gray-300\/50{
    background-color: rgb(138 138 138 / 0.5) !important;
}

.bg-gray-300\/55{
    background-color: rgb(138 138 138 / 0.55) !important;
}

.bg-gray-300\/60{
    background-color: rgb(138 138 138 / 0.6) !important;
}

.bg-gray-300\/65{
    background-color: rgb(138 138 138 / 0.65) !important;
}

.bg-gray-300\/70{
    background-color: rgb(138 138 138 / 0.7) !important;
}

.bg-gray-300\/75{
    background-color: rgb(138 138 138 / 0.75) !important;
}

.bg-gray-300\/80{
    background-color: rgb(138 138 138 / 0.8) !important;
}

.bg-gray-300\/85{
    background-color: rgb(138 138 138 / 0.85) !important;
}

.bg-gray-300\/90{
    background-color: rgb(138 138 138 / 0.9) !important;
}

.bg-gray-300\/95{
    background-color: rgb(138 138 138 / 0.95) !important;
}

.bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(94 94 94 / var(--tw-bg-opacity)) !important;
}

.bg-gray-400\/0{
    background-color: rgb(94 94 94 / 0) !important;
}

.bg-gray-400\/10{
    background-color: rgb(94 94 94 / 0.1) !important;
}

.bg-gray-400\/100{
    background-color: rgb(94 94 94 / 1) !important;
}

.bg-gray-400\/15{
    background-color: rgb(94 94 94 / 0.15) !important;
}

.bg-gray-400\/20{
    background-color: rgb(94 94 94 / 0.2) !important;
}

.bg-gray-400\/25{
    background-color: rgb(94 94 94 / 0.25) !important;
}

.bg-gray-400\/30{
    background-color: rgb(94 94 94 / 0.3) !important;
}

.bg-gray-400\/35{
    background-color: rgb(94 94 94 / 0.35) !important;
}

.bg-gray-400\/40{
    background-color: rgb(94 94 94 / 0.4) !important;
}

.bg-gray-400\/45{
    background-color: rgb(94 94 94 / 0.45) !important;
}

.bg-gray-400\/5{
    background-color: rgb(94 94 94 / 0.05) !important;
}

.bg-gray-400\/50{
    background-color: rgb(94 94 94 / 0.5) !important;
}

.bg-gray-400\/55{
    background-color: rgb(94 94 94 / 0.55) !important;
}

.bg-gray-400\/60{
    background-color: rgb(94 94 94 / 0.6) !important;
}

.bg-gray-400\/65{
    background-color: rgb(94 94 94 / 0.65) !important;
}

.bg-gray-400\/70{
    background-color: rgb(94 94 94 / 0.7) !important;
}

.bg-gray-400\/75{
    background-color: rgb(94 94 94 / 0.75) !important;
}

.bg-gray-400\/80{
    background-color: rgb(94 94 94 / 0.8) !important;
}

.bg-gray-400\/85{
    background-color: rgb(94 94 94 / 0.85) !important;
}

.bg-gray-400\/90{
    background-color: rgb(94 94 94 / 0.9) !important;
}

.bg-gray-400\/95{
    background-color: rgb(94 94 94 / 0.95) !important;
}

.bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(246 246 246 / var(--tw-bg-opacity)) !important;
}

.bg-gray-50\/0{
    background-color: rgb(246 246 246 / 0) !important;
}

.bg-gray-50\/10{
    background-color: rgb(246 246 246 / 0.1) !important;
}

.bg-gray-50\/100{
    background-color: rgb(246 246 246 / 1) !important;
}

.bg-gray-50\/15{
    background-color: rgb(246 246 246 / 0.15) !important;
}

.bg-gray-50\/20{
    background-color: rgb(246 246 246 / 0.2) !important;
}

.bg-gray-50\/25{
    background-color: rgb(246 246 246 / 0.25) !important;
}

.bg-gray-50\/30{
    background-color: rgb(246 246 246 / 0.3) !important;
}

.bg-gray-50\/35{
    background-color: rgb(246 246 246 / 0.35) !important;
}

.bg-gray-50\/40{
    background-color: rgb(246 246 246 / 0.4) !important;
}

.bg-gray-50\/45{
    background-color: rgb(246 246 246 / 0.45) !important;
}

.bg-gray-50\/5{
    background-color: rgb(246 246 246 / 0.05) !important;
}

.bg-gray-50\/50{
    background-color: rgb(246 246 246 / 0.5) !important;
}

.bg-gray-50\/55{
    background-color: rgb(246 246 246 / 0.55) !important;
}

.bg-gray-50\/60{
    background-color: rgb(246 246 246 / 0.6) !important;
}

.bg-gray-50\/65{
    background-color: rgb(246 246 246 / 0.65) !important;
}

.bg-gray-50\/70{
    background-color: rgb(246 246 246 / 0.7) !important;
}

.bg-gray-50\/75{
    background-color: rgb(246 246 246 / 0.75) !important;
}

.bg-gray-50\/80{
    background-color: rgb(246 246 246 / 0.8) !important;
}

.bg-gray-50\/85{
    background-color: rgb(246 246 246 / 0.85) !important;
}

.bg-gray-50\/90{
    background-color: rgb(246 246 246 / 0.9) !important;
}

.bg-gray-50\/95{
    background-color: rgb(246 246 246 / 0.95) !important;
}

.bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
}

.bg-gray-500\/0{
    background-color: rgb(51 51 51 / 0) !important;
}

.bg-gray-500\/10{
    background-color: rgb(51 51 51 / 0.1) !important;
}

.bg-gray-500\/100{
    background-color: rgb(51 51 51 / 1) !important;
}

.bg-gray-500\/15{
    background-color: rgb(51 51 51 / 0.15) !important;
}

.bg-gray-500\/20{
    background-color: rgb(51 51 51 / 0.2) !important;
}

.bg-gray-500\/25{
    background-color: rgb(51 51 51 / 0.25) !important;
}

.bg-gray-500\/30{
    background-color: rgb(51 51 51 / 0.3) !important;
}

.bg-gray-500\/35{
    background-color: rgb(51 51 51 / 0.35) !important;
}

.bg-gray-500\/40{
    background-color: rgb(51 51 51 / 0.4) !important;
}

.bg-gray-500\/45{
    background-color: rgb(51 51 51 / 0.45) !important;
}

.bg-gray-500\/5{
    background-color: rgb(51 51 51 / 0.05) !important;
}

.bg-gray-500\/50{
    background-color: rgb(51 51 51 / 0.5) !important;
}

.bg-gray-500\/55{
    background-color: rgb(51 51 51 / 0.55) !important;
}

.bg-gray-500\/60{
    background-color: rgb(51 51 51 / 0.6) !important;
}

.bg-gray-500\/65{
    background-color: rgb(51 51 51 / 0.65) !important;
}

.bg-gray-500\/70{
    background-color: rgb(51 51 51 / 0.7) !important;
}

.bg-gray-500\/75{
    background-color: rgb(51 51 51 / 0.75) !important;
}

.bg-gray-500\/80{
    background-color: rgb(51 51 51 / 0.8) !important;
}

.bg-gray-500\/85{
    background-color: rgb(51 51 51 / 0.85) !important;
}

.bg-gray-500\/90{
    background-color: rgb(51 51 51 / 0.9) !important;
}

.bg-gray-500\/95{
    background-color: rgb(51 51 51 / 0.95) !important;
}

.bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(43 43 43 / var(--tw-bg-opacity)) !important;
}

.bg-gray-600\/0{
    background-color: rgb(43 43 43 / 0) !important;
}

.bg-gray-600\/10{
    background-color: rgb(43 43 43 / 0.1) !important;
}

.bg-gray-600\/100{
    background-color: rgb(43 43 43 / 1) !important;
}

.bg-gray-600\/15{
    background-color: rgb(43 43 43 / 0.15) !important;
}

.bg-gray-600\/20{
    background-color: rgb(43 43 43 / 0.2) !important;
}

.bg-gray-600\/25{
    background-color: rgb(43 43 43 / 0.25) !important;
}

.bg-gray-600\/30{
    background-color: rgb(43 43 43 / 0.3) !important;
}

.bg-gray-600\/35{
    background-color: rgb(43 43 43 / 0.35) !important;
}

.bg-gray-600\/40{
    background-color: rgb(43 43 43 / 0.4) !important;
}

.bg-gray-600\/45{
    background-color: rgb(43 43 43 / 0.45) !important;
}

.bg-gray-600\/5{
    background-color: rgb(43 43 43 / 0.05) !important;
}

.bg-gray-600\/50{
    background-color: rgb(43 43 43 / 0.5) !important;
}

.bg-gray-600\/55{
    background-color: rgb(43 43 43 / 0.55) !important;
}

.bg-gray-600\/60{
    background-color: rgb(43 43 43 / 0.6) !important;
}

.bg-gray-600\/65{
    background-color: rgb(43 43 43 / 0.65) !important;
}

.bg-gray-600\/70{
    background-color: rgb(43 43 43 / 0.7) !important;
}

.bg-gray-600\/75{
    background-color: rgb(43 43 43 / 0.75) !important;
}

.bg-gray-600\/80{
    background-color: rgb(43 43 43 / 0.8) !important;
}

.bg-gray-600\/85{
    background-color: rgb(43 43 43 / 0.85) !important;
}

.bg-gray-600\/90{
    background-color: rgb(43 43 43 / 0.9) !important;
}

.bg-gray-600\/95{
    background-color: rgb(43 43 43 / 0.95) !important;
}

.bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(36 36 36 / var(--tw-bg-opacity)) !important;
}

.bg-gray-700\/0{
    background-color: rgb(36 36 36 / 0) !important;
}

.bg-gray-700\/10{
    background-color: rgb(36 36 36 / 0.1) !important;
}

.bg-gray-700\/100{
    background-color: rgb(36 36 36 / 1) !important;
}

.bg-gray-700\/15{
    background-color: rgb(36 36 36 / 0.15) !important;
}

.bg-gray-700\/20{
    background-color: rgb(36 36 36 / 0.2) !important;
}

.bg-gray-700\/25{
    background-color: rgb(36 36 36 / 0.25) !important;
}

.bg-gray-700\/30{
    background-color: rgb(36 36 36 / 0.3) !important;
}

.bg-gray-700\/35{
    background-color: rgb(36 36 36 / 0.35) !important;
}

.bg-gray-700\/40{
    background-color: rgb(36 36 36 / 0.4) !important;
}

.bg-gray-700\/45{
    background-color: rgb(36 36 36 / 0.45) !important;
}

.bg-gray-700\/5{
    background-color: rgb(36 36 36 / 0.05) !important;
}

.bg-gray-700\/50{
    background-color: rgb(36 36 36 / 0.5) !important;
}

.bg-gray-700\/55{
    background-color: rgb(36 36 36 / 0.55) !important;
}

.bg-gray-700\/60{
    background-color: rgb(36 36 36 / 0.6) !important;
}

.bg-gray-700\/65{
    background-color: rgb(36 36 36 / 0.65) !important;
}

.bg-gray-700\/70{
    background-color: rgb(36 36 36 / 0.7) !important;
}

.bg-gray-700\/75{
    background-color: rgb(36 36 36 / 0.75) !important;
}

.bg-gray-700\/80{
    background-color: rgb(36 36 36 / 0.8) !important;
}

.bg-gray-700\/85{
    background-color: rgb(36 36 36 / 0.85) !important;
}

.bg-gray-700\/90{
    background-color: rgb(36 36 36 / 0.9) !important;
}

.bg-gray-700\/95{
    background-color: rgb(36 36 36 / 0.95) !important;
}

.bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(28 28 28 / var(--tw-bg-opacity)) !important;
}

.bg-gray-800\/0{
    background-color: rgb(28 28 28 / 0) !important;
}

.bg-gray-800\/10{
    background-color: rgb(28 28 28 / 0.1) !important;
}

.bg-gray-800\/100{
    background-color: rgb(28 28 28 / 1) !important;
}

.bg-gray-800\/15{
    background-color: rgb(28 28 28 / 0.15) !important;
}

.bg-gray-800\/20{
    background-color: rgb(28 28 28 / 0.2) !important;
}

.bg-gray-800\/25{
    background-color: rgb(28 28 28 / 0.25) !important;
}

.bg-gray-800\/30{
    background-color: rgb(28 28 28 / 0.3) !important;
}

.bg-gray-800\/35{
    background-color: rgb(28 28 28 / 0.35) !important;
}

.bg-gray-800\/40{
    background-color: rgb(28 28 28 / 0.4) !important;
}

.bg-gray-800\/45{
    background-color: rgb(28 28 28 / 0.45) !important;
}

.bg-gray-800\/5{
    background-color: rgb(28 28 28 / 0.05) !important;
}

.bg-gray-800\/50{
    background-color: rgb(28 28 28 / 0.5) !important;
}

.bg-gray-800\/55{
    background-color: rgb(28 28 28 / 0.55) !important;
}

.bg-gray-800\/60{
    background-color: rgb(28 28 28 / 0.6) !important;
}

.bg-gray-800\/65{
    background-color: rgb(28 28 28 / 0.65) !important;
}

.bg-gray-800\/70{
    background-color: rgb(28 28 28 / 0.7) !important;
}

.bg-gray-800\/75{
    background-color: rgb(28 28 28 / 0.75) !important;
}

.bg-gray-800\/80{
    background-color: rgb(28 28 28 / 0.8) !important;
}

.bg-gray-800\/85{
    background-color: rgb(28 28 28 / 0.85) !important;
}

.bg-gray-800\/90{
    background-color: rgb(28 28 28 / 0.9) !important;
}

.bg-gray-800\/95{
    background-color: rgb(28 28 28 / 0.95) !important;
}

.bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 20 20 / var(--tw-bg-opacity)) !important;
}

.bg-gray-900\/0{
    background-color: rgb(20 20 20 / 0) !important;
}

.bg-gray-900\/10{
    background-color: rgb(20 20 20 / 0.1) !important;
}

.bg-gray-900\/100{
    background-color: rgb(20 20 20 / 1) !important;
}

.bg-gray-900\/15{
    background-color: rgb(20 20 20 / 0.15) !important;
}

.bg-gray-900\/20{
    background-color: rgb(20 20 20 / 0.2) !important;
}

.bg-gray-900\/25{
    background-color: rgb(20 20 20 / 0.25) !important;
}

.bg-gray-900\/30{
    background-color: rgb(20 20 20 / 0.3) !important;
}

.bg-gray-900\/35{
    background-color: rgb(20 20 20 / 0.35) !important;
}

.bg-gray-900\/40{
    background-color: rgb(20 20 20 / 0.4) !important;
}

.bg-gray-900\/45{
    background-color: rgb(20 20 20 / 0.45) !important;
}

.bg-gray-900\/5{
    background-color: rgb(20 20 20 / 0.05) !important;
}

.bg-gray-900\/50{
    background-color: rgb(20 20 20 / 0.5) !important;
}

.bg-gray-900\/55{
    background-color: rgb(20 20 20 / 0.55) !important;
}

.bg-gray-900\/60{
    background-color: rgb(20 20 20 / 0.6) !important;
}

.bg-gray-900\/65{
    background-color: rgb(20 20 20 / 0.65) !important;
}

.bg-gray-900\/70{
    background-color: rgb(20 20 20 / 0.7) !important;
}

.bg-gray-900\/75{
    background-color: rgb(20 20 20 / 0.75) !important;
}

.bg-gray-900\/80{
    background-color: rgb(20 20 20 / 0.8) !important;
}

.bg-gray-900\/85{
    background-color: rgb(20 20 20 / 0.85) !important;
}

.bg-gray-900\/90{
    background-color: rgb(20 20 20 / 0.9) !important;
}

.bg-gray-900\/95{
    background-color: rgb(20 20 20 / 0.95) !important;
}

.bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(230 248 191 / var(--tw-bg-opacity)) !important;
}

.bg-green-100\/0{
    background-color: rgb(230 248 191 / 0) !important;
}

.bg-green-100\/10{
    background-color: rgb(230 248 191 / 0.1) !important;
}

.bg-green-100\/100{
    background-color: rgb(230 248 191 / 1) !important;
}

.bg-green-100\/15{
    background-color: rgb(230 248 191 / 0.15) !important;
}

.bg-green-100\/20{
    background-color: rgb(230 248 191 / 0.2) !important;
}

.bg-green-100\/25{
    background-color: rgb(230 248 191 / 0.25) !important;
}

.bg-green-100\/30{
    background-color: rgb(230 248 191 / 0.3) !important;
}

.bg-green-100\/35{
    background-color: rgb(230 248 191 / 0.35) !important;
}

.bg-green-100\/40{
    background-color: rgb(230 248 191 / 0.4) !important;
}

.bg-green-100\/45{
    background-color: rgb(230 248 191 / 0.45) !important;
}

.bg-green-100\/5{
    background-color: rgb(230 248 191 / 0.05) !important;
}

.bg-green-100\/50{
    background-color: rgb(230 248 191 / 0.5) !important;
}

.bg-green-100\/55{
    background-color: rgb(230 248 191 / 0.55) !important;
}

.bg-green-100\/60{
    background-color: rgb(230 248 191 / 0.6) !important;
}

.bg-green-100\/65{
    background-color: rgb(230 248 191 / 0.65) !important;
}

.bg-green-100\/70{
    background-color: rgb(230 248 191 / 0.7) !important;
}

.bg-green-100\/75{
    background-color: rgb(230 248 191 / 0.75) !important;
}

.bg-green-100\/80{
    background-color: rgb(230 248 191 / 0.8) !important;
}

.bg-green-100\/85{
    background-color: rgb(230 248 191 / 0.85) !important;
}

.bg-green-100\/90{
    background-color: rgb(230 248 191 / 0.9) !important;
}

.bg-green-100\/95{
    background-color: rgb(230 248 191 / 0.95) !important;
}

.bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(203 241 122 / var(--tw-bg-opacity)) !important;
}

.bg-green-200\/0{
    background-color: rgb(203 241 122 / 0) !important;
}

.bg-green-200\/10{
    background-color: rgb(203 241 122 / 0.1) !important;
}

.bg-green-200\/100{
    background-color: rgb(203 241 122 / 1) !important;
}

.bg-green-200\/15{
    background-color: rgb(203 241 122 / 0.15) !important;
}

.bg-green-200\/20{
    background-color: rgb(203 241 122 / 0.2) !important;
}

.bg-green-200\/25{
    background-color: rgb(203 241 122 / 0.25) !important;
}

.bg-green-200\/30{
    background-color: rgb(203 241 122 / 0.3) !important;
}

.bg-green-200\/35{
    background-color: rgb(203 241 122 / 0.35) !important;
}

.bg-green-200\/40{
    background-color: rgb(203 241 122 / 0.4) !important;
}

.bg-green-200\/45{
    background-color: rgb(203 241 122 / 0.45) !important;
}

.bg-green-200\/5{
    background-color: rgb(203 241 122 / 0.05) !important;
}

.bg-green-200\/50{
    background-color: rgb(203 241 122 / 0.5) !important;
}

.bg-green-200\/55{
    background-color: rgb(203 241 122 / 0.55) !important;
}

.bg-green-200\/60{
    background-color: rgb(203 241 122 / 0.6) !important;
}

.bg-green-200\/65{
    background-color: rgb(203 241 122 / 0.65) !important;
}

.bg-green-200\/70{
    background-color: rgb(203 241 122 / 0.7) !important;
}

.bg-green-200\/75{
    background-color: rgb(203 241 122 / 0.75) !important;
}

.bg-green-200\/80{
    background-color: rgb(203 241 122 / 0.8) !important;
}

.bg-green-200\/85{
    background-color: rgb(203 241 122 / 0.85) !important;
}

.bg-green-200\/90{
    background-color: rgb(203 241 122 / 0.9) !important;
}

.bg-green-200\/95{
    background-color: rgb(203 241 122 / 0.95) !important;
}

.bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(178 234 57 / var(--tw-bg-opacity)) !important;
}

.bg-green-300\/0{
    background-color: rgb(178 234 57 / 0) !important;
}

.bg-green-300\/10{
    background-color: rgb(178 234 57 / 0.1) !important;
}

.bg-green-300\/100{
    background-color: rgb(178 234 57 / 1) !important;
}

.bg-green-300\/15{
    background-color: rgb(178 234 57 / 0.15) !important;
}

.bg-green-300\/20{
    background-color: rgb(178 234 57 / 0.2) !important;
}

.bg-green-300\/25{
    background-color: rgb(178 234 57 / 0.25) !important;
}

.bg-green-300\/30{
    background-color: rgb(178 234 57 / 0.3) !important;
}

.bg-green-300\/35{
    background-color: rgb(178 234 57 / 0.35) !important;
}

.bg-green-300\/40{
    background-color: rgb(178 234 57 / 0.4) !important;
}

.bg-green-300\/45{
    background-color: rgb(178 234 57 / 0.45) !important;
}

.bg-green-300\/5{
    background-color: rgb(178 234 57 / 0.05) !important;
}

.bg-green-300\/50{
    background-color: rgb(178 234 57 / 0.5) !important;
}

.bg-green-300\/55{
    background-color: rgb(178 234 57 / 0.55) !important;
}

.bg-green-300\/60{
    background-color: rgb(178 234 57 / 0.6) !important;
}

.bg-green-300\/65{
    background-color: rgb(178 234 57 / 0.65) !important;
}

.bg-green-300\/70{
    background-color: rgb(178 234 57 / 0.7) !important;
}

.bg-green-300\/75{
    background-color: rgb(178 234 57 / 0.75) !important;
}

.bg-green-300\/80{
    background-color: rgb(178 234 57 / 0.8) !important;
}

.bg-green-300\/85{
    background-color: rgb(178 234 57 / 0.85) !important;
}

.bg-green-300\/90{
    background-color: rgb(178 234 57 / 0.9) !important;
}

.bg-green-300\/95{
    background-color: rgb(178 234 57 / 0.95) !important;
}

.bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(139 193 21 / var(--tw-bg-opacity)) !important;
}

.bg-green-400\/0{
    background-color: rgb(139 193 21 / 0) !important;
}

.bg-green-400\/10{
    background-color: rgb(139 193 21 / 0.1) !important;
}

.bg-green-400\/100{
    background-color: rgb(139 193 21 / 1) !important;
}

.bg-green-400\/15{
    background-color: rgb(139 193 21 / 0.15) !important;
}

.bg-green-400\/20{
    background-color: rgb(139 193 21 / 0.2) !important;
}

.bg-green-400\/25{
    background-color: rgb(139 193 21 / 0.25) !important;
}

.bg-green-400\/30{
    background-color: rgb(139 193 21 / 0.3) !important;
}

.bg-green-400\/35{
    background-color: rgb(139 193 21 / 0.35) !important;
}

.bg-green-400\/40{
    background-color: rgb(139 193 21 / 0.4) !important;
}

.bg-green-400\/45{
    background-color: rgb(139 193 21 / 0.45) !important;
}

.bg-green-400\/5{
    background-color: rgb(139 193 21 / 0.05) !important;
}

.bg-green-400\/50{
    background-color: rgb(139 193 21 / 0.5) !important;
}

.bg-green-400\/55{
    background-color: rgb(139 193 21 / 0.55) !important;
}

.bg-green-400\/60{
    background-color: rgb(139 193 21 / 0.6) !important;
}

.bg-green-400\/65{
    background-color: rgb(139 193 21 / 0.65) !important;
}

.bg-green-400\/70{
    background-color: rgb(139 193 21 / 0.7) !important;
}

.bg-green-400\/75{
    background-color: rgb(139 193 21 / 0.75) !important;
}

.bg-green-400\/80{
    background-color: rgb(139 193 21 / 0.8) !important;
}

.bg-green-400\/85{
    background-color: rgb(139 193 21 / 0.85) !important;
}

.bg-green-400\/90{
    background-color: rgb(139 193 21 / 0.9) !important;
}

.bg-green-400\/95{
    background-color: rgb(139 193 21 / 0.95) !important;
}

.bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(242 252 223 / var(--tw-bg-opacity)) !important;
}

.bg-green-50\/0{
    background-color: rgb(242 252 223 / 0) !important;
}

.bg-green-50\/10{
    background-color: rgb(242 252 223 / 0.1) !important;
}

.bg-green-50\/100{
    background-color: rgb(242 252 223 / 1) !important;
}

.bg-green-50\/15{
    background-color: rgb(242 252 223 / 0.15) !important;
}

.bg-green-50\/20{
    background-color: rgb(242 252 223 / 0.2) !important;
}

.bg-green-50\/25{
    background-color: rgb(242 252 223 / 0.25) !important;
}

.bg-green-50\/30{
    background-color: rgb(242 252 223 / 0.3) !important;
}

.bg-green-50\/35{
    background-color: rgb(242 252 223 / 0.35) !important;
}

.bg-green-50\/40{
    background-color: rgb(242 252 223 / 0.4) !important;
}

.bg-green-50\/45{
    background-color: rgb(242 252 223 / 0.45) !important;
}

.bg-green-50\/5{
    background-color: rgb(242 252 223 / 0.05) !important;
}

.bg-green-50\/50{
    background-color: rgb(242 252 223 / 0.5) !important;
}

.bg-green-50\/55{
    background-color: rgb(242 252 223 / 0.55) !important;
}

.bg-green-50\/60{
    background-color: rgb(242 252 223 / 0.6) !important;
}

.bg-green-50\/65{
    background-color: rgb(242 252 223 / 0.65) !important;
}

.bg-green-50\/70{
    background-color: rgb(242 252 223 / 0.7) !important;
}

.bg-green-50\/75{
    background-color: rgb(242 252 223 / 0.75) !important;
}

.bg-green-50\/80{
    background-color: rgb(242 252 223 / 0.8) !important;
}

.bg-green-50\/85{
    background-color: rgb(242 252 223 / 0.85) !important;
}

.bg-green-50\/90{
    background-color: rgb(242 252 223 / 0.9) !important;
}

.bg-green-50\/95{
    background-color: rgb(242 252 223 / 0.95) !important;
}

.bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(93 129 14 / var(--tw-bg-opacity)) !important;
}

.bg-green-500\/0{
    background-color: rgb(93 129 14 / 0) !important;
}

.bg-green-500\/10{
    background-color: rgb(93 129 14 / 0.1) !important;
}

.bg-green-500\/100{
    background-color: rgb(93 129 14 / 1) !important;
}

.bg-green-500\/15{
    background-color: rgb(93 129 14 / 0.15) !important;
}

.bg-green-500\/20{
    background-color: rgb(93 129 14 / 0.2) !important;
}

.bg-green-500\/25{
    background-color: rgb(93 129 14 / 0.25) !important;
}

.bg-green-500\/30{
    background-color: rgb(93 129 14 / 0.3) !important;
}

.bg-green-500\/35{
    background-color: rgb(93 129 14 / 0.35) !important;
}

.bg-green-500\/40{
    background-color: rgb(93 129 14 / 0.4) !important;
}

.bg-green-500\/45{
    background-color: rgb(93 129 14 / 0.45) !important;
}

.bg-green-500\/5{
    background-color: rgb(93 129 14 / 0.05) !important;
}

.bg-green-500\/50{
    background-color: rgb(93 129 14 / 0.5) !important;
}

.bg-green-500\/55{
    background-color: rgb(93 129 14 / 0.55) !important;
}

.bg-green-500\/60{
    background-color: rgb(93 129 14 / 0.6) !important;
}

.bg-green-500\/65{
    background-color: rgb(93 129 14 / 0.65) !important;
}

.bg-green-500\/70{
    background-color: rgb(93 129 14 / 0.7) !important;
}

.bg-green-500\/75{
    background-color: rgb(93 129 14 / 0.75) !important;
}

.bg-green-500\/80{
    background-color: rgb(93 129 14 / 0.8) !important;
}

.bg-green-500\/85{
    background-color: rgb(93 129 14 / 0.85) !important;
}

.bg-green-500\/90{
    background-color: rgb(93 129 14 / 0.9) !important;
}

.bg-green-500\/95{
    background-color: rgb(93 129 14 / 0.95) !important;
}

.bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(73 101 11 / var(--tw-bg-opacity)) !important;
}

.bg-green-600\/0{
    background-color: rgb(73 101 11 / 0) !important;
}

.bg-green-600\/10{
    background-color: rgb(73 101 11 / 0.1) !important;
}

.bg-green-600\/100{
    background-color: rgb(73 101 11 / 1) !important;
}

.bg-green-600\/15{
    background-color: rgb(73 101 11 / 0.15) !important;
}

.bg-green-600\/20{
    background-color: rgb(73 101 11 / 0.2) !important;
}

.bg-green-600\/25{
    background-color: rgb(73 101 11 / 0.25) !important;
}

.bg-green-600\/30{
    background-color: rgb(73 101 11 / 0.3) !important;
}

.bg-green-600\/35{
    background-color: rgb(73 101 11 / 0.35) !important;
}

.bg-green-600\/40{
    background-color: rgb(73 101 11 / 0.4) !important;
}

.bg-green-600\/45{
    background-color: rgb(73 101 11 / 0.45) !important;
}

.bg-green-600\/5{
    background-color: rgb(73 101 11 / 0.05) !important;
}

.bg-green-600\/50{
    background-color: rgb(73 101 11 / 0.5) !important;
}

.bg-green-600\/55{
    background-color: rgb(73 101 11 / 0.55) !important;
}

.bg-green-600\/60{
    background-color: rgb(73 101 11 / 0.6) !important;
}

.bg-green-600\/65{
    background-color: rgb(73 101 11 / 0.65) !important;
}

.bg-green-600\/70{
    background-color: rgb(73 101 11 / 0.7) !important;
}

.bg-green-600\/75{
    background-color: rgb(73 101 11 / 0.75) !important;
}

.bg-green-600\/80{
    background-color: rgb(73 101 11 / 0.8) !important;
}

.bg-green-600\/85{
    background-color: rgb(73 101 11 / 0.85) !important;
}

.bg-green-600\/90{
    background-color: rgb(73 101 11 / 0.9) !important;
}

.bg-green-600\/95{
    background-color: rgb(73 101 11 / 0.95) !important;
}

.bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(56 78 8 / var(--tw-bg-opacity)) !important;
}

.bg-green-700\/0{
    background-color: rgb(56 78 8 / 0) !important;
}

.bg-green-700\/10{
    background-color: rgb(56 78 8 / 0.1) !important;
}

.bg-green-700\/100{
    background-color: rgb(56 78 8 / 1) !important;
}

.bg-green-700\/15{
    background-color: rgb(56 78 8 / 0.15) !important;
}

.bg-green-700\/20{
    background-color: rgb(56 78 8 / 0.2) !important;
}

.bg-green-700\/25{
    background-color: rgb(56 78 8 / 0.25) !important;
}

.bg-green-700\/30{
    background-color: rgb(56 78 8 / 0.3) !important;
}

.bg-green-700\/35{
    background-color: rgb(56 78 8 / 0.35) !important;
}

.bg-green-700\/40{
    background-color: rgb(56 78 8 / 0.4) !important;
}

.bg-green-700\/45{
    background-color: rgb(56 78 8 / 0.45) !important;
}

.bg-green-700\/5{
    background-color: rgb(56 78 8 / 0.05) !important;
}

.bg-green-700\/50{
    background-color: rgb(56 78 8 / 0.5) !important;
}

.bg-green-700\/55{
    background-color: rgb(56 78 8 / 0.55) !important;
}

.bg-green-700\/60{
    background-color: rgb(56 78 8 / 0.6) !important;
}

.bg-green-700\/65{
    background-color: rgb(56 78 8 / 0.65) !important;
}

.bg-green-700\/70{
    background-color: rgb(56 78 8 / 0.7) !important;
}

.bg-green-700\/75{
    background-color: rgb(56 78 8 / 0.75) !important;
}

.bg-green-700\/80{
    background-color: rgb(56 78 8 / 0.8) !important;
}

.bg-green-700\/85{
    background-color: rgb(56 78 8 / 0.85) !important;
}

.bg-green-700\/90{
    background-color: rgb(56 78 8 / 0.9) !important;
}

.bg-green-700\/95{
    background-color: rgb(56 78 8 / 0.95) !important;
}

.bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(36 51 5 / var(--tw-bg-opacity)) !important;
}

.bg-green-800\/0{
    background-color: rgb(36 51 5 / 0) !important;
}

.bg-green-800\/10{
    background-color: rgb(36 51 5 / 0.1) !important;
}

.bg-green-800\/100{
    background-color: rgb(36 51 5 / 1) !important;
}

.bg-green-800\/15{
    background-color: rgb(36 51 5 / 0.15) !important;
}

.bg-green-800\/20{
    background-color: rgb(36 51 5 / 0.2) !important;
}

.bg-green-800\/25{
    background-color: rgb(36 51 5 / 0.25) !important;
}

.bg-green-800\/30{
    background-color: rgb(36 51 5 / 0.3) !important;
}

.bg-green-800\/35{
    background-color: rgb(36 51 5 / 0.35) !important;
}

.bg-green-800\/40{
    background-color: rgb(36 51 5 / 0.4) !important;
}

.bg-green-800\/45{
    background-color: rgb(36 51 5 / 0.45) !important;
}

.bg-green-800\/5{
    background-color: rgb(36 51 5 / 0.05) !important;
}

.bg-green-800\/50{
    background-color: rgb(36 51 5 / 0.5) !important;
}

.bg-green-800\/55{
    background-color: rgb(36 51 5 / 0.55) !important;
}

.bg-green-800\/60{
    background-color: rgb(36 51 5 / 0.6) !important;
}

.bg-green-800\/65{
    background-color: rgb(36 51 5 / 0.65) !important;
}

.bg-green-800\/70{
    background-color: rgb(36 51 5 / 0.7) !important;
}

.bg-green-800\/75{
    background-color: rgb(36 51 5 / 0.75) !important;
}

.bg-green-800\/80{
    background-color: rgb(36 51 5 / 0.8) !important;
}

.bg-green-800\/85{
    background-color: rgb(36 51 5 / 0.85) !important;
}

.bg-green-800\/90{
    background-color: rgb(36 51 5 / 0.9) !important;
}

.bg-green-800\/95{
    background-color: rgb(36 51 5 / 0.95) !important;
}

.bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 28 3 / var(--tw-bg-opacity)) !important;
}

.bg-green-900\/0{
    background-color: rgb(20 28 3 / 0) !important;
}

.bg-green-900\/10{
    background-color: rgb(20 28 3 / 0.1) !important;
}

.bg-green-900\/100{
    background-color: rgb(20 28 3 / 1) !important;
}

.bg-green-900\/15{
    background-color: rgb(20 28 3 / 0.15) !important;
}

.bg-green-900\/20{
    background-color: rgb(20 28 3 / 0.2) !important;
}

.bg-green-900\/25{
    background-color: rgb(20 28 3 / 0.25) !important;
}

.bg-green-900\/30{
    background-color: rgb(20 28 3 / 0.3) !important;
}

.bg-green-900\/35{
    background-color: rgb(20 28 3 / 0.35) !important;
}

.bg-green-900\/40{
    background-color: rgb(20 28 3 / 0.4) !important;
}

.bg-green-900\/45{
    background-color: rgb(20 28 3 / 0.45) !important;
}

.bg-green-900\/5{
    background-color: rgb(20 28 3 / 0.05) !important;
}

.bg-green-900\/50{
    background-color: rgb(20 28 3 / 0.5) !important;
}

.bg-green-900\/55{
    background-color: rgb(20 28 3 / 0.55) !important;
}

.bg-green-900\/60{
    background-color: rgb(20 28 3 / 0.6) !important;
}

.bg-green-900\/65{
    background-color: rgb(20 28 3 / 0.65) !important;
}

.bg-green-900\/70{
    background-color: rgb(20 28 3 / 0.7) !important;
}

.bg-green-900\/75{
    background-color: rgb(20 28 3 / 0.75) !important;
}

.bg-green-900\/80{
    background-color: rgb(20 28 3 / 0.8) !important;
}

.bg-green-900\/85{
    background-color: rgb(20 28 3 / 0.85) !important;
}

.bg-green-900\/90{
    background-color: rgb(20 28 3 / 0.9) !important;
}

.bg-green-900\/95{
    background-color: rgb(20 28 3 / 0.95) !important;
}

.bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 231 255 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-100\/0{
    background-color: rgb(224 231 255 / 0) !important;
}

.bg-indigo-100\/10{
    background-color: rgb(224 231 255 / 0.1) !important;
}

.bg-indigo-100\/100{
    background-color: rgb(224 231 255 / 1) !important;
}

.bg-indigo-100\/15{
    background-color: rgb(224 231 255 / 0.15) !important;
}

.bg-indigo-100\/20{
    background-color: rgb(224 231 255 / 0.2) !important;
}

.bg-indigo-100\/25{
    background-color: rgb(224 231 255 / 0.25) !important;
}

.bg-indigo-100\/30{
    background-color: rgb(224 231 255 / 0.3) !important;
}

.bg-indigo-100\/35{
    background-color: rgb(224 231 255 / 0.35) !important;
}

.bg-indigo-100\/40{
    background-color: rgb(224 231 255 / 0.4) !important;
}

.bg-indigo-100\/45{
    background-color: rgb(224 231 255 / 0.45) !important;
}

.bg-indigo-100\/5{
    background-color: rgb(224 231 255 / 0.05) !important;
}

.bg-indigo-100\/50{
    background-color: rgb(224 231 255 / 0.5) !important;
}

.bg-indigo-100\/55{
    background-color: rgb(224 231 255 / 0.55) !important;
}

.bg-indigo-100\/60{
    background-color: rgb(224 231 255 / 0.6) !important;
}

.bg-indigo-100\/65{
    background-color: rgb(224 231 255 / 0.65) !important;
}

.bg-indigo-100\/70{
    background-color: rgb(224 231 255 / 0.7) !important;
}

.bg-indigo-100\/75{
    background-color: rgb(224 231 255 / 0.75) !important;
}

.bg-indigo-100\/80{
    background-color: rgb(224 231 255 / 0.8) !important;
}

.bg-indigo-100\/85{
    background-color: rgb(224 231 255 / 0.85) !important;
}

.bg-indigo-100\/90{
    background-color: rgb(224 231 255 / 0.9) !important;
}

.bg-indigo-100\/95{
    background-color: rgb(224 231 255 / 0.95) !important;
}

.bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(199 210 254 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-200\/0{
    background-color: rgb(199 210 254 / 0) !important;
}

.bg-indigo-200\/10{
    background-color: rgb(199 210 254 / 0.1) !important;
}

.bg-indigo-200\/100{
    background-color: rgb(199 210 254 / 1) !important;
}

.bg-indigo-200\/15{
    background-color: rgb(199 210 254 / 0.15) !important;
}

.bg-indigo-200\/20{
    background-color: rgb(199 210 254 / 0.2) !important;
}

.bg-indigo-200\/25{
    background-color: rgb(199 210 254 / 0.25) !important;
}

.bg-indigo-200\/30{
    background-color: rgb(199 210 254 / 0.3) !important;
}

.bg-indigo-200\/35{
    background-color: rgb(199 210 254 / 0.35) !important;
}

.bg-indigo-200\/40{
    background-color: rgb(199 210 254 / 0.4) !important;
}

.bg-indigo-200\/45{
    background-color: rgb(199 210 254 / 0.45) !important;
}

.bg-indigo-200\/5{
    background-color: rgb(199 210 254 / 0.05) !important;
}

.bg-indigo-200\/50{
    background-color: rgb(199 210 254 / 0.5) !important;
}

.bg-indigo-200\/55{
    background-color: rgb(199 210 254 / 0.55) !important;
}

.bg-indigo-200\/60{
    background-color: rgb(199 210 254 / 0.6) !important;
}

.bg-indigo-200\/65{
    background-color: rgb(199 210 254 / 0.65) !important;
}

.bg-indigo-200\/70{
    background-color: rgb(199 210 254 / 0.7) !important;
}

.bg-indigo-200\/75{
    background-color: rgb(199 210 254 / 0.75) !important;
}

.bg-indigo-200\/80{
    background-color: rgb(199 210 254 / 0.8) !important;
}

.bg-indigo-200\/85{
    background-color: rgb(199 210 254 / 0.85) !important;
}

.bg-indigo-200\/90{
    background-color: rgb(199 210 254 / 0.9) !important;
}

.bg-indigo-200\/95{
    background-color: rgb(199 210 254 / 0.95) !important;
}

.bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(165 180 252 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-300\/0{
    background-color: rgb(165 180 252 / 0) !important;
}

.bg-indigo-300\/10{
    background-color: rgb(165 180 252 / 0.1) !important;
}

.bg-indigo-300\/100{
    background-color: rgb(165 180 252 / 1) !important;
}

.bg-indigo-300\/15{
    background-color: rgb(165 180 252 / 0.15) !important;
}

.bg-indigo-300\/20{
    background-color: rgb(165 180 252 / 0.2) !important;
}

.bg-indigo-300\/25{
    background-color: rgb(165 180 252 / 0.25) !important;
}

.bg-indigo-300\/30{
    background-color: rgb(165 180 252 / 0.3) !important;
}

.bg-indigo-300\/35{
    background-color: rgb(165 180 252 / 0.35) !important;
}

.bg-indigo-300\/40{
    background-color: rgb(165 180 252 / 0.4) !important;
}

.bg-indigo-300\/45{
    background-color: rgb(165 180 252 / 0.45) !important;
}

.bg-indigo-300\/5{
    background-color: rgb(165 180 252 / 0.05) !important;
}

.bg-indigo-300\/50{
    background-color: rgb(165 180 252 / 0.5) !important;
}

.bg-indigo-300\/55{
    background-color: rgb(165 180 252 / 0.55) !important;
}

.bg-indigo-300\/60{
    background-color: rgb(165 180 252 / 0.6) !important;
}

.bg-indigo-300\/65{
    background-color: rgb(165 180 252 / 0.65) !important;
}

.bg-indigo-300\/70{
    background-color: rgb(165 180 252 / 0.7) !important;
}

.bg-indigo-300\/75{
    background-color: rgb(165 180 252 / 0.75) !important;
}

.bg-indigo-300\/80{
    background-color: rgb(165 180 252 / 0.8) !important;
}

.bg-indigo-300\/85{
    background-color: rgb(165 180 252 / 0.85) !important;
}

.bg-indigo-300\/90{
    background-color: rgb(165 180 252 / 0.9) !important;
}

.bg-indigo-300\/95{
    background-color: rgb(165 180 252 / 0.95) !important;
}

.bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(129 140 248 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-400\/0{
    background-color: rgb(129 140 248 / 0) !important;
}

.bg-indigo-400\/10{
    background-color: rgb(129 140 248 / 0.1) !important;
}

.bg-indigo-400\/100{
    background-color: rgb(129 140 248 / 1) !important;
}

.bg-indigo-400\/15{
    background-color: rgb(129 140 248 / 0.15) !important;
}

.bg-indigo-400\/20{
    background-color: rgb(129 140 248 / 0.2) !important;
}

.bg-indigo-400\/25{
    background-color: rgb(129 140 248 / 0.25) !important;
}

.bg-indigo-400\/30{
    background-color: rgb(129 140 248 / 0.3) !important;
}

.bg-indigo-400\/35{
    background-color: rgb(129 140 248 / 0.35) !important;
}

.bg-indigo-400\/40{
    background-color: rgb(129 140 248 / 0.4) !important;
}

.bg-indigo-400\/45{
    background-color: rgb(129 140 248 / 0.45) !important;
}

.bg-indigo-400\/5{
    background-color: rgb(129 140 248 / 0.05) !important;
}

.bg-indigo-400\/50{
    background-color: rgb(129 140 248 / 0.5) !important;
}

.bg-indigo-400\/55{
    background-color: rgb(129 140 248 / 0.55) !important;
}

.bg-indigo-400\/60{
    background-color: rgb(129 140 248 / 0.6) !important;
}

.bg-indigo-400\/65{
    background-color: rgb(129 140 248 / 0.65) !important;
}

.bg-indigo-400\/70{
    background-color: rgb(129 140 248 / 0.7) !important;
}

.bg-indigo-400\/75{
    background-color: rgb(129 140 248 / 0.75) !important;
}

.bg-indigo-400\/80{
    background-color: rgb(129 140 248 / 0.8) !important;
}

.bg-indigo-400\/85{
    background-color: rgb(129 140 248 / 0.85) !important;
}

.bg-indigo-400\/90{
    background-color: rgb(129 140 248 / 0.9) !important;
}

.bg-indigo-400\/95{
    background-color: rgb(129 140 248 / 0.95) !important;
}

.bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 242 255 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-50\/0{
    background-color: rgb(238 242 255 / 0) !important;
}

.bg-indigo-50\/10{
    background-color: rgb(238 242 255 / 0.1) !important;
}

.bg-indigo-50\/100{
    background-color: rgb(238 242 255 / 1) !important;
}

.bg-indigo-50\/15{
    background-color: rgb(238 242 255 / 0.15) !important;
}

.bg-indigo-50\/20{
    background-color: rgb(238 242 255 / 0.2) !important;
}

.bg-indigo-50\/25{
    background-color: rgb(238 242 255 / 0.25) !important;
}

.bg-indigo-50\/30{
    background-color: rgb(238 242 255 / 0.3) !important;
}

.bg-indigo-50\/35{
    background-color: rgb(238 242 255 / 0.35) !important;
}

.bg-indigo-50\/40{
    background-color: rgb(238 242 255 / 0.4) !important;
}

.bg-indigo-50\/45{
    background-color: rgb(238 242 255 / 0.45) !important;
}

.bg-indigo-50\/5{
    background-color: rgb(238 242 255 / 0.05) !important;
}

.bg-indigo-50\/50{
    background-color: rgb(238 242 255 / 0.5) !important;
}

.bg-indigo-50\/55{
    background-color: rgb(238 242 255 / 0.55) !important;
}

.bg-indigo-50\/60{
    background-color: rgb(238 242 255 / 0.6) !important;
}

.bg-indigo-50\/65{
    background-color: rgb(238 242 255 / 0.65) !important;
}

.bg-indigo-50\/70{
    background-color: rgb(238 242 255 / 0.7) !important;
}

.bg-indigo-50\/75{
    background-color: rgb(238 242 255 / 0.75) !important;
}

.bg-indigo-50\/80{
    background-color: rgb(238 242 255 / 0.8) !important;
}

.bg-indigo-50\/85{
    background-color: rgb(238 242 255 / 0.85) !important;
}

.bg-indigo-50\/90{
    background-color: rgb(238 242 255 / 0.9) !important;
}

.bg-indigo-50\/95{
    background-color: rgb(238 242 255 / 0.95) !important;
}

.bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-500\/0{
    background-color: rgb(99 102 241 / 0) !important;
}

.bg-indigo-500\/10{
    background-color: rgb(99 102 241 / 0.1) !important;
}

.bg-indigo-500\/100{
    background-color: rgb(99 102 241 / 1) !important;
}

.bg-indigo-500\/15{
    background-color: rgb(99 102 241 / 0.15) !important;
}

.bg-indigo-500\/20{
    background-color: rgb(99 102 241 / 0.2) !important;
}

.bg-indigo-500\/25{
    background-color: rgb(99 102 241 / 0.25) !important;
}

.bg-indigo-500\/30{
    background-color: rgb(99 102 241 / 0.3) !important;
}

.bg-indigo-500\/35{
    background-color: rgb(99 102 241 / 0.35) !important;
}

.bg-indigo-500\/40{
    background-color: rgb(99 102 241 / 0.4) !important;
}

.bg-indigo-500\/45{
    background-color: rgb(99 102 241 / 0.45) !important;
}

.bg-indigo-500\/5{
    background-color: rgb(99 102 241 / 0.05) !important;
}

.bg-indigo-500\/50{
    background-color: rgb(99 102 241 / 0.5) !important;
}

.bg-indigo-500\/55{
    background-color: rgb(99 102 241 / 0.55) !important;
}

.bg-indigo-500\/60{
    background-color: rgb(99 102 241 / 0.6) !important;
}

.bg-indigo-500\/65{
    background-color: rgb(99 102 241 / 0.65) !important;
}

.bg-indigo-500\/70{
    background-color: rgb(99 102 241 / 0.7) !important;
}

.bg-indigo-500\/75{
    background-color: rgb(99 102 241 / 0.75) !important;
}

.bg-indigo-500\/80{
    background-color: rgb(99 102 241 / 0.8) !important;
}

.bg-indigo-500\/85{
    background-color: rgb(99 102 241 / 0.85) !important;
}

.bg-indigo-500\/90{
    background-color: rgb(99 102 241 / 0.9) !important;
}

.bg-indigo-500\/95{
    background-color: rgb(99 102 241 / 0.95) !important;
}

.bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-600\/0{
    background-color: rgb(79 70 229 / 0) !important;
}

.bg-indigo-600\/10{
    background-color: rgb(79 70 229 / 0.1) !important;
}

.bg-indigo-600\/100{
    background-color: rgb(79 70 229 / 1) !important;
}

.bg-indigo-600\/15{
    background-color: rgb(79 70 229 / 0.15) !important;
}

.bg-indigo-600\/20{
    background-color: rgb(79 70 229 / 0.2) !important;
}

.bg-indigo-600\/25{
    background-color: rgb(79 70 229 / 0.25) !important;
}

.bg-indigo-600\/30{
    background-color: rgb(79 70 229 / 0.3) !important;
}

.bg-indigo-600\/35{
    background-color: rgb(79 70 229 / 0.35) !important;
}

.bg-indigo-600\/40{
    background-color: rgb(79 70 229 / 0.4) !important;
}

.bg-indigo-600\/45{
    background-color: rgb(79 70 229 / 0.45) !important;
}

.bg-indigo-600\/5{
    background-color: rgb(79 70 229 / 0.05) !important;
}

.bg-indigo-600\/50{
    background-color: rgb(79 70 229 / 0.5) !important;
}

.bg-indigo-600\/55{
    background-color: rgb(79 70 229 / 0.55) !important;
}

.bg-indigo-600\/60{
    background-color: rgb(79 70 229 / 0.6) !important;
}

.bg-indigo-600\/65{
    background-color: rgb(79 70 229 / 0.65) !important;
}

.bg-indigo-600\/70{
    background-color: rgb(79 70 229 / 0.7) !important;
}

.bg-indigo-600\/75{
    background-color: rgb(79 70 229 / 0.75) !important;
}

.bg-indigo-600\/80{
    background-color: rgb(79 70 229 / 0.8) !important;
}

.bg-indigo-600\/85{
    background-color: rgb(79 70 229 / 0.85) !important;
}

.bg-indigo-600\/90{
    background-color: rgb(79 70 229 / 0.9) !important;
}

.bg-indigo-600\/95{
    background-color: rgb(79 70 229 / 0.95) !important;
}

.bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(67 56 202 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-700\/0{
    background-color: rgb(67 56 202 / 0) !important;
}

.bg-indigo-700\/10{
    background-color: rgb(67 56 202 / 0.1) !important;
}

.bg-indigo-700\/100{
    background-color: rgb(67 56 202 / 1) !important;
}

.bg-indigo-700\/15{
    background-color: rgb(67 56 202 / 0.15) !important;
}

.bg-indigo-700\/20{
    background-color: rgb(67 56 202 / 0.2) !important;
}

.bg-indigo-700\/25{
    background-color: rgb(67 56 202 / 0.25) !important;
}

.bg-indigo-700\/30{
    background-color: rgb(67 56 202 / 0.3) !important;
}

.bg-indigo-700\/35{
    background-color: rgb(67 56 202 / 0.35) !important;
}

.bg-indigo-700\/40{
    background-color: rgb(67 56 202 / 0.4) !important;
}

.bg-indigo-700\/45{
    background-color: rgb(67 56 202 / 0.45) !important;
}

.bg-indigo-700\/5{
    background-color: rgb(67 56 202 / 0.05) !important;
}

.bg-indigo-700\/50{
    background-color: rgb(67 56 202 / 0.5) !important;
}

.bg-indigo-700\/55{
    background-color: rgb(67 56 202 / 0.55) !important;
}

.bg-indigo-700\/60{
    background-color: rgb(67 56 202 / 0.6) !important;
}

.bg-indigo-700\/65{
    background-color: rgb(67 56 202 / 0.65) !important;
}

.bg-indigo-700\/70{
    background-color: rgb(67 56 202 / 0.7) !important;
}

.bg-indigo-700\/75{
    background-color: rgb(67 56 202 / 0.75) !important;
}

.bg-indigo-700\/80{
    background-color: rgb(67 56 202 / 0.8) !important;
}

.bg-indigo-700\/85{
    background-color: rgb(67 56 202 / 0.85) !important;
}

.bg-indigo-700\/90{
    background-color: rgb(67 56 202 / 0.9) !important;
}

.bg-indigo-700\/95{
    background-color: rgb(67 56 202 / 0.95) !important;
}

.bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 48 163 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-800\/0{
    background-color: rgb(55 48 163 / 0) !important;
}

.bg-indigo-800\/10{
    background-color: rgb(55 48 163 / 0.1) !important;
}

.bg-indigo-800\/100{
    background-color: rgb(55 48 163 / 1) !important;
}

.bg-indigo-800\/15{
    background-color: rgb(55 48 163 / 0.15) !important;
}

.bg-indigo-800\/20{
    background-color: rgb(55 48 163 / 0.2) !important;
}

.bg-indigo-800\/25{
    background-color: rgb(55 48 163 / 0.25) !important;
}

.bg-indigo-800\/30{
    background-color: rgb(55 48 163 / 0.3) !important;
}

.bg-indigo-800\/35{
    background-color: rgb(55 48 163 / 0.35) !important;
}

.bg-indigo-800\/40{
    background-color: rgb(55 48 163 / 0.4) !important;
}

.bg-indigo-800\/45{
    background-color: rgb(55 48 163 / 0.45) !important;
}

.bg-indigo-800\/5{
    background-color: rgb(55 48 163 / 0.05) !important;
}

.bg-indigo-800\/50{
    background-color: rgb(55 48 163 / 0.5) !important;
}

.bg-indigo-800\/55{
    background-color: rgb(55 48 163 / 0.55) !important;
}

.bg-indigo-800\/60{
    background-color: rgb(55 48 163 / 0.6) !important;
}

.bg-indigo-800\/65{
    background-color: rgb(55 48 163 / 0.65) !important;
}

.bg-indigo-800\/70{
    background-color: rgb(55 48 163 / 0.7) !important;
}

.bg-indigo-800\/75{
    background-color: rgb(55 48 163 / 0.75) !important;
}

.bg-indigo-800\/80{
    background-color: rgb(55 48 163 / 0.8) !important;
}

.bg-indigo-800\/85{
    background-color: rgb(55 48 163 / 0.85) !important;
}

.bg-indigo-800\/90{
    background-color: rgb(55 48 163 / 0.9) !important;
}

.bg-indigo-800\/95{
    background-color: rgb(55 48 163 / 0.95) !important;
}

.bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(49 46 129 / var(--tw-bg-opacity)) !important;
}

.bg-indigo-900\/0{
    background-color: rgb(49 46 129 / 0) !important;
}

.bg-indigo-900\/10{
    background-color: rgb(49 46 129 / 0.1) !important;
}

.bg-indigo-900\/100{
    background-color: rgb(49 46 129 / 1) !important;
}

.bg-indigo-900\/15{
    background-color: rgb(49 46 129 / 0.15) !important;
}

.bg-indigo-900\/20{
    background-color: rgb(49 46 129 / 0.2) !important;
}

.bg-indigo-900\/25{
    background-color: rgb(49 46 129 / 0.25) !important;
}

.bg-indigo-900\/30{
    background-color: rgb(49 46 129 / 0.3) !important;
}

.bg-indigo-900\/35{
    background-color: rgb(49 46 129 / 0.35) !important;
}

.bg-indigo-900\/40{
    background-color: rgb(49 46 129 / 0.4) !important;
}

.bg-indigo-900\/45{
    background-color: rgb(49 46 129 / 0.45) !important;
}

.bg-indigo-900\/5{
    background-color: rgb(49 46 129 / 0.05) !important;
}

.bg-indigo-900\/50{
    background-color: rgb(49 46 129 / 0.5) !important;
}

.bg-indigo-900\/55{
    background-color: rgb(49 46 129 / 0.55) !important;
}

.bg-indigo-900\/60{
    background-color: rgb(49 46 129 / 0.6) !important;
}

.bg-indigo-900\/65{
    background-color: rgb(49 46 129 / 0.65) !important;
}

.bg-indigo-900\/70{
    background-color: rgb(49 46 129 / 0.7) !important;
}

.bg-indigo-900\/75{
    background-color: rgb(49 46 129 / 0.75) !important;
}

.bg-indigo-900\/80{
    background-color: rgb(49 46 129 / 0.8) !important;
}

.bg-indigo-900\/85{
    background-color: rgb(49 46 129 / 0.85) !important;
}

.bg-indigo-900\/90{
    background-color: rgb(49 46 129 / 0.9) !important;
}

.bg-indigo-900\/95{
    background-color: rgb(49 46 129 / 0.95) !important;
}

.bg-info{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 116 224 / var(--tw-bg-opacity)) !important;
}

.bg-info\/0{
    background-color: rgb(0 116 224 / 0) !important;
}

.bg-info\/10{
    background-color: rgb(0 116 224 / 0.1) !important;
}

.bg-info\/100{
    background-color: rgb(0 116 224 / 1) !important;
}

.bg-info\/15{
    background-color: rgb(0 116 224 / 0.15) !important;
}

.bg-info\/20{
    background-color: rgb(0 116 224 / 0.2) !important;
}

.bg-info\/25{
    background-color: rgb(0 116 224 / 0.25) !important;
}

.bg-info\/30{
    background-color: rgb(0 116 224 / 0.3) !important;
}

.bg-info\/35{
    background-color: rgb(0 116 224 / 0.35) !important;
}

.bg-info\/40{
    background-color: rgb(0 116 224 / 0.4) !important;
}

.bg-info\/45{
    background-color: rgb(0 116 224 / 0.45) !important;
}

.bg-info\/5{
    background-color: rgb(0 116 224 / 0.05) !important;
}

.bg-info\/50{
    background-color: rgb(0 116 224 / 0.5) !important;
}

.bg-info\/55{
    background-color: rgb(0 116 224 / 0.55) !important;
}

.bg-info\/60{
    background-color: rgb(0 116 224 / 0.6) !important;
}

.bg-info\/65{
    background-color: rgb(0 116 224 / 0.65) !important;
}

.bg-info\/70{
    background-color: rgb(0 116 224 / 0.7) !important;
}

.bg-info\/75{
    background-color: rgb(0 116 224 / 0.75) !important;
}

.bg-info\/80{
    background-color: rgb(0 116 224 / 0.8) !important;
}

.bg-info\/85{
    background-color: rgb(0 116 224 / 0.85) !important;
}

.bg-info\/90{
    background-color: rgb(0 116 224 / 0.9) !important;
}

.bg-info\/95{
    background-color: rgb(0 116 224 / 0.95) !important;
}

.bg-light{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 253 253 / var(--tw-bg-opacity)) !important;
}

.bg-light\/0{
    background-color: rgb(252 253 253 / 0) !important;
}

.bg-light\/10{
    background-color: rgb(252 253 253 / 0.1) !important;
}

.bg-light\/100{
    background-color: rgb(252 253 253 / 1) !important;
}

.bg-light\/15{
    background-color: rgb(252 253 253 / 0.15) !important;
}

.bg-light\/20{
    background-color: rgb(252 253 253 / 0.2) !important;
}

.bg-light\/25{
    background-color: rgb(252 253 253 / 0.25) !important;
}

.bg-light\/30{
    background-color: rgb(252 253 253 / 0.3) !important;
}

.bg-light\/35{
    background-color: rgb(252 253 253 / 0.35) !important;
}

.bg-light\/40{
    background-color: rgb(252 253 253 / 0.4) !important;
}

.bg-light\/45{
    background-color: rgb(252 253 253 / 0.45) !important;
}

.bg-light\/5{
    background-color: rgb(252 253 253 / 0.05) !important;
}

.bg-light\/50{
    background-color: rgb(252 253 253 / 0.5) !important;
}

.bg-light\/55{
    background-color: rgb(252 253 253 / 0.55) !important;
}

.bg-light\/60{
    background-color: rgb(252 253 253 / 0.6) !important;
}

.bg-light\/65{
    background-color: rgb(252 253 253 / 0.65) !important;
}

.bg-light\/70{
    background-color: rgb(252 253 253 / 0.7) !important;
}

.bg-light\/75{
    background-color: rgb(252 253 253 / 0.75) !important;
}

.bg-light\/80{
    background-color: rgb(252 253 253 / 0.8) !important;
}

.bg-light\/85{
    background-color: rgb(252 253 253 / 0.85) !important;
}

.bg-light\/90{
    background-color: rgb(252 253 253 / 0.9) !important;
}

.bg-light\/95{
    background-color: rgb(252 253 253 / 0.95) !important;
}

.bg-magenta-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 204 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-100\/0{
    background-color: rgb(255 204 255 / 0) !important;
}

.bg-magenta-100\/10{
    background-color: rgb(255 204 255 / 0.1) !important;
}

.bg-magenta-100\/100{
    background-color: rgb(255 204 255 / 1) !important;
}

.bg-magenta-100\/15{
    background-color: rgb(255 204 255 / 0.15) !important;
}

.bg-magenta-100\/20{
    background-color: rgb(255 204 255 / 0.2) !important;
}

.bg-magenta-100\/25{
    background-color: rgb(255 204 255 / 0.25) !important;
}

.bg-magenta-100\/30{
    background-color: rgb(255 204 255 / 0.3) !important;
}

.bg-magenta-100\/35{
    background-color: rgb(255 204 255 / 0.35) !important;
}

.bg-magenta-100\/40{
    background-color: rgb(255 204 255 / 0.4) !important;
}

.bg-magenta-100\/45{
    background-color: rgb(255 204 255 / 0.45) !important;
}

.bg-magenta-100\/5{
    background-color: rgb(255 204 255 / 0.05) !important;
}

.bg-magenta-100\/50{
    background-color: rgb(255 204 255 / 0.5) !important;
}

.bg-magenta-100\/55{
    background-color: rgb(255 204 255 / 0.55) !important;
}

.bg-magenta-100\/60{
    background-color: rgb(255 204 255 / 0.6) !important;
}

.bg-magenta-100\/65{
    background-color: rgb(255 204 255 / 0.65) !important;
}

.bg-magenta-100\/70{
    background-color: rgb(255 204 255 / 0.7) !important;
}

.bg-magenta-100\/75{
    background-color: rgb(255 204 255 / 0.75) !important;
}

.bg-magenta-100\/80{
    background-color: rgb(255 204 255 / 0.8) !important;
}

.bg-magenta-100\/85{
    background-color: rgb(255 204 255 / 0.85) !important;
}

.bg-magenta-100\/90{
    background-color: rgb(255 204 255 / 0.9) !important;
}

.bg-magenta-100\/95{
    background-color: rgb(255 204 255 / 0.95) !important;
}

.bg-magenta-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 153 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-200\/0{
    background-color: rgb(255 153 255 / 0) !important;
}

.bg-magenta-200\/10{
    background-color: rgb(255 153 255 / 0.1) !important;
}

.bg-magenta-200\/100{
    background-color: rgb(255 153 255 / 1) !important;
}

.bg-magenta-200\/15{
    background-color: rgb(255 153 255 / 0.15) !important;
}

.bg-magenta-200\/20{
    background-color: rgb(255 153 255 / 0.2) !important;
}

.bg-magenta-200\/25{
    background-color: rgb(255 153 255 / 0.25) !important;
}

.bg-magenta-200\/30{
    background-color: rgb(255 153 255 / 0.3) !important;
}

.bg-magenta-200\/35{
    background-color: rgb(255 153 255 / 0.35) !important;
}

.bg-magenta-200\/40{
    background-color: rgb(255 153 255 / 0.4) !important;
}

.bg-magenta-200\/45{
    background-color: rgb(255 153 255 / 0.45) !important;
}

.bg-magenta-200\/5{
    background-color: rgb(255 153 255 / 0.05) !important;
}

.bg-magenta-200\/50{
    background-color: rgb(255 153 255 / 0.5) !important;
}

.bg-magenta-200\/55{
    background-color: rgb(255 153 255 / 0.55) !important;
}

.bg-magenta-200\/60{
    background-color: rgb(255 153 255 / 0.6) !important;
}

.bg-magenta-200\/65{
    background-color: rgb(255 153 255 / 0.65) !important;
}

.bg-magenta-200\/70{
    background-color: rgb(255 153 255 / 0.7) !important;
}

.bg-magenta-200\/75{
    background-color: rgb(255 153 255 / 0.75) !important;
}

.bg-magenta-200\/80{
    background-color: rgb(255 153 255 / 0.8) !important;
}

.bg-magenta-200\/85{
    background-color: rgb(255 153 255 / 0.85) !important;
}

.bg-magenta-200\/90{
    background-color: rgb(255 153 255 / 0.9) !important;
}

.bg-magenta-200\/95{
    background-color: rgb(255 153 255 / 0.95) !important;
}

.bg-magenta-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 102 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-300\/0{
    background-color: rgb(255 102 255 / 0) !important;
}

.bg-magenta-300\/10{
    background-color: rgb(255 102 255 / 0.1) !important;
}

.bg-magenta-300\/100{
    background-color: rgb(255 102 255 / 1) !important;
}

.bg-magenta-300\/15{
    background-color: rgb(255 102 255 / 0.15) !important;
}

.bg-magenta-300\/20{
    background-color: rgb(255 102 255 / 0.2) !important;
}

.bg-magenta-300\/25{
    background-color: rgb(255 102 255 / 0.25) !important;
}

.bg-magenta-300\/30{
    background-color: rgb(255 102 255 / 0.3) !important;
}

.bg-magenta-300\/35{
    background-color: rgb(255 102 255 / 0.35) !important;
}

.bg-magenta-300\/40{
    background-color: rgb(255 102 255 / 0.4) !important;
}

.bg-magenta-300\/45{
    background-color: rgb(255 102 255 / 0.45) !important;
}

.bg-magenta-300\/5{
    background-color: rgb(255 102 255 / 0.05) !important;
}

.bg-magenta-300\/50{
    background-color: rgb(255 102 255 / 0.5) !important;
}

.bg-magenta-300\/55{
    background-color: rgb(255 102 255 / 0.55) !important;
}

.bg-magenta-300\/60{
    background-color: rgb(255 102 255 / 0.6) !important;
}

.bg-magenta-300\/65{
    background-color: rgb(255 102 255 / 0.65) !important;
}

.bg-magenta-300\/70{
    background-color: rgb(255 102 255 / 0.7) !important;
}

.bg-magenta-300\/75{
    background-color: rgb(255 102 255 / 0.75) !important;
}

.bg-magenta-300\/80{
    background-color: rgb(255 102 255 / 0.8) !important;
}

.bg-magenta-300\/85{
    background-color: rgb(255 102 255 / 0.85) !important;
}

.bg-magenta-300\/90{
    background-color: rgb(255 102 255 / 0.9) !important;
}

.bg-magenta-300\/95{
    background-color: rgb(255 102 255 / 0.95) !important;
}

.bg-magenta-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 51 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-400\/0{
    background-color: rgb(255 51 255 / 0) !important;
}

.bg-magenta-400\/10{
    background-color: rgb(255 51 255 / 0.1) !important;
}

.bg-magenta-400\/100{
    background-color: rgb(255 51 255 / 1) !important;
}

.bg-magenta-400\/15{
    background-color: rgb(255 51 255 / 0.15) !important;
}

.bg-magenta-400\/20{
    background-color: rgb(255 51 255 / 0.2) !important;
}

.bg-magenta-400\/25{
    background-color: rgb(255 51 255 / 0.25) !important;
}

.bg-magenta-400\/30{
    background-color: rgb(255 51 255 / 0.3) !important;
}

.bg-magenta-400\/35{
    background-color: rgb(255 51 255 / 0.35) !important;
}

.bg-magenta-400\/40{
    background-color: rgb(255 51 255 / 0.4) !important;
}

.bg-magenta-400\/45{
    background-color: rgb(255 51 255 / 0.45) !important;
}

.bg-magenta-400\/5{
    background-color: rgb(255 51 255 / 0.05) !important;
}

.bg-magenta-400\/50{
    background-color: rgb(255 51 255 / 0.5) !important;
}

.bg-magenta-400\/55{
    background-color: rgb(255 51 255 / 0.55) !important;
}

.bg-magenta-400\/60{
    background-color: rgb(255 51 255 / 0.6) !important;
}

.bg-magenta-400\/65{
    background-color: rgb(255 51 255 / 0.65) !important;
}

.bg-magenta-400\/70{
    background-color: rgb(255 51 255 / 0.7) !important;
}

.bg-magenta-400\/75{
    background-color: rgb(255 51 255 / 0.75) !important;
}

.bg-magenta-400\/80{
    background-color: rgb(255 51 255 / 0.8) !important;
}

.bg-magenta-400\/85{
    background-color: rgb(255 51 255 / 0.85) !important;
}

.bg-magenta-400\/90{
    background-color: rgb(255 51 255 / 0.9) !important;
}

.bg-magenta-400\/95{
    background-color: rgb(255 51 255 / 0.95) !important;
}

.bg-magenta-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 229 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-50\/0{
    background-color: rgb(255 229 255 / 0) !important;
}

.bg-magenta-50\/10{
    background-color: rgb(255 229 255 / 0.1) !important;
}

.bg-magenta-50\/100{
    background-color: rgb(255 229 255 / 1) !important;
}

.bg-magenta-50\/15{
    background-color: rgb(255 229 255 / 0.15) !important;
}

.bg-magenta-50\/20{
    background-color: rgb(255 229 255 / 0.2) !important;
}

.bg-magenta-50\/25{
    background-color: rgb(255 229 255 / 0.25) !important;
}

.bg-magenta-50\/30{
    background-color: rgb(255 229 255 / 0.3) !important;
}

.bg-magenta-50\/35{
    background-color: rgb(255 229 255 / 0.35) !important;
}

.bg-magenta-50\/40{
    background-color: rgb(255 229 255 / 0.4) !important;
}

.bg-magenta-50\/45{
    background-color: rgb(255 229 255 / 0.45) !important;
}

.bg-magenta-50\/5{
    background-color: rgb(255 229 255 / 0.05) !important;
}

.bg-magenta-50\/50{
    background-color: rgb(255 229 255 / 0.5) !important;
}

.bg-magenta-50\/55{
    background-color: rgb(255 229 255 / 0.55) !important;
}

.bg-magenta-50\/60{
    background-color: rgb(255 229 255 / 0.6) !important;
}

.bg-magenta-50\/65{
    background-color: rgb(255 229 255 / 0.65) !important;
}

.bg-magenta-50\/70{
    background-color: rgb(255 229 255 / 0.7) !important;
}

.bg-magenta-50\/75{
    background-color: rgb(255 229 255 / 0.75) !important;
}

.bg-magenta-50\/80{
    background-color: rgb(255 229 255 / 0.8) !important;
}

.bg-magenta-50\/85{
    background-color: rgb(255 229 255 / 0.85) !important;
}

.bg-magenta-50\/90{
    background-color: rgb(255 229 255 / 0.9) !important;
}

.bg-magenta-50\/95{
    background-color: rgb(255 229 255 / 0.95) !important;
}

.bg-magenta-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 0 255 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-500\/0{
    background-color: rgb(255 0 255 / 0) !important;
}

.bg-magenta-500\/10{
    background-color: rgb(255 0 255 / 0.1) !important;
}

.bg-magenta-500\/100{
    background-color: rgb(255 0 255 / 1) !important;
}

.bg-magenta-500\/15{
    background-color: rgb(255 0 255 / 0.15) !important;
}

.bg-magenta-500\/20{
    background-color: rgb(255 0 255 / 0.2) !important;
}

.bg-magenta-500\/25{
    background-color: rgb(255 0 255 / 0.25) !important;
}

.bg-magenta-500\/30{
    background-color: rgb(255 0 255 / 0.3) !important;
}

.bg-magenta-500\/35{
    background-color: rgb(255 0 255 / 0.35) !important;
}

.bg-magenta-500\/40{
    background-color: rgb(255 0 255 / 0.4) !important;
}

.bg-magenta-500\/45{
    background-color: rgb(255 0 255 / 0.45) !important;
}

.bg-magenta-500\/5{
    background-color: rgb(255 0 255 / 0.05) !important;
}

.bg-magenta-500\/50{
    background-color: rgb(255 0 255 / 0.5) !important;
}

.bg-magenta-500\/55{
    background-color: rgb(255 0 255 / 0.55) !important;
}

.bg-magenta-500\/60{
    background-color: rgb(255 0 255 / 0.6) !important;
}

.bg-magenta-500\/65{
    background-color: rgb(255 0 255 / 0.65) !important;
}

.bg-magenta-500\/70{
    background-color: rgb(255 0 255 / 0.7) !important;
}

.bg-magenta-500\/75{
    background-color: rgb(255 0 255 / 0.75) !important;
}

.bg-magenta-500\/80{
    background-color: rgb(255 0 255 / 0.8) !important;
}

.bg-magenta-500\/85{
    background-color: rgb(255 0 255 / 0.85) !important;
}

.bg-magenta-500\/90{
    background-color: rgb(255 0 255 / 0.9) !important;
}

.bg-magenta-500\/95{
    background-color: rgb(255 0 255 / 0.95) !important;
}

.bg-magenta-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(209 0 209 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-600\/0{
    background-color: rgb(209 0 209 / 0) !important;
}

.bg-magenta-600\/10{
    background-color: rgb(209 0 209 / 0.1) !important;
}

.bg-magenta-600\/100{
    background-color: rgb(209 0 209 / 1) !important;
}

.bg-magenta-600\/15{
    background-color: rgb(209 0 209 / 0.15) !important;
}

.bg-magenta-600\/20{
    background-color: rgb(209 0 209 / 0.2) !important;
}

.bg-magenta-600\/25{
    background-color: rgb(209 0 209 / 0.25) !important;
}

.bg-magenta-600\/30{
    background-color: rgb(209 0 209 / 0.3) !important;
}

.bg-magenta-600\/35{
    background-color: rgb(209 0 209 / 0.35) !important;
}

.bg-magenta-600\/40{
    background-color: rgb(209 0 209 / 0.4) !important;
}

.bg-magenta-600\/45{
    background-color: rgb(209 0 209 / 0.45) !important;
}

.bg-magenta-600\/5{
    background-color: rgb(209 0 209 / 0.05) !important;
}

.bg-magenta-600\/50{
    background-color: rgb(209 0 209 / 0.5) !important;
}

.bg-magenta-600\/55{
    background-color: rgb(209 0 209 / 0.55) !important;
}

.bg-magenta-600\/60{
    background-color: rgb(209 0 209 / 0.6) !important;
}

.bg-magenta-600\/65{
    background-color: rgb(209 0 209 / 0.65) !important;
}

.bg-magenta-600\/70{
    background-color: rgb(209 0 209 / 0.7) !important;
}

.bg-magenta-600\/75{
    background-color: rgb(209 0 209 / 0.75) !important;
}

.bg-magenta-600\/80{
    background-color: rgb(209 0 209 / 0.8) !important;
}

.bg-magenta-600\/85{
    background-color: rgb(209 0 209 / 0.85) !important;
}

.bg-magenta-600\/90{
    background-color: rgb(209 0 209 / 0.9) !important;
}

.bg-magenta-600\/95{
    background-color: rgb(209 0 209 / 0.95) !important;
}

.bg-magenta-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(163 0 163 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-700\/0{
    background-color: rgb(163 0 163 / 0) !important;
}

.bg-magenta-700\/10{
    background-color: rgb(163 0 163 / 0.1) !important;
}

.bg-magenta-700\/100{
    background-color: rgb(163 0 163 / 1) !important;
}

.bg-magenta-700\/15{
    background-color: rgb(163 0 163 / 0.15) !important;
}

.bg-magenta-700\/20{
    background-color: rgb(163 0 163 / 0.2) !important;
}

.bg-magenta-700\/25{
    background-color: rgb(163 0 163 / 0.25) !important;
}

.bg-magenta-700\/30{
    background-color: rgb(163 0 163 / 0.3) !important;
}

.bg-magenta-700\/35{
    background-color: rgb(163 0 163 / 0.35) !important;
}

.bg-magenta-700\/40{
    background-color: rgb(163 0 163 / 0.4) !important;
}

.bg-magenta-700\/45{
    background-color: rgb(163 0 163 / 0.45) !important;
}

.bg-magenta-700\/5{
    background-color: rgb(163 0 163 / 0.05) !important;
}

.bg-magenta-700\/50{
    background-color: rgb(163 0 163 / 0.5) !important;
}

.bg-magenta-700\/55{
    background-color: rgb(163 0 163 / 0.55) !important;
}

.bg-magenta-700\/60{
    background-color: rgb(163 0 163 / 0.6) !important;
}

.bg-magenta-700\/65{
    background-color: rgb(163 0 163 / 0.65) !important;
}

.bg-magenta-700\/70{
    background-color: rgb(163 0 163 / 0.7) !important;
}

.bg-magenta-700\/75{
    background-color: rgb(163 0 163 / 0.75) !important;
}

.bg-magenta-700\/80{
    background-color: rgb(163 0 163 / 0.8) !important;
}

.bg-magenta-700\/85{
    background-color: rgb(163 0 163 / 0.85) !important;
}

.bg-magenta-700\/90{
    background-color: rgb(163 0 163 / 0.9) !important;
}

.bg-magenta-700\/95{
    background-color: rgb(163 0 163 / 0.95) !important;
}

.bg-magenta-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(117 0 117 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-800\/0{
    background-color: rgb(117 0 117 / 0) !important;
}

.bg-magenta-800\/10{
    background-color: rgb(117 0 117 / 0.1) !important;
}

.bg-magenta-800\/100{
    background-color: rgb(117 0 117 / 1) !important;
}

.bg-magenta-800\/15{
    background-color: rgb(117 0 117 / 0.15) !important;
}

.bg-magenta-800\/20{
    background-color: rgb(117 0 117 / 0.2) !important;
}

.bg-magenta-800\/25{
    background-color: rgb(117 0 117 / 0.25) !important;
}

.bg-magenta-800\/30{
    background-color: rgb(117 0 117 / 0.3) !important;
}

.bg-magenta-800\/35{
    background-color: rgb(117 0 117 / 0.35) !important;
}

.bg-magenta-800\/40{
    background-color: rgb(117 0 117 / 0.4) !important;
}

.bg-magenta-800\/45{
    background-color: rgb(117 0 117 / 0.45) !important;
}

.bg-magenta-800\/5{
    background-color: rgb(117 0 117 / 0.05) !important;
}

.bg-magenta-800\/50{
    background-color: rgb(117 0 117 / 0.5) !important;
}

.bg-magenta-800\/55{
    background-color: rgb(117 0 117 / 0.55) !important;
}

.bg-magenta-800\/60{
    background-color: rgb(117 0 117 / 0.6) !important;
}

.bg-magenta-800\/65{
    background-color: rgb(117 0 117 / 0.65) !important;
}

.bg-magenta-800\/70{
    background-color: rgb(117 0 117 / 0.7) !important;
}

.bg-magenta-800\/75{
    background-color: rgb(117 0 117 / 0.75) !important;
}

.bg-magenta-800\/80{
    background-color: rgb(117 0 117 / 0.8) !important;
}

.bg-magenta-800\/85{
    background-color: rgb(117 0 117 / 0.85) !important;
}

.bg-magenta-800\/90{
    background-color: rgb(117 0 117 / 0.9) !important;
}

.bg-magenta-800\/95{
    background-color: rgb(117 0 117 / 0.95) !important;
}

.bg-magenta-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(71 0 71 / var(--tw-bg-opacity)) !important;
}

.bg-magenta-900\/0{
    background-color: rgb(71 0 71 / 0) !important;
}

.bg-magenta-900\/10{
    background-color: rgb(71 0 71 / 0.1) !important;
}

.bg-magenta-900\/100{
    background-color: rgb(71 0 71 / 1) !important;
}

.bg-magenta-900\/15{
    background-color: rgb(71 0 71 / 0.15) !important;
}

.bg-magenta-900\/20{
    background-color: rgb(71 0 71 / 0.2) !important;
}

.bg-magenta-900\/25{
    background-color: rgb(71 0 71 / 0.25) !important;
}

.bg-magenta-900\/30{
    background-color: rgb(71 0 71 / 0.3) !important;
}

.bg-magenta-900\/35{
    background-color: rgb(71 0 71 / 0.35) !important;
}

.bg-magenta-900\/40{
    background-color: rgb(71 0 71 / 0.4) !important;
}

.bg-magenta-900\/45{
    background-color: rgb(71 0 71 / 0.45) !important;
}

.bg-magenta-900\/5{
    background-color: rgb(71 0 71 / 0.05) !important;
}

.bg-magenta-900\/50{
    background-color: rgb(71 0 71 / 0.5) !important;
}

.bg-magenta-900\/55{
    background-color: rgb(71 0 71 / 0.55) !important;
}

.bg-magenta-900\/60{
    background-color: rgb(71 0 71 / 0.6) !important;
}

.bg-magenta-900\/65{
    background-color: rgb(71 0 71 / 0.65) !important;
}

.bg-magenta-900\/70{
    background-color: rgb(71 0 71 / 0.7) !important;
}

.bg-magenta-900\/75{
    background-color: rgb(71 0 71 / 0.75) !important;
}

.bg-magenta-900\/80{
    background-color: rgb(71 0 71 / 0.8) !important;
}

.bg-magenta-900\/85{
    background-color: rgb(71 0 71 / 0.85) !important;
}

.bg-magenta-900\/90{
    background-color: rgb(71 0 71 / 0.9) !important;
}

.bg-magenta-900\/95{
    background-color: rgb(71 0 71 / 0.95) !important;
}

.bg-medium{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(94 94 94 / var(--tw-bg-opacity)) !important;
}

.bg-medium\/0{
    background-color: rgb(94 94 94 / 0) !important;
}

.bg-medium\/10{
    background-color: rgb(94 94 94 / 0.1) !important;
}

.bg-medium\/100{
    background-color: rgb(94 94 94 / 1) !important;
}

.bg-medium\/15{
    background-color: rgb(94 94 94 / 0.15) !important;
}

.bg-medium\/20{
    background-color: rgb(94 94 94 / 0.2) !important;
}

.bg-medium\/25{
    background-color: rgb(94 94 94 / 0.25) !important;
}

.bg-medium\/30{
    background-color: rgb(94 94 94 / 0.3) !important;
}

.bg-medium\/35{
    background-color: rgb(94 94 94 / 0.35) !important;
}

.bg-medium\/40{
    background-color: rgb(94 94 94 / 0.4) !important;
}

.bg-medium\/45{
    background-color: rgb(94 94 94 / 0.45) !important;
}

.bg-medium\/5{
    background-color: rgb(94 94 94 / 0.05) !important;
}

.bg-medium\/50{
    background-color: rgb(94 94 94 / 0.5) !important;
}

.bg-medium\/55{
    background-color: rgb(94 94 94 / 0.55) !important;
}

.bg-medium\/60{
    background-color: rgb(94 94 94 / 0.6) !important;
}

.bg-medium\/65{
    background-color: rgb(94 94 94 / 0.65) !important;
}

.bg-medium\/70{
    background-color: rgb(94 94 94 / 0.7) !important;
}

.bg-medium\/75{
    background-color: rgb(94 94 94 / 0.75) !important;
}

.bg-medium\/80{
    background-color: rgb(94 94 94 / 0.8) !important;
}

.bg-medium\/85{
    background-color: rgb(94 94 94 / 0.85) !important;
}

.bg-medium\/90{
    background-color: rgb(94 94 94 / 0.9) !important;
}

.bg-medium\/95{
    background-color: rgb(94 94 94 / 0.95) !important;
}

.bg-orange-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 230 201 / var(--tw-bg-opacity)) !important;
}

.bg-orange-100\/0{
    background-color: rgb(254 230 201 / 0) !important;
}

.bg-orange-100\/10{
    background-color: rgb(254 230 201 / 0.1) !important;
}

.bg-orange-100\/100{
    background-color: rgb(254 230 201 / 1) !important;
}

.bg-orange-100\/15{
    background-color: rgb(254 230 201 / 0.15) !important;
}

.bg-orange-100\/20{
    background-color: rgb(254 230 201 / 0.2) !important;
}

.bg-orange-100\/25{
    background-color: rgb(254 230 201 / 0.25) !important;
}

.bg-orange-100\/30{
    background-color: rgb(254 230 201 / 0.3) !important;
}

.bg-orange-100\/35{
    background-color: rgb(254 230 201 / 0.35) !important;
}

.bg-orange-100\/40{
    background-color: rgb(254 230 201 / 0.4) !important;
}

.bg-orange-100\/45{
    background-color: rgb(254 230 201 / 0.45) !important;
}

.bg-orange-100\/5{
    background-color: rgb(254 230 201 / 0.05) !important;
}

.bg-orange-100\/50{
    background-color: rgb(254 230 201 / 0.5) !important;
}

.bg-orange-100\/55{
    background-color: rgb(254 230 201 / 0.55) !important;
}

.bg-orange-100\/60{
    background-color: rgb(254 230 201 / 0.6) !important;
}

.bg-orange-100\/65{
    background-color: rgb(254 230 201 / 0.65) !important;
}

.bg-orange-100\/70{
    background-color: rgb(254 230 201 / 0.7) !important;
}

.bg-orange-100\/75{
    background-color: rgb(254 230 201 / 0.75) !important;
}

.bg-orange-100\/80{
    background-color: rgb(254 230 201 / 0.8) !important;
}

.bg-orange-100\/85{
    background-color: rgb(254 230 201 / 0.85) !important;
}

.bg-orange-100\/90{
    background-color: rgb(254 230 201 / 0.9) !important;
}

.bg-orange-100\/95{
    background-color: rgb(254 230 201 / 0.95) !important;
}

.bg-orange-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 205 156 / var(--tw-bg-opacity)) !important;
}

.bg-orange-200\/0{
    background-color: rgb(252 205 156 / 0) !important;
}

.bg-orange-200\/10{
    background-color: rgb(252 205 156 / 0.1) !important;
}

.bg-orange-200\/100{
    background-color: rgb(252 205 156 / 1) !important;
}

.bg-orange-200\/15{
    background-color: rgb(252 205 156 / 0.15) !important;
}

.bg-orange-200\/20{
    background-color: rgb(252 205 156 / 0.2) !important;
}

.bg-orange-200\/25{
    background-color: rgb(252 205 156 / 0.25) !important;
}

.bg-orange-200\/30{
    background-color: rgb(252 205 156 / 0.3) !important;
}

.bg-orange-200\/35{
    background-color: rgb(252 205 156 / 0.35) !important;
}

.bg-orange-200\/40{
    background-color: rgb(252 205 156 / 0.4) !important;
}

.bg-orange-200\/45{
    background-color: rgb(252 205 156 / 0.45) !important;
}

.bg-orange-200\/5{
    background-color: rgb(252 205 156 / 0.05) !important;
}

.bg-orange-200\/50{
    background-color: rgb(252 205 156 / 0.5) !important;
}

.bg-orange-200\/55{
    background-color: rgb(252 205 156 / 0.55) !important;
}

.bg-orange-200\/60{
    background-color: rgb(252 205 156 / 0.6) !important;
}

.bg-orange-200\/65{
    background-color: rgb(252 205 156 / 0.65) !important;
}

.bg-orange-200\/70{
    background-color: rgb(252 205 156 / 0.7) !important;
}

.bg-orange-200\/75{
    background-color: rgb(252 205 156 / 0.75) !important;
}

.bg-orange-200\/80{
    background-color: rgb(252 205 156 / 0.8) !important;
}

.bg-orange-200\/85{
    background-color: rgb(252 205 156 / 0.85) !important;
}

.bg-orange-200\/90{
    background-color: rgb(252 205 156 / 0.9) !important;
}

.bg-orange-200\/95{
    background-color: rgb(252 205 156 / 0.95) !important;
}

.bg-orange-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 178 111 / var(--tw-bg-opacity)) !important;
}

.bg-orange-300\/0{
    background-color: rgb(251 178 111 / 0) !important;
}

.bg-orange-300\/10{
    background-color: rgb(251 178 111 / 0.1) !important;
}

.bg-orange-300\/100{
    background-color: rgb(251 178 111 / 1) !important;
}

.bg-orange-300\/15{
    background-color: rgb(251 178 111 / 0.15) !important;
}

.bg-orange-300\/20{
    background-color: rgb(251 178 111 / 0.2) !important;
}

.bg-orange-300\/25{
    background-color: rgb(251 178 111 / 0.25) !important;
}

.bg-orange-300\/30{
    background-color: rgb(251 178 111 / 0.3) !important;
}

.bg-orange-300\/35{
    background-color: rgb(251 178 111 / 0.35) !important;
}

.bg-orange-300\/40{
    background-color: rgb(251 178 111 / 0.4) !important;
}

.bg-orange-300\/45{
    background-color: rgb(251 178 111 / 0.45) !important;
}

.bg-orange-300\/5{
    background-color: rgb(251 178 111 / 0.05) !important;
}

.bg-orange-300\/50{
    background-color: rgb(251 178 111 / 0.5) !important;
}

.bg-orange-300\/55{
    background-color: rgb(251 178 111 / 0.55) !important;
}

.bg-orange-300\/60{
    background-color: rgb(251 178 111 / 0.6) !important;
}

.bg-orange-300\/65{
    background-color: rgb(251 178 111 / 0.65) !important;
}

.bg-orange-300\/70{
    background-color: rgb(251 178 111 / 0.7) !important;
}

.bg-orange-300\/75{
    background-color: rgb(251 178 111 / 0.75) !important;
}

.bg-orange-300\/80{
    background-color: rgb(251 178 111 / 0.8) !important;
}

.bg-orange-300\/85{
    background-color: rgb(251 178 111 / 0.85) !important;
}

.bg-orange-300\/90{
    background-color: rgb(251 178 111 / 0.9) !important;
}

.bg-orange-300\/95{
    background-color: rgb(251 178 111 / 0.95) !important;
}

.bg-orange-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(250 148 67 / var(--tw-bg-opacity)) !important;
}

.bg-orange-400\/0{
    background-color: rgb(250 148 67 / 0) !important;
}

.bg-orange-400\/10{
    background-color: rgb(250 148 67 / 0.1) !important;
}

.bg-orange-400\/100{
    background-color: rgb(250 148 67 / 1) !important;
}

.bg-orange-400\/15{
    background-color: rgb(250 148 67 / 0.15) !important;
}

.bg-orange-400\/20{
    background-color: rgb(250 148 67 / 0.2) !important;
}

.bg-orange-400\/25{
    background-color: rgb(250 148 67 / 0.25) !important;
}

.bg-orange-400\/30{
    background-color: rgb(250 148 67 / 0.3) !important;
}

.bg-orange-400\/35{
    background-color: rgb(250 148 67 / 0.35) !important;
}

.bg-orange-400\/40{
    background-color: rgb(250 148 67 / 0.4) !important;
}

.bg-orange-400\/45{
    background-color: rgb(250 148 67 / 0.45) !important;
}

.bg-orange-400\/5{
    background-color: rgb(250 148 67 / 0.05) !important;
}

.bg-orange-400\/50{
    background-color: rgb(250 148 67 / 0.5) !important;
}

.bg-orange-400\/55{
    background-color: rgb(250 148 67 / 0.55) !important;
}

.bg-orange-400\/60{
    background-color: rgb(250 148 67 / 0.6) !important;
}

.bg-orange-400\/65{
    background-color: rgb(250 148 67 / 0.65) !important;
}

.bg-orange-400\/70{
    background-color: rgb(250 148 67 / 0.7) !important;
}

.bg-orange-400\/75{
    background-color: rgb(250 148 67 / 0.75) !important;
}

.bg-orange-400\/80{
    background-color: rgb(250 148 67 / 0.8) !important;
}

.bg-orange-400\/85{
    background-color: rgb(250 148 67 / 0.85) !important;
}

.bg-orange-400\/90{
    background-color: rgb(250 148 67 / 0.9) !important;
}

.bg-orange-400\/95{
    background-color: rgb(250 148 67 / 0.95) !important;
}

.bg-orange-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 241 223 / var(--tw-bg-opacity)) !important;
}

.bg-orange-50\/0{
    background-color: rgb(254 241 223 / 0) !important;
}

.bg-orange-50\/10{
    background-color: rgb(254 241 223 / 0.1) !important;
}

.bg-orange-50\/100{
    background-color: rgb(254 241 223 / 1) !important;
}

.bg-orange-50\/15{
    background-color: rgb(254 241 223 / 0.15) !important;
}

.bg-orange-50\/20{
    background-color: rgb(254 241 223 / 0.2) !important;
}

.bg-orange-50\/25{
    background-color: rgb(254 241 223 / 0.25) !important;
}

.bg-orange-50\/30{
    background-color: rgb(254 241 223 / 0.3) !important;
}

.bg-orange-50\/35{
    background-color: rgb(254 241 223 / 0.35) !important;
}

.bg-orange-50\/40{
    background-color: rgb(254 241 223 / 0.4) !important;
}

.bg-orange-50\/45{
    background-color: rgb(254 241 223 / 0.45) !important;
}

.bg-orange-50\/5{
    background-color: rgb(254 241 223 / 0.05) !important;
}

.bg-orange-50\/50{
    background-color: rgb(254 241 223 / 0.5) !important;
}

.bg-orange-50\/55{
    background-color: rgb(254 241 223 / 0.55) !important;
}

.bg-orange-50\/60{
    background-color: rgb(254 241 223 / 0.6) !important;
}

.bg-orange-50\/65{
    background-color: rgb(254 241 223 / 0.65) !important;
}

.bg-orange-50\/70{
    background-color: rgb(254 241 223 / 0.7) !important;
}

.bg-orange-50\/75{
    background-color: rgb(254 241 223 / 0.75) !important;
}

.bg-orange-50\/80{
    background-color: rgb(254 241 223 / 0.8) !important;
}

.bg-orange-50\/85{
    background-color: rgb(254 241 223 / 0.85) !important;
}

.bg-orange-50\/90{
    background-color: rgb(254 241 223 / 0.9) !important;
}

.bg-orange-50\/95{
    background-color: rgb(254 241 223 / 0.95) !important;
}

.bg-orange-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity)) !important;
}

.bg-orange-500\/0{
    background-color: rgb(249 115 22 / 0) !important;
}

.bg-orange-500\/10{
    background-color: rgb(249 115 22 / 0.1) !important;
}

.bg-orange-500\/100{
    background-color: rgb(249 115 22 / 1) !important;
}

.bg-orange-500\/15{
    background-color: rgb(249 115 22 / 0.15) !important;
}

.bg-orange-500\/20{
    background-color: rgb(249 115 22 / 0.2) !important;
}

.bg-orange-500\/25{
    background-color: rgb(249 115 22 / 0.25) !important;
}

.bg-orange-500\/30{
    background-color: rgb(249 115 22 / 0.3) !important;
}

.bg-orange-500\/35{
    background-color: rgb(249 115 22 / 0.35) !important;
}

.bg-orange-500\/40{
    background-color: rgb(249 115 22 / 0.4) !important;
}

.bg-orange-500\/45{
    background-color: rgb(249 115 22 / 0.45) !important;
}

.bg-orange-500\/5{
    background-color: rgb(249 115 22 / 0.05) !important;
}

.bg-orange-500\/50{
    background-color: rgb(249 115 22 / 0.5) !important;
}

.bg-orange-500\/55{
    background-color: rgb(249 115 22 / 0.55) !important;
}

.bg-orange-500\/60{
    background-color: rgb(249 115 22 / 0.6) !important;
}

.bg-orange-500\/65{
    background-color: rgb(249 115 22 / 0.65) !important;
}

.bg-orange-500\/70{
    background-color: rgb(249 115 22 / 0.7) !important;
}

.bg-orange-500\/75{
    background-color: rgb(249 115 22 / 0.75) !important;
}

.bg-orange-500\/80{
    background-color: rgb(249 115 22 / 0.8) !important;
}

.bg-orange-500\/85{
    background-color: rgb(249 115 22 / 0.85) !important;
}

.bg-orange-500\/90{
    background-color: rgb(249 115 22 / 0.9) !important;
}

.bg-orange-500\/95{
    background-color: rgb(249 115 22 / 0.95) !important;
}

.bg-orange-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(214 84 6 / var(--tw-bg-opacity)) !important;
}

.bg-orange-600\/0{
    background-color: rgb(214 84 6 / 0) !important;
}

.bg-orange-600\/10{
    background-color: rgb(214 84 6 / 0.1) !important;
}

.bg-orange-600\/100{
    background-color: rgb(214 84 6 / 1) !important;
}

.bg-orange-600\/15{
    background-color: rgb(214 84 6 / 0.15) !important;
}

.bg-orange-600\/20{
    background-color: rgb(214 84 6 / 0.2) !important;
}

.bg-orange-600\/25{
    background-color: rgb(214 84 6 / 0.25) !important;
}

.bg-orange-600\/30{
    background-color: rgb(214 84 6 / 0.3) !important;
}

.bg-orange-600\/35{
    background-color: rgb(214 84 6 / 0.35) !important;
}

.bg-orange-600\/40{
    background-color: rgb(214 84 6 / 0.4) !important;
}

.bg-orange-600\/45{
    background-color: rgb(214 84 6 / 0.45) !important;
}

.bg-orange-600\/5{
    background-color: rgb(214 84 6 / 0.05) !important;
}

.bg-orange-600\/50{
    background-color: rgb(214 84 6 / 0.5) !important;
}

.bg-orange-600\/55{
    background-color: rgb(214 84 6 / 0.55) !important;
}

.bg-orange-600\/60{
    background-color: rgb(214 84 6 / 0.6) !important;
}

.bg-orange-600\/65{
    background-color: rgb(214 84 6 / 0.65) !important;
}

.bg-orange-600\/70{
    background-color: rgb(214 84 6 / 0.7) !important;
}

.bg-orange-600\/75{
    background-color: rgb(214 84 6 / 0.75) !important;
}

.bg-orange-600\/80{
    background-color: rgb(214 84 6 / 0.8) !important;
}

.bg-orange-600\/85{
    background-color: rgb(214 84 6 / 0.85) !important;
}

.bg-orange-600\/90{
    background-color: rgb(214 84 6 / 0.9) !important;
}

.bg-orange-600\/95{
    background-color: rgb(214 84 6 / 0.95) !important;
}

.bg-orange-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(165 59 4 / var(--tw-bg-opacity)) !important;
}

.bg-orange-700\/0{
    background-color: rgb(165 59 4 / 0) !important;
}

.bg-orange-700\/10{
    background-color: rgb(165 59 4 / 0.1) !important;
}

.bg-orange-700\/100{
    background-color: rgb(165 59 4 / 1) !important;
}

.bg-orange-700\/15{
    background-color: rgb(165 59 4 / 0.15) !important;
}

.bg-orange-700\/20{
    background-color: rgb(165 59 4 / 0.2) !important;
}

.bg-orange-700\/25{
    background-color: rgb(165 59 4 / 0.25) !important;
}

.bg-orange-700\/30{
    background-color: rgb(165 59 4 / 0.3) !important;
}

.bg-orange-700\/35{
    background-color: rgb(165 59 4 / 0.35) !important;
}

.bg-orange-700\/40{
    background-color: rgb(165 59 4 / 0.4) !important;
}

.bg-orange-700\/45{
    background-color: rgb(165 59 4 / 0.45) !important;
}

.bg-orange-700\/5{
    background-color: rgb(165 59 4 / 0.05) !important;
}

.bg-orange-700\/50{
    background-color: rgb(165 59 4 / 0.5) !important;
}

.bg-orange-700\/55{
    background-color: rgb(165 59 4 / 0.55) !important;
}

.bg-orange-700\/60{
    background-color: rgb(165 59 4 / 0.6) !important;
}

.bg-orange-700\/65{
    background-color: rgb(165 59 4 / 0.65) !important;
}

.bg-orange-700\/70{
    background-color: rgb(165 59 4 / 0.7) !important;
}

.bg-orange-700\/75{
    background-color: rgb(165 59 4 / 0.75) !important;
}

.bg-orange-700\/80{
    background-color: rgb(165 59 4 / 0.8) !important;
}

.bg-orange-700\/85{
    background-color: rgb(165 59 4 / 0.85) !important;
}

.bg-orange-700\/90{
    background-color: rgb(165 59 4 / 0.9) !important;
}

.bg-orange-700\/95{
    background-color: rgb(165 59 4 / 0.95) !important;
}

.bg-orange-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(115 38 3 / var(--tw-bg-opacity)) !important;
}

.bg-orange-800\/0{
    background-color: rgb(115 38 3 / 0) !important;
}

.bg-orange-800\/10{
    background-color: rgb(115 38 3 / 0.1) !important;
}

.bg-orange-800\/100{
    background-color: rgb(115 38 3 / 1) !important;
}

.bg-orange-800\/15{
    background-color: rgb(115 38 3 / 0.15) !important;
}

.bg-orange-800\/20{
    background-color: rgb(115 38 3 / 0.2) !important;
}

.bg-orange-800\/25{
    background-color: rgb(115 38 3 / 0.25) !important;
}

.bg-orange-800\/30{
    background-color: rgb(115 38 3 / 0.3) !important;
}

.bg-orange-800\/35{
    background-color: rgb(115 38 3 / 0.35) !important;
}

.bg-orange-800\/40{
    background-color: rgb(115 38 3 / 0.4) !important;
}

.bg-orange-800\/45{
    background-color: rgb(115 38 3 / 0.45) !important;
}

.bg-orange-800\/5{
    background-color: rgb(115 38 3 / 0.05) !important;
}

.bg-orange-800\/50{
    background-color: rgb(115 38 3 / 0.5) !important;
}

.bg-orange-800\/55{
    background-color: rgb(115 38 3 / 0.55) !important;
}

.bg-orange-800\/60{
    background-color: rgb(115 38 3 / 0.6) !important;
}

.bg-orange-800\/65{
    background-color: rgb(115 38 3 / 0.65) !important;
}

.bg-orange-800\/70{
    background-color: rgb(115 38 3 / 0.7) !important;
}

.bg-orange-800\/75{
    background-color: rgb(115 38 3 / 0.75) !important;
}

.bg-orange-800\/80{
    background-color: rgb(115 38 3 / 0.8) !important;
}

.bg-orange-800\/85{
    background-color: rgb(115 38 3 / 0.85) !important;
}

.bg-orange-800\/90{
    background-color: rgb(115 38 3 / 0.9) !important;
}

.bg-orange-800\/95{
    background-color: rgb(115 38 3 / 0.95) !important;
}

.bg-orange-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(65 19 2 / var(--tw-bg-opacity)) !important;
}

.bg-orange-900\/0{
    background-color: rgb(65 19 2 / 0) !important;
}

.bg-orange-900\/10{
    background-color: rgb(65 19 2 / 0.1) !important;
}

.bg-orange-900\/100{
    background-color: rgb(65 19 2 / 1) !important;
}

.bg-orange-900\/15{
    background-color: rgb(65 19 2 / 0.15) !important;
}

.bg-orange-900\/20{
    background-color: rgb(65 19 2 / 0.2) !important;
}

.bg-orange-900\/25{
    background-color: rgb(65 19 2 / 0.25) !important;
}

.bg-orange-900\/30{
    background-color: rgb(65 19 2 / 0.3) !important;
}

.bg-orange-900\/35{
    background-color: rgb(65 19 2 / 0.35) !important;
}

.bg-orange-900\/40{
    background-color: rgb(65 19 2 / 0.4) !important;
}

.bg-orange-900\/45{
    background-color: rgb(65 19 2 / 0.45) !important;
}

.bg-orange-900\/5{
    background-color: rgb(65 19 2 / 0.05) !important;
}

.bg-orange-900\/50{
    background-color: rgb(65 19 2 / 0.5) !important;
}

.bg-orange-900\/55{
    background-color: rgb(65 19 2 / 0.55) !important;
}

.bg-orange-900\/60{
    background-color: rgb(65 19 2 / 0.6) !important;
}

.bg-orange-900\/65{
    background-color: rgb(65 19 2 / 0.65) !important;
}

.bg-orange-900\/70{
    background-color: rgb(65 19 2 / 0.7) !important;
}

.bg-orange-900\/75{
    background-color: rgb(65 19 2 / 0.75) !important;
}

.bg-orange-900\/80{
    background-color: rgb(65 19 2 / 0.8) !important;
}

.bg-orange-900\/85{
    background-color: rgb(65 19 2 / 0.85) !important;
}

.bg-orange-900\/90{
    background-color: rgb(65 19 2 / 0.9) !important;
}

.bg-orange-900\/95{
    background-color: rgb(65 19 2 / 0.95) !important;
}

.bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(252 231 243 / var(--tw-bg-opacity)) !important;
}

.bg-pink-100\/0{
    background-color: rgb(252 231 243 / 0) !important;
}

.bg-pink-100\/10{
    background-color: rgb(252 231 243 / 0.1) !important;
}

.bg-pink-100\/100{
    background-color: rgb(252 231 243 / 1) !important;
}

.bg-pink-100\/15{
    background-color: rgb(252 231 243 / 0.15) !important;
}

.bg-pink-100\/20{
    background-color: rgb(252 231 243 / 0.2) !important;
}

.bg-pink-100\/25{
    background-color: rgb(252 231 243 / 0.25) !important;
}

.bg-pink-100\/30{
    background-color: rgb(252 231 243 / 0.3) !important;
}

.bg-pink-100\/35{
    background-color: rgb(252 231 243 / 0.35) !important;
}

.bg-pink-100\/40{
    background-color: rgb(252 231 243 / 0.4) !important;
}

.bg-pink-100\/45{
    background-color: rgb(252 231 243 / 0.45) !important;
}

.bg-pink-100\/5{
    background-color: rgb(252 231 243 / 0.05) !important;
}

.bg-pink-100\/50{
    background-color: rgb(252 231 243 / 0.5) !important;
}

.bg-pink-100\/55{
    background-color: rgb(252 231 243 / 0.55) !important;
}

.bg-pink-100\/60{
    background-color: rgb(252 231 243 / 0.6) !important;
}

.bg-pink-100\/65{
    background-color: rgb(252 231 243 / 0.65) !important;
}

.bg-pink-100\/70{
    background-color: rgb(252 231 243 / 0.7) !important;
}

.bg-pink-100\/75{
    background-color: rgb(252 231 243 / 0.75) !important;
}

.bg-pink-100\/80{
    background-color: rgb(252 231 243 / 0.8) !important;
}

.bg-pink-100\/85{
    background-color: rgb(252 231 243 / 0.85) !important;
}

.bg-pink-100\/90{
    background-color: rgb(252 231 243 / 0.9) !important;
}

.bg-pink-100\/95{
    background-color: rgb(252 231 243 / 0.95) !important;
}

.bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 207 232 / var(--tw-bg-opacity)) !important;
}

.bg-pink-200\/0{
    background-color: rgb(251 207 232 / 0) !important;
}

.bg-pink-200\/10{
    background-color: rgb(251 207 232 / 0.1) !important;
}

.bg-pink-200\/100{
    background-color: rgb(251 207 232 / 1) !important;
}

.bg-pink-200\/15{
    background-color: rgb(251 207 232 / 0.15) !important;
}

.bg-pink-200\/20{
    background-color: rgb(251 207 232 / 0.2) !important;
}

.bg-pink-200\/25{
    background-color: rgb(251 207 232 / 0.25) !important;
}

.bg-pink-200\/30{
    background-color: rgb(251 207 232 / 0.3) !important;
}

.bg-pink-200\/35{
    background-color: rgb(251 207 232 / 0.35) !important;
}

.bg-pink-200\/40{
    background-color: rgb(251 207 232 / 0.4) !important;
}

.bg-pink-200\/45{
    background-color: rgb(251 207 232 / 0.45) !important;
}

.bg-pink-200\/5{
    background-color: rgb(251 207 232 / 0.05) !important;
}

.bg-pink-200\/50{
    background-color: rgb(251 207 232 / 0.5) !important;
}

.bg-pink-200\/55{
    background-color: rgb(251 207 232 / 0.55) !important;
}

.bg-pink-200\/60{
    background-color: rgb(251 207 232 / 0.6) !important;
}

.bg-pink-200\/65{
    background-color: rgb(251 207 232 / 0.65) !important;
}

.bg-pink-200\/70{
    background-color: rgb(251 207 232 / 0.7) !important;
}

.bg-pink-200\/75{
    background-color: rgb(251 207 232 / 0.75) !important;
}

.bg-pink-200\/80{
    background-color: rgb(251 207 232 / 0.8) !important;
}

.bg-pink-200\/85{
    background-color: rgb(251 207 232 / 0.85) !important;
}

.bg-pink-200\/90{
    background-color: rgb(251 207 232 / 0.9) !important;
}

.bg-pink-200\/95{
    background-color: rgb(251 207 232 / 0.95) !important;
}

.bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 168 212 / var(--tw-bg-opacity)) !important;
}

.bg-pink-300\/0{
    background-color: rgb(249 168 212 / 0) !important;
}

.bg-pink-300\/10{
    background-color: rgb(249 168 212 / 0.1) !important;
}

.bg-pink-300\/100{
    background-color: rgb(249 168 212 / 1) !important;
}

.bg-pink-300\/15{
    background-color: rgb(249 168 212 / 0.15) !important;
}

.bg-pink-300\/20{
    background-color: rgb(249 168 212 / 0.2) !important;
}

.bg-pink-300\/25{
    background-color: rgb(249 168 212 / 0.25) !important;
}

.bg-pink-300\/30{
    background-color: rgb(249 168 212 / 0.3) !important;
}

.bg-pink-300\/35{
    background-color: rgb(249 168 212 / 0.35) !important;
}

.bg-pink-300\/40{
    background-color: rgb(249 168 212 / 0.4) !important;
}

.bg-pink-300\/45{
    background-color: rgb(249 168 212 / 0.45) !important;
}

.bg-pink-300\/5{
    background-color: rgb(249 168 212 / 0.05) !important;
}

.bg-pink-300\/50{
    background-color: rgb(249 168 212 / 0.5) !important;
}

.bg-pink-300\/55{
    background-color: rgb(249 168 212 / 0.55) !important;
}

.bg-pink-300\/60{
    background-color: rgb(249 168 212 / 0.6) !important;
}

.bg-pink-300\/65{
    background-color: rgb(249 168 212 / 0.65) !important;
}

.bg-pink-300\/70{
    background-color: rgb(249 168 212 / 0.7) !important;
}

.bg-pink-300\/75{
    background-color: rgb(249 168 212 / 0.75) !important;
}

.bg-pink-300\/80{
    background-color: rgb(249 168 212 / 0.8) !important;
}

.bg-pink-300\/85{
    background-color: rgb(249 168 212 / 0.85) !important;
}

.bg-pink-300\/90{
    background-color: rgb(249 168 212 / 0.9) !important;
}

.bg-pink-300\/95{
    background-color: rgb(249 168 212 / 0.95) !important;
}

.bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 114 182 / var(--tw-bg-opacity)) !important;
}

.bg-pink-400\/0{
    background-color: rgb(244 114 182 / 0) !important;
}

.bg-pink-400\/10{
    background-color: rgb(244 114 182 / 0.1) !important;
}

.bg-pink-400\/100{
    background-color: rgb(244 114 182 / 1) !important;
}

.bg-pink-400\/15{
    background-color: rgb(244 114 182 / 0.15) !important;
}

.bg-pink-400\/20{
    background-color: rgb(244 114 182 / 0.2) !important;
}

.bg-pink-400\/25{
    background-color: rgb(244 114 182 / 0.25) !important;
}

.bg-pink-400\/30{
    background-color: rgb(244 114 182 / 0.3) !important;
}

.bg-pink-400\/35{
    background-color: rgb(244 114 182 / 0.35) !important;
}

.bg-pink-400\/40{
    background-color: rgb(244 114 182 / 0.4) !important;
}

.bg-pink-400\/45{
    background-color: rgb(244 114 182 / 0.45) !important;
}

.bg-pink-400\/5{
    background-color: rgb(244 114 182 / 0.05) !important;
}

.bg-pink-400\/50{
    background-color: rgb(244 114 182 / 0.5) !important;
}

.bg-pink-400\/55{
    background-color: rgb(244 114 182 / 0.55) !important;
}

.bg-pink-400\/60{
    background-color: rgb(244 114 182 / 0.6) !important;
}

.bg-pink-400\/65{
    background-color: rgb(244 114 182 / 0.65) !important;
}

.bg-pink-400\/70{
    background-color: rgb(244 114 182 / 0.7) !important;
}

.bg-pink-400\/75{
    background-color: rgb(244 114 182 / 0.75) !important;
}

.bg-pink-400\/80{
    background-color: rgb(244 114 182 / 0.8) !important;
}

.bg-pink-400\/85{
    background-color: rgb(244 114 182 / 0.85) !important;
}

.bg-pink-400\/90{
    background-color: rgb(244 114 182 / 0.9) !important;
}

.bg-pink-400\/95{
    background-color: rgb(244 114 182 / 0.95) !important;
}

.bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 242 248 / var(--tw-bg-opacity)) !important;
}

.bg-pink-50\/0{
    background-color: rgb(253 242 248 / 0) !important;
}

.bg-pink-50\/10{
    background-color: rgb(253 242 248 / 0.1) !important;
}

.bg-pink-50\/100{
    background-color: rgb(253 242 248 / 1) !important;
}

.bg-pink-50\/15{
    background-color: rgb(253 242 248 / 0.15) !important;
}

.bg-pink-50\/20{
    background-color: rgb(253 242 248 / 0.2) !important;
}

.bg-pink-50\/25{
    background-color: rgb(253 242 248 / 0.25) !important;
}

.bg-pink-50\/30{
    background-color: rgb(253 242 248 / 0.3) !important;
}

.bg-pink-50\/35{
    background-color: rgb(253 242 248 / 0.35) !important;
}

.bg-pink-50\/40{
    background-color: rgb(253 242 248 / 0.4) !important;
}

.bg-pink-50\/45{
    background-color: rgb(253 242 248 / 0.45) !important;
}

.bg-pink-50\/5{
    background-color: rgb(253 242 248 / 0.05) !important;
}

.bg-pink-50\/50{
    background-color: rgb(253 242 248 / 0.5) !important;
}

.bg-pink-50\/55{
    background-color: rgb(253 242 248 / 0.55) !important;
}

.bg-pink-50\/60{
    background-color: rgb(253 242 248 / 0.6) !important;
}

.bg-pink-50\/65{
    background-color: rgb(253 242 248 / 0.65) !important;
}

.bg-pink-50\/70{
    background-color: rgb(253 242 248 / 0.7) !important;
}

.bg-pink-50\/75{
    background-color: rgb(253 242 248 / 0.75) !important;
}

.bg-pink-50\/80{
    background-color: rgb(253 242 248 / 0.8) !important;
}

.bg-pink-50\/85{
    background-color: rgb(253 242 248 / 0.85) !important;
}

.bg-pink-50\/90{
    background-color: rgb(253 242 248 / 0.9) !important;
}

.bg-pink-50\/95{
    background-color: rgb(253 242 248 / 0.95) !important;
}

.bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(236 72 153 / var(--tw-bg-opacity)) !important;
}

.bg-pink-500\/0{
    background-color: rgb(236 72 153 / 0) !important;
}

.bg-pink-500\/10{
    background-color: rgb(236 72 153 / 0.1) !important;
}

.bg-pink-500\/100{
    background-color: rgb(236 72 153 / 1) !important;
}

.bg-pink-500\/15{
    background-color: rgb(236 72 153 / 0.15) !important;
}

.bg-pink-500\/20{
    background-color: rgb(236 72 153 / 0.2) !important;
}

.bg-pink-500\/25{
    background-color: rgb(236 72 153 / 0.25) !important;
}

.bg-pink-500\/30{
    background-color: rgb(236 72 153 / 0.3) !important;
}

.bg-pink-500\/35{
    background-color: rgb(236 72 153 / 0.35) !important;
}

.bg-pink-500\/40{
    background-color: rgb(236 72 153 / 0.4) !important;
}

.bg-pink-500\/45{
    background-color: rgb(236 72 153 / 0.45) !important;
}

.bg-pink-500\/5{
    background-color: rgb(236 72 153 / 0.05) !important;
}

.bg-pink-500\/50{
    background-color: rgb(236 72 153 / 0.5) !important;
}

.bg-pink-500\/55{
    background-color: rgb(236 72 153 / 0.55) !important;
}

.bg-pink-500\/60{
    background-color: rgb(236 72 153 / 0.6) !important;
}

.bg-pink-500\/65{
    background-color: rgb(236 72 153 / 0.65) !important;
}

.bg-pink-500\/70{
    background-color: rgb(236 72 153 / 0.7) !important;
}

.bg-pink-500\/75{
    background-color: rgb(236 72 153 / 0.75) !important;
}

.bg-pink-500\/80{
    background-color: rgb(236 72 153 / 0.8) !important;
}

.bg-pink-500\/85{
    background-color: rgb(236 72 153 / 0.85) !important;
}

.bg-pink-500\/90{
    background-color: rgb(236 72 153 / 0.9) !important;
}

.bg-pink-500\/95{
    background-color: rgb(236 72 153 / 0.95) !important;
}

.bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(219 39 119 / var(--tw-bg-opacity)) !important;
}

.bg-pink-600\/0{
    background-color: rgb(219 39 119 / 0) !important;
}

.bg-pink-600\/10{
    background-color: rgb(219 39 119 / 0.1) !important;
}

.bg-pink-600\/100{
    background-color: rgb(219 39 119 / 1) !important;
}

.bg-pink-600\/15{
    background-color: rgb(219 39 119 / 0.15) !important;
}

.bg-pink-600\/20{
    background-color: rgb(219 39 119 / 0.2) !important;
}

.bg-pink-600\/25{
    background-color: rgb(219 39 119 / 0.25) !important;
}

.bg-pink-600\/30{
    background-color: rgb(219 39 119 / 0.3) !important;
}

.bg-pink-600\/35{
    background-color: rgb(219 39 119 / 0.35) !important;
}

.bg-pink-600\/40{
    background-color: rgb(219 39 119 / 0.4) !important;
}

.bg-pink-600\/45{
    background-color: rgb(219 39 119 / 0.45) !important;
}

.bg-pink-600\/5{
    background-color: rgb(219 39 119 / 0.05) !important;
}

.bg-pink-600\/50{
    background-color: rgb(219 39 119 / 0.5) !important;
}

.bg-pink-600\/55{
    background-color: rgb(219 39 119 / 0.55) !important;
}

.bg-pink-600\/60{
    background-color: rgb(219 39 119 / 0.6) !important;
}

.bg-pink-600\/65{
    background-color: rgb(219 39 119 / 0.65) !important;
}

.bg-pink-600\/70{
    background-color: rgb(219 39 119 / 0.7) !important;
}

.bg-pink-600\/75{
    background-color: rgb(219 39 119 / 0.75) !important;
}

.bg-pink-600\/80{
    background-color: rgb(219 39 119 / 0.8) !important;
}

.bg-pink-600\/85{
    background-color: rgb(219 39 119 / 0.85) !important;
}

.bg-pink-600\/90{
    background-color: rgb(219 39 119 / 0.9) !important;
}

.bg-pink-600\/95{
    background-color: rgb(219 39 119 / 0.95) !important;
}

.bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(190 24 93 / var(--tw-bg-opacity)) !important;
}

.bg-pink-700\/0{
    background-color: rgb(190 24 93 / 0) !important;
}

.bg-pink-700\/10{
    background-color: rgb(190 24 93 / 0.1) !important;
}

.bg-pink-700\/100{
    background-color: rgb(190 24 93 / 1) !important;
}

.bg-pink-700\/15{
    background-color: rgb(190 24 93 / 0.15) !important;
}

.bg-pink-700\/20{
    background-color: rgb(190 24 93 / 0.2) !important;
}

.bg-pink-700\/25{
    background-color: rgb(190 24 93 / 0.25) !important;
}

.bg-pink-700\/30{
    background-color: rgb(190 24 93 / 0.3) !important;
}

.bg-pink-700\/35{
    background-color: rgb(190 24 93 / 0.35) !important;
}

.bg-pink-700\/40{
    background-color: rgb(190 24 93 / 0.4) !important;
}

.bg-pink-700\/45{
    background-color: rgb(190 24 93 / 0.45) !important;
}

.bg-pink-700\/5{
    background-color: rgb(190 24 93 / 0.05) !important;
}

.bg-pink-700\/50{
    background-color: rgb(190 24 93 / 0.5) !important;
}

.bg-pink-700\/55{
    background-color: rgb(190 24 93 / 0.55) !important;
}

.bg-pink-700\/60{
    background-color: rgb(190 24 93 / 0.6) !important;
}

.bg-pink-700\/65{
    background-color: rgb(190 24 93 / 0.65) !important;
}

.bg-pink-700\/70{
    background-color: rgb(190 24 93 / 0.7) !important;
}

.bg-pink-700\/75{
    background-color: rgb(190 24 93 / 0.75) !important;
}

.bg-pink-700\/80{
    background-color: rgb(190 24 93 / 0.8) !important;
}

.bg-pink-700\/85{
    background-color: rgb(190 24 93 / 0.85) !important;
}

.bg-pink-700\/90{
    background-color: rgb(190 24 93 / 0.9) !important;
}

.bg-pink-700\/95{
    background-color: rgb(190 24 93 / 0.95) !important;
}

.bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(157 23 77 / var(--tw-bg-opacity)) !important;
}

.bg-pink-800\/0{
    background-color: rgb(157 23 77 / 0) !important;
}

.bg-pink-800\/10{
    background-color: rgb(157 23 77 / 0.1) !important;
}

.bg-pink-800\/100{
    background-color: rgb(157 23 77 / 1) !important;
}

.bg-pink-800\/15{
    background-color: rgb(157 23 77 / 0.15) !important;
}

.bg-pink-800\/20{
    background-color: rgb(157 23 77 / 0.2) !important;
}

.bg-pink-800\/25{
    background-color: rgb(157 23 77 / 0.25) !important;
}

.bg-pink-800\/30{
    background-color: rgb(157 23 77 / 0.3) !important;
}

.bg-pink-800\/35{
    background-color: rgb(157 23 77 / 0.35) !important;
}

.bg-pink-800\/40{
    background-color: rgb(157 23 77 / 0.4) !important;
}

.bg-pink-800\/45{
    background-color: rgb(157 23 77 / 0.45) !important;
}

.bg-pink-800\/5{
    background-color: rgb(157 23 77 / 0.05) !important;
}

.bg-pink-800\/50{
    background-color: rgb(157 23 77 / 0.5) !important;
}

.bg-pink-800\/55{
    background-color: rgb(157 23 77 / 0.55) !important;
}

.bg-pink-800\/60{
    background-color: rgb(157 23 77 / 0.6) !important;
}

.bg-pink-800\/65{
    background-color: rgb(157 23 77 / 0.65) !important;
}

.bg-pink-800\/70{
    background-color: rgb(157 23 77 / 0.7) !important;
}

.bg-pink-800\/75{
    background-color: rgb(157 23 77 / 0.75) !important;
}

.bg-pink-800\/80{
    background-color: rgb(157 23 77 / 0.8) !important;
}

.bg-pink-800\/85{
    background-color: rgb(157 23 77 / 0.85) !important;
}

.bg-pink-800\/90{
    background-color: rgb(157 23 77 / 0.9) !important;
}

.bg-pink-800\/95{
    background-color: rgb(157 23 77 / 0.95) !important;
}

.bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(131 24 67 / var(--tw-bg-opacity)) !important;
}

.bg-pink-900\/0{
    background-color: rgb(131 24 67 / 0) !important;
}

.bg-pink-900\/10{
    background-color: rgb(131 24 67 / 0.1) !important;
}

.bg-pink-900\/100{
    background-color: rgb(131 24 67 / 1) !important;
}

.bg-pink-900\/15{
    background-color: rgb(131 24 67 / 0.15) !important;
}

.bg-pink-900\/20{
    background-color: rgb(131 24 67 / 0.2) !important;
}

.bg-pink-900\/25{
    background-color: rgb(131 24 67 / 0.25) !important;
}

.bg-pink-900\/30{
    background-color: rgb(131 24 67 / 0.3) !important;
}

.bg-pink-900\/35{
    background-color: rgb(131 24 67 / 0.35) !important;
}

.bg-pink-900\/40{
    background-color: rgb(131 24 67 / 0.4) !important;
}

.bg-pink-900\/45{
    background-color: rgb(131 24 67 / 0.45) !important;
}

.bg-pink-900\/5{
    background-color: rgb(131 24 67 / 0.05) !important;
}

.bg-pink-900\/50{
    background-color: rgb(131 24 67 / 0.5) !important;
}

.bg-pink-900\/55{
    background-color: rgb(131 24 67 / 0.55) !important;
}

.bg-pink-900\/60{
    background-color: rgb(131 24 67 / 0.6) !important;
}

.bg-pink-900\/65{
    background-color: rgb(131 24 67 / 0.65) !important;
}

.bg-pink-900\/70{
    background-color: rgb(131 24 67 / 0.7) !important;
}

.bg-pink-900\/75{
    background-color: rgb(131 24 67 / 0.75) !important;
}

.bg-pink-900\/80{
    background-color: rgb(131 24 67 / 0.8) !important;
}

.bg-pink-900\/85{
    background-color: rgb(131 24 67 / 0.85) !important;
}

.bg-pink-900\/90{
    background-color: rgb(131 24 67 / 0.9) !important;
}

.bg-pink-900\/95{
    background-color: rgb(131 24 67 / 0.95) !important;
}

.bg-primary{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(93 129 14 / var(--tw-bg-opacity)) !important;
}

.bg-primary\/0{
    background-color: rgb(93 129 14 / 0) !important;
}

.bg-primary\/10{
    background-color: rgb(93 129 14 / 0.1) !important;
}

.bg-primary\/100{
    background-color: rgb(93 129 14 / 1) !important;
}

.bg-primary\/15{
    background-color: rgb(93 129 14 / 0.15) !important;
}

.bg-primary\/20{
    background-color: rgb(93 129 14 / 0.2) !important;
}

.bg-primary\/25{
    background-color: rgb(93 129 14 / 0.25) !important;
}

.bg-primary\/30{
    background-color: rgb(93 129 14 / 0.3) !important;
}

.bg-primary\/35{
    background-color: rgb(93 129 14 / 0.35) !important;
}

.bg-primary\/40{
    background-color: rgb(93 129 14 / 0.4) !important;
}

.bg-primary\/45{
    background-color: rgb(93 129 14 / 0.45) !important;
}

.bg-primary\/5{
    background-color: rgb(93 129 14 / 0.05) !important;
}

.bg-primary\/50{
    background-color: rgb(93 129 14 / 0.5) !important;
}

.bg-primary\/55{
    background-color: rgb(93 129 14 / 0.55) !important;
}

.bg-primary\/60{
    background-color: rgb(93 129 14 / 0.6) !important;
}

.bg-primary\/65{
    background-color: rgb(93 129 14 / 0.65) !important;
}

.bg-primary\/70{
    background-color: rgb(93 129 14 / 0.7) !important;
}

.bg-primary\/75{
    background-color: rgb(93 129 14 / 0.75) !important;
}

.bg-primary\/80{
    background-color: rgb(93 129 14 / 0.8) !important;
}

.bg-primary\/85{
    background-color: rgb(93 129 14 / 0.85) !important;
}

.bg-primary\/90{
    background-color: rgb(93 129 14 / 0.9) !important;
}

.bg-primary\/95{
    background-color: rgb(93 129 14 / 0.95) !important;
}

.bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(239 193 255 / var(--tw-bg-opacity)) !important;
}

.bg-purple-100\/0{
    background-color: rgb(239 193 255 / 0) !important;
}

.bg-purple-100\/10{
    background-color: rgb(239 193 255 / 0.1) !important;
}

.bg-purple-100\/100{
    background-color: rgb(239 193 255 / 1) !important;
}

.bg-purple-100\/15{
    background-color: rgb(239 193 255 / 0.15) !important;
}

.bg-purple-100\/20{
    background-color: rgb(239 193 255 / 0.2) !important;
}

.bg-purple-100\/25{
    background-color: rgb(239 193 255 / 0.25) !important;
}

.bg-purple-100\/30{
    background-color: rgb(239 193 255 / 0.3) !important;
}

.bg-purple-100\/35{
    background-color: rgb(239 193 255 / 0.35) !important;
}

.bg-purple-100\/40{
    background-color: rgb(239 193 255 / 0.4) !important;
}

.bg-purple-100\/45{
    background-color: rgb(239 193 255 / 0.45) !important;
}

.bg-purple-100\/5{
    background-color: rgb(239 193 255 / 0.05) !important;
}

.bg-purple-100\/50{
    background-color: rgb(239 193 255 / 0.5) !important;
}

.bg-purple-100\/55{
    background-color: rgb(239 193 255 / 0.55) !important;
}

.bg-purple-100\/60{
    background-color: rgb(239 193 255 / 0.6) !important;
}

.bg-purple-100\/65{
    background-color: rgb(239 193 255 / 0.65) !important;
}

.bg-purple-100\/70{
    background-color: rgb(239 193 255 / 0.7) !important;
}

.bg-purple-100\/75{
    background-color: rgb(239 193 255 / 0.75) !important;
}

.bg-purple-100\/80{
    background-color: rgb(239 193 255 / 0.8) !important;
}

.bg-purple-100\/85{
    background-color: rgb(239 193 255 / 0.85) !important;
}

.bg-purple-100\/90{
    background-color: rgb(239 193 255 / 0.9) !important;
}

.bg-purple-100\/95{
    background-color: rgb(239 193 255 / 0.95) !important;
}

.bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(225 137 255 / var(--tw-bg-opacity)) !important;
}

.bg-purple-200\/0{
    background-color: rgb(225 137 255 / 0) !important;
}

.bg-purple-200\/10{
    background-color: rgb(225 137 255 / 0.1) !important;
}

.bg-purple-200\/100{
    background-color: rgb(225 137 255 / 1) !important;
}

.bg-purple-200\/15{
    background-color: rgb(225 137 255 / 0.15) !important;
}

.bg-purple-200\/20{
    background-color: rgb(225 137 255 / 0.2) !important;
}

.bg-purple-200\/25{
    background-color: rgb(225 137 255 / 0.25) !important;
}

.bg-purple-200\/30{
    background-color: rgb(225 137 255 / 0.3) !important;
}

.bg-purple-200\/35{
    background-color: rgb(225 137 255 / 0.35) !important;
}

.bg-purple-200\/40{
    background-color: rgb(225 137 255 / 0.4) !important;
}

.bg-purple-200\/45{
    background-color: rgb(225 137 255 / 0.45) !important;
}

.bg-purple-200\/5{
    background-color: rgb(225 137 255 / 0.05) !important;
}

.bg-purple-200\/50{
    background-color: rgb(225 137 255 / 0.5) !important;
}

.bg-purple-200\/55{
    background-color: rgb(225 137 255 / 0.55) !important;
}

.bg-purple-200\/60{
    background-color: rgb(225 137 255 / 0.6) !important;
}

.bg-purple-200\/65{
    background-color: rgb(225 137 255 / 0.65) !important;
}

.bg-purple-200\/70{
    background-color: rgb(225 137 255 / 0.7) !important;
}

.bg-purple-200\/75{
    background-color: rgb(225 137 255 / 0.75) !important;
}

.bg-purple-200\/80{
    background-color: rgb(225 137 255 / 0.8) !important;
}

.bg-purple-200\/85{
    background-color: rgb(225 137 255 / 0.85) !important;
}

.bg-purple-200\/90{
    background-color: rgb(225 137 255 / 0.9) !important;
}

.bg-purple-200\/95{
    background-color: rgb(225 137 255 / 0.95) !important;
}

.bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(210 81 255 / var(--tw-bg-opacity)) !important;
}

.bg-purple-300\/0{
    background-color: rgb(210 81 255 / 0) !important;
}

.bg-purple-300\/10{
    background-color: rgb(210 81 255 / 0.1) !important;
}

.bg-purple-300\/100{
    background-color: rgb(210 81 255 / 1) !important;
}

.bg-purple-300\/15{
    background-color: rgb(210 81 255 / 0.15) !important;
}

.bg-purple-300\/20{
    background-color: rgb(210 81 255 / 0.2) !important;
}

.bg-purple-300\/25{
    background-color: rgb(210 81 255 / 0.25) !important;
}

.bg-purple-300\/30{
    background-color: rgb(210 81 255 / 0.3) !important;
}

.bg-purple-300\/35{
    background-color: rgb(210 81 255 / 0.35) !important;
}

.bg-purple-300\/40{
    background-color: rgb(210 81 255 / 0.4) !important;
}

.bg-purple-300\/45{
    background-color: rgb(210 81 255 / 0.45) !important;
}

.bg-purple-300\/5{
    background-color: rgb(210 81 255 / 0.05) !important;
}

.bg-purple-300\/50{
    background-color: rgb(210 81 255 / 0.5) !important;
}

.bg-purple-300\/55{
    background-color: rgb(210 81 255 / 0.55) !important;
}

.bg-purple-300\/60{
    background-color: rgb(210 81 255 / 0.6) !important;
}

.bg-purple-300\/65{
    background-color: rgb(210 81 255 / 0.65) !important;
}

.bg-purple-300\/70{
    background-color: rgb(210 81 255 / 0.7) !important;
}

.bg-purple-300\/75{
    background-color: rgb(210 81 255 / 0.75) !important;
}

.bg-purple-300\/80{
    background-color: rgb(210 81 255 / 0.8) !important;
}

.bg-purple-300\/85{
    background-color: rgb(210 81 255 / 0.85) !important;
}

.bg-purple-300\/90{
    background-color: rgb(210 81 255 / 0.9) !important;
}

.bg-purple-300\/95{
    background-color: rgb(210 81 255 / 0.95) !important;
}

.bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(195 25 255 / var(--tw-bg-opacity)) !important;
}

.bg-purple-400\/0{
    background-color: rgb(195 25 255 / 0) !important;
}

.bg-purple-400\/10{
    background-color: rgb(195 25 255 / 0.1) !important;
}

.bg-purple-400\/100{
    background-color: rgb(195 25 255 / 1) !important;
}

.bg-purple-400\/15{
    background-color: rgb(195 25 255 / 0.15) !important;
}

.bg-purple-400\/20{
    background-color: rgb(195 25 255 / 0.2) !important;
}

.bg-purple-400\/25{
    background-color: rgb(195 25 255 / 0.25) !important;
}

.bg-purple-400\/30{
    background-color: rgb(195 25 255 / 0.3) !important;
}

.bg-purple-400\/35{
    background-color: rgb(195 25 255 / 0.35) !important;
}

.bg-purple-400\/40{
    background-color: rgb(195 25 255 / 0.4) !important;
}

.bg-purple-400\/45{
    background-color: rgb(195 25 255 / 0.45) !important;
}

.bg-purple-400\/5{
    background-color: rgb(195 25 255 / 0.05) !important;
}

.bg-purple-400\/50{
    background-color: rgb(195 25 255 / 0.5) !important;
}

.bg-purple-400\/55{
    background-color: rgb(195 25 255 / 0.55) !important;
}

.bg-purple-400\/60{
    background-color: rgb(195 25 255 / 0.6) !important;
}

.bg-purple-400\/65{
    background-color: rgb(195 25 255 / 0.65) !important;
}

.bg-purple-400\/70{
    background-color: rgb(195 25 255 / 0.7) !important;
}

.bg-purple-400\/75{
    background-color: rgb(195 25 255 / 0.75) !important;
}

.bg-purple-400\/80{
    background-color: rgb(195 25 255 / 0.8) !important;
}

.bg-purple-400\/85{
    background-color: rgb(195 25 255 / 0.85) !important;
}

.bg-purple-400\/90{
    background-color: rgb(195 25 255 / 0.9) !important;
}

.bg-purple-400\/95{
    background-color: rgb(195 25 255 / 0.95) !important;
}

.bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(246 221 255 / var(--tw-bg-opacity)) !important;
}

.bg-purple-50\/0{
    background-color: rgb(246 221 255 / 0) !important;
}

.bg-purple-50\/10{
    background-color: rgb(246 221 255 / 0.1) !important;
}

.bg-purple-50\/100{
    background-color: rgb(246 221 255 / 1) !important;
}

.bg-purple-50\/15{
    background-color: rgb(246 221 255 / 0.15) !important;
}

.bg-purple-50\/20{
    background-color: rgb(246 221 255 / 0.2) !important;
}

.bg-purple-50\/25{
    background-color: rgb(246 221 255 / 0.25) !important;
}

.bg-purple-50\/30{
    background-color: rgb(246 221 255 / 0.3) !important;
}

.bg-purple-50\/35{
    background-color: rgb(246 221 255 / 0.35) !important;
}

.bg-purple-50\/40{
    background-color: rgb(246 221 255 / 0.4) !important;
}

.bg-purple-50\/45{
    background-color: rgb(246 221 255 / 0.45) !important;
}

.bg-purple-50\/5{
    background-color: rgb(246 221 255 / 0.05) !important;
}

.bg-purple-50\/50{
    background-color: rgb(246 221 255 / 0.5) !important;
}

.bg-purple-50\/55{
    background-color: rgb(246 221 255 / 0.55) !important;
}

.bg-purple-50\/60{
    background-color: rgb(246 221 255 / 0.6) !important;
}

.bg-purple-50\/65{
    background-color: rgb(246 221 255 / 0.65) !important;
}

.bg-purple-50\/70{
    background-color: rgb(246 221 255 / 0.7) !important;
}

.bg-purple-50\/75{
    background-color: rgb(246 221 255 / 0.75) !important;
}

.bg-purple-50\/80{
    background-color: rgb(246 221 255 / 0.8) !important;
}

.bg-purple-50\/85{
    background-color: rgb(246 221 255 / 0.85) !important;
}

.bg-purple-50\/90{
    background-color: rgb(246 221 255 / 0.9) !important;
}

.bg-purple-50\/95{
    background-color: rgb(246 221 255 / 0.95) !important;
}

.bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(166 0 224 / var(--tw-bg-opacity)) !important;
}

.bg-purple-500\/0{
    background-color: rgb(166 0 224 / 0) !important;
}

.bg-purple-500\/10{
    background-color: rgb(166 0 224 / 0.1) !important;
}

.bg-purple-500\/100{
    background-color: rgb(166 0 224 / 1) !important;
}

.bg-purple-500\/15{
    background-color: rgb(166 0 224 / 0.15) !important;
}

.bg-purple-500\/20{
    background-color: rgb(166 0 224 / 0.2) !important;
}

.bg-purple-500\/25{
    background-color: rgb(166 0 224 / 0.25) !important;
}

.bg-purple-500\/30{
    background-color: rgb(166 0 224 / 0.3) !important;
}

.bg-purple-500\/35{
    background-color: rgb(166 0 224 / 0.35) !important;
}

.bg-purple-500\/40{
    background-color: rgb(166 0 224 / 0.4) !important;
}

.bg-purple-500\/45{
    background-color: rgb(166 0 224 / 0.45) !important;
}

.bg-purple-500\/5{
    background-color: rgb(166 0 224 / 0.05) !important;
}

.bg-purple-500\/50{
    background-color: rgb(166 0 224 / 0.5) !important;
}

.bg-purple-500\/55{
    background-color: rgb(166 0 224 / 0.55) !important;
}

.bg-purple-500\/60{
    background-color: rgb(166 0 224 / 0.6) !important;
}

.bg-purple-500\/65{
    background-color: rgb(166 0 224 / 0.65) !important;
}

.bg-purple-500\/70{
    background-color: rgb(166 0 224 / 0.7) !important;
}

.bg-purple-500\/75{
    background-color: rgb(166 0 224 / 0.75) !important;
}

.bg-purple-500\/80{
    background-color: rgb(166 0 224 / 0.8) !important;
}

.bg-purple-500\/85{
    background-color: rgb(166 0 224 / 0.85) !important;
}

.bg-purple-500\/90{
    background-color: rgb(166 0 224 / 0.9) !important;
}

.bg-purple-500\/95{
    background-color: rgb(166 0 224 / 0.95) !important;
}

.bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(136 0 183 / var(--tw-bg-opacity)) !important;
}

.bg-purple-600\/0{
    background-color: rgb(136 0 183 / 0) !important;
}

.bg-purple-600\/10{
    background-color: rgb(136 0 183 / 0.1) !important;
}

.bg-purple-600\/100{
    background-color: rgb(136 0 183 / 1) !important;
}

.bg-purple-600\/15{
    background-color: rgb(136 0 183 / 0.15) !important;
}

.bg-purple-600\/20{
    background-color: rgb(136 0 183 / 0.2) !important;
}

.bg-purple-600\/25{
    background-color: rgb(136 0 183 / 0.25) !important;
}

.bg-purple-600\/30{
    background-color: rgb(136 0 183 / 0.3) !important;
}

.bg-purple-600\/35{
    background-color: rgb(136 0 183 / 0.35) !important;
}

.bg-purple-600\/40{
    background-color: rgb(136 0 183 / 0.4) !important;
}

.bg-purple-600\/45{
    background-color: rgb(136 0 183 / 0.45) !important;
}

.bg-purple-600\/5{
    background-color: rgb(136 0 183 / 0.05) !important;
}

.bg-purple-600\/50{
    background-color: rgb(136 0 183 / 0.5) !important;
}

.bg-purple-600\/55{
    background-color: rgb(136 0 183 / 0.55) !important;
}

.bg-purple-600\/60{
    background-color: rgb(136 0 183 / 0.6) !important;
}

.bg-purple-600\/65{
    background-color: rgb(136 0 183 / 0.65) !important;
}

.bg-purple-600\/70{
    background-color: rgb(136 0 183 / 0.7) !important;
}

.bg-purple-600\/75{
    background-color: rgb(136 0 183 / 0.75) !important;
}

.bg-purple-600\/80{
    background-color: rgb(136 0 183 / 0.8) !important;
}

.bg-purple-600\/85{
    background-color: rgb(136 0 183 / 0.85) !important;
}

.bg-purple-600\/90{
    background-color: rgb(136 0 183 / 0.9) !important;
}

.bg-purple-600\/95{
    background-color: rgb(136 0 183 / 0.95) !important;
}

.bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(106 0 142 / var(--tw-bg-opacity)) !important;
}

.bg-purple-700\/0{
    background-color: rgb(106 0 142 / 0) !important;
}

.bg-purple-700\/10{
    background-color: rgb(106 0 142 / 0.1) !important;
}

.bg-purple-700\/100{
    background-color: rgb(106 0 142 / 1) !important;
}

.bg-purple-700\/15{
    background-color: rgb(106 0 142 / 0.15) !important;
}

.bg-purple-700\/20{
    background-color: rgb(106 0 142 / 0.2) !important;
}

.bg-purple-700\/25{
    background-color: rgb(106 0 142 / 0.25) !important;
}

.bg-purple-700\/30{
    background-color: rgb(106 0 142 / 0.3) !important;
}

.bg-purple-700\/35{
    background-color: rgb(106 0 142 / 0.35) !important;
}

.bg-purple-700\/40{
    background-color: rgb(106 0 142 / 0.4) !important;
}

.bg-purple-700\/45{
    background-color: rgb(106 0 142 / 0.45) !important;
}

.bg-purple-700\/5{
    background-color: rgb(106 0 142 / 0.05) !important;
}

.bg-purple-700\/50{
    background-color: rgb(106 0 142 / 0.5) !important;
}

.bg-purple-700\/55{
    background-color: rgb(106 0 142 / 0.55) !important;
}

.bg-purple-700\/60{
    background-color: rgb(106 0 142 / 0.6) !important;
}

.bg-purple-700\/65{
    background-color: rgb(106 0 142 / 0.65) !important;
}

.bg-purple-700\/70{
    background-color: rgb(106 0 142 / 0.7) !important;
}

.bg-purple-700\/75{
    background-color: rgb(106 0 142 / 0.75) !important;
}

.bg-purple-700\/80{
    background-color: rgb(106 0 142 / 0.8) !important;
}

.bg-purple-700\/85{
    background-color: rgb(106 0 142 / 0.85) !important;
}

.bg-purple-700\/90{
    background-color: rgb(106 0 142 / 0.9) !important;
}

.bg-purple-700\/95{
    background-color: rgb(106 0 142 / 0.95) !important;
}

.bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(75 0 102 / var(--tw-bg-opacity)) !important;
}

.bg-purple-800\/0{
    background-color: rgb(75 0 102 / 0) !important;
}

.bg-purple-800\/10{
    background-color: rgb(75 0 102 / 0.1) !important;
}

.bg-purple-800\/100{
    background-color: rgb(75 0 102 / 1) !important;
}

.bg-purple-800\/15{
    background-color: rgb(75 0 102 / 0.15) !important;
}

.bg-purple-800\/20{
    background-color: rgb(75 0 102 / 0.2) !important;
}

.bg-purple-800\/25{
    background-color: rgb(75 0 102 / 0.25) !important;
}

.bg-purple-800\/30{
    background-color: rgb(75 0 102 / 0.3) !important;
}

.bg-purple-800\/35{
    background-color: rgb(75 0 102 / 0.35) !important;
}

.bg-purple-800\/40{
    background-color: rgb(75 0 102 / 0.4) !important;
}

.bg-purple-800\/45{
    background-color: rgb(75 0 102 / 0.45) !important;
}

.bg-purple-800\/5{
    background-color: rgb(75 0 102 / 0.05) !important;
}

.bg-purple-800\/50{
    background-color: rgb(75 0 102 / 0.5) !important;
}

.bg-purple-800\/55{
    background-color: rgb(75 0 102 / 0.55) !important;
}

.bg-purple-800\/60{
    background-color: rgb(75 0 102 / 0.6) !important;
}

.bg-purple-800\/65{
    background-color: rgb(75 0 102 / 0.65) !important;
}

.bg-purple-800\/70{
    background-color: rgb(75 0 102 / 0.7) !important;
}

.bg-purple-800\/75{
    background-color: rgb(75 0 102 / 0.75) !important;
}

.bg-purple-800\/80{
    background-color: rgb(75 0 102 / 0.8) !important;
}

.bg-purple-800\/85{
    background-color: rgb(75 0 102 / 0.85) !important;
}

.bg-purple-800\/90{
    background-color: rgb(75 0 102 / 0.9) !important;
}

.bg-purple-800\/95{
    background-color: rgb(75 0 102 / 0.95) !important;
}

.bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(45 0 61 / var(--tw-bg-opacity)) !important;
}

.bg-purple-900\/0{
    background-color: rgb(45 0 61 / 0) !important;
}

.bg-purple-900\/10{
    background-color: rgb(45 0 61 / 0.1) !important;
}

.bg-purple-900\/100{
    background-color: rgb(45 0 61 / 1) !important;
}

.bg-purple-900\/15{
    background-color: rgb(45 0 61 / 0.15) !important;
}

.bg-purple-900\/20{
    background-color: rgb(45 0 61 / 0.2) !important;
}

.bg-purple-900\/25{
    background-color: rgb(45 0 61 / 0.25) !important;
}

.bg-purple-900\/30{
    background-color: rgb(45 0 61 / 0.3) !important;
}

.bg-purple-900\/35{
    background-color: rgb(45 0 61 / 0.35) !important;
}

.bg-purple-900\/40{
    background-color: rgb(45 0 61 / 0.4) !important;
}

.bg-purple-900\/45{
    background-color: rgb(45 0 61 / 0.45) !important;
}

.bg-purple-900\/5{
    background-color: rgb(45 0 61 / 0.05) !important;
}

.bg-purple-900\/50{
    background-color: rgb(45 0 61 / 0.5) !important;
}

.bg-purple-900\/55{
    background-color: rgb(45 0 61 / 0.55) !important;
}

.bg-purple-900\/60{
    background-color: rgb(45 0 61 / 0.6) !important;
}

.bg-purple-900\/65{
    background-color: rgb(45 0 61 / 0.65) !important;
}

.bg-purple-900\/70{
    background-color: rgb(45 0 61 / 0.7) !important;
}

.bg-purple-900\/75{
    background-color: rgb(45 0 61 / 0.75) !important;
}

.bg-purple-900\/80{
    background-color: rgb(45 0 61 / 0.8) !important;
}

.bg-purple-900\/85{
    background-color: rgb(45 0 61 / 0.85) !important;
}

.bg-purple-900\/90{
    background-color: rgb(45 0 61 / 0.9) !important;
}

.bg-purple-900\/95{
    background-color: rgb(45 0 61 / 0.95) !important;
}

.bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(243 114 114 / var(--tw-bg-opacity)) !important;
}

.bg-red-100\/0{
    background-color: rgb(243 114 114 / 0) !important;
}

.bg-red-100\/10{
    background-color: rgb(243 114 114 / 0.1) !important;
}

.bg-red-100\/100{
    background-color: rgb(243 114 114 / 1) !important;
}

.bg-red-100\/15{
    background-color: rgb(243 114 114 / 0.15) !important;
}

.bg-red-100\/20{
    background-color: rgb(243 114 114 / 0.2) !important;
}

.bg-red-100\/25{
    background-color: rgb(243 114 114 / 0.25) !important;
}

.bg-red-100\/30{
    background-color: rgb(243 114 114 / 0.3) !important;
}

.bg-red-100\/35{
    background-color: rgb(243 114 114 / 0.35) !important;
}

.bg-red-100\/40{
    background-color: rgb(243 114 114 / 0.4) !important;
}

.bg-red-100\/45{
    background-color: rgb(243 114 114 / 0.45) !important;
}

.bg-red-100\/5{
    background-color: rgb(243 114 114 / 0.05) !important;
}

.bg-red-100\/50{
    background-color: rgb(243 114 114 / 0.5) !important;
}

.bg-red-100\/55{
    background-color: rgb(243 114 114 / 0.55) !important;
}

.bg-red-100\/60{
    background-color: rgb(243 114 114 / 0.6) !important;
}

.bg-red-100\/65{
    background-color: rgb(243 114 114 / 0.65) !important;
}

.bg-red-100\/70{
    background-color: rgb(243 114 114 / 0.7) !important;
}

.bg-red-100\/75{
    background-color: rgb(243 114 114 / 0.75) !important;
}

.bg-red-100\/80{
    background-color: rgb(243 114 114 / 0.8) !important;
}

.bg-red-100\/85{
    background-color: rgb(243 114 114 / 0.85) !important;
}

.bg-red-100\/90{
    background-color: rgb(243 114 114 / 0.9) !important;
}

.bg-red-100\/95{
    background-color: rgb(243 114 114 / 0.95) !important;
}

.bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 76 76 / var(--tw-bg-opacity)) !important;
}

.bg-red-200\/0{
    background-color: rgb(240 76 76 / 0) !important;
}

.bg-red-200\/10{
    background-color: rgb(240 76 76 / 0.1) !important;
}

.bg-red-200\/100{
    background-color: rgb(240 76 76 / 1) !important;
}

.bg-red-200\/15{
    background-color: rgb(240 76 76 / 0.15) !important;
}

.bg-red-200\/20{
    background-color: rgb(240 76 76 / 0.2) !important;
}

.bg-red-200\/25{
    background-color: rgb(240 76 76 / 0.25) !important;
}

.bg-red-200\/30{
    background-color: rgb(240 76 76 / 0.3) !important;
}

.bg-red-200\/35{
    background-color: rgb(240 76 76 / 0.35) !important;
}

.bg-red-200\/40{
    background-color: rgb(240 76 76 / 0.4) !important;
}

.bg-red-200\/45{
    background-color: rgb(240 76 76 / 0.45) !important;
}

.bg-red-200\/5{
    background-color: rgb(240 76 76 / 0.05) !important;
}

.bg-red-200\/50{
    background-color: rgb(240 76 76 / 0.5) !important;
}

.bg-red-200\/55{
    background-color: rgb(240 76 76 / 0.55) !important;
}

.bg-red-200\/60{
    background-color: rgb(240 76 76 / 0.6) !important;
}

.bg-red-200\/65{
    background-color: rgb(240 76 76 / 0.65) !important;
}

.bg-red-200\/70{
    background-color: rgb(240 76 76 / 0.7) !important;
}

.bg-red-200\/75{
    background-color: rgb(240 76 76 / 0.75) !important;
}

.bg-red-200\/80{
    background-color: rgb(240 76 76 / 0.8) !important;
}

.bg-red-200\/85{
    background-color: rgb(240 76 76 / 0.85) !important;
}

.bg-red-200\/90{
    background-color: rgb(240 76 76 / 0.9) !important;
}

.bg-red-200\/95{
    background-color: rgb(240 76 76 / 0.95) !important;
}

.bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(237 39 39 / var(--tw-bg-opacity)) !important;
}

.bg-red-300\/0{
    background-color: rgb(237 39 39 / 0) !important;
}

.bg-red-300\/10{
    background-color: rgb(237 39 39 / 0.1) !important;
}

.bg-red-300\/100{
    background-color: rgb(237 39 39 / 1) !important;
}

.bg-red-300\/15{
    background-color: rgb(237 39 39 / 0.15) !important;
}

.bg-red-300\/20{
    background-color: rgb(237 39 39 / 0.2) !important;
}

.bg-red-300\/25{
    background-color: rgb(237 39 39 / 0.25) !important;
}

.bg-red-300\/30{
    background-color: rgb(237 39 39 / 0.3) !important;
}

.bg-red-300\/35{
    background-color: rgb(237 39 39 / 0.35) !important;
}

.bg-red-300\/40{
    background-color: rgb(237 39 39 / 0.4) !important;
}

.bg-red-300\/45{
    background-color: rgb(237 39 39 / 0.45) !important;
}

.bg-red-300\/5{
    background-color: rgb(237 39 39 / 0.05) !important;
}

.bg-red-300\/50{
    background-color: rgb(237 39 39 / 0.5) !important;
}

.bg-red-300\/55{
    background-color: rgb(237 39 39 / 0.55) !important;
}

.bg-red-300\/60{
    background-color: rgb(237 39 39 / 0.6) !important;
}

.bg-red-300\/65{
    background-color: rgb(237 39 39 / 0.65) !important;
}

.bg-red-300\/70{
    background-color: rgb(237 39 39 / 0.7) !important;
}

.bg-red-300\/75{
    background-color: rgb(237 39 39 / 0.75) !important;
}

.bg-red-300\/80{
    background-color: rgb(237 39 39 / 0.8) !important;
}

.bg-red-300\/85{
    background-color: rgb(237 39 39 / 0.85) !important;
}

.bg-red-300\/90{
    background-color: rgb(237 39 39 / 0.9) !important;
}

.bg-red-300\/95{
    background-color: rgb(237 39 39 / 0.95) !important;
}

.bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(217 18 18 / var(--tw-bg-opacity)) !important;
}

.bg-red-400\/0{
    background-color: rgb(217 18 18 / 0) !important;
}

.bg-red-400\/10{
    background-color: rgb(217 18 18 / 0.1) !important;
}

.bg-red-400\/100{
    background-color: rgb(217 18 18 / 1) !important;
}

.bg-red-400\/15{
    background-color: rgb(217 18 18 / 0.15) !important;
}

.bg-red-400\/20{
    background-color: rgb(217 18 18 / 0.2) !important;
}

.bg-red-400\/25{
    background-color: rgb(217 18 18 / 0.25) !important;
}

.bg-red-400\/30{
    background-color: rgb(217 18 18 / 0.3) !important;
}

.bg-red-400\/35{
    background-color: rgb(217 18 18 / 0.35) !important;
}

.bg-red-400\/40{
    background-color: rgb(217 18 18 / 0.4) !important;
}

.bg-red-400\/45{
    background-color: rgb(217 18 18 / 0.45) !important;
}

.bg-red-400\/5{
    background-color: rgb(217 18 18 / 0.05) !important;
}

.bg-red-400\/50{
    background-color: rgb(217 18 18 / 0.5) !important;
}

.bg-red-400\/55{
    background-color: rgb(217 18 18 / 0.55) !important;
}

.bg-red-400\/60{
    background-color: rgb(217 18 18 / 0.6) !important;
}

.bg-red-400\/65{
    background-color: rgb(217 18 18 / 0.65) !important;
}

.bg-red-400\/70{
    background-color: rgb(217 18 18 / 0.7) !important;
}

.bg-red-400\/75{
    background-color: rgb(217 18 18 / 0.75) !important;
}

.bg-red-400\/80{
    background-color: rgb(217 18 18 / 0.8) !important;
}

.bg-red-400\/85{
    background-color: rgb(217 18 18 / 0.85) !important;
}

.bg-red-400\/90{
    background-color: rgb(217 18 18 / 0.9) !important;
}

.bg-red-400\/95{
    background-color: rgb(217 18 18 / 0.95) !important;
}

.bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 203 203 / var(--tw-bg-opacity)) !important;
}

.bg-red-50\/0{
    background-color: rgb(251 203 203 / 0) !important;
}

.bg-red-50\/10{
    background-color: rgb(251 203 203 / 0.1) !important;
}

.bg-red-50\/100{
    background-color: rgb(251 203 203 / 1) !important;
}

.bg-red-50\/15{
    background-color: rgb(251 203 203 / 0.15) !important;
}

.bg-red-50\/20{
    background-color: rgb(251 203 203 / 0.2) !important;
}

.bg-red-50\/25{
    background-color: rgb(251 203 203 / 0.25) !important;
}

.bg-red-50\/30{
    background-color: rgb(251 203 203 / 0.3) !important;
}

.bg-red-50\/35{
    background-color: rgb(251 203 203 / 0.35) !important;
}

.bg-red-50\/40{
    background-color: rgb(251 203 203 / 0.4) !important;
}

.bg-red-50\/45{
    background-color: rgb(251 203 203 / 0.45) !important;
}

.bg-red-50\/5{
    background-color: rgb(251 203 203 / 0.05) !important;
}

.bg-red-50\/50{
    background-color: rgb(251 203 203 / 0.5) !important;
}

.bg-red-50\/55{
    background-color: rgb(251 203 203 / 0.55) !important;
}

.bg-red-50\/60{
    background-color: rgb(251 203 203 / 0.6) !important;
}

.bg-red-50\/65{
    background-color: rgb(251 203 203 / 0.65) !important;
}

.bg-red-50\/70{
    background-color: rgb(251 203 203 / 0.7) !important;
}

.bg-red-50\/75{
    background-color: rgb(251 203 203 / 0.75) !important;
}

.bg-red-50\/80{
    background-color: rgb(251 203 203 / 0.8) !important;
}

.bg-red-50\/85{
    background-color: rgb(251 203 203 / 0.85) !important;
}

.bg-red-50\/90{
    background-color: rgb(251 203 203 / 0.9) !important;
}

.bg-red-50\/95{
    background-color: rgb(251 203 203 / 0.95) !important;
}

.bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(179 15 15 / var(--tw-bg-opacity)) !important;
}

.bg-red-500\/0{
    background-color: rgb(179 15 15 / 0) !important;
}

.bg-red-500\/10{
    background-color: rgb(179 15 15 / 0.1) !important;
}

.bg-red-500\/100{
    background-color: rgb(179 15 15 / 1) !important;
}

.bg-red-500\/15{
    background-color: rgb(179 15 15 / 0.15) !important;
}

.bg-red-500\/20{
    background-color: rgb(179 15 15 / 0.2) !important;
}

.bg-red-500\/25{
    background-color: rgb(179 15 15 / 0.25) !important;
}

.bg-red-500\/30{
    background-color: rgb(179 15 15 / 0.3) !important;
}

.bg-red-500\/35{
    background-color: rgb(179 15 15 / 0.35) !important;
}

.bg-red-500\/40{
    background-color: rgb(179 15 15 / 0.4) !important;
}

.bg-red-500\/45{
    background-color: rgb(179 15 15 / 0.45) !important;
}

.bg-red-500\/5{
    background-color: rgb(179 15 15 / 0.05) !important;
}

.bg-red-500\/50{
    background-color: rgb(179 15 15 / 0.5) !important;
}

.bg-red-500\/55{
    background-color: rgb(179 15 15 / 0.55) !important;
}

.bg-red-500\/60{
    background-color: rgb(179 15 15 / 0.6) !important;
}

.bg-red-500\/65{
    background-color: rgb(179 15 15 / 0.65) !important;
}

.bg-red-500\/70{
    background-color: rgb(179 15 15 / 0.7) !important;
}

.bg-red-500\/75{
    background-color: rgb(179 15 15 / 0.75) !important;
}

.bg-red-500\/80{
    background-color: rgb(179 15 15 / 0.8) !important;
}

.bg-red-500\/85{
    background-color: rgb(179 15 15 / 0.85) !important;
}

.bg-red-500\/90{
    background-color: rgb(179 15 15 / 0.9) !important;
}

.bg-red-500\/95{
    background-color: rgb(179 15 15 / 0.95) !important;
}

.bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(155 13 13 / var(--tw-bg-opacity)) !important;
}

.bg-red-600\/0{
    background-color: rgb(155 13 13 / 0) !important;
}

.bg-red-600\/10{
    background-color: rgb(155 13 13 / 0.1) !important;
}

.bg-red-600\/100{
    background-color: rgb(155 13 13 / 1) !important;
}

.bg-red-600\/15{
    background-color: rgb(155 13 13 / 0.15) !important;
}

.bg-red-600\/20{
    background-color: rgb(155 13 13 / 0.2) !important;
}

.bg-red-600\/25{
    background-color: rgb(155 13 13 / 0.25) !important;
}

.bg-red-600\/30{
    background-color: rgb(155 13 13 / 0.3) !important;
}

.bg-red-600\/35{
    background-color: rgb(155 13 13 / 0.35) !important;
}

.bg-red-600\/40{
    background-color: rgb(155 13 13 / 0.4) !important;
}

.bg-red-600\/45{
    background-color: rgb(155 13 13 / 0.45) !important;
}

.bg-red-600\/5{
    background-color: rgb(155 13 13 / 0.05) !important;
}

.bg-red-600\/50{
    background-color: rgb(155 13 13 / 0.5) !important;
}

.bg-red-600\/55{
    background-color: rgb(155 13 13 / 0.55) !important;
}

.bg-red-600\/60{
    background-color: rgb(155 13 13 / 0.6) !important;
}

.bg-red-600\/65{
    background-color: rgb(155 13 13 / 0.65) !important;
}

.bg-red-600\/70{
    background-color: rgb(155 13 13 / 0.7) !important;
}

.bg-red-600\/75{
    background-color: rgb(155 13 13 / 0.75) !important;
}

.bg-red-600\/80{
    background-color: rgb(155 13 13 / 0.8) !important;
}

.bg-red-600\/85{
    background-color: rgb(155 13 13 / 0.85) !important;
}

.bg-red-600\/90{
    background-color: rgb(155 13 13 / 0.9) !important;
}

.bg-red-600\/95{
    background-color: rgb(155 13 13 / 0.95) !important;
}

.bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(132 11 11 / var(--tw-bg-opacity)) !important;
}

.bg-red-700\/0{
    background-color: rgb(132 11 11 / 0) !important;
}

.bg-red-700\/10{
    background-color: rgb(132 11 11 / 0.1) !important;
}

.bg-red-700\/100{
    background-color: rgb(132 11 11 / 1) !important;
}

.bg-red-700\/15{
    background-color: rgb(132 11 11 / 0.15) !important;
}

.bg-red-700\/20{
    background-color: rgb(132 11 11 / 0.2) !important;
}

.bg-red-700\/25{
    background-color: rgb(132 11 11 / 0.25) !important;
}

.bg-red-700\/30{
    background-color: rgb(132 11 11 / 0.3) !important;
}

.bg-red-700\/35{
    background-color: rgb(132 11 11 / 0.35) !important;
}

.bg-red-700\/40{
    background-color: rgb(132 11 11 / 0.4) !important;
}

.bg-red-700\/45{
    background-color: rgb(132 11 11 / 0.45) !important;
}

.bg-red-700\/5{
    background-color: rgb(132 11 11 / 0.05) !important;
}

.bg-red-700\/50{
    background-color: rgb(132 11 11 / 0.5) !important;
}

.bg-red-700\/55{
    background-color: rgb(132 11 11 / 0.55) !important;
}

.bg-red-700\/60{
    background-color: rgb(132 11 11 / 0.6) !important;
}

.bg-red-700\/65{
    background-color: rgb(132 11 11 / 0.65) !important;
}

.bg-red-700\/70{
    background-color: rgb(132 11 11 / 0.7) !important;
}

.bg-red-700\/75{
    background-color: rgb(132 11 11 / 0.75) !important;
}

.bg-red-700\/80{
    background-color: rgb(132 11 11 / 0.8) !important;
}

.bg-red-700\/85{
    background-color: rgb(132 11 11 / 0.85) !important;
}

.bg-red-700\/90{
    background-color: rgb(132 11 11 / 0.9) !important;
}

.bg-red-700\/95{
    background-color: rgb(132 11 11 / 0.95) !important;
}

.bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(108 9 9 / var(--tw-bg-opacity)) !important;
}

.bg-red-800\/0{
    background-color: rgb(108 9 9 / 0) !important;
}

.bg-red-800\/10{
    background-color: rgb(108 9 9 / 0.1) !important;
}

.bg-red-800\/100{
    background-color: rgb(108 9 9 / 1) !important;
}

.bg-red-800\/15{
    background-color: rgb(108 9 9 / 0.15) !important;
}

.bg-red-800\/20{
    background-color: rgb(108 9 9 / 0.2) !important;
}

.bg-red-800\/25{
    background-color: rgb(108 9 9 / 0.25) !important;
}

.bg-red-800\/30{
    background-color: rgb(108 9 9 / 0.3) !important;
}

.bg-red-800\/35{
    background-color: rgb(108 9 9 / 0.35) !important;
}

.bg-red-800\/40{
    background-color: rgb(108 9 9 / 0.4) !important;
}

.bg-red-800\/45{
    background-color: rgb(108 9 9 / 0.45) !important;
}

.bg-red-800\/5{
    background-color: rgb(108 9 9 / 0.05) !important;
}

.bg-red-800\/50{
    background-color: rgb(108 9 9 / 0.5) !important;
}

.bg-red-800\/55{
    background-color: rgb(108 9 9 / 0.55) !important;
}

.bg-red-800\/60{
    background-color: rgb(108 9 9 / 0.6) !important;
}

.bg-red-800\/65{
    background-color: rgb(108 9 9 / 0.65) !important;
}

.bg-red-800\/70{
    background-color: rgb(108 9 9 / 0.7) !important;
}

.bg-red-800\/75{
    background-color: rgb(108 9 9 / 0.75) !important;
}

.bg-red-800\/80{
    background-color: rgb(108 9 9 / 0.8) !important;
}

.bg-red-800\/85{
    background-color: rgb(108 9 9 / 0.85) !important;
}

.bg-red-800\/90{
    background-color: rgb(108 9 9 / 0.9) !important;
}

.bg-red-800\/95{
    background-color: rgb(108 9 9 / 0.95) !important;
}

.bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(85 7 7 / var(--tw-bg-opacity)) !important;
}

.bg-red-900\/0{
    background-color: rgb(85 7 7 / 0) !important;
}

.bg-red-900\/10{
    background-color: rgb(85 7 7 / 0.1) !important;
}

.bg-red-900\/100{
    background-color: rgb(85 7 7 / 1) !important;
}

.bg-red-900\/15{
    background-color: rgb(85 7 7 / 0.15) !important;
}

.bg-red-900\/20{
    background-color: rgb(85 7 7 / 0.2) !important;
}

.bg-red-900\/25{
    background-color: rgb(85 7 7 / 0.25) !important;
}

.bg-red-900\/30{
    background-color: rgb(85 7 7 / 0.3) !important;
}

.bg-red-900\/35{
    background-color: rgb(85 7 7 / 0.35) !important;
}

.bg-red-900\/40{
    background-color: rgb(85 7 7 / 0.4) !important;
}

.bg-red-900\/45{
    background-color: rgb(85 7 7 / 0.45) !important;
}

.bg-red-900\/5{
    background-color: rgb(85 7 7 / 0.05) !important;
}

.bg-red-900\/50{
    background-color: rgb(85 7 7 / 0.5) !important;
}

.bg-red-900\/55{
    background-color: rgb(85 7 7 / 0.55) !important;
}

.bg-red-900\/60{
    background-color: rgb(85 7 7 / 0.6) !important;
}

.bg-red-900\/65{
    background-color: rgb(85 7 7 / 0.65) !important;
}

.bg-red-900\/70{
    background-color: rgb(85 7 7 / 0.7) !important;
}

.bg-red-900\/75{
    background-color: rgb(85 7 7 / 0.75) !important;
}

.bg-red-900\/80{
    background-color: rgb(85 7 7 / 0.8) !important;
}

.bg-red-900\/85{
    background-color: rgb(85 7 7 / 0.85) !important;
}

.bg-red-900\/90{
    background-color: rgb(85 7 7 / 0.9) !important;
}

.bg-red-900\/95{
    background-color: rgb(85 7 7 / 0.95) !important;
}

.bg-rose-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 228 230 / var(--tw-bg-opacity)) !important;
}

.bg-rose-100\/0{
    background-color: rgb(255 228 230 / 0) !important;
}

.bg-rose-100\/10{
    background-color: rgb(255 228 230 / 0.1) !important;
}

.bg-rose-100\/100{
    background-color: rgb(255 228 230 / 1) !important;
}

.bg-rose-100\/15{
    background-color: rgb(255 228 230 / 0.15) !important;
}

.bg-rose-100\/20{
    background-color: rgb(255 228 230 / 0.2) !important;
}

.bg-rose-100\/25{
    background-color: rgb(255 228 230 / 0.25) !important;
}

.bg-rose-100\/30{
    background-color: rgb(255 228 230 / 0.3) !important;
}

.bg-rose-100\/35{
    background-color: rgb(255 228 230 / 0.35) !important;
}

.bg-rose-100\/40{
    background-color: rgb(255 228 230 / 0.4) !important;
}

.bg-rose-100\/45{
    background-color: rgb(255 228 230 / 0.45) !important;
}

.bg-rose-100\/5{
    background-color: rgb(255 228 230 / 0.05) !important;
}

.bg-rose-100\/50{
    background-color: rgb(255 228 230 / 0.5) !important;
}

.bg-rose-100\/55{
    background-color: rgb(255 228 230 / 0.55) !important;
}

.bg-rose-100\/60{
    background-color: rgb(255 228 230 / 0.6) !important;
}

.bg-rose-100\/65{
    background-color: rgb(255 228 230 / 0.65) !important;
}

.bg-rose-100\/70{
    background-color: rgb(255 228 230 / 0.7) !important;
}

.bg-rose-100\/75{
    background-color: rgb(255 228 230 / 0.75) !important;
}

.bg-rose-100\/80{
    background-color: rgb(255 228 230 / 0.8) !important;
}

.bg-rose-100\/85{
    background-color: rgb(255 228 230 / 0.85) !important;
}

.bg-rose-100\/90{
    background-color: rgb(255 228 230 / 0.9) !important;
}

.bg-rose-100\/95{
    background-color: rgb(255 228 230 / 0.95) !important;
}

.bg-rose-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 205 211 / var(--tw-bg-opacity)) !important;
}

.bg-rose-200\/0{
    background-color: rgb(254 205 211 / 0) !important;
}

.bg-rose-200\/10{
    background-color: rgb(254 205 211 / 0.1) !important;
}

.bg-rose-200\/100{
    background-color: rgb(254 205 211 / 1) !important;
}

.bg-rose-200\/15{
    background-color: rgb(254 205 211 / 0.15) !important;
}

.bg-rose-200\/20{
    background-color: rgb(254 205 211 / 0.2) !important;
}

.bg-rose-200\/25{
    background-color: rgb(254 205 211 / 0.25) !important;
}

.bg-rose-200\/30{
    background-color: rgb(254 205 211 / 0.3) !important;
}

.bg-rose-200\/35{
    background-color: rgb(254 205 211 / 0.35) !important;
}

.bg-rose-200\/40{
    background-color: rgb(254 205 211 / 0.4) !important;
}

.bg-rose-200\/45{
    background-color: rgb(254 205 211 / 0.45) !important;
}

.bg-rose-200\/5{
    background-color: rgb(254 205 211 / 0.05) !important;
}

.bg-rose-200\/50{
    background-color: rgb(254 205 211 / 0.5) !important;
}

.bg-rose-200\/55{
    background-color: rgb(254 205 211 / 0.55) !important;
}

.bg-rose-200\/60{
    background-color: rgb(254 205 211 / 0.6) !important;
}

.bg-rose-200\/65{
    background-color: rgb(254 205 211 / 0.65) !important;
}

.bg-rose-200\/70{
    background-color: rgb(254 205 211 / 0.7) !important;
}

.bg-rose-200\/75{
    background-color: rgb(254 205 211 / 0.75) !important;
}

.bg-rose-200\/80{
    background-color: rgb(254 205 211 / 0.8) !important;
}

.bg-rose-200\/85{
    background-color: rgb(254 205 211 / 0.85) !important;
}

.bg-rose-200\/90{
    background-color: rgb(254 205 211 / 0.9) !important;
}

.bg-rose-200\/95{
    background-color: rgb(254 205 211 / 0.95) !important;
}

.bg-rose-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 164 175 / var(--tw-bg-opacity)) !important;
}

.bg-rose-300\/0{
    background-color: rgb(253 164 175 / 0) !important;
}

.bg-rose-300\/10{
    background-color: rgb(253 164 175 / 0.1) !important;
}

.bg-rose-300\/100{
    background-color: rgb(253 164 175 / 1) !important;
}

.bg-rose-300\/15{
    background-color: rgb(253 164 175 / 0.15) !important;
}

.bg-rose-300\/20{
    background-color: rgb(253 164 175 / 0.2) !important;
}

.bg-rose-300\/25{
    background-color: rgb(253 164 175 / 0.25) !important;
}

.bg-rose-300\/30{
    background-color: rgb(253 164 175 / 0.3) !important;
}

.bg-rose-300\/35{
    background-color: rgb(253 164 175 / 0.35) !important;
}

.bg-rose-300\/40{
    background-color: rgb(253 164 175 / 0.4) !important;
}

.bg-rose-300\/45{
    background-color: rgb(253 164 175 / 0.45) !important;
}

.bg-rose-300\/5{
    background-color: rgb(253 164 175 / 0.05) !important;
}

.bg-rose-300\/50{
    background-color: rgb(253 164 175 / 0.5) !important;
}

.bg-rose-300\/55{
    background-color: rgb(253 164 175 / 0.55) !important;
}

.bg-rose-300\/60{
    background-color: rgb(253 164 175 / 0.6) !important;
}

.bg-rose-300\/65{
    background-color: rgb(253 164 175 / 0.65) !important;
}

.bg-rose-300\/70{
    background-color: rgb(253 164 175 / 0.7) !important;
}

.bg-rose-300\/75{
    background-color: rgb(253 164 175 / 0.75) !important;
}

.bg-rose-300\/80{
    background-color: rgb(253 164 175 / 0.8) !important;
}

.bg-rose-300\/85{
    background-color: rgb(253 164 175 / 0.85) !important;
}

.bg-rose-300\/90{
    background-color: rgb(253 164 175 / 0.9) !important;
}

.bg-rose-300\/95{
    background-color: rgb(253 164 175 / 0.95) !important;
}

.bg-rose-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(251 113 133 / var(--tw-bg-opacity)) !important;
}

.bg-rose-400\/0{
    background-color: rgb(251 113 133 / 0) !important;
}

.bg-rose-400\/10{
    background-color: rgb(251 113 133 / 0.1) !important;
}

.bg-rose-400\/100{
    background-color: rgb(251 113 133 / 1) !important;
}

.bg-rose-400\/15{
    background-color: rgb(251 113 133 / 0.15) !important;
}

.bg-rose-400\/20{
    background-color: rgb(251 113 133 / 0.2) !important;
}

.bg-rose-400\/25{
    background-color: rgb(251 113 133 / 0.25) !important;
}

.bg-rose-400\/30{
    background-color: rgb(251 113 133 / 0.3) !important;
}

.bg-rose-400\/35{
    background-color: rgb(251 113 133 / 0.35) !important;
}

.bg-rose-400\/40{
    background-color: rgb(251 113 133 / 0.4) !important;
}

.bg-rose-400\/45{
    background-color: rgb(251 113 133 / 0.45) !important;
}

.bg-rose-400\/5{
    background-color: rgb(251 113 133 / 0.05) !important;
}

.bg-rose-400\/50{
    background-color: rgb(251 113 133 / 0.5) !important;
}

.bg-rose-400\/55{
    background-color: rgb(251 113 133 / 0.55) !important;
}

.bg-rose-400\/60{
    background-color: rgb(251 113 133 / 0.6) !important;
}

.bg-rose-400\/65{
    background-color: rgb(251 113 133 / 0.65) !important;
}

.bg-rose-400\/70{
    background-color: rgb(251 113 133 / 0.7) !important;
}

.bg-rose-400\/75{
    background-color: rgb(251 113 133 / 0.75) !important;
}

.bg-rose-400\/80{
    background-color: rgb(251 113 133 / 0.8) !important;
}

.bg-rose-400\/85{
    background-color: rgb(251 113 133 / 0.85) !important;
}

.bg-rose-400\/90{
    background-color: rgb(251 113 133 / 0.9) !important;
}

.bg-rose-400\/95{
    background-color: rgb(251 113 133 / 0.95) !important;
}

.bg-rose-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 241 242 / var(--tw-bg-opacity)) !important;
}

.bg-rose-50\/0{
    background-color: rgb(255 241 242 / 0) !important;
}

.bg-rose-50\/10{
    background-color: rgb(255 241 242 / 0.1) !important;
}

.bg-rose-50\/100{
    background-color: rgb(255 241 242 / 1) !important;
}

.bg-rose-50\/15{
    background-color: rgb(255 241 242 / 0.15) !important;
}

.bg-rose-50\/20{
    background-color: rgb(255 241 242 / 0.2) !important;
}

.bg-rose-50\/25{
    background-color: rgb(255 241 242 / 0.25) !important;
}

.bg-rose-50\/30{
    background-color: rgb(255 241 242 / 0.3) !important;
}

.bg-rose-50\/35{
    background-color: rgb(255 241 242 / 0.35) !important;
}

.bg-rose-50\/40{
    background-color: rgb(255 241 242 / 0.4) !important;
}

.bg-rose-50\/45{
    background-color: rgb(255 241 242 / 0.45) !important;
}

.bg-rose-50\/5{
    background-color: rgb(255 241 242 / 0.05) !important;
}

.bg-rose-50\/50{
    background-color: rgb(255 241 242 / 0.5) !important;
}

.bg-rose-50\/55{
    background-color: rgb(255 241 242 / 0.55) !important;
}

.bg-rose-50\/60{
    background-color: rgb(255 241 242 / 0.6) !important;
}

.bg-rose-50\/65{
    background-color: rgb(255 241 242 / 0.65) !important;
}

.bg-rose-50\/70{
    background-color: rgb(255 241 242 / 0.7) !important;
}

.bg-rose-50\/75{
    background-color: rgb(255 241 242 / 0.75) !important;
}

.bg-rose-50\/80{
    background-color: rgb(255 241 242 / 0.8) !important;
}

.bg-rose-50\/85{
    background-color: rgb(255 241 242 / 0.85) !important;
}

.bg-rose-50\/90{
    background-color: rgb(255 241 242 / 0.9) !important;
}

.bg-rose-50\/95{
    background-color: rgb(255 241 242 / 0.95) !important;
}

.bg-rose-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 63 94 / var(--tw-bg-opacity)) !important;
}

.bg-rose-500\/0{
    background-color: rgb(244 63 94 / 0) !important;
}

.bg-rose-500\/10{
    background-color: rgb(244 63 94 / 0.1) !important;
}

.bg-rose-500\/100{
    background-color: rgb(244 63 94 / 1) !important;
}

.bg-rose-500\/15{
    background-color: rgb(244 63 94 / 0.15) !important;
}

.bg-rose-500\/20{
    background-color: rgb(244 63 94 / 0.2) !important;
}

.bg-rose-500\/25{
    background-color: rgb(244 63 94 / 0.25) !important;
}

.bg-rose-500\/30{
    background-color: rgb(244 63 94 / 0.3) !important;
}

.bg-rose-500\/35{
    background-color: rgb(244 63 94 / 0.35) !important;
}

.bg-rose-500\/40{
    background-color: rgb(244 63 94 / 0.4) !important;
}

.bg-rose-500\/45{
    background-color: rgb(244 63 94 / 0.45) !important;
}

.bg-rose-500\/5{
    background-color: rgb(244 63 94 / 0.05) !important;
}

.bg-rose-500\/50{
    background-color: rgb(244 63 94 / 0.5) !important;
}

.bg-rose-500\/55{
    background-color: rgb(244 63 94 / 0.55) !important;
}

.bg-rose-500\/60{
    background-color: rgb(244 63 94 / 0.6) !important;
}

.bg-rose-500\/65{
    background-color: rgb(244 63 94 / 0.65) !important;
}

.bg-rose-500\/70{
    background-color: rgb(244 63 94 / 0.7) !important;
}

.bg-rose-500\/75{
    background-color: rgb(244 63 94 / 0.75) !important;
}

.bg-rose-500\/80{
    background-color: rgb(244 63 94 / 0.8) !important;
}

.bg-rose-500\/85{
    background-color: rgb(244 63 94 / 0.85) !important;
}

.bg-rose-500\/90{
    background-color: rgb(244 63 94 / 0.9) !important;
}

.bg-rose-500\/95{
    background-color: rgb(244 63 94 / 0.95) !important;
}

.bg-rose-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(225 29 72 / var(--tw-bg-opacity)) !important;
}

.bg-rose-600\/0{
    background-color: rgb(225 29 72 / 0) !important;
}

.bg-rose-600\/10{
    background-color: rgb(225 29 72 / 0.1) !important;
}

.bg-rose-600\/100{
    background-color: rgb(225 29 72 / 1) !important;
}

.bg-rose-600\/15{
    background-color: rgb(225 29 72 / 0.15) !important;
}

.bg-rose-600\/20{
    background-color: rgb(225 29 72 / 0.2) !important;
}

.bg-rose-600\/25{
    background-color: rgb(225 29 72 / 0.25) !important;
}

.bg-rose-600\/30{
    background-color: rgb(225 29 72 / 0.3) !important;
}

.bg-rose-600\/35{
    background-color: rgb(225 29 72 / 0.35) !important;
}

.bg-rose-600\/40{
    background-color: rgb(225 29 72 / 0.4) !important;
}

.bg-rose-600\/45{
    background-color: rgb(225 29 72 / 0.45) !important;
}

.bg-rose-600\/5{
    background-color: rgb(225 29 72 / 0.05) !important;
}

.bg-rose-600\/50{
    background-color: rgb(225 29 72 / 0.5) !important;
}

.bg-rose-600\/55{
    background-color: rgb(225 29 72 / 0.55) !important;
}

.bg-rose-600\/60{
    background-color: rgb(225 29 72 / 0.6) !important;
}

.bg-rose-600\/65{
    background-color: rgb(225 29 72 / 0.65) !important;
}

.bg-rose-600\/70{
    background-color: rgb(225 29 72 / 0.7) !important;
}

.bg-rose-600\/75{
    background-color: rgb(225 29 72 / 0.75) !important;
}

.bg-rose-600\/80{
    background-color: rgb(225 29 72 / 0.8) !important;
}

.bg-rose-600\/85{
    background-color: rgb(225 29 72 / 0.85) !important;
}

.bg-rose-600\/90{
    background-color: rgb(225 29 72 / 0.9) !important;
}

.bg-rose-600\/95{
    background-color: rgb(225 29 72 / 0.95) !important;
}

.bg-rose-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(190 18 60 / var(--tw-bg-opacity)) !important;
}

.bg-rose-700\/0{
    background-color: rgb(190 18 60 / 0) !important;
}

.bg-rose-700\/10{
    background-color: rgb(190 18 60 / 0.1) !important;
}

.bg-rose-700\/100{
    background-color: rgb(190 18 60 / 1) !important;
}

.bg-rose-700\/15{
    background-color: rgb(190 18 60 / 0.15) !important;
}

.bg-rose-700\/20{
    background-color: rgb(190 18 60 / 0.2) !important;
}

.bg-rose-700\/25{
    background-color: rgb(190 18 60 / 0.25) !important;
}

.bg-rose-700\/30{
    background-color: rgb(190 18 60 / 0.3) !important;
}

.bg-rose-700\/35{
    background-color: rgb(190 18 60 / 0.35) !important;
}

.bg-rose-700\/40{
    background-color: rgb(190 18 60 / 0.4) !important;
}

.bg-rose-700\/45{
    background-color: rgb(190 18 60 / 0.45) !important;
}

.bg-rose-700\/5{
    background-color: rgb(190 18 60 / 0.05) !important;
}

.bg-rose-700\/50{
    background-color: rgb(190 18 60 / 0.5) !important;
}

.bg-rose-700\/55{
    background-color: rgb(190 18 60 / 0.55) !important;
}

.bg-rose-700\/60{
    background-color: rgb(190 18 60 / 0.6) !important;
}

.bg-rose-700\/65{
    background-color: rgb(190 18 60 / 0.65) !important;
}

.bg-rose-700\/70{
    background-color: rgb(190 18 60 / 0.7) !important;
}

.bg-rose-700\/75{
    background-color: rgb(190 18 60 / 0.75) !important;
}

.bg-rose-700\/80{
    background-color: rgb(190 18 60 / 0.8) !important;
}

.bg-rose-700\/85{
    background-color: rgb(190 18 60 / 0.85) !important;
}

.bg-rose-700\/90{
    background-color: rgb(190 18 60 / 0.9) !important;
}

.bg-rose-700\/95{
    background-color: rgb(190 18 60 / 0.95) !important;
}

.bg-rose-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(159 18 57 / var(--tw-bg-opacity)) !important;
}

.bg-rose-800\/0{
    background-color: rgb(159 18 57 / 0) !important;
}

.bg-rose-800\/10{
    background-color: rgb(159 18 57 / 0.1) !important;
}

.bg-rose-800\/100{
    background-color: rgb(159 18 57 / 1) !important;
}

.bg-rose-800\/15{
    background-color: rgb(159 18 57 / 0.15) !important;
}

.bg-rose-800\/20{
    background-color: rgb(159 18 57 / 0.2) !important;
}

.bg-rose-800\/25{
    background-color: rgb(159 18 57 / 0.25) !important;
}

.bg-rose-800\/30{
    background-color: rgb(159 18 57 / 0.3) !important;
}

.bg-rose-800\/35{
    background-color: rgb(159 18 57 / 0.35) !important;
}

.bg-rose-800\/40{
    background-color: rgb(159 18 57 / 0.4) !important;
}

.bg-rose-800\/45{
    background-color: rgb(159 18 57 / 0.45) !important;
}

.bg-rose-800\/5{
    background-color: rgb(159 18 57 / 0.05) !important;
}

.bg-rose-800\/50{
    background-color: rgb(159 18 57 / 0.5) !important;
}

.bg-rose-800\/55{
    background-color: rgb(159 18 57 / 0.55) !important;
}

.bg-rose-800\/60{
    background-color: rgb(159 18 57 / 0.6) !important;
}

.bg-rose-800\/65{
    background-color: rgb(159 18 57 / 0.65) !important;
}

.bg-rose-800\/70{
    background-color: rgb(159 18 57 / 0.7) !important;
}

.bg-rose-800\/75{
    background-color: rgb(159 18 57 / 0.75) !important;
}

.bg-rose-800\/80{
    background-color: rgb(159 18 57 / 0.8) !important;
}

.bg-rose-800\/85{
    background-color: rgb(159 18 57 / 0.85) !important;
}

.bg-rose-800\/90{
    background-color: rgb(159 18 57 / 0.9) !important;
}

.bg-rose-800\/95{
    background-color: rgb(159 18 57 / 0.95) !important;
}

.bg-rose-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(136 19 55 / var(--tw-bg-opacity)) !important;
}

.bg-rose-900\/0{
    background-color: rgb(136 19 55 / 0) !important;
}

.bg-rose-900\/10{
    background-color: rgb(136 19 55 / 0.1) !important;
}

.bg-rose-900\/100{
    background-color: rgb(136 19 55 / 1) !important;
}

.bg-rose-900\/15{
    background-color: rgb(136 19 55 / 0.15) !important;
}

.bg-rose-900\/20{
    background-color: rgb(136 19 55 / 0.2) !important;
}

.bg-rose-900\/25{
    background-color: rgb(136 19 55 / 0.25) !important;
}

.bg-rose-900\/30{
    background-color: rgb(136 19 55 / 0.3) !important;
}

.bg-rose-900\/35{
    background-color: rgb(136 19 55 / 0.35) !important;
}

.bg-rose-900\/40{
    background-color: rgb(136 19 55 / 0.4) !important;
}

.bg-rose-900\/45{
    background-color: rgb(136 19 55 / 0.45) !important;
}

.bg-rose-900\/5{
    background-color: rgb(136 19 55 / 0.05) !important;
}

.bg-rose-900\/50{
    background-color: rgb(136 19 55 / 0.5) !important;
}

.bg-rose-900\/55{
    background-color: rgb(136 19 55 / 0.55) !important;
}

.bg-rose-900\/60{
    background-color: rgb(136 19 55 / 0.6) !important;
}

.bg-rose-900\/65{
    background-color: rgb(136 19 55 / 0.65) !important;
}

.bg-rose-900\/70{
    background-color: rgb(136 19 55 / 0.7) !important;
}

.bg-rose-900\/75{
    background-color: rgb(136 19 55 / 0.75) !important;
}

.bg-rose-900\/80{
    background-color: rgb(136 19 55 / 0.8) !important;
}

.bg-rose-900\/85{
    background-color: rgb(136 19 55 / 0.85) !important;
}

.bg-rose-900\/90{
    background-color: rgb(136 19 55 / 0.9) !important;
}

.bg-rose-900\/95{
    background-color: rgb(136 19 55 / 0.95) !important;
}

.bg-secondary{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(0 95 184 / var(--tw-bg-opacity)) !important;
}

.bg-secondary\/0{
    background-color: rgb(0 95 184 / 0) !important;
}

.bg-secondary\/10{
    background-color: rgb(0 95 184 / 0.1) !important;
}

.bg-secondary\/100{
    background-color: rgb(0 95 184 / 1) !important;
}

.bg-secondary\/15{
    background-color: rgb(0 95 184 / 0.15) !important;
}

.bg-secondary\/20{
    background-color: rgb(0 95 184 / 0.2) !important;
}

.bg-secondary\/25{
    background-color: rgb(0 95 184 / 0.25) !important;
}

.bg-secondary\/30{
    background-color: rgb(0 95 184 / 0.3) !important;
}

.bg-secondary\/35{
    background-color: rgb(0 95 184 / 0.35) !important;
}

.bg-secondary\/40{
    background-color: rgb(0 95 184 / 0.4) !important;
}

.bg-secondary\/45{
    background-color: rgb(0 95 184 / 0.45) !important;
}

.bg-secondary\/5{
    background-color: rgb(0 95 184 / 0.05) !important;
}

.bg-secondary\/50{
    background-color: rgb(0 95 184 / 0.5) !important;
}

.bg-secondary\/55{
    background-color: rgb(0 95 184 / 0.55) !important;
}

.bg-secondary\/60{
    background-color: rgb(0 95 184 / 0.6) !important;
}

.bg-secondary\/65{
    background-color: rgb(0 95 184 / 0.65) !important;
}

.bg-secondary\/70{
    background-color: rgb(0 95 184 / 0.7) !important;
}

.bg-secondary\/75{
    background-color: rgb(0 95 184 / 0.75) !important;
}

.bg-secondary\/80{
    background-color: rgb(0 95 184 / 0.8) !important;
}

.bg-secondary\/85{
    background-color: rgb(0 95 184 / 0.85) !important;
}

.bg-secondary\/90{
    background-color: rgb(0 95 184 / 0.9) !important;
}

.bg-secondary\/95{
    background-color: rgb(0 95 184 / 0.95) !important;
}

.bg-sky-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(224 242 254 / var(--tw-bg-opacity)) !important;
}

.bg-sky-100\/0{
    background-color: rgb(224 242 254 / 0) !important;
}

.bg-sky-100\/10{
    background-color: rgb(224 242 254 / 0.1) !important;
}

.bg-sky-100\/100{
    background-color: rgb(224 242 254 / 1) !important;
}

.bg-sky-100\/15{
    background-color: rgb(224 242 254 / 0.15) !important;
}

.bg-sky-100\/20{
    background-color: rgb(224 242 254 / 0.2) !important;
}

.bg-sky-100\/25{
    background-color: rgb(224 242 254 / 0.25) !important;
}

.bg-sky-100\/30{
    background-color: rgb(224 242 254 / 0.3) !important;
}

.bg-sky-100\/35{
    background-color: rgb(224 242 254 / 0.35) !important;
}

.bg-sky-100\/40{
    background-color: rgb(224 242 254 / 0.4) !important;
}

.bg-sky-100\/45{
    background-color: rgb(224 242 254 / 0.45) !important;
}

.bg-sky-100\/5{
    background-color: rgb(224 242 254 / 0.05) !important;
}

.bg-sky-100\/50{
    background-color: rgb(224 242 254 / 0.5) !important;
}

.bg-sky-100\/55{
    background-color: rgb(224 242 254 / 0.55) !important;
}

.bg-sky-100\/60{
    background-color: rgb(224 242 254 / 0.6) !important;
}

.bg-sky-100\/65{
    background-color: rgb(224 242 254 / 0.65) !important;
}

.bg-sky-100\/70{
    background-color: rgb(224 242 254 / 0.7) !important;
}

.bg-sky-100\/75{
    background-color: rgb(224 242 254 / 0.75) !important;
}

.bg-sky-100\/80{
    background-color: rgb(224 242 254 / 0.8) !important;
}

.bg-sky-100\/85{
    background-color: rgb(224 242 254 / 0.85) !important;
}

.bg-sky-100\/90{
    background-color: rgb(224 242 254 / 0.9) !important;
}

.bg-sky-100\/95{
    background-color: rgb(224 242 254 / 0.95) !important;
}

.bg-sky-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity)) !important;
}

.bg-sky-200\/0{
    background-color: rgb(186 230 253 / 0) !important;
}

.bg-sky-200\/10{
    background-color: rgb(186 230 253 / 0.1) !important;
}

.bg-sky-200\/100{
    background-color: rgb(186 230 253 / 1) !important;
}

.bg-sky-200\/15{
    background-color: rgb(186 230 253 / 0.15) !important;
}

.bg-sky-200\/20{
    background-color: rgb(186 230 253 / 0.2) !important;
}

.bg-sky-200\/25{
    background-color: rgb(186 230 253 / 0.25) !important;
}

.bg-sky-200\/30{
    background-color: rgb(186 230 253 / 0.3) !important;
}

.bg-sky-200\/35{
    background-color: rgb(186 230 253 / 0.35) !important;
}

.bg-sky-200\/40{
    background-color: rgb(186 230 253 / 0.4) !important;
}

.bg-sky-200\/45{
    background-color: rgb(186 230 253 / 0.45) !important;
}

.bg-sky-200\/5{
    background-color: rgb(186 230 253 / 0.05) !important;
}

.bg-sky-200\/50{
    background-color: rgb(186 230 253 / 0.5) !important;
}

.bg-sky-200\/55{
    background-color: rgb(186 230 253 / 0.55) !important;
}

.bg-sky-200\/60{
    background-color: rgb(186 230 253 / 0.6) !important;
}

.bg-sky-200\/65{
    background-color: rgb(186 230 253 / 0.65) !important;
}

.bg-sky-200\/70{
    background-color: rgb(186 230 253 / 0.7) !important;
}

.bg-sky-200\/75{
    background-color: rgb(186 230 253 / 0.75) !important;
}

.bg-sky-200\/80{
    background-color: rgb(186 230 253 / 0.8) !important;
}

.bg-sky-200\/85{
    background-color: rgb(186 230 253 / 0.85) !important;
}

.bg-sky-200\/90{
    background-color: rgb(186 230 253 / 0.9) !important;
}

.bg-sky-200\/95{
    background-color: rgb(186 230 253 / 0.95) !important;
}

.bg-sky-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(125 211 252 / var(--tw-bg-opacity)) !important;
}

.bg-sky-300\/0{
    background-color: rgb(125 211 252 / 0) !important;
}

.bg-sky-300\/10{
    background-color: rgb(125 211 252 / 0.1) !important;
}

.bg-sky-300\/100{
    background-color: rgb(125 211 252 / 1) !important;
}

.bg-sky-300\/15{
    background-color: rgb(125 211 252 / 0.15) !important;
}

.bg-sky-300\/20{
    background-color: rgb(125 211 252 / 0.2) !important;
}

.bg-sky-300\/25{
    background-color: rgb(125 211 252 / 0.25) !important;
}

.bg-sky-300\/30{
    background-color: rgb(125 211 252 / 0.3) !important;
}

.bg-sky-300\/35{
    background-color: rgb(125 211 252 / 0.35) !important;
}

.bg-sky-300\/40{
    background-color: rgb(125 211 252 / 0.4) !important;
}

.bg-sky-300\/45{
    background-color: rgb(125 211 252 / 0.45) !important;
}

.bg-sky-300\/5{
    background-color: rgb(125 211 252 / 0.05) !important;
}

.bg-sky-300\/50{
    background-color: rgb(125 211 252 / 0.5) !important;
}

.bg-sky-300\/55{
    background-color: rgb(125 211 252 / 0.55) !important;
}

.bg-sky-300\/60{
    background-color: rgb(125 211 252 / 0.6) !important;
}

.bg-sky-300\/65{
    background-color: rgb(125 211 252 / 0.65) !important;
}

.bg-sky-300\/70{
    background-color: rgb(125 211 252 / 0.7) !important;
}

.bg-sky-300\/75{
    background-color: rgb(125 211 252 / 0.75) !important;
}

.bg-sky-300\/80{
    background-color: rgb(125 211 252 / 0.8) !important;
}

.bg-sky-300\/85{
    background-color: rgb(125 211 252 / 0.85) !important;
}

.bg-sky-300\/90{
    background-color: rgb(125 211 252 / 0.9) !important;
}

.bg-sky-300\/95{
    background-color: rgb(125 211 252 / 0.95) !important;
}

.bg-sky-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(56 189 248 / var(--tw-bg-opacity)) !important;
}

.bg-sky-400\/0{
    background-color: rgb(56 189 248 / 0) !important;
}

.bg-sky-400\/10{
    background-color: rgb(56 189 248 / 0.1) !important;
}

.bg-sky-400\/100{
    background-color: rgb(56 189 248 / 1) !important;
}

.bg-sky-400\/15{
    background-color: rgb(56 189 248 / 0.15) !important;
}

.bg-sky-400\/20{
    background-color: rgb(56 189 248 / 0.2) !important;
}

.bg-sky-400\/25{
    background-color: rgb(56 189 248 / 0.25) !important;
}

.bg-sky-400\/30{
    background-color: rgb(56 189 248 / 0.3) !important;
}

.bg-sky-400\/35{
    background-color: rgb(56 189 248 / 0.35) !important;
}

.bg-sky-400\/40{
    background-color: rgb(56 189 248 / 0.4) !important;
}

.bg-sky-400\/45{
    background-color: rgb(56 189 248 / 0.45) !important;
}

.bg-sky-400\/5{
    background-color: rgb(56 189 248 / 0.05) !important;
}

.bg-sky-400\/50{
    background-color: rgb(56 189 248 / 0.5) !important;
}

.bg-sky-400\/55{
    background-color: rgb(56 189 248 / 0.55) !important;
}

.bg-sky-400\/60{
    background-color: rgb(56 189 248 / 0.6) !important;
}

.bg-sky-400\/65{
    background-color: rgb(56 189 248 / 0.65) !important;
}

.bg-sky-400\/70{
    background-color: rgb(56 189 248 / 0.7) !important;
}

.bg-sky-400\/75{
    background-color: rgb(56 189 248 / 0.75) !important;
}

.bg-sky-400\/80{
    background-color: rgb(56 189 248 / 0.8) !important;
}

.bg-sky-400\/85{
    background-color: rgb(56 189 248 / 0.85) !important;
}

.bg-sky-400\/90{
    background-color: rgb(56 189 248 / 0.9) !important;
}

.bg-sky-400\/95{
    background-color: rgb(56 189 248 / 0.95) !important;
}

.bg-sky-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 249 255 / var(--tw-bg-opacity)) !important;
}

.bg-sky-50\/0{
    background-color: rgb(240 249 255 / 0) !important;
}

.bg-sky-50\/10{
    background-color: rgb(240 249 255 / 0.1) !important;
}

.bg-sky-50\/100{
    background-color: rgb(240 249 255 / 1) !important;
}

.bg-sky-50\/15{
    background-color: rgb(240 249 255 / 0.15) !important;
}

.bg-sky-50\/20{
    background-color: rgb(240 249 255 / 0.2) !important;
}

.bg-sky-50\/25{
    background-color: rgb(240 249 255 / 0.25) !important;
}

.bg-sky-50\/30{
    background-color: rgb(240 249 255 / 0.3) !important;
}

.bg-sky-50\/35{
    background-color: rgb(240 249 255 / 0.35) !important;
}

.bg-sky-50\/40{
    background-color: rgb(240 249 255 / 0.4) !important;
}

.bg-sky-50\/45{
    background-color: rgb(240 249 255 / 0.45) !important;
}

.bg-sky-50\/5{
    background-color: rgb(240 249 255 / 0.05) !important;
}

.bg-sky-50\/50{
    background-color: rgb(240 249 255 / 0.5) !important;
}

.bg-sky-50\/55{
    background-color: rgb(240 249 255 / 0.55) !important;
}

.bg-sky-50\/60{
    background-color: rgb(240 249 255 / 0.6) !important;
}

.bg-sky-50\/65{
    background-color: rgb(240 249 255 / 0.65) !important;
}

.bg-sky-50\/70{
    background-color: rgb(240 249 255 / 0.7) !important;
}

.bg-sky-50\/75{
    background-color: rgb(240 249 255 / 0.75) !important;
}

.bg-sky-50\/80{
    background-color: rgb(240 249 255 / 0.8) !important;
}

.bg-sky-50\/85{
    background-color: rgb(240 249 255 / 0.85) !important;
}

.bg-sky-50\/90{
    background-color: rgb(240 249 255 / 0.9) !important;
}

.bg-sky-50\/95{
    background-color: rgb(240 249 255 / 0.95) !important;
}

.bg-sky-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(14 165 233 / var(--tw-bg-opacity)) !important;
}

.bg-sky-500\/0{
    background-color: rgb(14 165 233 / 0) !important;
}

.bg-sky-500\/10{
    background-color: rgb(14 165 233 / 0.1) !important;
}

.bg-sky-500\/100{
    background-color: rgb(14 165 233 / 1) !important;
}

.bg-sky-500\/15{
    background-color: rgb(14 165 233 / 0.15) !important;
}

.bg-sky-500\/20{
    background-color: rgb(14 165 233 / 0.2) !important;
}

.bg-sky-500\/25{
    background-color: rgb(14 165 233 / 0.25) !important;
}

.bg-sky-500\/30{
    background-color: rgb(14 165 233 / 0.3) !important;
}

.bg-sky-500\/35{
    background-color: rgb(14 165 233 / 0.35) !important;
}

.bg-sky-500\/40{
    background-color: rgb(14 165 233 / 0.4) !important;
}

.bg-sky-500\/45{
    background-color: rgb(14 165 233 / 0.45) !important;
}

.bg-sky-500\/5{
    background-color: rgb(14 165 233 / 0.05) !important;
}

.bg-sky-500\/50{
    background-color: rgb(14 165 233 / 0.5) !important;
}

.bg-sky-500\/55{
    background-color: rgb(14 165 233 / 0.55) !important;
}

.bg-sky-500\/60{
    background-color: rgb(14 165 233 / 0.6) !important;
}

.bg-sky-500\/65{
    background-color: rgb(14 165 233 / 0.65) !important;
}

.bg-sky-500\/70{
    background-color: rgb(14 165 233 / 0.7) !important;
}

.bg-sky-500\/75{
    background-color: rgb(14 165 233 / 0.75) !important;
}

.bg-sky-500\/80{
    background-color: rgb(14 165 233 / 0.8) !important;
}

.bg-sky-500\/85{
    background-color: rgb(14 165 233 / 0.85) !important;
}

.bg-sky-500\/90{
    background-color: rgb(14 165 233 / 0.9) !important;
}

.bg-sky-500\/95{
    background-color: rgb(14 165 233 / 0.95) !important;
}

.bg-sky-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(2 132 199 / var(--tw-bg-opacity)) !important;
}

.bg-sky-600\/0{
    background-color: rgb(2 132 199 / 0) !important;
}

.bg-sky-600\/10{
    background-color: rgb(2 132 199 / 0.1) !important;
}

.bg-sky-600\/100{
    background-color: rgb(2 132 199 / 1) !important;
}

.bg-sky-600\/15{
    background-color: rgb(2 132 199 / 0.15) !important;
}

.bg-sky-600\/20{
    background-color: rgb(2 132 199 / 0.2) !important;
}

.bg-sky-600\/25{
    background-color: rgb(2 132 199 / 0.25) !important;
}

.bg-sky-600\/30{
    background-color: rgb(2 132 199 / 0.3) !important;
}

.bg-sky-600\/35{
    background-color: rgb(2 132 199 / 0.35) !important;
}

.bg-sky-600\/40{
    background-color: rgb(2 132 199 / 0.4) !important;
}

.bg-sky-600\/45{
    background-color: rgb(2 132 199 / 0.45) !important;
}

.bg-sky-600\/5{
    background-color: rgb(2 132 199 / 0.05) !important;
}

.bg-sky-600\/50{
    background-color: rgb(2 132 199 / 0.5) !important;
}

.bg-sky-600\/55{
    background-color: rgb(2 132 199 / 0.55) !important;
}

.bg-sky-600\/60{
    background-color: rgb(2 132 199 / 0.6) !important;
}

.bg-sky-600\/65{
    background-color: rgb(2 132 199 / 0.65) !important;
}

.bg-sky-600\/70{
    background-color: rgb(2 132 199 / 0.7) !important;
}

.bg-sky-600\/75{
    background-color: rgb(2 132 199 / 0.75) !important;
}

.bg-sky-600\/80{
    background-color: rgb(2 132 199 / 0.8) !important;
}

.bg-sky-600\/85{
    background-color: rgb(2 132 199 / 0.85) !important;
}

.bg-sky-600\/90{
    background-color: rgb(2 132 199 / 0.9) !important;
}

.bg-sky-600\/95{
    background-color: rgb(2 132 199 / 0.95) !important;
}

.bg-sky-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(3 105 161 / var(--tw-bg-opacity)) !important;
}

.bg-sky-700\/0{
    background-color: rgb(3 105 161 / 0) !important;
}

.bg-sky-700\/10{
    background-color: rgb(3 105 161 / 0.1) !important;
}

.bg-sky-700\/100{
    background-color: rgb(3 105 161 / 1) !important;
}

.bg-sky-700\/15{
    background-color: rgb(3 105 161 / 0.15) !important;
}

.bg-sky-700\/20{
    background-color: rgb(3 105 161 / 0.2) !important;
}

.bg-sky-700\/25{
    background-color: rgb(3 105 161 / 0.25) !important;
}

.bg-sky-700\/30{
    background-color: rgb(3 105 161 / 0.3) !important;
}

.bg-sky-700\/35{
    background-color: rgb(3 105 161 / 0.35) !important;
}

.bg-sky-700\/40{
    background-color: rgb(3 105 161 / 0.4) !important;
}

.bg-sky-700\/45{
    background-color: rgb(3 105 161 / 0.45) !important;
}

.bg-sky-700\/5{
    background-color: rgb(3 105 161 / 0.05) !important;
}

.bg-sky-700\/50{
    background-color: rgb(3 105 161 / 0.5) !important;
}

.bg-sky-700\/55{
    background-color: rgb(3 105 161 / 0.55) !important;
}

.bg-sky-700\/60{
    background-color: rgb(3 105 161 / 0.6) !important;
}

.bg-sky-700\/65{
    background-color: rgb(3 105 161 / 0.65) !important;
}

.bg-sky-700\/70{
    background-color: rgb(3 105 161 / 0.7) !important;
}

.bg-sky-700\/75{
    background-color: rgb(3 105 161 / 0.75) !important;
}

.bg-sky-700\/80{
    background-color: rgb(3 105 161 / 0.8) !important;
}

.bg-sky-700\/85{
    background-color: rgb(3 105 161 / 0.85) !important;
}

.bg-sky-700\/90{
    background-color: rgb(3 105 161 / 0.9) !important;
}

.bg-sky-700\/95{
    background-color: rgb(3 105 161 / 0.95) !important;
}

.bg-sky-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(7 89 133 / var(--tw-bg-opacity)) !important;
}

.bg-sky-800\/0{
    background-color: rgb(7 89 133 / 0) !important;
}

.bg-sky-800\/10{
    background-color: rgb(7 89 133 / 0.1) !important;
}

.bg-sky-800\/100{
    background-color: rgb(7 89 133 / 1) !important;
}

.bg-sky-800\/15{
    background-color: rgb(7 89 133 / 0.15) !important;
}

.bg-sky-800\/20{
    background-color: rgb(7 89 133 / 0.2) !important;
}

.bg-sky-800\/25{
    background-color: rgb(7 89 133 / 0.25) !important;
}

.bg-sky-800\/30{
    background-color: rgb(7 89 133 / 0.3) !important;
}

.bg-sky-800\/35{
    background-color: rgb(7 89 133 / 0.35) !important;
}

.bg-sky-800\/40{
    background-color: rgb(7 89 133 / 0.4) !important;
}

.bg-sky-800\/45{
    background-color: rgb(7 89 133 / 0.45) !important;
}

.bg-sky-800\/5{
    background-color: rgb(7 89 133 / 0.05) !important;
}

.bg-sky-800\/50{
    background-color: rgb(7 89 133 / 0.5) !important;
}

.bg-sky-800\/55{
    background-color: rgb(7 89 133 / 0.55) !important;
}

.bg-sky-800\/60{
    background-color: rgb(7 89 133 / 0.6) !important;
}

.bg-sky-800\/65{
    background-color: rgb(7 89 133 / 0.65) !important;
}

.bg-sky-800\/70{
    background-color: rgb(7 89 133 / 0.7) !important;
}

.bg-sky-800\/75{
    background-color: rgb(7 89 133 / 0.75) !important;
}

.bg-sky-800\/80{
    background-color: rgb(7 89 133 / 0.8) !important;
}

.bg-sky-800\/85{
    background-color: rgb(7 89 133 / 0.85) !important;
}

.bg-sky-800\/90{
    background-color: rgb(7 89 133 / 0.9) !important;
}

.bg-sky-800\/95{
    background-color: rgb(7 89 133 / 0.95) !important;
}

.bg-sky-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(12 74 110 / var(--tw-bg-opacity)) !important;
}

.bg-sky-900\/0{
    background-color: rgb(12 74 110 / 0) !important;
}

.bg-sky-900\/10{
    background-color: rgb(12 74 110 / 0.1) !important;
}

.bg-sky-900\/100{
    background-color: rgb(12 74 110 / 1) !important;
}

.bg-sky-900\/15{
    background-color: rgb(12 74 110 / 0.15) !important;
}

.bg-sky-900\/20{
    background-color: rgb(12 74 110 / 0.2) !important;
}

.bg-sky-900\/25{
    background-color: rgb(12 74 110 / 0.25) !important;
}

.bg-sky-900\/30{
    background-color: rgb(12 74 110 / 0.3) !important;
}

.bg-sky-900\/35{
    background-color: rgb(12 74 110 / 0.35) !important;
}

.bg-sky-900\/40{
    background-color: rgb(12 74 110 / 0.4) !important;
}

.bg-sky-900\/45{
    background-color: rgb(12 74 110 / 0.45) !important;
}

.bg-sky-900\/5{
    background-color: rgb(12 74 110 / 0.05) !important;
}

.bg-sky-900\/50{
    background-color: rgb(12 74 110 / 0.5) !important;
}

.bg-sky-900\/55{
    background-color: rgb(12 74 110 / 0.55) !important;
}

.bg-sky-900\/60{
    background-color: rgb(12 74 110 / 0.6) !important;
}

.bg-sky-900\/65{
    background-color: rgb(12 74 110 / 0.65) !important;
}

.bg-sky-900\/70{
    background-color: rgb(12 74 110 / 0.7) !important;
}

.bg-sky-900\/75{
    background-color: rgb(12 74 110 / 0.75) !important;
}

.bg-sky-900\/80{
    background-color: rgb(12 74 110 / 0.8) !important;
}

.bg-sky-900\/85{
    background-color: rgb(12 74 110 / 0.85) !important;
}

.bg-sky-900\/90{
    background-color: rgb(12 74 110 / 0.9) !important;
}

.bg-sky-900\/95{
    background-color: rgb(12 74 110 / 0.95) !important;
}

.bg-slate-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.bg-slate-100\/0{
    background-color: rgb(241 245 249 / 0) !important;
}

.bg-slate-100\/10{
    background-color: rgb(241 245 249 / 0.1) !important;
}

.bg-slate-100\/100{
    background-color: rgb(241 245 249 / 1) !important;
}

.bg-slate-100\/15{
    background-color: rgb(241 245 249 / 0.15) !important;
}

.bg-slate-100\/20{
    background-color: rgb(241 245 249 / 0.2) !important;
}

.bg-slate-100\/25{
    background-color: rgb(241 245 249 / 0.25) !important;
}

.bg-slate-100\/30{
    background-color: rgb(241 245 249 / 0.3) !important;
}

.bg-slate-100\/35{
    background-color: rgb(241 245 249 / 0.35) !important;
}

.bg-slate-100\/40{
    background-color: rgb(241 245 249 / 0.4) !important;
}

.bg-slate-100\/45{
    background-color: rgb(241 245 249 / 0.45) !important;
}

.bg-slate-100\/5{
    background-color: rgb(241 245 249 / 0.05) !important;
}

.bg-slate-100\/50{
    background-color: rgb(241 245 249 / 0.5) !important;
}

.bg-slate-100\/55{
    background-color: rgb(241 245 249 / 0.55) !important;
}

.bg-slate-100\/60{
    background-color: rgb(241 245 249 / 0.6) !important;
}

.bg-slate-100\/65{
    background-color: rgb(241 245 249 / 0.65) !important;
}

.bg-slate-100\/70{
    background-color: rgb(241 245 249 / 0.7) !important;
}

.bg-slate-100\/75{
    background-color: rgb(241 245 249 / 0.75) !important;
}

.bg-slate-100\/80{
    background-color: rgb(241 245 249 / 0.8) !important;
}

.bg-slate-100\/85{
    background-color: rgb(241 245 249 / 0.85) !important;
}

.bg-slate-100\/90{
    background-color: rgb(241 245 249 / 0.9) !important;
}

.bg-slate-100\/95{
    background-color: rgb(241 245 249 / 0.95) !important;
}

.bg-slate-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity)) !important;
}

.bg-slate-200\/0{
    background-color: rgb(226 232 240 / 0) !important;
}

.bg-slate-200\/10{
    background-color: rgb(226 232 240 / 0.1) !important;
}

.bg-slate-200\/100{
    background-color: rgb(226 232 240 / 1) !important;
}

.bg-slate-200\/15{
    background-color: rgb(226 232 240 / 0.15) !important;
}

.bg-slate-200\/20{
    background-color: rgb(226 232 240 / 0.2) !important;
}

.bg-slate-200\/25{
    background-color: rgb(226 232 240 / 0.25) !important;
}

.bg-slate-200\/30{
    background-color: rgb(226 232 240 / 0.3) !important;
}

.bg-slate-200\/35{
    background-color: rgb(226 232 240 / 0.35) !important;
}

.bg-slate-200\/40{
    background-color: rgb(226 232 240 / 0.4) !important;
}

.bg-slate-200\/45{
    background-color: rgb(226 232 240 / 0.45) !important;
}

.bg-slate-200\/5{
    background-color: rgb(226 232 240 / 0.05) !important;
}

.bg-slate-200\/50{
    background-color: rgb(226 232 240 / 0.5) !important;
}

.bg-slate-200\/55{
    background-color: rgb(226 232 240 / 0.55) !important;
}

.bg-slate-200\/60{
    background-color: rgb(226 232 240 / 0.6) !important;
}

.bg-slate-200\/65{
    background-color: rgb(226 232 240 / 0.65) !important;
}

.bg-slate-200\/70{
    background-color: rgb(226 232 240 / 0.7) !important;
}

.bg-slate-200\/75{
    background-color: rgb(226 232 240 / 0.75) !important;
}

.bg-slate-200\/80{
    background-color: rgb(226 232 240 / 0.8) !important;
}

.bg-slate-200\/85{
    background-color: rgb(226 232 240 / 0.85) !important;
}

.bg-slate-200\/90{
    background-color: rgb(226 232 240 / 0.9) !important;
}

.bg-slate-200\/95{
    background-color: rgb(226 232 240 / 0.95) !important;
}

.bg-slate-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}

.bg-slate-300\/0{
    background-color: rgb(203 213 225 / 0) !important;
}

.bg-slate-300\/10{
    background-color: rgb(203 213 225 / 0.1) !important;
}

.bg-slate-300\/100{
    background-color: rgb(203 213 225 / 1) !important;
}

.bg-slate-300\/15{
    background-color: rgb(203 213 225 / 0.15) !important;
}

.bg-slate-300\/20{
    background-color: rgb(203 213 225 / 0.2) !important;
}

.bg-slate-300\/25{
    background-color: rgb(203 213 225 / 0.25) !important;
}

.bg-slate-300\/30{
    background-color: rgb(203 213 225 / 0.3) !important;
}

.bg-slate-300\/35{
    background-color: rgb(203 213 225 / 0.35) !important;
}

.bg-slate-300\/40{
    background-color: rgb(203 213 225 / 0.4) !important;
}

.bg-slate-300\/45{
    background-color: rgb(203 213 225 / 0.45) !important;
}

.bg-slate-300\/5{
    background-color: rgb(203 213 225 / 0.05) !important;
}

.bg-slate-300\/50{
    background-color: rgb(203 213 225 / 0.5) !important;
}

.bg-slate-300\/55{
    background-color: rgb(203 213 225 / 0.55) !important;
}

.bg-slate-300\/60{
    background-color: rgb(203 213 225 / 0.6) !important;
}

.bg-slate-300\/65{
    background-color: rgb(203 213 225 / 0.65) !important;
}

.bg-slate-300\/70{
    background-color: rgb(203 213 225 / 0.7) !important;
}

.bg-slate-300\/75{
    background-color: rgb(203 213 225 / 0.75) !important;
}

.bg-slate-300\/80{
    background-color: rgb(203 213 225 / 0.8) !important;
}

.bg-slate-300\/85{
    background-color: rgb(203 213 225 / 0.85) !important;
}

.bg-slate-300\/90{
    background-color: rgb(203 213 225 / 0.9) !important;
}

.bg-slate-300\/95{
    background-color: rgb(203 213 225 / 0.95) !important;
}

.bg-slate-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity)) !important;
}

.bg-slate-400\/0{
    background-color: rgb(148 163 184 / 0) !important;
}

.bg-slate-400\/10{
    background-color: rgb(148 163 184 / 0.1) !important;
}

.bg-slate-400\/100{
    background-color: rgb(148 163 184 / 1) !important;
}

.bg-slate-400\/15{
    background-color: rgb(148 163 184 / 0.15) !important;
}

.bg-slate-400\/20{
    background-color: rgb(148 163 184 / 0.2) !important;
}

.bg-slate-400\/25{
    background-color: rgb(148 163 184 / 0.25) !important;
}

.bg-slate-400\/30{
    background-color: rgb(148 163 184 / 0.3) !important;
}

.bg-slate-400\/35{
    background-color: rgb(148 163 184 / 0.35) !important;
}

.bg-slate-400\/40{
    background-color: rgb(148 163 184 / 0.4) !important;
}

.bg-slate-400\/45{
    background-color: rgb(148 163 184 / 0.45) !important;
}

.bg-slate-400\/5{
    background-color: rgb(148 163 184 / 0.05) !important;
}

.bg-slate-400\/50{
    background-color: rgb(148 163 184 / 0.5) !important;
}

.bg-slate-400\/55{
    background-color: rgb(148 163 184 / 0.55) !important;
}

.bg-slate-400\/60{
    background-color: rgb(148 163 184 / 0.6) !important;
}

.bg-slate-400\/65{
    background-color: rgb(148 163 184 / 0.65) !important;
}

.bg-slate-400\/70{
    background-color: rgb(148 163 184 / 0.7) !important;
}

.bg-slate-400\/75{
    background-color: rgb(148 163 184 / 0.75) !important;
}

.bg-slate-400\/80{
    background-color: rgb(148 163 184 / 0.8) !important;
}

.bg-slate-400\/85{
    background-color: rgb(148 163 184 / 0.85) !important;
}

.bg-slate-400\/90{
    background-color: rgb(148 163 184 / 0.9) !important;
}

.bg-slate-400\/95{
    background-color: rgb(148 163 184 / 0.95) !important;
}

.bg-slate-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}

.bg-slate-50\/0{
    background-color: rgb(248 250 252 / 0) !important;
}

.bg-slate-50\/10{
    background-color: rgb(248 250 252 / 0.1) !important;
}

.bg-slate-50\/100{
    background-color: rgb(248 250 252 / 1) !important;
}

.bg-slate-50\/15{
    background-color: rgb(248 250 252 / 0.15) !important;
}

.bg-slate-50\/20{
    background-color: rgb(248 250 252 / 0.2) !important;
}

.bg-slate-50\/25{
    background-color: rgb(248 250 252 / 0.25) !important;
}

.bg-slate-50\/30{
    background-color: rgb(248 250 252 / 0.3) !important;
}

.bg-slate-50\/35{
    background-color: rgb(248 250 252 / 0.35) !important;
}

.bg-slate-50\/40{
    background-color: rgb(248 250 252 / 0.4) !important;
}

.bg-slate-50\/45{
    background-color: rgb(248 250 252 / 0.45) !important;
}

.bg-slate-50\/5{
    background-color: rgb(248 250 252 / 0.05) !important;
}

.bg-slate-50\/50{
    background-color: rgb(248 250 252 / 0.5) !important;
}

.bg-slate-50\/55{
    background-color: rgb(248 250 252 / 0.55) !important;
}

.bg-slate-50\/60{
    background-color: rgb(248 250 252 / 0.6) !important;
}

.bg-slate-50\/65{
    background-color: rgb(248 250 252 / 0.65) !important;
}

.bg-slate-50\/70{
    background-color: rgb(248 250 252 / 0.7) !important;
}

.bg-slate-50\/75{
    background-color: rgb(248 250 252 / 0.75) !important;
}

.bg-slate-50\/80{
    background-color: rgb(248 250 252 / 0.8) !important;
}

.bg-slate-50\/85{
    background-color: rgb(248 250 252 / 0.85) !important;
}

.bg-slate-50\/90{
    background-color: rgb(248 250 252 / 0.9) !important;
}

.bg-slate-50\/95{
    background-color: rgb(248 250 252 / 0.95) !important;
}

.bg-slate-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(100 116 139 / var(--tw-bg-opacity)) !important;
}

.bg-slate-500\/0{
    background-color: rgb(100 116 139 / 0) !important;
}

.bg-slate-500\/10{
    background-color: rgb(100 116 139 / 0.1) !important;
}

.bg-slate-500\/100{
    background-color: rgb(100 116 139 / 1) !important;
}

.bg-slate-500\/15{
    background-color: rgb(100 116 139 / 0.15) !important;
}

.bg-slate-500\/20{
    background-color: rgb(100 116 139 / 0.2) !important;
}

.bg-slate-500\/25{
    background-color: rgb(100 116 139 / 0.25) !important;
}

.bg-slate-500\/30{
    background-color: rgb(100 116 139 / 0.3) !important;
}

.bg-slate-500\/35{
    background-color: rgb(100 116 139 / 0.35) !important;
}

.bg-slate-500\/40{
    background-color: rgb(100 116 139 / 0.4) !important;
}

.bg-slate-500\/45{
    background-color: rgb(100 116 139 / 0.45) !important;
}

.bg-slate-500\/5{
    background-color: rgb(100 116 139 / 0.05) !important;
}

.bg-slate-500\/50{
    background-color: rgb(100 116 139 / 0.5) !important;
}

.bg-slate-500\/55{
    background-color: rgb(100 116 139 / 0.55) !important;
}

.bg-slate-500\/60{
    background-color: rgb(100 116 139 / 0.6) !important;
}

.bg-slate-500\/65{
    background-color: rgb(100 116 139 / 0.65) !important;
}

.bg-slate-500\/70{
    background-color: rgb(100 116 139 / 0.7) !important;
}

.bg-slate-500\/75{
    background-color: rgb(100 116 139 / 0.75) !important;
}

.bg-slate-500\/80{
    background-color: rgb(100 116 139 / 0.8) !important;
}

.bg-slate-500\/85{
    background-color: rgb(100 116 139 / 0.85) !important;
}

.bg-slate-500\/90{
    background-color: rgb(100 116 139 / 0.9) !important;
}

.bg-slate-500\/95{
    background-color: rgb(100 116 139 / 0.95) !important;
}

.bg-slate-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(71 85 105 / var(--tw-bg-opacity)) !important;
}

.bg-slate-600\/0{
    background-color: rgb(71 85 105 / 0) !important;
}

.bg-slate-600\/10{
    background-color: rgb(71 85 105 / 0.1) !important;
}

.bg-slate-600\/100{
    background-color: rgb(71 85 105 / 1) !important;
}

.bg-slate-600\/15{
    background-color: rgb(71 85 105 / 0.15) !important;
}

.bg-slate-600\/20{
    background-color: rgb(71 85 105 / 0.2) !important;
}

.bg-slate-600\/25{
    background-color: rgb(71 85 105 / 0.25) !important;
}

.bg-slate-600\/30{
    background-color: rgb(71 85 105 / 0.3) !important;
}

.bg-slate-600\/35{
    background-color: rgb(71 85 105 / 0.35) !important;
}

.bg-slate-600\/40{
    background-color: rgb(71 85 105 / 0.4) !important;
}

.bg-slate-600\/45{
    background-color: rgb(71 85 105 / 0.45) !important;
}

.bg-slate-600\/5{
    background-color: rgb(71 85 105 / 0.05) !important;
}

.bg-slate-600\/50{
    background-color: rgb(71 85 105 / 0.5) !important;
}

.bg-slate-600\/55{
    background-color: rgb(71 85 105 / 0.55) !important;
}

.bg-slate-600\/60{
    background-color: rgb(71 85 105 / 0.6) !important;
}

.bg-slate-600\/65{
    background-color: rgb(71 85 105 / 0.65) !important;
}

.bg-slate-600\/70{
    background-color: rgb(71 85 105 / 0.7) !important;
}

.bg-slate-600\/75{
    background-color: rgb(71 85 105 / 0.75) !important;
}

.bg-slate-600\/80{
    background-color: rgb(71 85 105 / 0.8) !important;
}

.bg-slate-600\/85{
    background-color: rgb(71 85 105 / 0.85) !important;
}

.bg-slate-600\/90{
    background-color: rgb(71 85 105 / 0.9) !important;
}

.bg-slate-600\/95{
    background-color: rgb(71 85 105 / 0.95) !important;
}

.bg-slate-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
}

.bg-slate-700\/0{
    background-color: rgb(51 65 85 / 0) !important;
}

.bg-slate-700\/10{
    background-color: rgb(51 65 85 / 0.1) !important;
}

.bg-slate-700\/100{
    background-color: rgb(51 65 85 / 1) !important;
}

.bg-slate-700\/15{
    background-color: rgb(51 65 85 / 0.15) !important;
}

.bg-slate-700\/20{
    background-color: rgb(51 65 85 / 0.2) !important;
}

.bg-slate-700\/25{
    background-color: rgb(51 65 85 / 0.25) !important;
}

.bg-slate-700\/30{
    background-color: rgb(51 65 85 / 0.3) !important;
}

.bg-slate-700\/35{
    background-color: rgb(51 65 85 / 0.35) !important;
}

.bg-slate-700\/40{
    background-color: rgb(51 65 85 / 0.4) !important;
}

.bg-slate-700\/45{
    background-color: rgb(51 65 85 / 0.45) !important;
}

.bg-slate-700\/5{
    background-color: rgb(51 65 85 / 0.05) !important;
}

.bg-slate-700\/50{
    background-color: rgb(51 65 85 / 0.5) !important;
}

.bg-slate-700\/55{
    background-color: rgb(51 65 85 / 0.55) !important;
}

.bg-slate-700\/60{
    background-color: rgb(51 65 85 / 0.6) !important;
}

.bg-slate-700\/65{
    background-color: rgb(51 65 85 / 0.65) !important;
}

.bg-slate-700\/70{
    background-color: rgb(51 65 85 / 0.7) !important;
}

.bg-slate-700\/75{
    background-color: rgb(51 65 85 / 0.75) !important;
}

.bg-slate-700\/80{
    background-color: rgb(51 65 85 / 0.8) !important;
}

.bg-slate-700\/85{
    background-color: rgb(51 65 85 / 0.85) !important;
}

.bg-slate-700\/90{
    background-color: rgb(51 65 85 / 0.9) !important;
}

.bg-slate-700\/95{
    background-color: rgb(51 65 85 / 0.95) !important;
}

.bg-slate-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.bg-slate-800\/0{
    background-color: rgb(30 41 59 / 0) !important;
}

.bg-slate-800\/10{
    background-color: rgb(30 41 59 / 0.1) !important;
}

.bg-slate-800\/100{
    background-color: rgb(30 41 59 / 1) !important;
}

.bg-slate-800\/15{
    background-color: rgb(30 41 59 / 0.15) !important;
}

.bg-slate-800\/20{
    background-color: rgb(30 41 59 / 0.2) !important;
}

.bg-slate-800\/25{
    background-color: rgb(30 41 59 / 0.25) !important;
}

.bg-slate-800\/30{
    background-color: rgb(30 41 59 / 0.3) !important;
}

.bg-slate-800\/35{
    background-color: rgb(30 41 59 / 0.35) !important;
}

.bg-slate-800\/40{
    background-color: rgb(30 41 59 / 0.4) !important;
}

.bg-slate-800\/45{
    background-color: rgb(30 41 59 / 0.45) !important;
}

.bg-slate-800\/5{
    background-color: rgb(30 41 59 / 0.05) !important;
}

.bg-slate-800\/50{
    background-color: rgb(30 41 59 / 0.5) !important;
}

.bg-slate-800\/55{
    background-color: rgb(30 41 59 / 0.55) !important;
}

.bg-slate-800\/60{
    background-color: rgb(30 41 59 / 0.6) !important;
}

.bg-slate-800\/65{
    background-color: rgb(30 41 59 / 0.65) !important;
}

.bg-slate-800\/70{
    background-color: rgb(30 41 59 / 0.7) !important;
}

.bg-slate-800\/75{
    background-color: rgb(30 41 59 / 0.75) !important;
}

.bg-slate-800\/80{
    background-color: rgb(30 41 59 / 0.8) !important;
}

.bg-slate-800\/85{
    background-color: rgb(30 41 59 / 0.85) !important;
}

.bg-slate-800\/90{
    background-color: rgb(30 41 59 / 0.9) !important;
}

.bg-slate-800\/95{
    background-color: rgb(30 41 59 / 0.95) !important;
}

.bg-slate-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.bg-slate-900\/0{
    background-color: rgb(15 23 42 / 0) !important;
}

.bg-slate-900\/10{
    background-color: rgb(15 23 42 / 0.1) !important;
}

.bg-slate-900\/100{
    background-color: rgb(15 23 42 / 1) !important;
}

.bg-slate-900\/15{
    background-color: rgb(15 23 42 / 0.15) !important;
}

.bg-slate-900\/20{
    background-color: rgb(15 23 42 / 0.2) !important;
}

.bg-slate-900\/25{
    background-color: rgb(15 23 42 / 0.25) !important;
}

.bg-slate-900\/30{
    background-color: rgb(15 23 42 / 0.3) !important;
}

.bg-slate-900\/35{
    background-color: rgb(15 23 42 / 0.35) !important;
}

.bg-slate-900\/40{
    background-color: rgb(15 23 42 / 0.4) !important;
}

.bg-slate-900\/45{
    background-color: rgb(15 23 42 / 0.45) !important;
}

.bg-slate-900\/5{
    background-color: rgb(15 23 42 / 0.05) !important;
}

.bg-slate-900\/50{
    background-color: rgb(15 23 42 / 0.5) !important;
}

.bg-slate-900\/55{
    background-color: rgb(15 23 42 / 0.55) !important;
}

.bg-slate-900\/60{
    background-color: rgb(15 23 42 / 0.6) !important;
}

.bg-slate-900\/65{
    background-color: rgb(15 23 42 / 0.65) !important;
}

.bg-slate-900\/70{
    background-color: rgb(15 23 42 / 0.7) !important;
}

.bg-slate-900\/75{
    background-color: rgb(15 23 42 / 0.75) !important;
}

.bg-slate-900\/80{
    background-color: rgb(15 23 42 / 0.8) !important;
}

.bg-slate-900\/85{
    background-color: rgb(15 23 42 / 0.85) !important;
}

.bg-slate-900\/90{
    background-color: rgb(15 23 42 / 0.9) !important;
}

.bg-slate-900\/95{
    background-color: rgb(15 23 42 / 0.95) !important;
}

.bg-srm-1{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(248 244 180 / var(--tw-bg-opacity)) !important;
}

.bg-srm-1\/0{
    background-color: rgb(248 244 180 / 0) !important;
}

.bg-srm-1\/10{
    background-color: rgb(248 244 180 / 0.1) !important;
}

.bg-srm-1\/100{
    background-color: rgb(248 244 180 / 1) !important;
}

.bg-srm-1\/15{
    background-color: rgb(248 244 180 / 0.15) !important;
}

.bg-srm-1\/20{
    background-color: rgb(248 244 180 / 0.2) !important;
}

.bg-srm-1\/25{
    background-color: rgb(248 244 180 / 0.25) !important;
}

.bg-srm-1\/30{
    background-color: rgb(248 244 180 / 0.3) !important;
}

.bg-srm-1\/35{
    background-color: rgb(248 244 180 / 0.35) !important;
}

.bg-srm-1\/40{
    background-color: rgb(248 244 180 / 0.4) !important;
}

.bg-srm-1\/45{
    background-color: rgb(248 244 180 / 0.45) !important;
}

.bg-srm-1\/5{
    background-color: rgb(248 244 180 / 0.05) !important;
}

.bg-srm-1\/50{
    background-color: rgb(248 244 180 / 0.5) !important;
}

.bg-srm-1\/55{
    background-color: rgb(248 244 180 / 0.55) !important;
}

.bg-srm-1\/60{
    background-color: rgb(248 244 180 / 0.6) !important;
}

.bg-srm-1\/65{
    background-color: rgb(248 244 180 / 0.65) !important;
}

.bg-srm-1\/70{
    background-color: rgb(248 244 180 / 0.7) !important;
}

.bg-srm-1\/75{
    background-color: rgb(248 244 180 / 0.75) !important;
}

.bg-srm-1\/80{
    background-color: rgb(248 244 180 / 0.8) !important;
}

.bg-srm-1\/85{
    background-color: rgb(248 244 180 / 0.85) !important;
}

.bg-srm-1\/90{
    background-color: rgb(248 244 180 / 0.9) !important;
}

.bg-srm-1\/95{
    background-color: rgb(248 244 180 / 0.95) !important;
}

.bg-srm-10{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(189 89 27 / var(--tw-bg-opacity)) !important;
}

.bg-srm-10\/0{
    background-color: rgb(189 89 27 / 0) !important;
}

.bg-srm-10\/10{
    background-color: rgb(189 89 27 / 0.1) !important;
}

.bg-srm-10\/100{
    background-color: rgb(189 89 27 / 1) !important;
}

.bg-srm-10\/15{
    background-color: rgb(189 89 27 / 0.15) !important;
}

.bg-srm-10\/20{
    background-color: rgb(189 89 27 / 0.2) !important;
}

.bg-srm-10\/25{
    background-color: rgb(189 89 27 / 0.25) !important;
}

.bg-srm-10\/30{
    background-color: rgb(189 89 27 / 0.3) !important;
}

.bg-srm-10\/35{
    background-color: rgb(189 89 27 / 0.35) !important;
}

.bg-srm-10\/40{
    background-color: rgb(189 89 27 / 0.4) !important;
}

.bg-srm-10\/45{
    background-color: rgb(189 89 27 / 0.45) !important;
}

.bg-srm-10\/5{
    background-color: rgb(189 89 27 / 0.05) !important;
}

.bg-srm-10\/50{
    background-color: rgb(189 89 27 / 0.5) !important;
}

.bg-srm-10\/55{
    background-color: rgb(189 89 27 / 0.55) !important;
}

.bg-srm-10\/60{
    background-color: rgb(189 89 27 / 0.6) !important;
}

.bg-srm-10\/65{
    background-color: rgb(189 89 27 / 0.65) !important;
}

.bg-srm-10\/70{
    background-color: rgb(189 89 27 / 0.7) !important;
}

.bg-srm-10\/75{
    background-color: rgb(189 89 27 / 0.75) !important;
}

.bg-srm-10\/80{
    background-color: rgb(189 89 27 / 0.8) !important;
}

.bg-srm-10\/85{
    background-color: rgb(189 89 27 / 0.85) !important;
}

.bg-srm-10\/90{
    background-color: rgb(189 89 27 / 0.9) !important;
}

.bg-srm-10\/95{
    background-color: rgb(189 89 27 / 0.95) !important;
}

.bg-srm-11{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(195 98 27 / var(--tw-bg-opacity)) !important;
}

.bg-srm-11\/0{
    background-color: rgb(195 98 27 / 0) !important;
}

.bg-srm-11\/10{
    background-color: rgb(195 98 27 / 0.1) !important;
}

.bg-srm-11\/100{
    background-color: rgb(195 98 27 / 1) !important;
}

.bg-srm-11\/15{
    background-color: rgb(195 98 27 / 0.15) !important;
}

.bg-srm-11\/20{
    background-color: rgb(195 98 27 / 0.2) !important;
}

.bg-srm-11\/25{
    background-color: rgb(195 98 27 / 0.25) !important;
}

.bg-srm-11\/30{
    background-color: rgb(195 98 27 / 0.3) !important;
}

.bg-srm-11\/35{
    background-color: rgb(195 98 27 / 0.35) !important;
}

.bg-srm-11\/40{
    background-color: rgb(195 98 27 / 0.4) !important;
}

.bg-srm-11\/45{
    background-color: rgb(195 98 27 / 0.45) !important;
}

.bg-srm-11\/5{
    background-color: rgb(195 98 27 / 0.05) !important;
}

.bg-srm-11\/50{
    background-color: rgb(195 98 27 / 0.5) !important;
}

.bg-srm-11\/55{
    background-color: rgb(195 98 27 / 0.55) !important;
}

.bg-srm-11\/60{
    background-color: rgb(195 98 27 / 0.6) !important;
}

.bg-srm-11\/65{
    background-color: rgb(195 98 27 / 0.65) !important;
}

.bg-srm-11\/70{
    background-color: rgb(195 98 27 / 0.7) !important;
}

.bg-srm-11\/75{
    background-color: rgb(195 98 27 / 0.75) !important;
}

.bg-srm-11\/80{
    background-color: rgb(195 98 27 / 0.8) !important;
}

.bg-srm-11\/85{
    background-color: rgb(195 98 27 / 0.85) !important;
}

.bg-srm-11\/90{
    background-color: rgb(195 98 27 / 0.9) !important;
}

.bg-srm-11\/95{
    background-color: rgb(195 98 27 / 0.95) !important;
}

.bg-srm-12{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(200 107 27 / var(--tw-bg-opacity)) !important;
}

.bg-srm-12\/0{
    background-color: rgb(200 107 27 / 0) !important;
}

.bg-srm-12\/10{
    background-color: rgb(200 107 27 / 0.1) !important;
}

.bg-srm-12\/100{
    background-color: rgb(200 107 27 / 1) !important;
}

.bg-srm-12\/15{
    background-color: rgb(200 107 27 / 0.15) !important;
}

.bg-srm-12\/20{
    background-color: rgb(200 107 27 / 0.2) !important;
}

.bg-srm-12\/25{
    background-color: rgb(200 107 27 / 0.25) !important;
}

.bg-srm-12\/30{
    background-color: rgb(200 107 27 / 0.3) !important;
}

.bg-srm-12\/35{
    background-color: rgb(200 107 27 / 0.35) !important;
}

.bg-srm-12\/40{
    background-color: rgb(200 107 27 / 0.4) !important;
}

.bg-srm-12\/45{
    background-color: rgb(200 107 27 / 0.45) !important;
}

.bg-srm-12\/5{
    background-color: rgb(200 107 27 / 0.05) !important;
}

.bg-srm-12\/50{
    background-color: rgb(200 107 27 / 0.5) !important;
}

.bg-srm-12\/55{
    background-color: rgb(200 107 27 / 0.55) !important;
}

.bg-srm-12\/60{
    background-color: rgb(200 107 27 / 0.6) !important;
}

.bg-srm-12\/65{
    background-color: rgb(200 107 27 / 0.65) !important;
}

.bg-srm-12\/70{
    background-color: rgb(200 107 27 / 0.7) !important;
}

.bg-srm-12\/75{
    background-color: rgb(200 107 27 / 0.75) !important;
}

.bg-srm-12\/80{
    background-color: rgb(200 107 27 / 0.8) !important;
}

.bg-srm-12\/85{
    background-color: rgb(200 107 27 / 0.85) !important;
}

.bg-srm-12\/90{
    background-color: rgb(200 107 27 / 0.9) !important;
}

.bg-srm-12\/95{
    background-color: rgb(200 107 27 / 0.95) !important;
}

.bg-srm-13{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(192 87 39 / var(--tw-bg-opacity)) !important;
}

.bg-srm-13\/0{
    background-color: rgb(192 87 39 / 0) !important;
}

.bg-srm-13\/10{
    background-color: rgb(192 87 39 / 0.1) !important;
}

.bg-srm-13\/100{
    background-color: rgb(192 87 39 / 1) !important;
}

.bg-srm-13\/15{
    background-color: rgb(192 87 39 / 0.15) !important;
}

.bg-srm-13\/20{
    background-color: rgb(192 87 39 / 0.2) !important;
}

.bg-srm-13\/25{
    background-color: rgb(192 87 39 / 0.25) !important;
}

.bg-srm-13\/30{
    background-color: rgb(192 87 39 / 0.3) !important;
}

.bg-srm-13\/35{
    background-color: rgb(192 87 39 / 0.35) !important;
}

.bg-srm-13\/40{
    background-color: rgb(192 87 39 / 0.4) !important;
}

.bg-srm-13\/45{
    background-color: rgb(192 87 39 / 0.45) !important;
}

.bg-srm-13\/5{
    background-color: rgb(192 87 39 / 0.05) !important;
}

.bg-srm-13\/50{
    background-color: rgb(192 87 39 / 0.5) !important;
}

.bg-srm-13\/55{
    background-color: rgb(192 87 39 / 0.55) !important;
}

.bg-srm-13\/60{
    background-color: rgb(192 87 39 / 0.6) !important;
}

.bg-srm-13\/65{
    background-color: rgb(192 87 39 / 0.65) !important;
}

.bg-srm-13\/70{
    background-color: rgb(192 87 39 / 0.7) !important;
}

.bg-srm-13\/75{
    background-color: rgb(192 87 39 / 0.75) !important;
}

.bg-srm-13\/80{
    background-color: rgb(192 87 39 / 0.8) !important;
}

.bg-srm-13\/85{
    background-color: rgb(192 87 39 / 0.85) !important;
}

.bg-srm-13\/90{
    background-color: rgb(192 87 39 / 0.9) !important;
}

.bg-srm-13\/95{
    background-color: rgb(192 87 39 / 0.95) !important;
}

.bg-srm-14{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(173 68 23 / var(--tw-bg-opacity)) !important;
}

.bg-srm-14\/0{
    background-color: rgb(173 68 23 / 0) !important;
}

.bg-srm-14\/10{
    background-color: rgb(173 68 23 / 0.1) !important;
}

.bg-srm-14\/100{
    background-color: rgb(173 68 23 / 1) !important;
}

.bg-srm-14\/15{
    background-color: rgb(173 68 23 / 0.15) !important;
}

.bg-srm-14\/20{
    background-color: rgb(173 68 23 / 0.2) !important;
}

.bg-srm-14\/25{
    background-color: rgb(173 68 23 / 0.25) !important;
}

.bg-srm-14\/30{
    background-color: rgb(173 68 23 / 0.3) !important;
}

.bg-srm-14\/35{
    background-color: rgb(173 68 23 / 0.35) !important;
}

.bg-srm-14\/40{
    background-color: rgb(173 68 23 / 0.4) !important;
}

.bg-srm-14\/45{
    background-color: rgb(173 68 23 / 0.45) !important;
}

.bg-srm-14\/5{
    background-color: rgb(173 68 23 / 0.05) !important;
}

.bg-srm-14\/50{
    background-color: rgb(173 68 23 / 0.5) !important;
}

.bg-srm-14\/55{
    background-color: rgb(173 68 23 / 0.55) !important;
}

.bg-srm-14\/60{
    background-color: rgb(173 68 23 / 0.6) !important;
}

.bg-srm-14\/65{
    background-color: rgb(173 68 23 / 0.65) !important;
}

.bg-srm-14\/70{
    background-color: rgb(173 68 23 / 0.7) !important;
}

.bg-srm-14\/75{
    background-color: rgb(173 68 23 / 0.75) !important;
}

.bg-srm-14\/80{
    background-color: rgb(173 68 23 / 0.8) !important;
}

.bg-srm-14\/85{
    background-color: rgb(173 68 23 / 0.85) !important;
}

.bg-srm-14\/90{
    background-color: rgb(173 68 23 / 0.9) !important;
}

.bg-srm-14\/95{
    background-color: rgb(173 68 23 / 0.95) !important;
}

.bg-srm-15{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(174 72 24 / var(--tw-bg-opacity)) !important;
}

.bg-srm-15\/0{
    background-color: rgb(174 72 24 / 0) !important;
}

.bg-srm-15\/10{
    background-color: rgb(174 72 24 / 0.1) !important;
}

.bg-srm-15\/100{
    background-color: rgb(174 72 24 / 1) !important;
}

.bg-srm-15\/15{
    background-color: rgb(174 72 24 / 0.15) !important;
}

.bg-srm-15\/20{
    background-color: rgb(174 72 24 / 0.2) !important;
}

.bg-srm-15\/25{
    background-color: rgb(174 72 24 / 0.25) !important;
}

.bg-srm-15\/30{
    background-color: rgb(174 72 24 / 0.3) !important;
}

.bg-srm-15\/35{
    background-color: rgb(174 72 24 / 0.35) !important;
}

.bg-srm-15\/40{
    background-color: rgb(174 72 24 / 0.4) !important;
}

.bg-srm-15\/45{
    background-color: rgb(174 72 24 / 0.45) !important;
}

.bg-srm-15\/5{
    background-color: rgb(174 72 24 / 0.05) !important;
}

.bg-srm-15\/50{
    background-color: rgb(174 72 24 / 0.5) !important;
}

.bg-srm-15\/55{
    background-color: rgb(174 72 24 / 0.55) !important;
}

.bg-srm-15\/60{
    background-color: rgb(174 72 24 / 0.6) !important;
}

.bg-srm-15\/65{
    background-color: rgb(174 72 24 / 0.65) !important;
}

.bg-srm-15\/70{
    background-color: rgb(174 72 24 / 0.7) !important;
}

.bg-srm-15\/75{
    background-color: rgb(174 72 24 / 0.75) !important;
}

.bg-srm-15\/80{
    background-color: rgb(174 72 24 / 0.8) !important;
}

.bg-srm-15\/85{
    background-color: rgb(174 72 24 / 0.85) !important;
}

.bg-srm-15\/90{
    background-color: rgb(174 72 24 / 0.9) !important;
}

.bg-srm-15\/95{
    background-color: rgb(174 72 24 / 0.95) !important;
}

.bg-srm-16{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(173 68 23 / var(--tw-bg-opacity)) !important;
}

.bg-srm-16\/0{
    background-color: rgb(173 68 23 / 0) !important;
}

.bg-srm-16\/10{
    background-color: rgb(173 68 23 / 0.1) !important;
}

.bg-srm-16\/100{
    background-color: rgb(173 68 23 / 1) !important;
}

.bg-srm-16\/15{
    background-color: rgb(173 68 23 / 0.15) !important;
}

.bg-srm-16\/20{
    background-color: rgb(173 68 23 / 0.2) !important;
}

.bg-srm-16\/25{
    background-color: rgb(173 68 23 / 0.25) !important;
}

.bg-srm-16\/30{
    background-color: rgb(173 68 23 / 0.3) !important;
}

.bg-srm-16\/35{
    background-color: rgb(173 68 23 / 0.35) !important;
}

.bg-srm-16\/40{
    background-color: rgb(173 68 23 / 0.4) !important;
}

.bg-srm-16\/45{
    background-color: rgb(173 68 23 / 0.45) !important;
}

.bg-srm-16\/5{
    background-color: rgb(173 68 23 / 0.05) !important;
}

.bg-srm-16\/50{
    background-color: rgb(173 68 23 / 0.5) !important;
}

.bg-srm-16\/55{
    background-color: rgb(173 68 23 / 0.55) !important;
}

.bg-srm-16\/60{
    background-color: rgb(173 68 23 / 0.6) !important;
}

.bg-srm-16\/65{
    background-color: rgb(173 68 23 / 0.65) !important;
}

.bg-srm-16\/70{
    background-color: rgb(173 68 23 / 0.7) !important;
}

.bg-srm-16\/75{
    background-color: rgb(173 68 23 / 0.75) !important;
}

.bg-srm-16\/80{
    background-color: rgb(173 68 23 / 0.8) !important;
}

.bg-srm-16\/85{
    background-color: rgb(173 68 23 / 0.85) !important;
}

.bg-srm-16\/90{
    background-color: rgb(173 68 23 / 0.9) !important;
}

.bg-srm-16\/95{
    background-color: rgb(173 68 23 / 0.95) !important;
}

.bg-srm-17{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(167 61 21 / var(--tw-bg-opacity)) !important;
}

.bg-srm-17\/0{
    background-color: rgb(167 61 21 / 0) !important;
}

.bg-srm-17\/10{
    background-color: rgb(167 61 21 / 0.1) !important;
}

.bg-srm-17\/100{
    background-color: rgb(167 61 21 / 1) !important;
}

.bg-srm-17\/15{
    background-color: rgb(167 61 21 / 0.15) !important;
}

.bg-srm-17\/20{
    background-color: rgb(167 61 21 / 0.2) !important;
}

.bg-srm-17\/25{
    background-color: rgb(167 61 21 / 0.25) !important;
}

.bg-srm-17\/30{
    background-color: rgb(167 61 21 / 0.3) !important;
}

.bg-srm-17\/35{
    background-color: rgb(167 61 21 / 0.35) !important;
}

.bg-srm-17\/40{
    background-color: rgb(167 61 21 / 0.4) !important;
}

.bg-srm-17\/45{
    background-color: rgb(167 61 21 / 0.45) !important;
}

.bg-srm-17\/5{
    background-color: rgb(167 61 21 / 0.05) !important;
}

.bg-srm-17\/50{
    background-color: rgb(167 61 21 / 0.5) !important;
}

.bg-srm-17\/55{
    background-color: rgb(167 61 21 / 0.55) !important;
}

.bg-srm-17\/60{
    background-color: rgb(167 61 21 / 0.6) !important;
}

.bg-srm-17\/65{
    background-color: rgb(167 61 21 / 0.65) !important;
}

.bg-srm-17\/70{
    background-color: rgb(167 61 21 / 0.7) !important;
}

.bg-srm-17\/75{
    background-color: rgb(167 61 21 / 0.75) !important;
}

.bg-srm-17\/80{
    background-color: rgb(167 61 21 / 0.8) !important;
}

.bg-srm-17\/85{
    background-color: rgb(167 61 21 / 0.85) !important;
}

.bg-srm-17\/90{
    background-color: rgb(167 61 21 / 0.9) !important;
}

.bg-srm-17\/95{
    background-color: rgb(167 61 21 / 0.95) !important;
}

.bg-srm-18{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(162 58 21 / var(--tw-bg-opacity)) !important;
}

.bg-srm-18\/0{
    background-color: rgb(162 58 21 / 0) !important;
}

.bg-srm-18\/10{
    background-color: rgb(162 58 21 / 0.1) !important;
}

.bg-srm-18\/100{
    background-color: rgb(162 58 21 / 1) !important;
}

.bg-srm-18\/15{
    background-color: rgb(162 58 21 / 0.15) !important;
}

.bg-srm-18\/20{
    background-color: rgb(162 58 21 / 0.2) !important;
}

.bg-srm-18\/25{
    background-color: rgb(162 58 21 / 0.25) !important;
}

.bg-srm-18\/30{
    background-color: rgb(162 58 21 / 0.3) !important;
}

.bg-srm-18\/35{
    background-color: rgb(162 58 21 / 0.35) !important;
}

.bg-srm-18\/40{
    background-color: rgb(162 58 21 / 0.4) !important;
}

.bg-srm-18\/45{
    background-color: rgb(162 58 21 / 0.45) !important;
}

.bg-srm-18\/5{
    background-color: rgb(162 58 21 / 0.05) !important;
}

.bg-srm-18\/50{
    background-color: rgb(162 58 21 / 0.5) !important;
}

.bg-srm-18\/55{
    background-color: rgb(162 58 21 / 0.55) !important;
}

.bg-srm-18\/60{
    background-color: rgb(162 58 21 / 0.6) !important;
}

.bg-srm-18\/65{
    background-color: rgb(162 58 21 / 0.65) !important;
}

.bg-srm-18\/70{
    background-color: rgb(162 58 21 / 0.7) !important;
}

.bg-srm-18\/75{
    background-color: rgb(162 58 21 / 0.75) !important;
}

.bg-srm-18\/80{
    background-color: rgb(162 58 21 / 0.8) !important;
}

.bg-srm-18\/85{
    background-color: rgb(162 58 21 / 0.85) !important;
}

.bg-srm-18\/90{
    background-color: rgb(162 58 21 / 0.9) !important;
}

.bg-srm-18\/95{
    background-color: rgb(162 58 21 / 0.95) !important;
}

.bg-srm-19{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(157 52 20 / var(--tw-bg-opacity)) !important;
}

.bg-srm-19\/0{
    background-color: rgb(157 52 20 / 0) !important;
}

.bg-srm-19\/10{
    background-color: rgb(157 52 20 / 0.1) !important;
}

.bg-srm-19\/100{
    background-color: rgb(157 52 20 / 1) !important;
}

.bg-srm-19\/15{
    background-color: rgb(157 52 20 / 0.15) !important;
}

.bg-srm-19\/20{
    background-color: rgb(157 52 20 / 0.2) !important;
}

.bg-srm-19\/25{
    background-color: rgb(157 52 20 / 0.25) !important;
}

.bg-srm-19\/30{
    background-color: rgb(157 52 20 / 0.3) !important;
}

.bg-srm-19\/35{
    background-color: rgb(157 52 20 / 0.35) !important;
}

.bg-srm-19\/40{
    background-color: rgb(157 52 20 / 0.4) !important;
}

.bg-srm-19\/45{
    background-color: rgb(157 52 20 / 0.45) !important;
}

.bg-srm-19\/5{
    background-color: rgb(157 52 20 / 0.05) !important;
}

.bg-srm-19\/50{
    background-color: rgb(157 52 20 / 0.5) !important;
}

.bg-srm-19\/55{
    background-color: rgb(157 52 20 / 0.55) !important;
}

.bg-srm-19\/60{
    background-color: rgb(157 52 20 / 0.6) !important;
}

.bg-srm-19\/65{
    background-color: rgb(157 52 20 / 0.65) !important;
}

.bg-srm-19\/70{
    background-color: rgb(157 52 20 / 0.7) !important;
}

.bg-srm-19\/75{
    background-color: rgb(157 52 20 / 0.75) !important;
}

.bg-srm-19\/80{
    background-color: rgb(157 52 20 / 0.8) !important;
}

.bg-srm-19\/85{
    background-color: rgb(157 52 20 / 0.85) !important;
}

.bg-srm-19\/90{
    background-color: rgb(157 52 20 / 0.9) !important;
}

.bg-srm-19\/95{
    background-color: rgb(157 52 20 / 0.95) !important;
}

.bg-srm-2{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(249 224 108 / var(--tw-bg-opacity)) !important;
}

.bg-srm-2\/0{
    background-color: rgb(249 224 108 / 0) !important;
}

.bg-srm-2\/10{
    background-color: rgb(249 224 108 / 0.1) !important;
}

.bg-srm-2\/100{
    background-color: rgb(249 224 108 / 1) !important;
}

.bg-srm-2\/15{
    background-color: rgb(249 224 108 / 0.15) !important;
}

.bg-srm-2\/20{
    background-color: rgb(249 224 108 / 0.2) !important;
}

.bg-srm-2\/25{
    background-color: rgb(249 224 108 / 0.25) !important;
}

.bg-srm-2\/30{
    background-color: rgb(249 224 108 / 0.3) !important;
}

.bg-srm-2\/35{
    background-color: rgb(249 224 108 / 0.35) !important;
}

.bg-srm-2\/40{
    background-color: rgb(249 224 108 / 0.4) !important;
}

.bg-srm-2\/45{
    background-color: rgb(249 224 108 / 0.45) !important;
}

.bg-srm-2\/5{
    background-color: rgb(249 224 108 / 0.05) !important;
}

.bg-srm-2\/50{
    background-color: rgb(249 224 108 / 0.5) !important;
}

.bg-srm-2\/55{
    background-color: rgb(249 224 108 / 0.55) !important;
}

.bg-srm-2\/60{
    background-color: rgb(249 224 108 / 0.6) !important;
}

.bg-srm-2\/65{
    background-color: rgb(249 224 108 / 0.65) !important;
}

.bg-srm-2\/70{
    background-color: rgb(249 224 108 / 0.7) !important;
}

.bg-srm-2\/75{
    background-color: rgb(249 224 108 / 0.75) !important;
}

.bg-srm-2\/80{
    background-color: rgb(249 224 108 / 0.8) !important;
}

.bg-srm-2\/85{
    background-color: rgb(249 224 108 / 0.85) !important;
}

.bg-srm-2\/90{
    background-color: rgb(249 224 108 / 0.9) !important;
}

.bg-srm-2\/95{
    background-color: rgb(249 224 108 / 0.95) !important;
}

.bg-srm-20{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(152 48 21 / var(--tw-bg-opacity)) !important;
}

.bg-srm-20\/0{
    background-color: rgb(152 48 21 / 0) !important;
}

.bg-srm-20\/10{
    background-color: rgb(152 48 21 / 0.1) !important;
}

.bg-srm-20\/100{
    background-color: rgb(152 48 21 / 1) !important;
}

.bg-srm-20\/15{
    background-color: rgb(152 48 21 / 0.15) !important;
}

.bg-srm-20\/20{
    background-color: rgb(152 48 21 / 0.2) !important;
}

.bg-srm-20\/25{
    background-color: rgb(152 48 21 / 0.25) !important;
}

.bg-srm-20\/30{
    background-color: rgb(152 48 21 / 0.3) !important;
}

.bg-srm-20\/35{
    background-color: rgb(152 48 21 / 0.35) !important;
}

.bg-srm-20\/40{
    background-color: rgb(152 48 21 / 0.4) !important;
}

.bg-srm-20\/45{
    background-color: rgb(152 48 21 / 0.45) !important;
}

.bg-srm-20\/5{
    background-color: rgb(152 48 21 / 0.05) !important;
}

.bg-srm-20\/50{
    background-color: rgb(152 48 21 / 0.5) !important;
}

.bg-srm-20\/55{
    background-color: rgb(152 48 21 / 0.55) !important;
}

.bg-srm-20\/60{
    background-color: rgb(152 48 21 / 0.6) !important;
}

.bg-srm-20\/65{
    background-color: rgb(152 48 21 / 0.65) !important;
}

.bg-srm-20\/70{
    background-color: rgb(152 48 21 / 0.7) !important;
}

.bg-srm-20\/75{
    background-color: rgb(152 48 21 / 0.75) !important;
}

.bg-srm-20\/80{
    background-color: rgb(152 48 21 / 0.8) !important;
}

.bg-srm-20\/85{
    background-color: rgb(152 48 21 / 0.85) !important;
}

.bg-srm-20\/90{
    background-color: rgb(152 48 21 / 0.9) !important;
}

.bg-srm-20\/95{
    background-color: rgb(152 48 21 / 0.95) !important;
}

.bg-srm-21{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(147 42 20 / var(--tw-bg-opacity)) !important;
}

.bg-srm-21\/0{
    background-color: rgb(147 42 20 / 0) !important;
}

.bg-srm-21\/10{
    background-color: rgb(147 42 20 / 0.1) !important;
}

.bg-srm-21\/100{
    background-color: rgb(147 42 20 / 1) !important;
}

.bg-srm-21\/15{
    background-color: rgb(147 42 20 / 0.15) !important;
}

.bg-srm-21\/20{
    background-color: rgb(147 42 20 / 0.2) !important;
}

.bg-srm-21\/25{
    background-color: rgb(147 42 20 / 0.25) !important;
}

.bg-srm-21\/30{
    background-color: rgb(147 42 20 / 0.3) !important;
}

.bg-srm-21\/35{
    background-color: rgb(147 42 20 / 0.35) !important;
}

.bg-srm-21\/40{
    background-color: rgb(147 42 20 / 0.4) !important;
}

.bg-srm-21\/45{
    background-color: rgb(147 42 20 / 0.45) !important;
}

.bg-srm-21\/5{
    background-color: rgb(147 42 20 / 0.05) !important;
}

.bg-srm-21\/50{
    background-color: rgb(147 42 20 / 0.5) !important;
}

.bg-srm-21\/55{
    background-color: rgb(147 42 20 / 0.55) !important;
}

.bg-srm-21\/60{
    background-color: rgb(147 42 20 / 0.6) !important;
}

.bg-srm-21\/65{
    background-color: rgb(147 42 20 / 0.65) !important;
}

.bg-srm-21\/70{
    background-color: rgb(147 42 20 / 0.7) !important;
}

.bg-srm-21\/75{
    background-color: rgb(147 42 20 / 0.75) !important;
}

.bg-srm-21\/80{
    background-color: rgb(147 42 20 / 0.8) !important;
}

.bg-srm-21\/85{
    background-color: rgb(147 42 20 / 0.85) !important;
}

.bg-srm-21\/90{
    background-color: rgb(147 42 20 / 0.9) !important;
}

.bg-srm-21\/95{
    background-color: rgb(147 42 20 / 0.95) !important;
}

.bg-srm-22{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(141 38 21 / var(--tw-bg-opacity)) !important;
}

.bg-srm-22\/0{
    background-color: rgb(141 38 21 / 0) !important;
}

.bg-srm-22\/10{
    background-color: rgb(141 38 21 / 0.1) !important;
}

.bg-srm-22\/100{
    background-color: rgb(141 38 21 / 1) !important;
}

.bg-srm-22\/15{
    background-color: rgb(141 38 21 / 0.15) !important;
}

.bg-srm-22\/20{
    background-color: rgb(141 38 21 / 0.2) !important;
}

.bg-srm-22\/25{
    background-color: rgb(141 38 21 / 0.25) !important;
}

.bg-srm-22\/30{
    background-color: rgb(141 38 21 / 0.3) !important;
}

.bg-srm-22\/35{
    background-color: rgb(141 38 21 / 0.35) !important;
}

.bg-srm-22\/40{
    background-color: rgb(141 38 21 / 0.4) !important;
}

.bg-srm-22\/45{
    background-color: rgb(141 38 21 / 0.45) !important;
}

.bg-srm-22\/5{
    background-color: rgb(141 38 21 / 0.05) !important;
}

.bg-srm-22\/50{
    background-color: rgb(141 38 21 / 0.5) !important;
}

.bg-srm-22\/55{
    background-color: rgb(141 38 21 / 0.55) !important;
}

.bg-srm-22\/60{
    background-color: rgb(141 38 21 / 0.6) !important;
}

.bg-srm-22\/65{
    background-color: rgb(141 38 21 / 0.65) !important;
}

.bg-srm-22\/70{
    background-color: rgb(141 38 21 / 0.7) !important;
}

.bg-srm-22\/75{
    background-color: rgb(141 38 21 / 0.75) !important;
}

.bg-srm-22\/80{
    background-color: rgb(141 38 21 / 0.8) !important;
}

.bg-srm-22\/85{
    background-color: rgb(141 38 21 / 0.85) !important;
}

.bg-srm-22\/90{
    background-color: rgb(141 38 21 / 0.9) !important;
}

.bg-srm-22\/95{
    background-color: rgb(141 38 21 / 0.95) !important;
}

.bg-srm-23{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(137 37 21 / var(--tw-bg-opacity)) !important;
}

.bg-srm-23\/0{
    background-color: rgb(137 37 21 / 0) !important;
}

.bg-srm-23\/10{
    background-color: rgb(137 37 21 / 0.1) !important;
}

.bg-srm-23\/100{
    background-color: rgb(137 37 21 / 1) !important;
}

.bg-srm-23\/15{
    background-color: rgb(137 37 21 / 0.15) !important;
}

.bg-srm-23\/20{
    background-color: rgb(137 37 21 / 0.2) !important;
}

.bg-srm-23\/25{
    background-color: rgb(137 37 21 / 0.25) !important;
}

.bg-srm-23\/30{
    background-color: rgb(137 37 21 / 0.3) !important;
}

.bg-srm-23\/35{
    background-color: rgb(137 37 21 / 0.35) !important;
}

.bg-srm-23\/40{
    background-color: rgb(137 37 21 / 0.4) !important;
}

.bg-srm-23\/45{
    background-color: rgb(137 37 21 / 0.45) !important;
}

.bg-srm-23\/5{
    background-color: rgb(137 37 21 / 0.05) !important;
}

.bg-srm-23\/50{
    background-color: rgb(137 37 21 / 0.5) !important;
}

.bg-srm-23\/55{
    background-color: rgb(137 37 21 / 0.55) !important;
}

.bg-srm-23\/60{
    background-color: rgb(137 37 21 / 0.6) !important;
}

.bg-srm-23\/65{
    background-color: rgb(137 37 21 / 0.65) !important;
}

.bg-srm-23\/70{
    background-color: rgb(137 37 21 / 0.7) !important;
}

.bg-srm-23\/75{
    background-color: rgb(137 37 21 / 0.75) !important;
}

.bg-srm-23\/80{
    background-color: rgb(137 37 21 / 0.8) !important;
}

.bg-srm-23\/85{
    background-color: rgb(137 37 21 / 0.85) !important;
}

.bg-srm-23\/90{
    background-color: rgb(137 37 21 / 0.9) !important;
}

.bg-srm-23\/95{
    background-color: rgb(137 37 21 / 0.95) !important;
}

.bg-srm-24{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(131 34 18 / var(--tw-bg-opacity)) !important;
}

.bg-srm-24\/0{
    background-color: rgb(131 34 18 / 0) !important;
}

.bg-srm-24\/10{
    background-color: rgb(131 34 18 / 0.1) !important;
}

.bg-srm-24\/100{
    background-color: rgb(131 34 18 / 1) !important;
}

.bg-srm-24\/15{
    background-color: rgb(131 34 18 / 0.15) !important;
}

.bg-srm-24\/20{
    background-color: rgb(131 34 18 / 0.2) !important;
}

.bg-srm-24\/25{
    background-color: rgb(131 34 18 / 0.25) !important;
}

.bg-srm-24\/30{
    background-color: rgb(131 34 18 / 0.3) !important;
}

.bg-srm-24\/35{
    background-color: rgb(131 34 18 / 0.35) !important;
}

.bg-srm-24\/40{
    background-color: rgb(131 34 18 / 0.4) !important;
}

.bg-srm-24\/45{
    background-color: rgb(131 34 18 / 0.45) !important;
}

.bg-srm-24\/5{
    background-color: rgb(131 34 18 / 0.05) !important;
}

.bg-srm-24\/50{
    background-color: rgb(131 34 18 / 0.5) !important;
}

.bg-srm-24\/55{
    background-color: rgb(131 34 18 / 0.55) !important;
}

.bg-srm-24\/60{
    background-color: rgb(131 34 18 / 0.6) !important;
}

.bg-srm-24\/65{
    background-color: rgb(131 34 18 / 0.65) !important;
}

.bg-srm-24\/70{
    background-color: rgb(131 34 18 / 0.7) !important;
}

.bg-srm-24\/75{
    background-color: rgb(131 34 18 / 0.75) !important;
}

.bg-srm-24\/80{
    background-color: rgb(131 34 18 / 0.8) !important;
}

.bg-srm-24\/85{
    background-color: rgb(131 34 18 / 0.85) !important;
}

.bg-srm-24\/90{
    background-color: rgb(131 34 18 / 0.9) !important;
}

.bg-srm-24\/95{
    background-color: rgb(131 34 18 / 0.95) !important;
}

.bg-srm-25{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(125 32 15 / var(--tw-bg-opacity)) !important;
}

.bg-srm-25\/0{
    background-color: rgb(125 32 15 / 0) !important;
}

.bg-srm-25\/10{
    background-color: rgb(125 32 15 / 0.1) !important;
}

.bg-srm-25\/100{
    background-color: rgb(125 32 15 / 1) !important;
}

.bg-srm-25\/15{
    background-color: rgb(125 32 15 / 0.15) !important;
}

.bg-srm-25\/20{
    background-color: rgb(125 32 15 / 0.2) !important;
}

.bg-srm-25\/25{
    background-color: rgb(125 32 15 / 0.25) !important;
}

.bg-srm-25\/30{
    background-color: rgb(125 32 15 / 0.3) !important;
}

.bg-srm-25\/35{
    background-color: rgb(125 32 15 / 0.35) !important;
}

.bg-srm-25\/40{
    background-color: rgb(125 32 15 / 0.4) !important;
}

.bg-srm-25\/45{
    background-color: rgb(125 32 15 / 0.45) !important;
}

.bg-srm-25\/5{
    background-color: rgb(125 32 15 / 0.05) !important;
}

.bg-srm-25\/50{
    background-color: rgb(125 32 15 / 0.5) !important;
}

.bg-srm-25\/55{
    background-color: rgb(125 32 15 / 0.55) !important;
}

.bg-srm-25\/60{
    background-color: rgb(125 32 15 / 0.6) !important;
}

.bg-srm-25\/65{
    background-color: rgb(125 32 15 / 0.65) !important;
}

.bg-srm-25\/70{
    background-color: rgb(125 32 15 / 0.7) !important;
}

.bg-srm-25\/75{
    background-color: rgb(125 32 15 / 0.75) !important;
}

.bg-srm-25\/80{
    background-color: rgb(125 32 15 / 0.8) !important;
}

.bg-srm-25\/85{
    background-color: rgb(125 32 15 / 0.85) !important;
}

.bg-srm-25\/90{
    background-color: rgb(125 32 15 / 0.9) !important;
}

.bg-srm-25\/95{
    background-color: rgb(125 32 15 / 0.95) !important;
}

.bg-srm-26{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(119 30 14 / var(--tw-bg-opacity)) !important;
}

.bg-srm-26\/0{
    background-color: rgb(119 30 14 / 0) !important;
}

.bg-srm-26\/10{
    background-color: rgb(119 30 14 / 0.1) !important;
}

.bg-srm-26\/100{
    background-color: rgb(119 30 14 / 1) !important;
}

.bg-srm-26\/15{
    background-color: rgb(119 30 14 / 0.15) !important;
}

.bg-srm-26\/20{
    background-color: rgb(119 30 14 / 0.2) !important;
}

.bg-srm-26\/25{
    background-color: rgb(119 30 14 / 0.25) !important;
}

.bg-srm-26\/30{
    background-color: rgb(119 30 14 / 0.3) !important;
}

.bg-srm-26\/35{
    background-color: rgb(119 30 14 / 0.35) !important;
}

.bg-srm-26\/40{
    background-color: rgb(119 30 14 / 0.4) !important;
}

.bg-srm-26\/45{
    background-color: rgb(119 30 14 / 0.45) !important;
}

.bg-srm-26\/5{
    background-color: rgb(119 30 14 / 0.05) !important;
}

.bg-srm-26\/50{
    background-color: rgb(119 30 14 / 0.5) !important;
}

.bg-srm-26\/55{
    background-color: rgb(119 30 14 / 0.55) !important;
}

.bg-srm-26\/60{
    background-color: rgb(119 30 14 / 0.6) !important;
}

.bg-srm-26\/65{
    background-color: rgb(119 30 14 / 0.65) !important;
}

.bg-srm-26\/70{
    background-color: rgb(119 30 14 / 0.7) !important;
}

.bg-srm-26\/75{
    background-color: rgb(119 30 14 / 0.75) !important;
}

.bg-srm-26\/80{
    background-color: rgb(119 30 14 / 0.8) !important;
}

.bg-srm-26\/85{
    background-color: rgb(119 30 14 / 0.85) !important;
}

.bg-srm-26\/90{
    background-color: rgb(119 30 14 / 0.9) !important;
}

.bg-srm-26\/95{
    background-color: rgb(119 30 14 / 0.95) !important;
}

.bg-srm-27{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(115 28 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-27\/0{
    background-color: rgb(115 28 11 / 0) !important;
}

.bg-srm-27\/10{
    background-color: rgb(115 28 11 / 0.1) !important;
}

.bg-srm-27\/100{
    background-color: rgb(115 28 11 / 1) !important;
}

.bg-srm-27\/15{
    background-color: rgb(115 28 11 / 0.15) !important;
}

.bg-srm-27\/20{
    background-color: rgb(115 28 11 / 0.2) !important;
}

.bg-srm-27\/25{
    background-color: rgb(115 28 11 / 0.25) !important;
}

.bg-srm-27\/30{
    background-color: rgb(115 28 11 / 0.3) !important;
}

.bg-srm-27\/35{
    background-color: rgb(115 28 11 / 0.35) !important;
}

.bg-srm-27\/40{
    background-color: rgb(115 28 11 / 0.4) !important;
}

.bg-srm-27\/45{
    background-color: rgb(115 28 11 / 0.45) !important;
}

.bg-srm-27\/5{
    background-color: rgb(115 28 11 / 0.05) !important;
}

.bg-srm-27\/50{
    background-color: rgb(115 28 11 / 0.5) !important;
}

.bg-srm-27\/55{
    background-color: rgb(115 28 11 / 0.55) !important;
}

.bg-srm-27\/60{
    background-color: rgb(115 28 11 / 0.6) !important;
}

.bg-srm-27\/65{
    background-color: rgb(115 28 11 / 0.65) !important;
}

.bg-srm-27\/70{
    background-color: rgb(115 28 11 / 0.7) !important;
}

.bg-srm-27\/75{
    background-color: rgb(115 28 11 / 0.75) !important;
}

.bg-srm-27\/80{
    background-color: rgb(115 28 11 / 0.8) !important;
}

.bg-srm-27\/85{
    background-color: rgb(115 28 11 / 0.85) !important;
}

.bg-srm-27\/90{
    background-color: rgb(115 28 11 / 0.9) !important;
}

.bg-srm-27\/95{
    background-color: rgb(115 28 11 / 0.95) !important;
}

.bg-srm-28{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(112 24 12 / var(--tw-bg-opacity)) !important;
}

.bg-srm-28\/0{
    background-color: rgb(112 24 12 / 0) !important;
}

.bg-srm-28\/10{
    background-color: rgb(112 24 12 / 0.1) !important;
}

.bg-srm-28\/100{
    background-color: rgb(112 24 12 / 1) !important;
}

.bg-srm-28\/15{
    background-color: rgb(112 24 12 / 0.15) !important;
}

.bg-srm-28\/20{
    background-color: rgb(112 24 12 / 0.2) !important;
}

.bg-srm-28\/25{
    background-color: rgb(112 24 12 / 0.25) !important;
}

.bg-srm-28\/30{
    background-color: rgb(112 24 12 / 0.3) !important;
}

.bg-srm-28\/35{
    background-color: rgb(112 24 12 / 0.35) !important;
}

.bg-srm-28\/40{
    background-color: rgb(112 24 12 / 0.4) !important;
}

.bg-srm-28\/45{
    background-color: rgb(112 24 12 / 0.45) !important;
}

.bg-srm-28\/5{
    background-color: rgb(112 24 12 / 0.05) !important;
}

.bg-srm-28\/50{
    background-color: rgb(112 24 12 / 0.5) !important;
}

.bg-srm-28\/55{
    background-color: rgb(112 24 12 / 0.55) !important;
}

.bg-srm-28\/60{
    background-color: rgb(112 24 12 / 0.6) !important;
}

.bg-srm-28\/65{
    background-color: rgb(112 24 12 / 0.65) !important;
}

.bg-srm-28\/70{
    background-color: rgb(112 24 12 / 0.7) !important;
}

.bg-srm-28\/75{
    background-color: rgb(112 24 12 / 0.75) !important;
}

.bg-srm-28\/80{
    background-color: rgb(112 24 12 / 0.8) !important;
}

.bg-srm-28\/85{
    background-color: rgb(112 24 12 / 0.85) !important;
}

.bg-srm-28\/90{
    background-color: rgb(112 24 12 / 0.9) !important;
}

.bg-srm-28\/95{
    background-color: rgb(112 24 12 / 0.95) !important;
}

.bg-srm-29{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(106 22 12 / var(--tw-bg-opacity)) !important;
}

.bg-srm-29\/0{
    background-color: rgb(106 22 12 / 0) !important;
}

.bg-srm-29\/10{
    background-color: rgb(106 22 12 / 0.1) !important;
}

.bg-srm-29\/100{
    background-color: rgb(106 22 12 / 1) !important;
}

.bg-srm-29\/15{
    background-color: rgb(106 22 12 / 0.15) !important;
}

.bg-srm-29\/20{
    background-color: rgb(106 22 12 / 0.2) !important;
}

.bg-srm-29\/25{
    background-color: rgb(106 22 12 / 0.25) !important;
}

.bg-srm-29\/30{
    background-color: rgb(106 22 12 / 0.3) !important;
}

.bg-srm-29\/35{
    background-color: rgb(106 22 12 / 0.35) !important;
}

.bg-srm-29\/40{
    background-color: rgb(106 22 12 / 0.4) !important;
}

.bg-srm-29\/45{
    background-color: rgb(106 22 12 / 0.45) !important;
}

.bg-srm-29\/5{
    background-color: rgb(106 22 12 / 0.05) !important;
}

.bg-srm-29\/50{
    background-color: rgb(106 22 12 / 0.5) !important;
}

.bg-srm-29\/55{
    background-color: rgb(106 22 12 / 0.55) !important;
}

.bg-srm-29\/60{
    background-color: rgb(106 22 12 / 0.6) !important;
}

.bg-srm-29\/65{
    background-color: rgb(106 22 12 / 0.65) !important;
}

.bg-srm-29\/70{
    background-color: rgb(106 22 12 / 0.7) !important;
}

.bg-srm-29\/75{
    background-color: rgb(106 22 12 / 0.75) !important;
}

.bg-srm-29\/80{
    background-color: rgb(106 22 12 / 0.8) !important;
}

.bg-srm-29\/85{
    background-color: rgb(106 22 12 / 0.85) !important;
}

.bg-srm-29\/90{
    background-color: rgb(106 22 12 / 0.9) !important;
}

.bg-srm-29\/95{
    background-color: rgb(106 22 12 / 0.95) !important;
}

.bg-srm-3{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 206 81 / var(--tw-bg-opacity)) !important;
}

.bg-srm-3\/0{
    background-color: rgb(244 206 81 / 0) !important;
}

.bg-srm-3\/10{
    background-color: rgb(244 206 81 / 0.1) !important;
}

.bg-srm-3\/100{
    background-color: rgb(244 206 81 / 1) !important;
}

.bg-srm-3\/15{
    background-color: rgb(244 206 81 / 0.15) !important;
}

.bg-srm-3\/20{
    background-color: rgb(244 206 81 / 0.2) !important;
}

.bg-srm-3\/25{
    background-color: rgb(244 206 81 / 0.25) !important;
}

.bg-srm-3\/30{
    background-color: rgb(244 206 81 / 0.3) !important;
}

.bg-srm-3\/35{
    background-color: rgb(244 206 81 / 0.35) !important;
}

.bg-srm-3\/40{
    background-color: rgb(244 206 81 / 0.4) !important;
}

.bg-srm-3\/45{
    background-color: rgb(244 206 81 / 0.45) !important;
}

.bg-srm-3\/5{
    background-color: rgb(244 206 81 / 0.05) !important;
}

.bg-srm-3\/50{
    background-color: rgb(244 206 81 / 0.5) !important;
}

.bg-srm-3\/55{
    background-color: rgb(244 206 81 / 0.55) !important;
}

.bg-srm-3\/60{
    background-color: rgb(244 206 81 / 0.6) !important;
}

.bg-srm-3\/65{
    background-color: rgb(244 206 81 / 0.65) !important;
}

.bg-srm-3\/70{
    background-color: rgb(244 206 81 / 0.7) !important;
}

.bg-srm-3\/75{
    background-color: rgb(244 206 81 / 0.75) !important;
}

.bg-srm-3\/80{
    background-color: rgb(244 206 81 / 0.8) !important;
}

.bg-srm-3\/85{
    background-color: rgb(244 206 81 / 0.85) !important;
}

.bg-srm-3\/90{
    background-color: rgb(244 206 81 / 0.9) !important;
}

.bg-srm-3\/95{
    background-color: rgb(244 206 81 / 0.95) !important;
}

.bg-srm-30{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(103 18 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-30\/0{
    background-color: rgb(103 18 11 / 0) !important;
}

.bg-srm-30\/10{
    background-color: rgb(103 18 11 / 0.1) !important;
}

.bg-srm-30\/100{
    background-color: rgb(103 18 11 / 1) !important;
}

.bg-srm-30\/15{
    background-color: rgb(103 18 11 / 0.15) !important;
}

.bg-srm-30\/20{
    background-color: rgb(103 18 11 / 0.2) !important;
}

.bg-srm-30\/25{
    background-color: rgb(103 18 11 / 0.25) !important;
}

.bg-srm-30\/30{
    background-color: rgb(103 18 11 / 0.3) !important;
}

.bg-srm-30\/35{
    background-color: rgb(103 18 11 / 0.35) !important;
}

.bg-srm-30\/40{
    background-color: rgb(103 18 11 / 0.4) !important;
}

.bg-srm-30\/45{
    background-color: rgb(103 18 11 / 0.45) !important;
}

.bg-srm-30\/5{
    background-color: rgb(103 18 11 / 0.05) !important;
}

.bg-srm-30\/50{
    background-color: rgb(103 18 11 / 0.5) !important;
}

.bg-srm-30\/55{
    background-color: rgb(103 18 11 / 0.55) !important;
}

.bg-srm-30\/60{
    background-color: rgb(103 18 11 / 0.6) !important;
}

.bg-srm-30\/65{
    background-color: rgb(103 18 11 / 0.65) !important;
}

.bg-srm-30\/70{
    background-color: rgb(103 18 11 / 0.7) !important;
}

.bg-srm-30\/75{
    background-color: rgb(103 18 11 / 0.75) !important;
}

.bg-srm-30\/80{
    background-color: rgb(103 18 11 / 0.8) !important;
}

.bg-srm-30\/85{
    background-color: rgb(103 18 11 / 0.85) !important;
}

.bg-srm-30\/90{
    background-color: rgb(103 18 11 / 0.9) !important;
}

.bg-srm-30\/95{
    background-color: rgb(103 18 11 / 0.95) !important;
}

.bg-srm-31{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(99 16 10 / var(--tw-bg-opacity)) !important;
}

.bg-srm-31\/0{
    background-color: rgb(99 16 10 / 0) !important;
}

.bg-srm-31\/10{
    background-color: rgb(99 16 10 / 0.1) !important;
}

.bg-srm-31\/100{
    background-color: rgb(99 16 10 / 1) !important;
}

.bg-srm-31\/15{
    background-color: rgb(99 16 10 / 0.15) !important;
}

.bg-srm-31\/20{
    background-color: rgb(99 16 10 / 0.2) !important;
}

.bg-srm-31\/25{
    background-color: rgb(99 16 10 / 0.25) !important;
}

.bg-srm-31\/30{
    background-color: rgb(99 16 10 / 0.3) !important;
}

.bg-srm-31\/35{
    background-color: rgb(99 16 10 / 0.35) !important;
}

.bg-srm-31\/40{
    background-color: rgb(99 16 10 / 0.4) !important;
}

.bg-srm-31\/45{
    background-color: rgb(99 16 10 / 0.45) !important;
}

.bg-srm-31\/5{
    background-color: rgb(99 16 10 / 0.05) !important;
}

.bg-srm-31\/50{
    background-color: rgb(99 16 10 / 0.5) !important;
}

.bg-srm-31\/55{
    background-color: rgb(99 16 10 / 0.55) !important;
}

.bg-srm-31\/60{
    background-color: rgb(99 16 10 / 0.6) !important;
}

.bg-srm-31\/65{
    background-color: rgb(99 16 10 / 0.65) !important;
}

.bg-srm-31\/70{
    background-color: rgb(99 16 10 / 0.7) !important;
}

.bg-srm-31\/75{
    background-color: rgb(99 16 10 / 0.75) !important;
}

.bg-srm-31\/80{
    background-color: rgb(99 16 10 / 0.8) !important;
}

.bg-srm-31\/85{
    background-color: rgb(99 16 10 / 0.85) !important;
}

.bg-srm-31\/90{
    background-color: rgb(99 16 10 / 0.9) !important;
}

.bg-srm-31\/95{
    background-color: rgb(99 16 10 / 0.95) !important;
}

.bg-srm-32{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(95 14 10 / var(--tw-bg-opacity)) !important;
}

.bg-srm-32\/0{
    background-color: rgb(95 14 10 / 0) !important;
}

.bg-srm-32\/10{
    background-color: rgb(95 14 10 / 0.1) !important;
}

.bg-srm-32\/100{
    background-color: rgb(95 14 10 / 1) !important;
}

.bg-srm-32\/15{
    background-color: rgb(95 14 10 / 0.15) !important;
}

.bg-srm-32\/20{
    background-color: rgb(95 14 10 / 0.2) !important;
}

.bg-srm-32\/25{
    background-color: rgb(95 14 10 / 0.25) !important;
}

.bg-srm-32\/30{
    background-color: rgb(95 14 10 / 0.3) !important;
}

.bg-srm-32\/35{
    background-color: rgb(95 14 10 / 0.35) !important;
}

.bg-srm-32\/40{
    background-color: rgb(95 14 10 / 0.4) !important;
}

.bg-srm-32\/45{
    background-color: rgb(95 14 10 / 0.45) !important;
}

.bg-srm-32\/5{
    background-color: rgb(95 14 10 / 0.05) !important;
}

.bg-srm-32\/50{
    background-color: rgb(95 14 10 / 0.5) !important;
}

.bg-srm-32\/55{
    background-color: rgb(95 14 10 / 0.55) !important;
}

.bg-srm-32\/60{
    background-color: rgb(95 14 10 / 0.6) !important;
}

.bg-srm-32\/65{
    background-color: rgb(95 14 10 / 0.65) !important;
}

.bg-srm-32\/70{
    background-color: rgb(95 14 10 / 0.7) !important;
}

.bg-srm-32\/75{
    background-color: rgb(95 14 10 / 0.75) !important;
}

.bg-srm-32\/80{
    background-color: rgb(95 14 10 / 0.8) !important;
}

.bg-srm-32\/85{
    background-color: rgb(95 14 10 / 0.85) !important;
}

.bg-srm-32\/90{
    background-color: rgb(95 14 10 / 0.9) !important;
}

.bg-srm-32\/95{
    background-color: rgb(95 14 10 / 0.95) !important;
}

.bg-srm-33{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(91 11 10 / var(--tw-bg-opacity)) !important;
}

.bg-srm-33\/0{
    background-color: rgb(91 11 10 / 0) !important;
}

.bg-srm-33\/10{
    background-color: rgb(91 11 10 / 0.1) !important;
}

.bg-srm-33\/100{
    background-color: rgb(91 11 10 / 1) !important;
}

.bg-srm-33\/15{
    background-color: rgb(91 11 10 / 0.15) !important;
}

.bg-srm-33\/20{
    background-color: rgb(91 11 10 / 0.2) !important;
}

.bg-srm-33\/25{
    background-color: rgb(91 11 10 / 0.25) !important;
}

.bg-srm-33\/30{
    background-color: rgb(91 11 10 / 0.3) !important;
}

.bg-srm-33\/35{
    background-color: rgb(91 11 10 / 0.35) !important;
}

.bg-srm-33\/40{
    background-color: rgb(91 11 10 / 0.4) !important;
}

.bg-srm-33\/45{
    background-color: rgb(91 11 10 / 0.45) !important;
}

.bg-srm-33\/5{
    background-color: rgb(91 11 10 / 0.05) !important;
}

.bg-srm-33\/50{
    background-color: rgb(91 11 10 / 0.5) !important;
}

.bg-srm-33\/55{
    background-color: rgb(91 11 10 / 0.55) !important;
}

.bg-srm-33\/60{
    background-color: rgb(91 11 10 / 0.6) !important;
}

.bg-srm-33\/65{
    background-color: rgb(91 11 10 / 0.65) !important;
}

.bg-srm-33\/70{
    background-color: rgb(91 11 10 / 0.7) !important;
}

.bg-srm-33\/75{
    background-color: rgb(91 11 10 / 0.75) !important;
}

.bg-srm-33\/80{
    background-color: rgb(91 11 10 / 0.8) !important;
}

.bg-srm-33\/85{
    background-color: rgb(91 11 10 / 0.85) !important;
}

.bg-srm-33\/90{
    background-color: rgb(91 11 10 / 0.9) !important;
}

.bg-srm-33\/95{
    background-color: rgb(91 11 10 / 0.95) !important;
}

.bg-srm-34{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(88 8 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-34\/0{
    background-color: rgb(88 8 11 / 0) !important;
}

.bg-srm-34\/10{
    background-color: rgb(88 8 11 / 0.1) !important;
}

.bg-srm-34\/100{
    background-color: rgb(88 8 11 / 1) !important;
}

.bg-srm-34\/15{
    background-color: rgb(88 8 11 / 0.15) !important;
}

.bg-srm-34\/20{
    background-color: rgb(88 8 11 / 0.2) !important;
}

.bg-srm-34\/25{
    background-color: rgb(88 8 11 / 0.25) !important;
}

.bg-srm-34\/30{
    background-color: rgb(88 8 11 / 0.3) !important;
}

.bg-srm-34\/35{
    background-color: rgb(88 8 11 / 0.35) !important;
}

.bg-srm-34\/40{
    background-color: rgb(88 8 11 / 0.4) !important;
}

.bg-srm-34\/45{
    background-color: rgb(88 8 11 / 0.45) !important;
}

.bg-srm-34\/5{
    background-color: rgb(88 8 11 / 0.05) !important;
}

.bg-srm-34\/50{
    background-color: rgb(88 8 11 / 0.5) !important;
}

.bg-srm-34\/55{
    background-color: rgb(88 8 11 / 0.55) !important;
}

.bg-srm-34\/60{
    background-color: rgb(88 8 11 / 0.6) !important;
}

.bg-srm-34\/65{
    background-color: rgb(88 8 11 / 0.65) !important;
}

.bg-srm-34\/70{
    background-color: rgb(88 8 11 / 0.7) !important;
}

.bg-srm-34\/75{
    background-color: rgb(88 8 11 / 0.75) !important;
}

.bg-srm-34\/80{
    background-color: rgb(88 8 11 / 0.8) !important;
}

.bg-srm-34\/85{
    background-color: rgb(88 8 11 / 0.85) !important;
}

.bg-srm-34\/90{
    background-color: rgb(88 8 11 / 0.9) !important;
}

.bg-srm-34\/95{
    background-color: rgb(88 8 11 / 0.95) !important;
}

.bg-srm-35{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(75 9 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-35\/0{
    background-color: rgb(75 9 11 / 0) !important;
}

.bg-srm-35\/10{
    background-color: rgb(75 9 11 / 0.1) !important;
}

.bg-srm-35\/100{
    background-color: rgb(75 9 11 / 1) !important;
}

.bg-srm-35\/15{
    background-color: rgb(75 9 11 / 0.15) !important;
}

.bg-srm-35\/20{
    background-color: rgb(75 9 11 / 0.2) !important;
}

.bg-srm-35\/25{
    background-color: rgb(75 9 11 / 0.25) !important;
}

.bg-srm-35\/30{
    background-color: rgb(75 9 11 / 0.3) !important;
}

.bg-srm-35\/35{
    background-color: rgb(75 9 11 / 0.35) !important;
}

.bg-srm-35\/40{
    background-color: rgb(75 9 11 / 0.4) !important;
}

.bg-srm-35\/45{
    background-color: rgb(75 9 11 / 0.45) !important;
}

.bg-srm-35\/5{
    background-color: rgb(75 9 11 / 0.05) !important;
}

.bg-srm-35\/50{
    background-color: rgb(75 9 11 / 0.5) !important;
}

.bg-srm-35\/55{
    background-color: rgb(75 9 11 / 0.55) !important;
}

.bg-srm-35\/60{
    background-color: rgb(75 9 11 / 0.6) !important;
}

.bg-srm-35\/65{
    background-color: rgb(75 9 11 / 0.65) !important;
}

.bg-srm-35\/70{
    background-color: rgb(75 9 11 / 0.7) !important;
}

.bg-srm-35\/75{
    background-color: rgb(75 9 11 / 0.75) !important;
}

.bg-srm-35\/80{
    background-color: rgb(75 9 11 / 0.8) !important;
}

.bg-srm-35\/85{
    background-color: rgb(75 9 11 / 0.85) !important;
}

.bg-srm-35\/90{
    background-color: rgb(75 9 11 / 0.9) !important;
}

.bg-srm-35\/95{
    background-color: rgb(75 9 11 / 0.95) !important;
}

.bg-srm-36{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(75 9 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-36\/0{
    background-color: rgb(75 9 11 / 0) !important;
}

.bg-srm-36\/10{
    background-color: rgb(75 9 11 / 0.1) !important;
}

.bg-srm-36\/100{
    background-color: rgb(75 9 11 / 1) !important;
}

.bg-srm-36\/15{
    background-color: rgb(75 9 11 / 0.15) !important;
}

.bg-srm-36\/20{
    background-color: rgb(75 9 11 / 0.2) !important;
}

.bg-srm-36\/25{
    background-color: rgb(75 9 11 / 0.25) !important;
}

.bg-srm-36\/30{
    background-color: rgb(75 9 11 / 0.3) !important;
}

.bg-srm-36\/35{
    background-color: rgb(75 9 11 / 0.35) !important;
}

.bg-srm-36\/40{
    background-color: rgb(75 9 11 / 0.4) !important;
}

.bg-srm-36\/45{
    background-color: rgb(75 9 11 / 0.45) !important;
}

.bg-srm-36\/5{
    background-color: rgb(75 9 11 / 0.05) !important;
}

.bg-srm-36\/50{
    background-color: rgb(75 9 11 / 0.5) !important;
}

.bg-srm-36\/55{
    background-color: rgb(75 9 11 / 0.55) !important;
}

.bg-srm-36\/60{
    background-color: rgb(75 9 11 / 0.6) !important;
}

.bg-srm-36\/65{
    background-color: rgb(75 9 11 / 0.65) !important;
}

.bg-srm-36\/70{
    background-color: rgb(75 9 11 / 0.7) !important;
}

.bg-srm-36\/75{
    background-color: rgb(75 9 11 / 0.75) !important;
}

.bg-srm-36\/80{
    background-color: rgb(75 9 11 / 0.8) !important;
}

.bg-srm-36\/85{
    background-color: rgb(75 9 11 / 0.85) !important;
}

.bg-srm-36\/90{
    background-color: rgb(75 9 11 / 0.9) !important;
}

.bg-srm-36\/95{
    background-color: rgb(75 9 11 / 0.95) !important;
}

.bg-srm-37{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(71 13 12 / var(--tw-bg-opacity)) !important;
}

.bg-srm-37\/0{
    background-color: rgb(71 13 12 / 0) !important;
}

.bg-srm-37\/10{
    background-color: rgb(71 13 12 / 0.1) !important;
}

.bg-srm-37\/100{
    background-color: rgb(71 13 12 / 1) !important;
}

.bg-srm-37\/15{
    background-color: rgb(71 13 12 / 0.15) !important;
}

.bg-srm-37\/20{
    background-color: rgb(71 13 12 / 0.2) !important;
}

.bg-srm-37\/25{
    background-color: rgb(71 13 12 / 0.25) !important;
}

.bg-srm-37\/30{
    background-color: rgb(71 13 12 / 0.3) !important;
}

.bg-srm-37\/35{
    background-color: rgb(71 13 12 / 0.35) !important;
}

.bg-srm-37\/40{
    background-color: rgb(71 13 12 / 0.4) !important;
}

.bg-srm-37\/45{
    background-color: rgb(71 13 12 / 0.45) !important;
}

.bg-srm-37\/5{
    background-color: rgb(71 13 12 / 0.05) !important;
}

.bg-srm-37\/50{
    background-color: rgb(71 13 12 / 0.5) !important;
}

.bg-srm-37\/55{
    background-color: rgb(71 13 12 / 0.55) !important;
}

.bg-srm-37\/60{
    background-color: rgb(71 13 12 / 0.6) !important;
}

.bg-srm-37\/65{
    background-color: rgb(71 13 12 / 0.65) !important;
}

.bg-srm-37\/70{
    background-color: rgb(71 13 12 / 0.7) !important;
}

.bg-srm-37\/75{
    background-color: rgb(71 13 12 / 0.75) !important;
}

.bg-srm-37\/80{
    background-color: rgb(71 13 12 / 0.8) !important;
}

.bg-srm-37\/85{
    background-color: rgb(71 13 12 / 0.85) !important;
}

.bg-srm-37\/90{
    background-color: rgb(71 13 12 / 0.9) !important;
}

.bg-srm-37\/95{
    background-color: rgb(71 13 12 / 0.95) !important;
}

.bg-srm-38{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(64 12 14 / var(--tw-bg-opacity)) !important;
}

.bg-srm-38\/0{
    background-color: rgb(64 12 14 / 0) !important;
}

.bg-srm-38\/10{
    background-color: rgb(64 12 14 / 0.1) !important;
}

.bg-srm-38\/100{
    background-color: rgb(64 12 14 / 1) !important;
}

.bg-srm-38\/15{
    background-color: rgb(64 12 14 / 0.15) !important;
}

.bg-srm-38\/20{
    background-color: rgb(64 12 14 / 0.2) !important;
}

.bg-srm-38\/25{
    background-color: rgb(64 12 14 / 0.25) !important;
}

.bg-srm-38\/30{
    background-color: rgb(64 12 14 / 0.3) !important;
}

.bg-srm-38\/35{
    background-color: rgb(64 12 14 / 0.35) !important;
}

.bg-srm-38\/40{
    background-color: rgb(64 12 14 / 0.4) !important;
}

.bg-srm-38\/45{
    background-color: rgb(64 12 14 / 0.45) !important;
}

.bg-srm-38\/5{
    background-color: rgb(64 12 14 / 0.05) !important;
}

.bg-srm-38\/50{
    background-color: rgb(64 12 14 / 0.5) !important;
}

.bg-srm-38\/55{
    background-color: rgb(64 12 14 / 0.55) !important;
}

.bg-srm-38\/60{
    background-color: rgb(64 12 14 / 0.6) !important;
}

.bg-srm-38\/65{
    background-color: rgb(64 12 14 / 0.65) !important;
}

.bg-srm-38\/70{
    background-color: rgb(64 12 14 / 0.7) !important;
}

.bg-srm-38\/75{
    background-color: rgb(64 12 14 / 0.75) !important;
}

.bg-srm-38\/80{
    background-color: rgb(64 12 14 / 0.8) !important;
}

.bg-srm-38\/85{
    background-color: rgb(64 12 14 / 0.85) !important;
}

.bg-srm-38\/90{
    background-color: rgb(64 12 14 / 0.9) !important;
}

.bg-srm-38\/95{
    background-color: rgb(64 12 14 / 0.95) !important;
}

.bg-srm-39{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(60 11 14 / var(--tw-bg-opacity)) !important;
}

.bg-srm-39\/0{
    background-color: rgb(60 11 14 / 0) !important;
}

.bg-srm-39\/10{
    background-color: rgb(60 11 14 / 0.1) !important;
}

.bg-srm-39\/100{
    background-color: rgb(60 11 14 / 1) !important;
}

.bg-srm-39\/15{
    background-color: rgb(60 11 14 / 0.15) !important;
}

.bg-srm-39\/20{
    background-color: rgb(60 11 14 / 0.2) !important;
}

.bg-srm-39\/25{
    background-color: rgb(60 11 14 / 0.25) !important;
}

.bg-srm-39\/30{
    background-color: rgb(60 11 14 / 0.3) !important;
}

.bg-srm-39\/35{
    background-color: rgb(60 11 14 / 0.35) !important;
}

.bg-srm-39\/40{
    background-color: rgb(60 11 14 / 0.4) !important;
}

.bg-srm-39\/45{
    background-color: rgb(60 11 14 / 0.45) !important;
}

.bg-srm-39\/5{
    background-color: rgb(60 11 14 / 0.05) !important;
}

.bg-srm-39\/50{
    background-color: rgb(60 11 14 / 0.5) !important;
}

.bg-srm-39\/55{
    background-color: rgb(60 11 14 / 0.55) !important;
}

.bg-srm-39\/60{
    background-color: rgb(60 11 14 / 0.6) !important;
}

.bg-srm-39\/65{
    background-color: rgb(60 11 14 / 0.65) !important;
}

.bg-srm-39\/70{
    background-color: rgb(60 11 14 / 0.7) !important;
}

.bg-srm-39\/75{
    background-color: rgb(60 11 14 / 0.75) !important;
}

.bg-srm-39\/80{
    background-color: rgb(60 11 14 / 0.8) !important;
}

.bg-srm-39\/85{
    background-color: rgb(60 11 14 / 0.85) !important;
}

.bg-srm-39\/90{
    background-color: rgb(60 11 14 / 0.9) !important;
}

.bg-srm-39\/95{
    background-color: rgb(60 11 14 / 0.95) !important;
}

.bg-srm-4{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(242 190 55 / var(--tw-bg-opacity)) !important;
}

.bg-srm-4\/0{
    background-color: rgb(242 190 55 / 0) !important;
}

.bg-srm-4\/10{
    background-color: rgb(242 190 55 / 0.1) !important;
}

.bg-srm-4\/100{
    background-color: rgb(242 190 55 / 1) !important;
}

.bg-srm-4\/15{
    background-color: rgb(242 190 55 / 0.15) !important;
}

.bg-srm-4\/20{
    background-color: rgb(242 190 55 / 0.2) !important;
}

.bg-srm-4\/25{
    background-color: rgb(242 190 55 / 0.25) !important;
}

.bg-srm-4\/30{
    background-color: rgb(242 190 55 / 0.3) !important;
}

.bg-srm-4\/35{
    background-color: rgb(242 190 55 / 0.35) !important;
}

.bg-srm-4\/40{
    background-color: rgb(242 190 55 / 0.4) !important;
}

.bg-srm-4\/45{
    background-color: rgb(242 190 55 / 0.45) !important;
}

.bg-srm-4\/5{
    background-color: rgb(242 190 55 / 0.05) !important;
}

.bg-srm-4\/50{
    background-color: rgb(242 190 55 / 0.5) !important;
}

.bg-srm-4\/55{
    background-color: rgb(242 190 55 / 0.55) !important;
}

.bg-srm-4\/60{
    background-color: rgb(242 190 55 / 0.6) !important;
}

.bg-srm-4\/65{
    background-color: rgb(242 190 55 / 0.65) !important;
}

.bg-srm-4\/70{
    background-color: rgb(242 190 55 / 0.7) !important;
}

.bg-srm-4\/75{
    background-color: rgb(242 190 55 / 0.75) !important;
}

.bg-srm-4\/80{
    background-color: rgb(242 190 55 / 0.8) !important;
}

.bg-srm-4\/85{
    background-color: rgb(242 190 55 / 0.85) !important;
}

.bg-srm-4\/90{
    background-color: rgb(242 190 55 / 0.9) !important;
}

.bg-srm-4\/95{
    background-color: rgb(242 190 55 / 0.95) !important;
}

.bg-srm-40{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(36 10 11 / var(--tw-bg-opacity)) !important;
}

.bg-srm-40\/0{
    background-color: rgb(36 10 11 / 0) !important;
}

.bg-srm-40\/10{
    background-color: rgb(36 10 11 / 0.1) !important;
}

.bg-srm-40\/100{
    background-color: rgb(36 10 11 / 1) !important;
}

.bg-srm-40\/15{
    background-color: rgb(36 10 11 / 0.15) !important;
}

.bg-srm-40\/20{
    background-color: rgb(36 10 11 / 0.2) !important;
}

.bg-srm-40\/25{
    background-color: rgb(36 10 11 / 0.25) !important;
}

.bg-srm-40\/30{
    background-color: rgb(36 10 11 / 0.3) !important;
}

.bg-srm-40\/35{
    background-color: rgb(36 10 11 / 0.35) !important;
}

.bg-srm-40\/40{
    background-color: rgb(36 10 11 / 0.4) !important;
}

.bg-srm-40\/45{
    background-color: rgb(36 10 11 / 0.45) !important;
}

.bg-srm-40\/5{
    background-color: rgb(36 10 11 / 0.05) !important;
}

.bg-srm-40\/50{
    background-color: rgb(36 10 11 / 0.5) !important;
}

.bg-srm-40\/55{
    background-color: rgb(36 10 11 / 0.55) !important;
}

.bg-srm-40\/60{
    background-color: rgb(36 10 11 / 0.6) !important;
}

.bg-srm-40\/65{
    background-color: rgb(36 10 11 / 0.65) !important;
}

.bg-srm-40\/70{
    background-color: rgb(36 10 11 / 0.7) !important;
}

.bg-srm-40\/75{
    background-color: rgb(36 10 11 / 0.75) !important;
}

.bg-srm-40\/80{
    background-color: rgb(36 10 11 / 0.8) !important;
}

.bg-srm-40\/85{
    background-color: rgb(36 10 11 / 0.85) !important;
}

.bg-srm-40\/90{
    background-color: rgb(36 10 11 / 0.9) !important;
}

.bg-srm-40\/95{
    background-color: rgb(36 10 11 / 0.95) !important;
}

.bg-srm-5{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(237 172 30 / var(--tw-bg-opacity)) !important;
}

.bg-srm-5\/0{
    background-color: rgb(237 172 30 / 0) !important;
}

.bg-srm-5\/10{
    background-color: rgb(237 172 30 / 0.1) !important;
}

.bg-srm-5\/100{
    background-color: rgb(237 172 30 / 1) !important;
}

.bg-srm-5\/15{
    background-color: rgb(237 172 30 / 0.15) !important;
}

.bg-srm-5\/20{
    background-color: rgb(237 172 30 / 0.2) !important;
}

.bg-srm-5\/25{
    background-color: rgb(237 172 30 / 0.25) !important;
}

.bg-srm-5\/30{
    background-color: rgb(237 172 30 / 0.3) !important;
}

.bg-srm-5\/35{
    background-color: rgb(237 172 30 / 0.35) !important;
}

.bg-srm-5\/40{
    background-color: rgb(237 172 30 / 0.4) !important;
}

.bg-srm-5\/45{
    background-color: rgb(237 172 30 / 0.45) !important;
}

.bg-srm-5\/5{
    background-color: rgb(237 172 30 / 0.05) !important;
}

.bg-srm-5\/50{
    background-color: rgb(237 172 30 / 0.5) !important;
}

.bg-srm-5\/55{
    background-color: rgb(237 172 30 / 0.55) !important;
}

.bg-srm-5\/60{
    background-color: rgb(237 172 30 / 0.6) !important;
}

.bg-srm-5\/65{
    background-color: rgb(237 172 30 / 0.65) !important;
}

.bg-srm-5\/70{
    background-color: rgb(237 172 30 / 0.7) !important;
}

.bg-srm-5\/75{
    background-color: rgb(237 172 30 / 0.75) !important;
}

.bg-srm-5\/80{
    background-color: rgb(237 172 30 / 0.8) !important;
}

.bg-srm-5\/85{
    background-color: rgb(237 172 30 / 0.85) !important;
}

.bg-srm-5\/90{
    background-color: rgb(237 172 30 / 0.9) !important;
}

.bg-srm-5\/95{
    background-color: rgb(237 172 30 / 0.95) !important;
}

.bg-srm-6{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(229 156 25 / var(--tw-bg-opacity)) !important;
}

.bg-srm-6\/0{
    background-color: rgb(229 156 25 / 0) !important;
}

.bg-srm-6\/10{
    background-color: rgb(229 156 25 / 0.1) !important;
}

.bg-srm-6\/100{
    background-color: rgb(229 156 25 / 1) !important;
}

.bg-srm-6\/15{
    background-color: rgb(229 156 25 / 0.15) !important;
}

.bg-srm-6\/20{
    background-color: rgb(229 156 25 / 0.2) !important;
}

.bg-srm-6\/25{
    background-color: rgb(229 156 25 / 0.25) !important;
}

.bg-srm-6\/30{
    background-color: rgb(229 156 25 / 0.3) !important;
}

.bg-srm-6\/35{
    background-color: rgb(229 156 25 / 0.35) !important;
}

.bg-srm-6\/40{
    background-color: rgb(229 156 25 / 0.4) !important;
}

.bg-srm-6\/45{
    background-color: rgb(229 156 25 / 0.45) !important;
}

.bg-srm-6\/5{
    background-color: rgb(229 156 25 / 0.05) !important;
}

.bg-srm-6\/50{
    background-color: rgb(229 156 25 / 0.5) !important;
}

.bg-srm-6\/55{
    background-color: rgb(229 156 25 / 0.55) !important;
}

.bg-srm-6\/60{
    background-color: rgb(229 156 25 / 0.6) !important;
}

.bg-srm-6\/65{
    background-color: rgb(229 156 25 / 0.65) !important;
}

.bg-srm-6\/70{
    background-color: rgb(229 156 25 / 0.7) !important;
}

.bg-srm-6\/75{
    background-color: rgb(229 156 25 / 0.75) !important;
}

.bg-srm-6\/80{
    background-color: rgb(229 156 25 / 0.8) !important;
}

.bg-srm-6\/85{
    background-color: rgb(229 156 25 / 0.85) !important;
}

.bg-srm-6\/90{
    background-color: rgb(229 156 25 / 0.9) !important;
}

.bg-srm-6\/95{
    background-color: rgb(229 156 25 / 0.95) !important;
}

.bg-srm-7{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(223 143 22 / var(--tw-bg-opacity)) !important;
}

.bg-srm-7\/0{
    background-color: rgb(223 143 22 / 0) !important;
}

.bg-srm-7\/10{
    background-color: rgb(223 143 22 / 0.1) !important;
}

.bg-srm-7\/100{
    background-color: rgb(223 143 22 / 1) !important;
}

.bg-srm-7\/15{
    background-color: rgb(223 143 22 / 0.15) !important;
}

.bg-srm-7\/20{
    background-color: rgb(223 143 22 / 0.2) !important;
}

.bg-srm-7\/25{
    background-color: rgb(223 143 22 / 0.25) !important;
}

.bg-srm-7\/30{
    background-color: rgb(223 143 22 / 0.3) !important;
}

.bg-srm-7\/35{
    background-color: rgb(223 143 22 / 0.35) !important;
}

.bg-srm-7\/40{
    background-color: rgb(223 143 22 / 0.4) !important;
}

.bg-srm-7\/45{
    background-color: rgb(223 143 22 / 0.45) !important;
}

.bg-srm-7\/5{
    background-color: rgb(223 143 22 / 0.05) !important;
}

.bg-srm-7\/50{
    background-color: rgb(223 143 22 / 0.5) !important;
}

.bg-srm-7\/55{
    background-color: rgb(223 143 22 / 0.55) !important;
}

.bg-srm-7\/60{
    background-color: rgb(223 143 22 / 0.6) !important;
}

.bg-srm-7\/65{
    background-color: rgb(223 143 22 / 0.65) !important;
}

.bg-srm-7\/70{
    background-color: rgb(223 143 22 / 0.7) !important;
}

.bg-srm-7\/75{
    background-color: rgb(223 143 22 / 0.75) !important;
}

.bg-srm-7\/80{
    background-color: rgb(223 143 22 / 0.8) !important;
}

.bg-srm-7\/85{
    background-color: rgb(223 143 22 / 0.85) !important;
}

.bg-srm-7\/90{
    background-color: rgb(223 143 22 / 0.9) !important;
}

.bg-srm-7\/95{
    background-color: rgb(223 143 22 / 0.95) !important;
}

.bg-srm-8{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(214 128 25 / var(--tw-bg-opacity)) !important;
}

.bg-srm-8\/0{
    background-color: rgb(214 128 25 / 0) !important;
}

.bg-srm-8\/10{
    background-color: rgb(214 128 25 / 0.1) !important;
}

.bg-srm-8\/100{
    background-color: rgb(214 128 25 / 1) !important;
}

.bg-srm-8\/15{
    background-color: rgb(214 128 25 / 0.15) !important;
}

.bg-srm-8\/20{
    background-color: rgb(214 128 25 / 0.2) !important;
}

.bg-srm-8\/25{
    background-color: rgb(214 128 25 / 0.25) !important;
}

.bg-srm-8\/30{
    background-color: rgb(214 128 25 / 0.3) !important;
}

.bg-srm-8\/35{
    background-color: rgb(214 128 25 / 0.35) !important;
}

.bg-srm-8\/40{
    background-color: rgb(214 128 25 / 0.4) !important;
}

.bg-srm-8\/45{
    background-color: rgb(214 128 25 / 0.45) !important;
}

.bg-srm-8\/5{
    background-color: rgb(214 128 25 / 0.05) !important;
}

.bg-srm-8\/50{
    background-color: rgb(214 128 25 / 0.5) !important;
}

.bg-srm-8\/55{
    background-color: rgb(214 128 25 / 0.55) !important;
}

.bg-srm-8\/60{
    background-color: rgb(214 128 25 / 0.6) !important;
}

.bg-srm-8\/65{
    background-color: rgb(214 128 25 / 0.65) !important;
}

.bg-srm-8\/70{
    background-color: rgb(214 128 25 / 0.7) !important;
}

.bg-srm-8\/75{
    background-color: rgb(214 128 25 / 0.75) !important;
}

.bg-srm-8\/80{
    background-color: rgb(214 128 25 / 0.8) !important;
}

.bg-srm-8\/85{
    background-color: rgb(214 128 25 / 0.85) !important;
}

.bg-srm-8\/90{
    background-color: rgb(214 128 25 / 0.9) !important;
}

.bg-srm-8\/95{
    background-color: rgb(214 128 25 / 0.95) !important;
}

.bg-srm-9{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(207 115 28 / var(--tw-bg-opacity)) !important;
}

.bg-srm-9\/0{
    background-color: rgb(207 115 28 / 0) !important;
}

.bg-srm-9\/10{
    background-color: rgb(207 115 28 / 0.1) !important;
}

.bg-srm-9\/100{
    background-color: rgb(207 115 28 / 1) !important;
}

.bg-srm-9\/15{
    background-color: rgb(207 115 28 / 0.15) !important;
}

.bg-srm-9\/20{
    background-color: rgb(207 115 28 / 0.2) !important;
}

.bg-srm-9\/25{
    background-color: rgb(207 115 28 / 0.25) !important;
}

.bg-srm-9\/30{
    background-color: rgb(207 115 28 / 0.3) !important;
}

.bg-srm-9\/35{
    background-color: rgb(207 115 28 / 0.35) !important;
}

.bg-srm-9\/40{
    background-color: rgb(207 115 28 / 0.4) !important;
}

.bg-srm-9\/45{
    background-color: rgb(207 115 28 / 0.45) !important;
}

.bg-srm-9\/5{
    background-color: rgb(207 115 28 / 0.05) !important;
}

.bg-srm-9\/50{
    background-color: rgb(207 115 28 / 0.5) !important;
}

.bg-srm-9\/55{
    background-color: rgb(207 115 28 / 0.55) !important;
}

.bg-srm-9\/60{
    background-color: rgb(207 115 28 / 0.6) !important;
}

.bg-srm-9\/65{
    background-color: rgb(207 115 28 / 0.65) !important;
}

.bg-srm-9\/70{
    background-color: rgb(207 115 28 / 0.7) !important;
}

.bg-srm-9\/75{
    background-color: rgb(207 115 28 / 0.75) !important;
}

.bg-srm-9\/80{
    background-color: rgb(207 115 28 / 0.8) !important;
}

.bg-srm-9\/85{
    background-color: rgb(207 115 28 / 0.85) !important;
}

.bg-srm-9\/90{
    background-color: rgb(207 115 28 / 0.9) !important;
}

.bg-srm-9\/95{
    background-color: rgb(207 115 28 / 0.95) !important;
}

.bg-success{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(84 126 17 / var(--tw-bg-opacity)) !important;
}

.bg-success\/0{
    background-color: rgb(84 126 17 / 0) !important;
}

.bg-success\/10{
    background-color: rgb(84 126 17 / 0.1) !important;
}

.bg-success\/100{
    background-color: rgb(84 126 17 / 1) !important;
}

.bg-success\/15{
    background-color: rgb(84 126 17 / 0.15) !important;
}

.bg-success\/20{
    background-color: rgb(84 126 17 / 0.2) !important;
}

.bg-success\/25{
    background-color: rgb(84 126 17 / 0.25) !important;
}

.bg-success\/30{
    background-color: rgb(84 126 17 / 0.3) !important;
}

.bg-success\/35{
    background-color: rgb(84 126 17 / 0.35) !important;
}

.bg-success\/40{
    background-color: rgb(84 126 17 / 0.4) !important;
}

.bg-success\/45{
    background-color: rgb(84 126 17 / 0.45) !important;
}

.bg-success\/5{
    background-color: rgb(84 126 17 / 0.05) !important;
}

.bg-success\/50{
    background-color: rgb(84 126 17 / 0.5) !important;
}

.bg-success\/55{
    background-color: rgb(84 126 17 / 0.55) !important;
}

.bg-success\/60{
    background-color: rgb(84 126 17 / 0.6) !important;
}

.bg-success\/65{
    background-color: rgb(84 126 17 / 0.65) !important;
}

.bg-success\/70{
    background-color: rgb(84 126 17 / 0.7) !important;
}

.bg-success\/75{
    background-color: rgb(84 126 17 / 0.75) !important;
}

.bg-success\/80{
    background-color: rgb(84 126 17 / 0.8) !important;
}

.bg-success\/85{
    background-color: rgb(84 126 17 / 0.85) !important;
}

.bg-success\/90{
    background-color: rgb(84 126 17 / 0.9) !important;
}

.bg-success\/95{
    background-color: rgb(84 126 17 / 0.95) !important;
}

.bg-teal-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(204 251 241 / var(--tw-bg-opacity)) !important;
}

.bg-teal-100\/0{
    background-color: rgb(204 251 241 / 0) !important;
}

.bg-teal-100\/10{
    background-color: rgb(204 251 241 / 0.1) !important;
}

.bg-teal-100\/100{
    background-color: rgb(204 251 241 / 1) !important;
}

.bg-teal-100\/15{
    background-color: rgb(204 251 241 / 0.15) !important;
}

.bg-teal-100\/20{
    background-color: rgb(204 251 241 / 0.2) !important;
}

.bg-teal-100\/25{
    background-color: rgb(204 251 241 / 0.25) !important;
}

.bg-teal-100\/30{
    background-color: rgb(204 251 241 / 0.3) !important;
}

.bg-teal-100\/35{
    background-color: rgb(204 251 241 / 0.35) !important;
}

.bg-teal-100\/40{
    background-color: rgb(204 251 241 / 0.4) !important;
}

.bg-teal-100\/45{
    background-color: rgb(204 251 241 / 0.45) !important;
}

.bg-teal-100\/5{
    background-color: rgb(204 251 241 / 0.05) !important;
}

.bg-teal-100\/50{
    background-color: rgb(204 251 241 / 0.5) !important;
}

.bg-teal-100\/55{
    background-color: rgb(204 251 241 / 0.55) !important;
}

.bg-teal-100\/60{
    background-color: rgb(204 251 241 / 0.6) !important;
}

.bg-teal-100\/65{
    background-color: rgb(204 251 241 / 0.65) !important;
}

.bg-teal-100\/70{
    background-color: rgb(204 251 241 / 0.7) !important;
}

.bg-teal-100\/75{
    background-color: rgb(204 251 241 / 0.75) !important;
}

.bg-teal-100\/80{
    background-color: rgb(204 251 241 / 0.8) !important;
}

.bg-teal-100\/85{
    background-color: rgb(204 251 241 / 0.85) !important;
}

.bg-teal-100\/90{
    background-color: rgb(204 251 241 / 0.9) !important;
}

.bg-teal-100\/95{
    background-color: rgb(204 251 241 / 0.95) !important;
}

.bg-teal-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(153 246 228 / var(--tw-bg-opacity)) !important;
}

.bg-teal-200\/0{
    background-color: rgb(153 246 228 / 0) !important;
}

.bg-teal-200\/10{
    background-color: rgb(153 246 228 / 0.1) !important;
}

.bg-teal-200\/100{
    background-color: rgb(153 246 228 / 1) !important;
}

.bg-teal-200\/15{
    background-color: rgb(153 246 228 / 0.15) !important;
}

.bg-teal-200\/20{
    background-color: rgb(153 246 228 / 0.2) !important;
}

.bg-teal-200\/25{
    background-color: rgb(153 246 228 / 0.25) !important;
}

.bg-teal-200\/30{
    background-color: rgb(153 246 228 / 0.3) !important;
}

.bg-teal-200\/35{
    background-color: rgb(153 246 228 / 0.35) !important;
}

.bg-teal-200\/40{
    background-color: rgb(153 246 228 / 0.4) !important;
}

.bg-teal-200\/45{
    background-color: rgb(153 246 228 / 0.45) !important;
}

.bg-teal-200\/5{
    background-color: rgb(153 246 228 / 0.05) !important;
}

.bg-teal-200\/50{
    background-color: rgb(153 246 228 / 0.5) !important;
}

.bg-teal-200\/55{
    background-color: rgb(153 246 228 / 0.55) !important;
}

.bg-teal-200\/60{
    background-color: rgb(153 246 228 / 0.6) !important;
}

.bg-teal-200\/65{
    background-color: rgb(153 246 228 / 0.65) !important;
}

.bg-teal-200\/70{
    background-color: rgb(153 246 228 / 0.7) !important;
}

.bg-teal-200\/75{
    background-color: rgb(153 246 228 / 0.75) !important;
}

.bg-teal-200\/80{
    background-color: rgb(153 246 228 / 0.8) !important;
}

.bg-teal-200\/85{
    background-color: rgb(153 246 228 / 0.85) !important;
}

.bg-teal-200\/90{
    background-color: rgb(153 246 228 / 0.9) !important;
}

.bg-teal-200\/95{
    background-color: rgb(153 246 228 / 0.95) !important;
}

.bg-teal-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(94 234 212 / var(--tw-bg-opacity)) !important;
}

.bg-teal-300\/0{
    background-color: rgb(94 234 212 / 0) !important;
}

.bg-teal-300\/10{
    background-color: rgb(94 234 212 / 0.1) !important;
}

.bg-teal-300\/100{
    background-color: rgb(94 234 212 / 1) !important;
}

.bg-teal-300\/15{
    background-color: rgb(94 234 212 / 0.15) !important;
}

.bg-teal-300\/20{
    background-color: rgb(94 234 212 / 0.2) !important;
}

.bg-teal-300\/25{
    background-color: rgb(94 234 212 / 0.25) !important;
}

.bg-teal-300\/30{
    background-color: rgb(94 234 212 / 0.3) !important;
}

.bg-teal-300\/35{
    background-color: rgb(94 234 212 / 0.35) !important;
}

.bg-teal-300\/40{
    background-color: rgb(94 234 212 / 0.4) !important;
}

.bg-teal-300\/45{
    background-color: rgb(94 234 212 / 0.45) !important;
}

.bg-teal-300\/5{
    background-color: rgb(94 234 212 / 0.05) !important;
}

.bg-teal-300\/50{
    background-color: rgb(94 234 212 / 0.5) !important;
}

.bg-teal-300\/55{
    background-color: rgb(94 234 212 / 0.55) !important;
}

.bg-teal-300\/60{
    background-color: rgb(94 234 212 / 0.6) !important;
}

.bg-teal-300\/65{
    background-color: rgb(94 234 212 / 0.65) !important;
}

.bg-teal-300\/70{
    background-color: rgb(94 234 212 / 0.7) !important;
}

.bg-teal-300\/75{
    background-color: rgb(94 234 212 / 0.75) !important;
}

.bg-teal-300\/80{
    background-color: rgb(94 234 212 / 0.8) !important;
}

.bg-teal-300\/85{
    background-color: rgb(94 234 212 / 0.85) !important;
}

.bg-teal-300\/90{
    background-color: rgb(94 234 212 / 0.9) !important;
}

.bg-teal-300\/95{
    background-color: rgb(94 234 212 / 0.95) !important;
}

.bg-teal-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(45 212 191 / var(--tw-bg-opacity)) !important;
}

.bg-teal-400\/0{
    background-color: rgb(45 212 191 / 0) !important;
}

.bg-teal-400\/10{
    background-color: rgb(45 212 191 / 0.1) !important;
}

.bg-teal-400\/100{
    background-color: rgb(45 212 191 / 1) !important;
}

.bg-teal-400\/15{
    background-color: rgb(45 212 191 / 0.15) !important;
}

.bg-teal-400\/20{
    background-color: rgb(45 212 191 / 0.2) !important;
}

.bg-teal-400\/25{
    background-color: rgb(45 212 191 / 0.25) !important;
}

.bg-teal-400\/30{
    background-color: rgb(45 212 191 / 0.3) !important;
}

.bg-teal-400\/35{
    background-color: rgb(45 212 191 / 0.35) !important;
}

.bg-teal-400\/40{
    background-color: rgb(45 212 191 / 0.4) !important;
}

.bg-teal-400\/45{
    background-color: rgb(45 212 191 / 0.45) !important;
}

.bg-teal-400\/5{
    background-color: rgb(45 212 191 / 0.05) !important;
}

.bg-teal-400\/50{
    background-color: rgb(45 212 191 / 0.5) !important;
}

.bg-teal-400\/55{
    background-color: rgb(45 212 191 / 0.55) !important;
}

.bg-teal-400\/60{
    background-color: rgb(45 212 191 / 0.6) !important;
}

.bg-teal-400\/65{
    background-color: rgb(45 212 191 / 0.65) !important;
}

.bg-teal-400\/70{
    background-color: rgb(45 212 191 / 0.7) !important;
}

.bg-teal-400\/75{
    background-color: rgb(45 212 191 / 0.75) !important;
}

.bg-teal-400\/80{
    background-color: rgb(45 212 191 / 0.8) !important;
}

.bg-teal-400\/85{
    background-color: rgb(45 212 191 / 0.85) !important;
}

.bg-teal-400\/90{
    background-color: rgb(45 212 191 / 0.9) !important;
}

.bg-teal-400\/95{
    background-color: rgb(45 212 191 / 0.95) !important;
}

.bg-teal-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(240 253 250 / var(--tw-bg-opacity)) !important;
}

.bg-teal-50\/0{
    background-color: rgb(240 253 250 / 0) !important;
}

.bg-teal-50\/10{
    background-color: rgb(240 253 250 / 0.1) !important;
}

.bg-teal-50\/100{
    background-color: rgb(240 253 250 / 1) !important;
}

.bg-teal-50\/15{
    background-color: rgb(240 253 250 / 0.15) !important;
}

.bg-teal-50\/20{
    background-color: rgb(240 253 250 / 0.2) !important;
}

.bg-teal-50\/25{
    background-color: rgb(240 253 250 / 0.25) !important;
}

.bg-teal-50\/30{
    background-color: rgb(240 253 250 / 0.3) !important;
}

.bg-teal-50\/35{
    background-color: rgb(240 253 250 / 0.35) !important;
}

.bg-teal-50\/40{
    background-color: rgb(240 253 250 / 0.4) !important;
}

.bg-teal-50\/45{
    background-color: rgb(240 253 250 / 0.45) !important;
}

.bg-teal-50\/5{
    background-color: rgb(240 253 250 / 0.05) !important;
}

.bg-teal-50\/50{
    background-color: rgb(240 253 250 / 0.5) !important;
}

.bg-teal-50\/55{
    background-color: rgb(240 253 250 / 0.55) !important;
}

.bg-teal-50\/60{
    background-color: rgb(240 253 250 / 0.6) !important;
}

.bg-teal-50\/65{
    background-color: rgb(240 253 250 / 0.65) !important;
}

.bg-teal-50\/70{
    background-color: rgb(240 253 250 / 0.7) !important;
}

.bg-teal-50\/75{
    background-color: rgb(240 253 250 / 0.75) !important;
}

.bg-teal-50\/80{
    background-color: rgb(240 253 250 / 0.8) !important;
}

.bg-teal-50\/85{
    background-color: rgb(240 253 250 / 0.85) !important;
}

.bg-teal-50\/90{
    background-color: rgb(240 253 250 / 0.9) !important;
}

.bg-teal-50\/95{
    background-color: rgb(240 253 250 / 0.95) !important;
}

.bg-teal-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 184 166 / var(--tw-bg-opacity)) !important;
}

.bg-teal-500\/0{
    background-color: rgb(20 184 166 / 0) !important;
}

.bg-teal-500\/10{
    background-color: rgb(20 184 166 / 0.1) !important;
}

.bg-teal-500\/100{
    background-color: rgb(20 184 166 / 1) !important;
}

.bg-teal-500\/15{
    background-color: rgb(20 184 166 / 0.15) !important;
}

.bg-teal-500\/20{
    background-color: rgb(20 184 166 / 0.2) !important;
}

.bg-teal-500\/25{
    background-color: rgb(20 184 166 / 0.25) !important;
}

.bg-teal-500\/30{
    background-color: rgb(20 184 166 / 0.3) !important;
}

.bg-teal-500\/35{
    background-color: rgb(20 184 166 / 0.35) !important;
}

.bg-teal-500\/40{
    background-color: rgb(20 184 166 / 0.4) !important;
}

.bg-teal-500\/45{
    background-color: rgb(20 184 166 / 0.45) !important;
}

.bg-teal-500\/5{
    background-color: rgb(20 184 166 / 0.05) !important;
}

.bg-teal-500\/50{
    background-color: rgb(20 184 166 / 0.5) !important;
}

.bg-teal-500\/55{
    background-color: rgb(20 184 166 / 0.55) !important;
}

.bg-teal-500\/60{
    background-color: rgb(20 184 166 / 0.6) !important;
}

.bg-teal-500\/65{
    background-color: rgb(20 184 166 / 0.65) !important;
}

.bg-teal-500\/70{
    background-color: rgb(20 184 166 / 0.7) !important;
}

.bg-teal-500\/75{
    background-color: rgb(20 184 166 / 0.75) !important;
}

.bg-teal-500\/80{
    background-color: rgb(20 184 166 / 0.8) !important;
}

.bg-teal-500\/85{
    background-color: rgb(20 184 166 / 0.85) !important;
}

.bg-teal-500\/90{
    background-color: rgb(20 184 166 / 0.9) !important;
}

.bg-teal-500\/95{
    background-color: rgb(20 184 166 / 0.95) !important;
}

.bg-teal-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity)) !important;
}

.bg-teal-600\/0{
    background-color: rgb(13 148 136 / 0) !important;
}

.bg-teal-600\/10{
    background-color: rgb(13 148 136 / 0.1) !important;
}

.bg-teal-600\/100{
    background-color: rgb(13 148 136 / 1) !important;
}

.bg-teal-600\/15{
    background-color: rgb(13 148 136 / 0.15) !important;
}

.bg-teal-600\/20{
    background-color: rgb(13 148 136 / 0.2) !important;
}

.bg-teal-600\/25{
    background-color: rgb(13 148 136 / 0.25) !important;
}

.bg-teal-600\/30{
    background-color: rgb(13 148 136 / 0.3) !important;
}

.bg-teal-600\/35{
    background-color: rgb(13 148 136 / 0.35) !important;
}

.bg-teal-600\/40{
    background-color: rgb(13 148 136 / 0.4) !important;
}

.bg-teal-600\/45{
    background-color: rgb(13 148 136 / 0.45) !important;
}

.bg-teal-600\/5{
    background-color: rgb(13 148 136 / 0.05) !important;
}

.bg-teal-600\/50{
    background-color: rgb(13 148 136 / 0.5) !important;
}

.bg-teal-600\/55{
    background-color: rgb(13 148 136 / 0.55) !important;
}

.bg-teal-600\/60{
    background-color: rgb(13 148 136 / 0.6) !important;
}

.bg-teal-600\/65{
    background-color: rgb(13 148 136 / 0.65) !important;
}

.bg-teal-600\/70{
    background-color: rgb(13 148 136 / 0.7) !important;
}

.bg-teal-600\/75{
    background-color: rgb(13 148 136 / 0.75) !important;
}

.bg-teal-600\/80{
    background-color: rgb(13 148 136 / 0.8) !important;
}

.bg-teal-600\/85{
    background-color: rgb(13 148 136 / 0.85) !important;
}

.bg-teal-600\/90{
    background-color: rgb(13 148 136 / 0.9) !important;
}

.bg-teal-600\/95{
    background-color: rgb(13 148 136 / 0.95) !important;
}

.bg-teal-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
}

.bg-teal-700\/0{
    background-color: rgb(15 118 110 / 0) !important;
}

.bg-teal-700\/10{
    background-color: rgb(15 118 110 / 0.1) !important;
}

.bg-teal-700\/100{
    background-color: rgb(15 118 110 / 1) !important;
}

.bg-teal-700\/15{
    background-color: rgb(15 118 110 / 0.15) !important;
}

.bg-teal-700\/20{
    background-color: rgb(15 118 110 / 0.2) !important;
}

.bg-teal-700\/25{
    background-color: rgb(15 118 110 / 0.25) !important;
}

.bg-teal-700\/30{
    background-color: rgb(15 118 110 / 0.3) !important;
}

.bg-teal-700\/35{
    background-color: rgb(15 118 110 / 0.35) !important;
}

.bg-teal-700\/40{
    background-color: rgb(15 118 110 / 0.4) !important;
}

.bg-teal-700\/45{
    background-color: rgb(15 118 110 / 0.45) !important;
}

.bg-teal-700\/5{
    background-color: rgb(15 118 110 / 0.05) !important;
}

.bg-teal-700\/50{
    background-color: rgb(15 118 110 / 0.5) !important;
}

.bg-teal-700\/55{
    background-color: rgb(15 118 110 / 0.55) !important;
}

.bg-teal-700\/60{
    background-color: rgb(15 118 110 / 0.6) !important;
}

.bg-teal-700\/65{
    background-color: rgb(15 118 110 / 0.65) !important;
}

.bg-teal-700\/70{
    background-color: rgb(15 118 110 / 0.7) !important;
}

.bg-teal-700\/75{
    background-color: rgb(15 118 110 / 0.75) !important;
}

.bg-teal-700\/80{
    background-color: rgb(15 118 110 / 0.8) !important;
}

.bg-teal-700\/85{
    background-color: rgb(15 118 110 / 0.85) !important;
}

.bg-teal-700\/90{
    background-color: rgb(15 118 110 / 0.9) !important;
}

.bg-teal-700\/95{
    background-color: rgb(15 118 110 / 0.95) !important;
}

.bg-teal-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(17 94 89 / var(--tw-bg-opacity)) !important;
}

.bg-teal-800\/0{
    background-color: rgb(17 94 89 / 0) !important;
}

.bg-teal-800\/10{
    background-color: rgb(17 94 89 / 0.1) !important;
}

.bg-teal-800\/100{
    background-color: rgb(17 94 89 / 1) !important;
}

.bg-teal-800\/15{
    background-color: rgb(17 94 89 / 0.15) !important;
}

.bg-teal-800\/20{
    background-color: rgb(17 94 89 / 0.2) !important;
}

.bg-teal-800\/25{
    background-color: rgb(17 94 89 / 0.25) !important;
}

.bg-teal-800\/30{
    background-color: rgb(17 94 89 / 0.3) !important;
}

.bg-teal-800\/35{
    background-color: rgb(17 94 89 / 0.35) !important;
}

.bg-teal-800\/40{
    background-color: rgb(17 94 89 / 0.4) !important;
}

.bg-teal-800\/45{
    background-color: rgb(17 94 89 / 0.45) !important;
}

.bg-teal-800\/5{
    background-color: rgb(17 94 89 / 0.05) !important;
}

.bg-teal-800\/50{
    background-color: rgb(17 94 89 / 0.5) !important;
}

.bg-teal-800\/55{
    background-color: rgb(17 94 89 / 0.55) !important;
}

.bg-teal-800\/60{
    background-color: rgb(17 94 89 / 0.6) !important;
}

.bg-teal-800\/65{
    background-color: rgb(17 94 89 / 0.65) !important;
}

.bg-teal-800\/70{
    background-color: rgb(17 94 89 / 0.7) !important;
}

.bg-teal-800\/75{
    background-color: rgb(17 94 89 / 0.75) !important;
}

.bg-teal-800\/80{
    background-color: rgb(17 94 89 / 0.8) !important;
}

.bg-teal-800\/85{
    background-color: rgb(17 94 89 / 0.85) !important;
}

.bg-teal-800\/90{
    background-color: rgb(17 94 89 / 0.9) !important;
}

.bg-teal-800\/95{
    background-color: rgb(17 94 89 / 0.95) !important;
}

.bg-teal-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(19 78 74 / var(--tw-bg-opacity)) !important;
}

.bg-teal-900\/0{
    background-color: rgb(19 78 74 / 0) !important;
}

.bg-teal-900\/10{
    background-color: rgb(19 78 74 / 0.1) !important;
}

.bg-teal-900\/100{
    background-color: rgb(19 78 74 / 1) !important;
}

.bg-teal-900\/15{
    background-color: rgb(19 78 74 / 0.15) !important;
}

.bg-teal-900\/20{
    background-color: rgb(19 78 74 / 0.2) !important;
}

.bg-teal-900\/25{
    background-color: rgb(19 78 74 / 0.25) !important;
}

.bg-teal-900\/30{
    background-color: rgb(19 78 74 / 0.3) !important;
}

.bg-teal-900\/35{
    background-color: rgb(19 78 74 / 0.35) !important;
}

.bg-teal-900\/40{
    background-color: rgb(19 78 74 / 0.4) !important;
}

.bg-teal-900\/45{
    background-color: rgb(19 78 74 / 0.45) !important;
}

.bg-teal-900\/5{
    background-color: rgb(19 78 74 / 0.05) !important;
}

.bg-teal-900\/50{
    background-color: rgb(19 78 74 / 0.5) !important;
}

.bg-teal-900\/55{
    background-color: rgb(19 78 74 / 0.55) !important;
}

.bg-teal-900\/60{
    background-color: rgb(19 78 74 / 0.6) !important;
}

.bg-teal-900\/65{
    background-color: rgb(19 78 74 / 0.65) !important;
}

.bg-teal-900\/70{
    background-color: rgb(19 78 74 / 0.7) !important;
}

.bg-teal-900\/75{
    background-color: rgb(19 78 74 / 0.75) !important;
}

.bg-teal-900\/80{
    background-color: rgb(19 78 74 / 0.8) !important;
}

.bg-teal-900\/85{
    background-color: rgb(19 78 74 / 0.85) !important;
}

.bg-teal-900\/90{
    background-color: rgb(19 78 74 / 0.9) !important;
}

.bg-teal-900\/95{
    background-color: rgb(19 78 74 / 0.95) !important;
}

.bg-transparent{
    background-color: transparent !important;
}

.bg-transparent\/0{
    background-color: rgb(0 0 0 / 0) !important;
}

.bg-transparent\/10{
    background-color: rgb(0 0 0 / 0.1) !important;
}

.bg-transparent\/100{
    background-color: rgb(0 0 0 / 1) !important;
}

.bg-transparent\/15{
    background-color: rgb(0 0 0 / 0.15) !important;
}

.bg-transparent\/20{
    background-color: rgb(0 0 0 / 0.2) !important;
}

.bg-transparent\/25{
    background-color: rgb(0 0 0 / 0.25) !important;
}

.bg-transparent\/30{
    background-color: rgb(0 0 0 / 0.3) !important;
}

.bg-transparent\/35{
    background-color: rgb(0 0 0 / 0.35) !important;
}

.bg-transparent\/40{
    background-color: rgb(0 0 0 / 0.4) !important;
}

.bg-transparent\/45{
    background-color: rgb(0 0 0 / 0.45) !important;
}

.bg-transparent\/5{
    background-color: rgb(0 0 0 / 0.05) !important;
}

.bg-transparent\/50{
    background-color: rgb(0 0 0 / 0.5) !important;
}

.bg-transparent\/55{
    background-color: rgb(0 0 0 / 0.55) !important;
}

.bg-transparent\/60{
    background-color: rgb(0 0 0 / 0.6) !important;
}

.bg-transparent\/65{
    background-color: rgb(0 0 0 / 0.65) !important;
}

.bg-transparent\/70{
    background-color: rgb(0 0 0 / 0.7) !important;
}

.bg-transparent\/75{
    background-color: rgb(0 0 0 / 0.75) !important;
}

.bg-transparent\/80{
    background-color: rgb(0 0 0 / 0.8) !important;
}

.bg-transparent\/85{
    background-color: rgb(0 0 0 / 0.85) !important;
}

.bg-transparent\/90{
    background-color: rgb(0 0 0 / 0.9) !important;
}

.bg-transparent\/95{
    background-color: rgb(0 0 0 / 0.95) !important;
}

.bg-violet-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(237 233 254 / var(--tw-bg-opacity)) !important;
}

.bg-violet-100\/0{
    background-color: rgb(237 233 254 / 0) !important;
}

.bg-violet-100\/10{
    background-color: rgb(237 233 254 / 0.1) !important;
}

.bg-violet-100\/100{
    background-color: rgb(237 233 254 / 1) !important;
}

.bg-violet-100\/15{
    background-color: rgb(237 233 254 / 0.15) !important;
}

.bg-violet-100\/20{
    background-color: rgb(237 233 254 / 0.2) !important;
}

.bg-violet-100\/25{
    background-color: rgb(237 233 254 / 0.25) !important;
}

.bg-violet-100\/30{
    background-color: rgb(237 233 254 / 0.3) !important;
}

.bg-violet-100\/35{
    background-color: rgb(237 233 254 / 0.35) !important;
}

.bg-violet-100\/40{
    background-color: rgb(237 233 254 / 0.4) !important;
}

.bg-violet-100\/45{
    background-color: rgb(237 233 254 / 0.45) !important;
}

.bg-violet-100\/5{
    background-color: rgb(237 233 254 / 0.05) !important;
}

.bg-violet-100\/50{
    background-color: rgb(237 233 254 / 0.5) !important;
}

.bg-violet-100\/55{
    background-color: rgb(237 233 254 / 0.55) !important;
}

.bg-violet-100\/60{
    background-color: rgb(237 233 254 / 0.6) !important;
}

.bg-violet-100\/65{
    background-color: rgb(237 233 254 / 0.65) !important;
}

.bg-violet-100\/70{
    background-color: rgb(237 233 254 / 0.7) !important;
}

.bg-violet-100\/75{
    background-color: rgb(237 233 254 / 0.75) !important;
}

.bg-violet-100\/80{
    background-color: rgb(237 233 254 / 0.8) !important;
}

.bg-violet-100\/85{
    background-color: rgb(237 233 254 / 0.85) !important;
}

.bg-violet-100\/90{
    background-color: rgb(237 233 254 / 0.9) !important;
}

.bg-violet-100\/95{
    background-color: rgb(237 233 254 / 0.95) !important;
}

.bg-violet-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(221 214 254 / var(--tw-bg-opacity)) !important;
}

.bg-violet-200\/0{
    background-color: rgb(221 214 254 / 0) !important;
}

.bg-violet-200\/10{
    background-color: rgb(221 214 254 / 0.1) !important;
}

.bg-violet-200\/100{
    background-color: rgb(221 214 254 / 1) !important;
}

.bg-violet-200\/15{
    background-color: rgb(221 214 254 / 0.15) !important;
}

.bg-violet-200\/20{
    background-color: rgb(221 214 254 / 0.2) !important;
}

.bg-violet-200\/25{
    background-color: rgb(221 214 254 / 0.25) !important;
}

.bg-violet-200\/30{
    background-color: rgb(221 214 254 / 0.3) !important;
}

.bg-violet-200\/35{
    background-color: rgb(221 214 254 / 0.35) !important;
}

.bg-violet-200\/40{
    background-color: rgb(221 214 254 / 0.4) !important;
}

.bg-violet-200\/45{
    background-color: rgb(221 214 254 / 0.45) !important;
}

.bg-violet-200\/5{
    background-color: rgb(221 214 254 / 0.05) !important;
}

.bg-violet-200\/50{
    background-color: rgb(221 214 254 / 0.5) !important;
}

.bg-violet-200\/55{
    background-color: rgb(221 214 254 / 0.55) !important;
}

.bg-violet-200\/60{
    background-color: rgb(221 214 254 / 0.6) !important;
}

.bg-violet-200\/65{
    background-color: rgb(221 214 254 / 0.65) !important;
}

.bg-violet-200\/70{
    background-color: rgb(221 214 254 / 0.7) !important;
}

.bg-violet-200\/75{
    background-color: rgb(221 214 254 / 0.75) !important;
}

.bg-violet-200\/80{
    background-color: rgb(221 214 254 / 0.8) !important;
}

.bg-violet-200\/85{
    background-color: rgb(221 214 254 / 0.85) !important;
}

.bg-violet-200\/90{
    background-color: rgb(221 214 254 / 0.9) !important;
}

.bg-violet-200\/95{
    background-color: rgb(221 214 254 / 0.95) !important;
}

.bg-violet-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(196 181 253 / var(--tw-bg-opacity)) !important;
}

.bg-violet-300\/0{
    background-color: rgb(196 181 253 / 0) !important;
}

.bg-violet-300\/10{
    background-color: rgb(196 181 253 / 0.1) !important;
}

.bg-violet-300\/100{
    background-color: rgb(196 181 253 / 1) !important;
}

.bg-violet-300\/15{
    background-color: rgb(196 181 253 / 0.15) !important;
}

.bg-violet-300\/20{
    background-color: rgb(196 181 253 / 0.2) !important;
}

.bg-violet-300\/25{
    background-color: rgb(196 181 253 / 0.25) !important;
}

.bg-violet-300\/30{
    background-color: rgb(196 181 253 / 0.3) !important;
}

.bg-violet-300\/35{
    background-color: rgb(196 181 253 / 0.35) !important;
}

.bg-violet-300\/40{
    background-color: rgb(196 181 253 / 0.4) !important;
}

.bg-violet-300\/45{
    background-color: rgb(196 181 253 / 0.45) !important;
}

.bg-violet-300\/5{
    background-color: rgb(196 181 253 / 0.05) !important;
}

.bg-violet-300\/50{
    background-color: rgb(196 181 253 / 0.5) !important;
}

.bg-violet-300\/55{
    background-color: rgb(196 181 253 / 0.55) !important;
}

.bg-violet-300\/60{
    background-color: rgb(196 181 253 / 0.6) !important;
}

.bg-violet-300\/65{
    background-color: rgb(196 181 253 / 0.65) !important;
}

.bg-violet-300\/70{
    background-color: rgb(196 181 253 / 0.7) !important;
}

.bg-violet-300\/75{
    background-color: rgb(196 181 253 / 0.75) !important;
}

.bg-violet-300\/80{
    background-color: rgb(196 181 253 / 0.8) !important;
}

.bg-violet-300\/85{
    background-color: rgb(196 181 253 / 0.85) !important;
}

.bg-violet-300\/90{
    background-color: rgb(196 181 253 / 0.9) !important;
}

.bg-violet-300\/95{
    background-color: rgb(196 181 253 / 0.95) !important;
}

.bg-violet-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(167 139 250 / var(--tw-bg-opacity)) !important;
}

.bg-violet-400\/0{
    background-color: rgb(167 139 250 / 0) !important;
}

.bg-violet-400\/10{
    background-color: rgb(167 139 250 / 0.1) !important;
}

.bg-violet-400\/100{
    background-color: rgb(167 139 250 / 1) !important;
}

.bg-violet-400\/15{
    background-color: rgb(167 139 250 / 0.15) !important;
}

.bg-violet-400\/20{
    background-color: rgb(167 139 250 / 0.2) !important;
}

.bg-violet-400\/25{
    background-color: rgb(167 139 250 / 0.25) !important;
}

.bg-violet-400\/30{
    background-color: rgb(167 139 250 / 0.3) !important;
}

.bg-violet-400\/35{
    background-color: rgb(167 139 250 / 0.35) !important;
}

.bg-violet-400\/40{
    background-color: rgb(167 139 250 / 0.4) !important;
}

.bg-violet-400\/45{
    background-color: rgb(167 139 250 / 0.45) !important;
}

.bg-violet-400\/5{
    background-color: rgb(167 139 250 / 0.05) !important;
}

.bg-violet-400\/50{
    background-color: rgb(167 139 250 / 0.5) !important;
}

.bg-violet-400\/55{
    background-color: rgb(167 139 250 / 0.55) !important;
}

.bg-violet-400\/60{
    background-color: rgb(167 139 250 / 0.6) !important;
}

.bg-violet-400\/65{
    background-color: rgb(167 139 250 / 0.65) !important;
}

.bg-violet-400\/70{
    background-color: rgb(167 139 250 / 0.7) !important;
}

.bg-violet-400\/75{
    background-color: rgb(167 139 250 / 0.75) !important;
}

.bg-violet-400\/80{
    background-color: rgb(167 139 250 / 0.8) !important;
}

.bg-violet-400\/85{
    background-color: rgb(167 139 250 / 0.85) !important;
}

.bg-violet-400\/90{
    background-color: rgb(167 139 250 / 0.9) !important;
}

.bg-violet-400\/95{
    background-color: rgb(167 139 250 / 0.95) !important;
}

.bg-violet-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(245 243 255 / var(--tw-bg-opacity)) !important;
}

.bg-violet-50\/0{
    background-color: rgb(245 243 255 / 0) !important;
}

.bg-violet-50\/10{
    background-color: rgb(245 243 255 / 0.1) !important;
}

.bg-violet-50\/100{
    background-color: rgb(245 243 255 / 1) !important;
}

.bg-violet-50\/15{
    background-color: rgb(245 243 255 / 0.15) !important;
}

.bg-violet-50\/20{
    background-color: rgb(245 243 255 / 0.2) !important;
}

.bg-violet-50\/25{
    background-color: rgb(245 243 255 / 0.25) !important;
}

.bg-violet-50\/30{
    background-color: rgb(245 243 255 / 0.3) !important;
}

.bg-violet-50\/35{
    background-color: rgb(245 243 255 / 0.35) !important;
}

.bg-violet-50\/40{
    background-color: rgb(245 243 255 / 0.4) !important;
}

.bg-violet-50\/45{
    background-color: rgb(245 243 255 / 0.45) !important;
}

.bg-violet-50\/5{
    background-color: rgb(245 243 255 / 0.05) !important;
}

.bg-violet-50\/50{
    background-color: rgb(245 243 255 / 0.5) !important;
}

.bg-violet-50\/55{
    background-color: rgb(245 243 255 / 0.55) !important;
}

.bg-violet-50\/60{
    background-color: rgb(245 243 255 / 0.6) !important;
}

.bg-violet-50\/65{
    background-color: rgb(245 243 255 / 0.65) !important;
}

.bg-violet-50\/70{
    background-color: rgb(245 243 255 / 0.7) !important;
}

.bg-violet-50\/75{
    background-color: rgb(245 243 255 / 0.75) !important;
}

.bg-violet-50\/80{
    background-color: rgb(245 243 255 / 0.8) !important;
}

.bg-violet-50\/85{
    background-color: rgb(245 243 255 / 0.85) !important;
}

.bg-violet-50\/90{
    background-color: rgb(245 243 255 / 0.9) !important;
}

.bg-violet-50\/95{
    background-color: rgb(245 243 255 / 0.95) !important;
}

.bg-violet-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(139 92 246 / var(--tw-bg-opacity)) !important;
}

.bg-violet-500\/0{
    background-color: rgb(139 92 246 / 0) !important;
}

.bg-violet-500\/10{
    background-color: rgb(139 92 246 / 0.1) !important;
}

.bg-violet-500\/100{
    background-color: rgb(139 92 246 / 1) !important;
}

.bg-violet-500\/15{
    background-color: rgb(139 92 246 / 0.15) !important;
}

.bg-violet-500\/20{
    background-color: rgb(139 92 246 / 0.2) !important;
}

.bg-violet-500\/25{
    background-color: rgb(139 92 246 / 0.25) !important;
}

.bg-violet-500\/30{
    background-color: rgb(139 92 246 / 0.3) !important;
}

.bg-violet-500\/35{
    background-color: rgb(139 92 246 / 0.35) !important;
}

.bg-violet-500\/40{
    background-color: rgb(139 92 246 / 0.4) !important;
}

.bg-violet-500\/45{
    background-color: rgb(139 92 246 / 0.45) !important;
}

.bg-violet-500\/5{
    background-color: rgb(139 92 246 / 0.05) !important;
}

.bg-violet-500\/50{
    background-color: rgb(139 92 246 / 0.5) !important;
}

.bg-violet-500\/55{
    background-color: rgb(139 92 246 / 0.55) !important;
}

.bg-violet-500\/60{
    background-color: rgb(139 92 246 / 0.6) !important;
}

.bg-violet-500\/65{
    background-color: rgb(139 92 246 / 0.65) !important;
}

.bg-violet-500\/70{
    background-color: rgb(139 92 246 / 0.7) !important;
}

.bg-violet-500\/75{
    background-color: rgb(139 92 246 / 0.75) !important;
}

.bg-violet-500\/80{
    background-color: rgb(139 92 246 / 0.8) !important;
}

.bg-violet-500\/85{
    background-color: rgb(139 92 246 / 0.85) !important;
}

.bg-violet-500\/90{
    background-color: rgb(139 92 246 / 0.9) !important;
}

.bg-violet-500\/95{
    background-color: rgb(139 92 246 / 0.95) !important;
}

.bg-violet-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(124 58 237 / var(--tw-bg-opacity)) !important;
}

.bg-violet-600\/0{
    background-color: rgb(124 58 237 / 0) !important;
}

.bg-violet-600\/10{
    background-color: rgb(124 58 237 / 0.1) !important;
}

.bg-violet-600\/100{
    background-color: rgb(124 58 237 / 1) !important;
}

.bg-violet-600\/15{
    background-color: rgb(124 58 237 / 0.15) !important;
}

.bg-violet-600\/20{
    background-color: rgb(124 58 237 / 0.2) !important;
}

.bg-violet-600\/25{
    background-color: rgb(124 58 237 / 0.25) !important;
}

.bg-violet-600\/30{
    background-color: rgb(124 58 237 / 0.3) !important;
}

.bg-violet-600\/35{
    background-color: rgb(124 58 237 / 0.35) !important;
}

.bg-violet-600\/40{
    background-color: rgb(124 58 237 / 0.4) !important;
}

.bg-violet-600\/45{
    background-color: rgb(124 58 237 / 0.45) !important;
}

.bg-violet-600\/5{
    background-color: rgb(124 58 237 / 0.05) !important;
}

.bg-violet-600\/50{
    background-color: rgb(124 58 237 / 0.5) !important;
}

.bg-violet-600\/55{
    background-color: rgb(124 58 237 / 0.55) !important;
}

.bg-violet-600\/60{
    background-color: rgb(124 58 237 / 0.6) !important;
}

.bg-violet-600\/65{
    background-color: rgb(124 58 237 / 0.65) !important;
}

.bg-violet-600\/70{
    background-color: rgb(124 58 237 / 0.7) !important;
}

.bg-violet-600\/75{
    background-color: rgb(124 58 237 / 0.75) !important;
}

.bg-violet-600\/80{
    background-color: rgb(124 58 237 / 0.8) !important;
}

.bg-violet-600\/85{
    background-color: rgb(124 58 237 / 0.85) !important;
}

.bg-violet-600\/90{
    background-color: rgb(124 58 237 / 0.9) !important;
}

.bg-violet-600\/95{
    background-color: rgb(124 58 237 / 0.95) !important;
}

.bg-violet-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(109 40 217 / var(--tw-bg-opacity)) !important;
}

.bg-violet-700\/0{
    background-color: rgb(109 40 217 / 0) !important;
}

.bg-violet-700\/10{
    background-color: rgb(109 40 217 / 0.1) !important;
}

.bg-violet-700\/100{
    background-color: rgb(109 40 217 / 1) !important;
}

.bg-violet-700\/15{
    background-color: rgb(109 40 217 / 0.15) !important;
}

.bg-violet-700\/20{
    background-color: rgb(109 40 217 / 0.2) !important;
}

.bg-violet-700\/25{
    background-color: rgb(109 40 217 / 0.25) !important;
}

.bg-violet-700\/30{
    background-color: rgb(109 40 217 / 0.3) !important;
}

.bg-violet-700\/35{
    background-color: rgb(109 40 217 / 0.35) !important;
}

.bg-violet-700\/40{
    background-color: rgb(109 40 217 / 0.4) !important;
}

.bg-violet-700\/45{
    background-color: rgb(109 40 217 / 0.45) !important;
}

.bg-violet-700\/5{
    background-color: rgb(109 40 217 / 0.05) !important;
}

.bg-violet-700\/50{
    background-color: rgb(109 40 217 / 0.5) !important;
}

.bg-violet-700\/55{
    background-color: rgb(109 40 217 / 0.55) !important;
}

.bg-violet-700\/60{
    background-color: rgb(109 40 217 / 0.6) !important;
}

.bg-violet-700\/65{
    background-color: rgb(109 40 217 / 0.65) !important;
}

.bg-violet-700\/70{
    background-color: rgb(109 40 217 / 0.7) !important;
}

.bg-violet-700\/75{
    background-color: rgb(109 40 217 / 0.75) !important;
}

.bg-violet-700\/80{
    background-color: rgb(109 40 217 / 0.8) !important;
}

.bg-violet-700\/85{
    background-color: rgb(109 40 217 / 0.85) !important;
}

.bg-violet-700\/90{
    background-color: rgb(109 40 217 / 0.9) !important;
}

.bg-violet-700\/95{
    background-color: rgb(109 40 217 / 0.95) !important;
}

.bg-violet-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(91 33 182 / var(--tw-bg-opacity)) !important;
}

.bg-violet-800\/0{
    background-color: rgb(91 33 182 / 0) !important;
}

.bg-violet-800\/10{
    background-color: rgb(91 33 182 / 0.1) !important;
}

.bg-violet-800\/100{
    background-color: rgb(91 33 182 / 1) !important;
}

.bg-violet-800\/15{
    background-color: rgb(91 33 182 / 0.15) !important;
}

.bg-violet-800\/20{
    background-color: rgb(91 33 182 / 0.2) !important;
}

.bg-violet-800\/25{
    background-color: rgb(91 33 182 / 0.25) !important;
}

.bg-violet-800\/30{
    background-color: rgb(91 33 182 / 0.3) !important;
}

.bg-violet-800\/35{
    background-color: rgb(91 33 182 / 0.35) !important;
}

.bg-violet-800\/40{
    background-color: rgb(91 33 182 / 0.4) !important;
}

.bg-violet-800\/45{
    background-color: rgb(91 33 182 / 0.45) !important;
}

.bg-violet-800\/5{
    background-color: rgb(91 33 182 / 0.05) !important;
}

.bg-violet-800\/50{
    background-color: rgb(91 33 182 / 0.5) !important;
}

.bg-violet-800\/55{
    background-color: rgb(91 33 182 / 0.55) !important;
}

.bg-violet-800\/60{
    background-color: rgb(91 33 182 / 0.6) !important;
}

.bg-violet-800\/65{
    background-color: rgb(91 33 182 / 0.65) !important;
}

.bg-violet-800\/70{
    background-color: rgb(91 33 182 / 0.7) !important;
}

.bg-violet-800\/75{
    background-color: rgb(91 33 182 / 0.75) !important;
}

.bg-violet-800\/80{
    background-color: rgb(91 33 182 / 0.8) !important;
}

.bg-violet-800\/85{
    background-color: rgb(91 33 182 / 0.85) !important;
}

.bg-violet-800\/90{
    background-color: rgb(91 33 182 / 0.9) !important;
}

.bg-violet-800\/95{
    background-color: rgb(91 33 182 / 0.95) !important;
}

.bg-violet-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(76 29 149 / var(--tw-bg-opacity)) !important;
}

.bg-violet-900\/0{
    background-color: rgb(76 29 149 / 0) !important;
}

.bg-violet-900\/10{
    background-color: rgb(76 29 149 / 0.1) !important;
}

.bg-violet-900\/100{
    background-color: rgb(76 29 149 / 1) !important;
}

.bg-violet-900\/15{
    background-color: rgb(76 29 149 / 0.15) !important;
}

.bg-violet-900\/20{
    background-color: rgb(76 29 149 / 0.2) !important;
}

.bg-violet-900\/25{
    background-color: rgb(76 29 149 / 0.25) !important;
}

.bg-violet-900\/30{
    background-color: rgb(76 29 149 / 0.3) !important;
}

.bg-violet-900\/35{
    background-color: rgb(76 29 149 / 0.35) !important;
}

.bg-violet-900\/40{
    background-color: rgb(76 29 149 / 0.4) !important;
}

.bg-violet-900\/45{
    background-color: rgb(76 29 149 / 0.45) !important;
}

.bg-violet-900\/5{
    background-color: rgb(76 29 149 / 0.05) !important;
}

.bg-violet-900\/50{
    background-color: rgb(76 29 149 / 0.5) !important;
}

.bg-violet-900\/55{
    background-color: rgb(76 29 149 / 0.55) !important;
}

.bg-violet-900\/60{
    background-color: rgb(76 29 149 / 0.6) !important;
}

.bg-violet-900\/65{
    background-color: rgb(76 29 149 / 0.65) !important;
}

.bg-violet-900\/70{
    background-color: rgb(76 29 149 / 0.7) !important;
}

.bg-violet-900\/75{
    background-color: rgb(76 29 149 / 0.75) !important;
}

.bg-violet-900\/80{
    background-color: rgb(76 29 149 / 0.8) !important;
}

.bg-violet-900\/85{
    background-color: rgb(76 29 149 / 0.85) !important;
}

.bg-violet-900\/90{
    background-color: rgb(76 29 149 / 0.9) !important;
}

.bg-violet-900\/95{
    background-color: rgb(76 29 149 / 0.95) !important;
}

.bg-warning{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 210 2 / var(--tw-bg-opacity)) !important;
}

.bg-warning\/0{
    background-color: rgb(238 210 2 / 0) !important;
}

.bg-warning\/10{
    background-color: rgb(238 210 2 / 0.1) !important;
}

.bg-warning\/100{
    background-color: rgb(238 210 2 / 1) !important;
}

.bg-warning\/15{
    background-color: rgb(238 210 2 / 0.15) !important;
}

.bg-warning\/20{
    background-color: rgb(238 210 2 / 0.2) !important;
}

.bg-warning\/25{
    background-color: rgb(238 210 2 / 0.25) !important;
}

.bg-warning\/30{
    background-color: rgb(238 210 2 / 0.3) !important;
}

.bg-warning\/35{
    background-color: rgb(238 210 2 / 0.35) !important;
}

.bg-warning\/40{
    background-color: rgb(238 210 2 / 0.4) !important;
}

.bg-warning\/45{
    background-color: rgb(238 210 2 / 0.45) !important;
}

.bg-warning\/5{
    background-color: rgb(238 210 2 / 0.05) !important;
}

.bg-warning\/50{
    background-color: rgb(238 210 2 / 0.5) !important;
}

.bg-warning\/55{
    background-color: rgb(238 210 2 / 0.55) !important;
}

.bg-warning\/60{
    background-color: rgb(238 210 2 / 0.6) !important;
}

.bg-warning\/65{
    background-color: rgb(238 210 2 / 0.65) !important;
}

.bg-warning\/70{
    background-color: rgb(238 210 2 / 0.7) !important;
}

.bg-warning\/75{
    background-color: rgb(238 210 2 / 0.75) !important;
}

.bg-warning\/80{
    background-color: rgb(238 210 2 / 0.8) !important;
}

.bg-warning\/85{
    background-color: rgb(238 210 2 / 0.85) !important;
}

.bg-warning\/90{
    background-color: rgb(238 210 2 / 0.9) !important;
}

.bg-warning\/95{
    background-color: rgb(238 210 2 / 0.95) !important;
}

.bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-white\/0{
    background-color: rgb(255 255 255 / 0) !important;
}

.bg-white\/10{
    background-color: rgb(255 255 255 / 0.1) !important;
}

.bg-white\/100{
    background-color: rgb(255 255 255 / 1) !important;
}

.bg-white\/15{
    background-color: rgb(255 255 255 / 0.15) !important;
}

.bg-white\/20{
    background-color: rgb(255 255 255 / 0.2) !important;
}

.bg-white\/25{
    background-color: rgb(255 255 255 / 0.25) !important;
}

.bg-white\/30{
    background-color: rgb(255 255 255 / 0.3) !important;
}

.bg-white\/35{
    background-color: rgb(255 255 255 / 0.35) !important;
}

.bg-white\/40{
    background-color: rgb(255 255 255 / 0.4) !important;
}

.bg-white\/45{
    background-color: rgb(255 255 255 / 0.45) !important;
}

.bg-white\/5{
    background-color: rgb(255 255 255 / 0.05) !important;
}

.bg-white\/50{
    background-color: rgb(255 255 255 / 0.5) !important;
}

.bg-white\/55{
    background-color: rgb(255 255 255 / 0.55) !important;
}

.bg-white\/60{
    background-color: rgb(255 255 255 / 0.6) !important;
}

.bg-white\/65{
    background-color: rgb(255 255 255 / 0.65) !important;
}

.bg-white\/70{
    background-color: rgb(255 255 255 / 0.7) !important;
}

.bg-white\/75{
    background-color: rgb(255 255 255 / 0.75) !important;
}

.bg-white\/80{
    background-color: rgb(255 255 255 / 0.8) !important;
}

.bg-white\/85{
    background-color: rgb(255 255 255 / 0.85) !important;
}

.bg-white\/90{
    background-color: rgb(255 255 255 / 0.9) !important;
}

.bg-white\/95{
    background-color: rgb(255 255 255 / 0.95) !important;
}

.bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 242 149 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-100\/0{
    background-color: rgb(254 242 149 / 0) !important;
}

.bg-yellow-100\/10{
    background-color: rgb(254 242 149 / 0.1) !important;
}

.bg-yellow-100\/100{
    background-color: rgb(254 242 149 / 1) !important;
}

.bg-yellow-100\/15{
    background-color: rgb(254 242 149 / 0.15) !important;
}

.bg-yellow-100\/20{
    background-color: rgb(254 242 149 / 0.2) !important;
}

.bg-yellow-100\/25{
    background-color: rgb(254 242 149 / 0.25) !important;
}

.bg-yellow-100\/30{
    background-color: rgb(254 242 149 / 0.3) !important;
}

.bg-yellow-100\/35{
    background-color: rgb(254 242 149 / 0.35) !important;
}

.bg-yellow-100\/40{
    background-color: rgb(254 242 149 / 0.4) !important;
}

.bg-yellow-100\/45{
    background-color: rgb(254 242 149 / 0.45) !important;
}

.bg-yellow-100\/5{
    background-color: rgb(254 242 149 / 0.05) !important;
}

.bg-yellow-100\/50{
    background-color: rgb(254 242 149 / 0.5) !important;
}

.bg-yellow-100\/55{
    background-color: rgb(254 242 149 / 0.55) !important;
}

.bg-yellow-100\/60{
    background-color: rgb(254 242 149 / 0.6) !important;
}

.bg-yellow-100\/65{
    background-color: rgb(254 242 149 / 0.65) !important;
}

.bg-yellow-100\/70{
    background-color: rgb(254 242 149 / 0.7) !important;
}

.bg-yellow-100\/75{
    background-color: rgb(254 242 149 / 0.75) !important;
}

.bg-yellow-100\/80{
    background-color: rgb(254 242 149 / 0.8) !important;
}

.bg-yellow-100\/85{
    background-color: rgb(254 242 149 / 0.85) !important;
}

.bg-yellow-100\/90{
    background-color: rgb(254 242 149 / 0.9) !important;
}

.bg-yellow-100\/95{
    background-color: rgb(254 242 149 / 0.95) !important;
}

.bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 237 109 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-200\/0{
    background-color: rgb(254 237 109 / 0) !important;
}

.bg-yellow-200\/10{
    background-color: rgb(254 237 109 / 0.1) !important;
}

.bg-yellow-200\/100{
    background-color: rgb(254 237 109 / 1) !important;
}

.bg-yellow-200\/15{
    background-color: rgb(254 237 109 / 0.15) !important;
}

.bg-yellow-200\/20{
    background-color: rgb(254 237 109 / 0.2) !important;
}

.bg-yellow-200\/25{
    background-color: rgb(254 237 109 / 0.25) !important;
}

.bg-yellow-200\/30{
    background-color: rgb(254 237 109 / 0.3) !important;
}

.bg-yellow-200\/35{
    background-color: rgb(254 237 109 / 0.35) !important;
}

.bg-yellow-200\/40{
    background-color: rgb(254 237 109 / 0.4) !important;
}

.bg-yellow-200\/45{
    background-color: rgb(254 237 109 / 0.45) !important;
}

.bg-yellow-200\/5{
    background-color: rgb(254 237 109 / 0.05) !important;
}

.bg-yellow-200\/50{
    background-color: rgb(254 237 109 / 0.5) !important;
}

.bg-yellow-200\/55{
    background-color: rgb(254 237 109 / 0.55) !important;
}

.bg-yellow-200\/60{
    background-color: rgb(254 237 109 / 0.6) !important;
}

.bg-yellow-200\/65{
    background-color: rgb(254 237 109 / 0.65) !important;
}

.bg-yellow-200\/70{
    background-color: rgb(254 237 109 / 0.7) !important;
}

.bg-yellow-200\/75{
    background-color: rgb(254 237 109 / 0.75) !important;
}

.bg-yellow-200\/80{
    background-color: rgb(254 237 109 / 0.8) !important;
}

.bg-yellow-200\/85{
    background-color: rgb(254 237 109 / 0.85) !important;
}

.bg-yellow-200\/90{
    background-color: rgb(254 237 109 / 0.9) !important;
}

.bg-yellow-200\/95{
    background-color: rgb(254 237 109 / 0.95) !important;
}

.bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 231 68 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-300\/0{
    background-color: rgb(253 231 68 / 0) !important;
}

.bg-yellow-300\/10{
    background-color: rgb(253 231 68 / 0.1) !important;
}

.bg-yellow-300\/100{
    background-color: rgb(253 231 68 / 1) !important;
}

.bg-yellow-300\/15{
    background-color: rgb(253 231 68 / 0.15) !important;
}

.bg-yellow-300\/20{
    background-color: rgb(253 231 68 / 0.2) !important;
}

.bg-yellow-300\/25{
    background-color: rgb(253 231 68 / 0.25) !important;
}

.bg-yellow-300\/30{
    background-color: rgb(253 231 68 / 0.3) !important;
}

.bg-yellow-300\/35{
    background-color: rgb(253 231 68 / 0.35) !important;
}

.bg-yellow-300\/40{
    background-color: rgb(253 231 68 / 0.4) !important;
}

.bg-yellow-300\/45{
    background-color: rgb(253 231 68 / 0.45) !important;
}

.bg-yellow-300\/5{
    background-color: rgb(253 231 68 / 0.05) !important;
}

.bg-yellow-300\/50{
    background-color: rgb(253 231 68 / 0.5) !important;
}

.bg-yellow-300\/55{
    background-color: rgb(253 231 68 / 0.55) !important;
}

.bg-yellow-300\/60{
    background-color: rgb(253 231 68 / 0.6) !important;
}

.bg-yellow-300\/65{
    background-color: rgb(253 231 68 / 0.65) !important;
}

.bg-yellow-300\/70{
    background-color: rgb(253 231 68 / 0.7) !important;
}

.bg-yellow-300\/75{
    background-color: rgb(253 231 68 / 0.75) !important;
}

.bg-yellow-300\/80{
    background-color: rgb(253 231 68 / 0.8) !important;
}

.bg-yellow-300\/85{
    background-color: rgb(253 231 68 / 0.85) !important;
}

.bg-yellow-300\/90{
    background-color: rgb(253 231 68 / 0.9) !important;
}

.bg-yellow-300\/95{
    background-color: rgb(253 231 68 / 0.95) !important;
}

.bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(253 226 28 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-400\/0{
    background-color: rgb(253 226 28 / 0) !important;
}

.bg-yellow-400\/10{
    background-color: rgb(253 226 28 / 0.1) !important;
}

.bg-yellow-400\/100{
    background-color: rgb(253 226 28 / 1) !important;
}

.bg-yellow-400\/15{
    background-color: rgb(253 226 28 / 0.15) !important;
}

.bg-yellow-400\/20{
    background-color: rgb(253 226 28 / 0.2) !important;
}

.bg-yellow-400\/25{
    background-color: rgb(253 226 28 / 0.25) !important;
}

.bg-yellow-400\/30{
    background-color: rgb(253 226 28 / 0.3) !important;
}

.bg-yellow-400\/35{
    background-color: rgb(253 226 28 / 0.35) !important;
}

.bg-yellow-400\/40{
    background-color: rgb(253 226 28 / 0.4) !important;
}

.bg-yellow-400\/45{
    background-color: rgb(253 226 28 / 0.45) !important;
}

.bg-yellow-400\/5{
    background-color: rgb(253 226 28 / 0.05) !important;
}

.bg-yellow-400\/50{
    background-color: rgb(253 226 28 / 0.5) !important;
}

.bg-yellow-400\/55{
    background-color: rgb(253 226 28 / 0.55) !important;
}

.bg-yellow-400\/60{
    background-color: rgb(253 226 28 / 0.6) !important;
}

.bg-yellow-400\/65{
    background-color: rgb(253 226 28 / 0.65) !important;
}

.bg-yellow-400\/70{
    background-color: rgb(253 226 28 / 0.7) !important;
}

.bg-yellow-400\/75{
    background-color: rgb(253 226 28 / 0.75) !important;
}

.bg-yellow-400\/80{
    background-color: rgb(253 226 28 / 0.8) !important;
}

.bg-yellow-400\/85{
    background-color: rgb(253 226 28 / 0.85) !important;
}

.bg-yellow-400\/90{
    background-color: rgb(253 226 28 / 0.9) !important;
}

.bg-yellow-400\/95{
    background-color: rgb(253 226 28 / 0.95) !important;
}

.bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(254 244 169 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-50\/0{
    background-color: rgb(254 244 169 / 0) !important;
}

.bg-yellow-50\/10{
    background-color: rgb(254 244 169 / 0.1) !important;
}

.bg-yellow-50\/100{
    background-color: rgb(254 244 169 / 1) !important;
}

.bg-yellow-50\/15{
    background-color: rgb(254 244 169 / 0.15) !important;
}

.bg-yellow-50\/20{
    background-color: rgb(254 244 169 / 0.2) !important;
}

.bg-yellow-50\/25{
    background-color: rgb(254 244 169 / 0.25) !important;
}

.bg-yellow-50\/30{
    background-color: rgb(254 244 169 / 0.3) !important;
}

.bg-yellow-50\/35{
    background-color: rgb(254 244 169 / 0.35) !important;
}

.bg-yellow-50\/40{
    background-color: rgb(254 244 169 / 0.4) !important;
}

.bg-yellow-50\/45{
    background-color: rgb(254 244 169 / 0.45) !important;
}

.bg-yellow-50\/5{
    background-color: rgb(254 244 169 / 0.05) !important;
}

.bg-yellow-50\/50{
    background-color: rgb(254 244 169 / 0.5) !important;
}

.bg-yellow-50\/55{
    background-color: rgb(254 244 169 / 0.55) !important;
}

.bg-yellow-50\/60{
    background-color: rgb(254 244 169 / 0.6) !important;
}

.bg-yellow-50\/65{
    background-color: rgb(254 244 169 / 0.65) !important;
}

.bg-yellow-50\/70{
    background-color: rgb(254 244 169 / 0.7) !important;
}

.bg-yellow-50\/75{
    background-color: rgb(254 244 169 / 0.75) !important;
}

.bg-yellow-50\/80{
    background-color: rgb(254 244 169 / 0.8) !important;
}

.bg-yellow-50\/85{
    background-color: rgb(254 244 169 / 0.85) !important;
}

.bg-yellow-50\/90{
    background-color: rgb(254 244 169 / 0.9) !important;
}

.bg-yellow-50\/95{
    background-color: rgb(254 244 169 / 0.95) !important;
}

.bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(238 210 2 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-500\/0{
    background-color: rgb(238 210 2 / 0) !important;
}

.bg-yellow-500\/10{
    background-color: rgb(238 210 2 / 0.1) !important;
}

.bg-yellow-500\/100{
    background-color: rgb(238 210 2 / 1) !important;
}

.bg-yellow-500\/15{
    background-color: rgb(238 210 2 / 0.15) !important;
}

.bg-yellow-500\/20{
    background-color: rgb(238 210 2 / 0.2) !important;
}

.bg-yellow-500\/25{
    background-color: rgb(238 210 2 / 0.25) !important;
}

.bg-yellow-500\/30{
    background-color: rgb(238 210 2 / 0.3) !important;
}

.bg-yellow-500\/35{
    background-color: rgb(238 210 2 / 0.35) !important;
}

.bg-yellow-500\/40{
    background-color: rgb(238 210 2 / 0.4) !important;
}

.bg-yellow-500\/45{
    background-color: rgb(238 210 2 / 0.45) !important;
}

.bg-yellow-500\/5{
    background-color: rgb(238 210 2 / 0.05) !important;
}

.bg-yellow-500\/50{
    background-color: rgb(238 210 2 / 0.5) !important;
}

.bg-yellow-500\/55{
    background-color: rgb(238 210 2 / 0.55) !important;
}

.bg-yellow-500\/60{
    background-color: rgb(238 210 2 / 0.6) !important;
}

.bg-yellow-500\/65{
    background-color: rgb(238 210 2 / 0.65) !important;
}

.bg-yellow-500\/70{
    background-color: rgb(238 210 2 / 0.7) !important;
}

.bg-yellow-500\/75{
    background-color: rgb(238 210 2 / 0.75) !important;
}

.bg-yellow-500\/80{
    background-color: rgb(238 210 2 / 0.8) !important;
}

.bg-yellow-500\/85{
    background-color: rgb(238 210 2 / 0.85) !important;
}

.bg-yellow-500\/90{
    background-color: rgb(238 210 2 / 0.9) !important;
}

.bg-yellow-500\/95{
    background-color: rgb(238 210 2 / 0.95) !important;
}

.bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(198 174 2 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-600\/0{
    background-color: rgb(198 174 2 / 0) !important;
}

.bg-yellow-600\/10{
    background-color: rgb(198 174 2 / 0.1) !important;
}

.bg-yellow-600\/100{
    background-color: rgb(198 174 2 / 1) !important;
}

.bg-yellow-600\/15{
    background-color: rgb(198 174 2 / 0.15) !important;
}

.bg-yellow-600\/20{
    background-color: rgb(198 174 2 / 0.2) !important;
}

.bg-yellow-600\/25{
    background-color: rgb(198 174 2 / 0.25) !important;
}

.bg-yellow-600\/30{
    background-color: rgb(198 174 2 / 0.3) !important;
}

.bg-yellow-600\/35{
    background-color: rgb(198 174 2 / 0.35) !important;
}

.bg-yellow-600\/40{
    background-color: rgb(198 174 2 / 0.4) !important;
}

.bg-yellow-600\/45{
    background-color: rgb(198 174 2 / 0.45) !important;
}

.bg-yellow-600\/5{
    background-color: rgb(198 174 2 / 0.05) !important;
}

.bg-yellow-600\/50{
    background-color: rgb(198 174 2 / 0.5) !important;
}

.bg-yellow-600\/55{
    background-color: rgb(198 174 2 / 0.55) !important;
}

.bg-yellow-600\/60{
    background-color: rgb(198 174 2 / 0.6) !important;
}

.bg-yellow-600\/65{
    background-color: rgb(198 174 2 / 0.65) !important;
}

.bg-yellow-600\/70{
    background-color: rgb(198 174 2 / 0.7) !important;
}

.bg-yellow-600\/75{
    background-color: rgb(198 174 2 / 0.75) !important;
}

.bg-yellow-600\/80{
    background-color: rgb(198 174 2 / 0.8) !important;
}

.bg-yellow-600\/85{
    background-color: rgb(198 174 2 / 0.85) !important;
}

.bg-yellow-600\/90{
    background-color: rgb(198 174 2 / 0.9) !important;
}

.bg-yellow-600\/95{
    background-color: rgb(198 174 2 / 0.95) !important;
}

.bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(157 139 1 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-700\/0{
    background-color: rgb(157 139 1 / 0) !important;
}

.bg-yellow-700\/10{
    background-color: rgb(157 139 1 / 0.1) !important;
}

.bg-yellow-700\/100{
    background-color: rgb(157 139 1 / 1) !important;
}

.bg-yellow-700\/15{
    background-color: rgb(157 139 1 / 0.15) !important;
}

.bg-yellow-700\/20{
    background-color: rgb(157 139 1 / 0.2) !important;
}

.bg-yellow-700\/25{
    background-color: rgb(157 139 1 / 0.25) !important;
}

.bg-yellow-700\/30{
    background-color: rgb(157 139 1 / 0.3) !important;
}

.bg-yellow-700\/35{
    background-color: rgb(157 139 1 / 0.35) !important;
}

.bg-yellow-700\/40{
    background-color: rgb(157 139 1 / 0.4) !important;
}

.bg-yellow-700\/45{
    background-color: rgb(157 139 1 / 0.45) !important;
}

.bg-yellow-700\/5{
    background-color: rgb(157 139 1 / 0.05) !important;
}

.bg-yellow-700\/50{
    background-color: rgb(157 139 1 / 0.5) !important;
}

.bg-yellow-700\/55{
    background-color: rgb(157 139 1 / 0.55) !important;
}

.bg-yellow-700\/60{
    background-color: rgb(157 139 1 / 0.6) !important;
}

.bg-yellow-700\/65{
    background-color: rgb(157 139 1 / 0.65) !important;
}

.bg-yellow-700\/70{
    background-color: rgb(157 139 1 / 0.7) !important;
}

.bg-yellow-700\/75{
    background-color: rgb(157 139 1 / 0.75) !important;
}

.bg-yellow-700\/80{
    background-color: rgb(157 139 1 / 0.8) !important;
}

.bg-yellow-700\/85{
    background-color: rgb(157 139 1 / 0.85) !important;
}

.bg-yellow-700\/90{
    background-color: rgb(157 139 1 / 0.9) !important;
}

.bg-yellow-700\/95{
    background-color: rgb(157 139 1 / 0.95) !important;
}

.bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(117 103 1 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-800\/0{
    background-color: rgb(117 103 1 / 0) !important;
}

.bg-yellow-800\/10{
    background-color: rgb(117 103 1 / 0.1) !important;
}

.bg-yellow-800\/100{
    background-color: rgb(117 103 1 / 1) !important;
}

.bg-yellow-800\/15{
    background-color: rgb(117 103 1 / 0.15) !important;
}

.bg-yellow-800\/20{
    background-color: rgb(117 103 1 / 0.2) !important;
}

.bg-yellow-800\/25{
    background-color: rgb(117 103 1 / 0.25) !important;
}

.bg-yellow-800\/30{
    background-color: rgb(117 103 1 / 0.3) !important;
}

.bg-yellow-800\/35{
    background-color: rgb(117 103 1 / 0.35) !important;
}

.bg-yellow-800\/40{
    background-color: rgb(117 103 1 / 0.4) !important;
}

.bg-yellow-800\/45{
    background-color: rgb(117 103 1 / 0.45) !important;
}

.bg-yellow-800\/5{
    background-color: rgb(117 103 1 / 0.05) !important;
}

.bg-yellow-800\/50{
    background-color: rgb(117 103 1 / 0.5) !important;
}

.bg-yellow-800\/55{
    background-color: rgb(117 103 1 / 0.55) !important;
}

.bg-yellow-800\/60{
    background-color: rgb(117 103 1 / 0.6) !important;
}

.bg-yellow-800\/65{
    background-color: rgb(117 103 1 / 0.65) !important;
}

.bg-yellow-800\/70{
    background-color: rgb(117 103 1 / 0.7) !important;
}

.bg-yellow-800\/75{
    background-color: rgb(117 103 1 / 0.75) !important;
}

.bg-yellow-800\/80{
    background-color: rgb(117 103 1 / 0.8) !important;
}

.bg-yellow-800\/85{
    background-color: rgb(117 103 1 / 0.85) !important;
}

.bg-yellow-800\/90{
    background-color: rgb(117 103 1 / 0.9) !important;
}

.bg-yellow-800\/95{
    background-color: rgb(117 103 1 / 0.95) !important;
}

.bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(76 67 1 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-900\/0{
    background-color: rgb(76 67 1 / 0) !important;
}

.bg-yellow-900\/10{
    background-color: rgb(76 67 1 / 0.1) !important;
}

.bg-yellow-900\/100{
    background-color: rgb(76 67 1 / 1) !important;
}

.bg-yellow-900\/15{
    background-color: rgb(76 67 1 / 0.15) !important;
}

.bg-yellow-900\/20{
    background-color: rgb(76 67 1 / 0.2) !important;
}

.bg-yellow-900\/25{
    background-color: rgb(76 67 1 / 0.25) !important;
}

.bg-yellow-900\/30{
    background-color: rgb(76 67 1 / 0.3) !important;
}

.bg-yellow-900\/35{
    background-color: rgb(76 67 1 / 0.35) !important;
}

.bg-yellow-900\/40{
    background-color: rgb(76 67 1 / 0.4) !important;
}

.bg-yellow-900\/45{
    background-color: rgb(76 67 1 / 0.45) !important;
}

.bg-yellow-900\/5{
    background-color: rgb(76 67 1 / 0.05) !important;
}

.bg-yellow-900\/50{
    background-color: rgb(76 67 1 / 0.5) !important;
}

.bg-yellow-900\/55{
    background-color: rgb(76 67 1 / 0.55) !important;
}

.bg-yellow-900\/60{
    background-color: rgb(76 67 1 / 0.6) !important;
}

.bg-yellow-900\/65{
    background-color: rgb(76 67 1 / 0.65) !important;
}

.bg-yellow-900\/70{
    background-color: rgb(76 67 1 / 0.7) !important;
}

.bg-yellow-900\/75{
    background-color: rgb(76 67 1 / 0.75) !important;
}

.bg-yellow-900\/80{
    background-color: rgb(76 67 1 / 0.8) !important;
}

.bg-yellow-900\/85{
    background-color: rgb(76 67 1 / 0.85) !important;
}

.bg-yellow-900\/90{
    background-color: rgb(76 67 1 / 0.9) !important;
}

.bg-yellow-900\/95{
    background-color: rgb(76 67 1 / 0.95) !important;
}

.bg-zinc-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(244 244 245 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-100\/0{
    background-color: rgb(244 244 245 / 0) !important;
}

.bg-zinc-100\/10{
    background-color: rgb(244 244 245 / 0.1) !important;
}

.bg-zinc-100\/100{
    background-color: rgb(244 244 245 / 1) !important;
}

.bg-zinc-100\/15{
    background-color: rgb(244 244 245 / 0.15) !important;
}

.bg-zinc-100\/20{
    background-color: rgb(244 244 245 / 0.2) !important;
}

.bg-zinc-100\/25{
    background-color: rgb(244 244 245 / 0.25) !important;
}

.bg-zinc-100\/30{
    background-color: rgb(244 244 245 / 0.3) !important;
}

.bg-zinc-100\/35{
    background-color: rgb(244 244 245 / 0.35) !important;
}

.bg-zinc-100\/40{
    background-color: rgb(244 244 245 / 0.4) !important;
}

.bg-zinc-100\/45{
    background-color: rgb(244 244 245 / 0.45) !important;
}

.bg-zinc-100\/5{
    background-color: rgb(244 244 245 / 0.05) !important;
}

.bg-zinc-100\/50{
    background-color: rgb(244 244 245 / 0.5) !important;
}

.bg-zinc-100\/55{
    background-color: rgb(244 244 245 / 0.55) !important;
}

.bg-zinc-100\/60{
    background-color: rgb(244 244 245 / 0.6) !important;
}

.bg-zinc-100\/65{
    background-color: rgb(244 244 245 / 0.65) !important;
}

.bg-zinc-100\/70{
    background-color: rgb(244 244 245 / 0.7) !important;
}

.bg-zinc-100\/75{
    background-color: rgb(244 244 245 / 0.75) !important;
}

.bg-zinc-100\/80{
    background-color: rgb(244 244 245 / 0.8) !important;
}

.bg-zinc-100\/85{
    background-color: rgb(244 244 245 / 0.85) !important;
}

.bg-zinc-100\/90{
    background-color: rgb(244 244 245 / 0.9) !important;
}

.bg-zinc-100\/95{
    background-color: rgb(244 244 245 / 0.95) !important;
}

.bg-zinc-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(228 228 231 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-200\/0{
    background-color: rgb(228 228 231 / 0) !important;
}

.bg-zinc-200\/10{
    background-color: rgb(228 228 231 / 0.1) !important;
}

.bg-zinc-200\/100{
    background-color: rgb(228 228 231 / 1) !important;
}

.bg-zinc-200\/15{
    background-color: rgb(228 228 231 / 0.15) !important;
}

.bg-zinc-200\/20{
    background-color: rgb(228 228 231 / 0.2) !important;
}

.bg-zinc-200\/25{
    background-color: rgb(228 228 231 / 0.25) !important;
}

.bg-zinc-200\/30{
    background-color: rgb(228 228 231 / 0.3) !important;
}

.bg-zinc-200\/35{
    background-color: rgb(228 228 231 / 0.35) !important;
}

.bg-zinc-200\/40{
    background-color: rgb(228 228 231 / 0.4) !important;
}

.bg-zinc-200\/45{
    background-color: rgb(228 228 231 / 0.45) !important;
}

.bg-zinc-200\/5{
    background-color: rgb(228 228 231 / 0.05) !important;
}

.bg-zinc-200\/50{
    background-color: rgb(228 228 231 / 0.5) !important;
}

.bg-zinc-200\/55{
    background-color: rgb(228 228 231 / 0.55) !important;
}

.bg-zinc-200\/60{
    background-color: rgb(228 228 231 / 0.6) !important;
}

.bg-zinc-200\/65{
    background-color: rgb(228 228 231 / 0.65) !important;
}

.bg-zinc-200\/70{
    background-color: rgb(228 228 231 / 0.7) !important;
}

.bg-zinc-200\/75{
    background-color: rgb(228 228 231 / 0.75) !important;
}

.bg-zinc-200\/80{
    background-color: rgb(228 228 231 / 0.8) !important;
}

.bg-zinc-200\/85{
    background-color: rgb(228 228 231 / 0.85) !important;
}

.bg-zinc-200\/90{
    background-color: rgb(228 228 231 / 0.9) !important;
}

.bg-zinc-200\/95{
    background-color: rgb(228 228 231 / 0.95) !important;
}

.bg-zinc-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(212 212 216 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-300\/0{
    background-color: rgb(212 212 216 / 0) !important;
}

.bg-zinc-300\/10{
    background-color: rgb(212 212 216 / 0.1) !important;
}

.bg-zinc-300\/100{
    background-color: rgb(212 212 216 / 1) !important;
}

.bg-zinc-300\/15{
    background-color: rgb(212 212 216 / 0.15) !important;
}

.bg-zinc-300\/20{
    background-color: rgb(212 212 216 / 0.2) !important;
}

.bg-zinc-300\/25{
    background-color: rgb(212 212 216 / 0.25) !important;
}

.bg-zinc-300\/30{
    background-color: rgb(212 212 216 / 0.3) !important;
}

.bg-zinc-300\/35{
    background-color: rgb(212 212 216 / 0.35) !important;
}

.bg-zinc-300\/40{
    background-color: rgb(212 212 216 / 0.4) !important;
}

.bg-zinc-300\/45{
    background-color: rgb(212 212 216 / 0.45) !important;
}

.bg-zinc-300\/5{
    background-color: rgb(212 212 216 / 0.05) !important;
}

.bg-zinc-300\/50{
    background-color: rgb(212 212 216 / 0.5) !important;
}

.bg-zinc-300\/55{
    background-color: rgb(212 212 216 / 0.55) !important;
}

.bg-zinc-300\/60{
    background-color: rgb(212 212 216 / 0.6) !important;
}

.bg-zinc-300\/65{
    background-color: rgb(212 212 216 / 0.65) !important;
}

.bg-zinc-300\/70{
    background-color: rgb(212 212 216 / 0.7) !important;
}

.bg-zinc-300\/75{
    background-color: rgb(212 212 216 / 0.75) !important;
}

.bg-zinc-300\/80{
    background-color: rgb(212 212 216 / 0.8) !important;
}

.bg-zinc-300\/85{
    background-color: rgb(212 212 216 / 0.85) !important;
}

.bg-zinc-300\/90{
    background-color: rgb(212 212 216 / 0.9) !important;
}

.bg-zinc-300\/95{
    background-color: rgb(212 212 216 / 0.95) !important;
}

.bg-zinc-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(161 161 170 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-400\/0{
    background-color: rgb(161 161 170 / 0) !important;
}

.bg-zinc-400\/10{
    background-color: rgb(161 161 170 / 0.1) !important;
}

.bg-zinc-400\/100{
    background-color: rgb(161 161 170 / 1) !important;
}

.bg-zinc-400\/15{
    background-color: rgb(161 161 170 / 0.15) !important;
}

.bg-zinc-400\/20{
    background-color: rgb(161 161 170 / 0.2) !important;
}

.bg-zinc-400\/25{
    background-color: rgb(161 161 170 / 0.25) !important;
}

.bg-zinc-400\/30{
    background-color: rgb(161 161 170 / 0.3) !important;
}

.bg-zinc-400\/35{
    background-color: rgb(161 161 170 / 0.35) !important;
}

.bg-zinc-400\/40{
    background-color: rgb(161 161 170 / 0.4) !important;
}

.bg-zinc-400\/45{
    background-color: rgb(161 161 170 / 0.45) !important;
}

.bg-zinc-400\/5{
    background-color: rgb(161 161 170 / 0.05) !important;
}

.bg-zinc-400\/50{
    background-color: rgb(161 161 170 / 0.5) !important;
}

.bg-zinc-400\/55{
    background-color: rgb(161 161 170 / 0.55) !important;
}

.bg-zinc-400\/60{
    background-color: rgb(161 161 170 / 0.6) !important;
}

.bg-zinc-400\/65{
    background-color: rgb(161 161 170 / 0.65) !important;
}

.bg-zinc-400\/70{
    background-color: rgb(161 161 170 / 0.7) !important;
}

.bg-zinc-400\/75{
    background-color: rgb(161 161 170 / 0.75) !important;
}

.bg-zinc-400\/80{
    background-color: rgb(161 161 170 / 0.8) !important;
}

.bg-zinc-400\/85{
    background-color: rgb(161 161 170 / 0.85) !important;
}

.bg-zinc-400\/90{
    background-color: rgb(161 161 170 / 0.9) !important;
}

.bg-zinc-400\/95{
    background-color: rgb(161 161 170 / 0.95) !important;
}

.bg-zinc-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-50\/0{
    background-color: rgb(250 250 250 / 0) !important;
}

.bg-zinc-50\/10{
    background-color: rgb(250 250 250 / 0.1) !important;
}

.bg-zinc-50\/100{
    background-color: rgb(250 250 250 / 1) !important;
}

.bg-zinc-50\/15{
    background-color: rgb(250 250 250 / 0.15) !important;
}

.bg-zinc-50\/20{
    background-color: rgb(250 250 250 / 0.2) !important;
}

.bg-zinc-50\/25{
    background-color: rgb(250 250 250 / 0.25) !important;
}

.bg-zinc-50\/30{
    background-color: rgb(250 250 250 / 0.3) !important;
}

.bg-zinc-50\/35{
    background-color: rgb(250 250 250 / 0.35) !important;
}

.bg-zinc-50\/40{
    background-color: rgb(250 250 250 / 0.4) !important;
}

.bg-zinc-50\/45{
    background-color: rgb(250 250 250 / 0.45) !important;
}

.bg-zinc-50\/5{
    background-color: rgb(250 250 250 / 0.05) !important;
}

.bg-zinc-50\/50{
    background-color: rgb(250 250 250 / 0.5) !important;
}

.bg-zinc-50\/55{
    background-color: rgb(250 250 250 / 0.55) !important;
}

.bg-zinc-50\/60{
    background-color: rgb(250 250 250 / 0.6) !important;
}

.bg-zinc-50\/65{
    background-color: rgb(250 250 250 / 0.65) !important;
}

.bg-zinc-50\/70{
    background-color: rgb(250 250 250 / 0.7) !important;
}

.bg-zinc-50\/75{
    background-color: rgb(250 250 250 / 0.75) !important;
}

.bg-zinc-50\/80{
    background-color: rgb(250 250 250 / 0.8) !important;
}

.bg-zinc-50\/85{
    background-color: rgb(250 250 250 / 0.85) !important;
}

.bg-zinc-50\/90{
    background-color: rgb(250 250 250 / 0.9) !important;
}

.bg-zinc-50\/95{
    background-color: rgb(250 250 250 / 0.95) !important;
}

.bg-zinc-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(113 113 122 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-500\/0{
    background-color: rgb(113 113 122 / 0) !important;
}

.bg-zinc-500\/10{
    background-color: rgb(113 113 122 / 0.1) !important;
}

.bg-zinc-500\/100{
    background-color: rgb(113 113 122 / 1) !important;
}

.bg-zinc-500\/15{
    background-color: rgb(113 113 122 / 0.15) !important;
}

.bg-zinc-500\/20{
    background-color: rgb(113 113 122 / 0.2) !important;
}

.bg-zinc-500\/25{
    background-color: rgb(113 113 122 / 0.25) !important;
}

.bg-zinc-500\/30{
    background-color: rgb(113 113 122 / 0.3) !important;
}

.bg-zinc-500\/35{
    background-color: rgb(113 113 122 / 0.35) !important;
}

.bg-zinc-500\/40{
    background-color: rgb(113 113 122 / 0.4) !important;
}

.bg-zinc-500\/45{
    background-color: rgb(113 113 122 / 0.45) !important;
}

.bg-zinc-500\/5{
    background-color: rgb(113 113 122 / 0.05) !important;
}

.bg-zinc-500\/50{
    background-color: rgb(113 113 122 / 0.5) !important;
}

.bg-zinc-500\/55{
    background-color: rgb(113 113 122 / 0.55) !important;
}

.bg-zinc-500\/60{
    background-color: rgb(113 113 122 / 0.6) !important;
}

.bg-zinc-500\/65{
    background-color: rgb(113 113 122 / 0.65) !important;
}

.bg-zinc-500\/70{
    background-color: rgb(113 113 122 / 0.7) !important;
}

.bg-zinc-500\/75{
    background-color: rgb(113 113 122 / 0.75) !important;
}

.bg-zinc-500\/80{
    background-color: rgb(113 113 122 / 0.8) !important;
}

.bg-zinc-500\/85{
    background-color: rgb(113 113 122 / 0.85) !important;
}

.bg-zinc-500\/90{
    background-color: rgb(113 113 122 / 0.9) !important;
}

.bg-zinc-500\/95{
    background-color: rgb(113 113 122 / 0.95) !important;
}

.bg-zinc-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(82 82 91 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-600\/0{
    background-color: rgb(82 82 91 / 0) !important;
}

.bg-zinc-600\/10{
    background-color: rgb(82 82 91 / 0.1) !important;
}

.bg-zinc-600\/100{
    background-color: rgb(82 82 91 / 1) !important;
}

.bg-zinc-600\/15{
    background-color: rgb(82 82 91 / 0.15) !important;
}

.bg-zinc-600\/20{
    background-color: rgb(82 82 91 / 0.2) !important;
}

.bg-zinc-600\/25{
    background-color: rgb(82 82 91 / 0.25) !important;
}

.bg-zinc-600\/30{
    background-color: rgb(82 82 91 / 0.3) !important;
}

.bg-zinc-600\/35{
    background-color: rgb(82 82 91 / 0.35) !important;
}

.bg-zinc-600\/40{
    background-color: rgb(82 82 91 / 0.4) !important;
}

.bg-zinc-600\/45{
    background-color: rgb(82 82 91 / 0.45) !important;
}

.bg-zinc-600\/5{
    background-color: rgb(82 82 91 / 0.05) !important;
}

.bg-zinc-600\/50{
    background-color: rgb(82 82 91 / 0.5) !important;
}

.bg-zinc-600\/55{
    background-color: rgb(82 82 91 / 0.55) !important;
}

.bg-zinc-600\/60{
    background-color: rgb(82 82 91 / 0.6) !important;
}

.bg-zinc-600\/65{
    background-color: rgb(82 82 91 / 0.65) !important;
}

.bg-zinc-600\/70{
    background-color: rgb(82 82 91 / 0.7) !important;
}

.bg-zinc-600\/75{
    background-color: rgb(82 82 91 / 0.75) !important;
}

.bg-zinc-600\/80{
    background-color: rgb(82 82 91 / 0.8) !important;
}

.bg-zinc-600\/85{
    background-color: rgb(82 82 91 / 0.85) !important;
}

.bg-zinc-600\/90{
    background-color: rgb(82 82 91 / 0.9) !important;
}

.bg-zinc-600\/95{
    background-color: rgb(82 82 91 / 0.95) !important;
}

.bg-zinc-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(63 63 70 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-700\/0{
    background-color: rgb(63 63 70 / 0) !important;
}

.bg-zinc-700\/10{
    background-color: rgb(63 63 70 / 0.1) !important;
}

.bg-zinc-700\/100{
    background-color: rgb(63 63 70 / 1) !important;
}

.bg-zinc-700\/15{
    background-color: rgb(63 63 70 / 0.15) !important;
}

.bg-zinc-700\/20{
    background-color: rgb(63 63 70 / 0.2) !important;
}

.bg-zinc-700\/25{
    background-color: rgb(63 63 70 / 0.25) !important;
}

.bg-zinc-700\/30{
    background-color: rgb(63 63 70 / 0.3) !important;
}

.bg-zinc-700\/35{
    background-color: rgb(63 63 70 / 0.35) !important;
}

.bg-zinc-700\/40{
    background-color: rgb(63 63 70 / 0.4) !important;
}

.bg-zinc-700\/45{
    background-color: rgb(63 63 70 / 0.45) !important;
}

.bg-zinc-700\/5{
    background-color: rgb(63 63 70 / 0.05) !important;
}

.bg-zinc-700\/50{
    background-color: rgb(63 63 70 / 0.5) !important;
}

.bg-zinc-700\/55{
    background-color: rgb(63 63 70 / 0.55) !important;
}

.bg-zinc-700\/60{
    background-color: rgb(63 63 70 / 0.6) !important;
}

.bg-zinc-700\/65{
    background-color: rgb(63 63 70 / 0.65) !important;
}

.bg-zinc-700\/70{
    background-color: rgb(63 63 70 / 0.7) !important;
}

.bg-zinc-700\/75{
    background-color: rgb(63 63 70 / 0.75) !important;
}

.bg-zinc-700\/80{
    background-color: rgb(63 63 70 / 0.8) !important;
}

.bg-zinc-700\/85{
    background-color: rgb(63 63 70 / 0.85) !important;
}

.bg-zinc-700\/90{
    background-color: rgb(63 63 70 / 0.9) !important;
}

.bg-zinc-700\/95{
    background-color: rgb(63 63 70 / 0.95) !important;
}

.bg-zinc-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(39 39 42 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-800\/0{
    background-color: rgb(39 39 42 / 0) !important;
}

.bg-zinc-800\/10{
    background-color: rgb(39 39 42 / 0.1) !important;
}

.bg-zinc-800\/100{
    background-color: rgb(39 39 42 / 1) !important;
}

.bg-zinc-800\/15{
    background-color: rgb(39 39 42 / 0.15) !important;
}

.bg-zinc-800\/20{
    background-color: rgb(39 39 42 / 0.2) !important;
}

.bg-zinc-800\/25{
    background-color: rgb(39 39 42 / 0.25) !important;
}

.bg-zinc-800\/30{
    background-color: rgb(39 39 42 / 0.3) !important;
}

.bg-zinc-800\/35{
    background-color: rgb(39 39 42 / 0.35) !important;
}

.bg-zinc-800\/40{
    background-color: rgb(39 39 42 / 0.4) !important;
}

.bg-zinc-800\/45{
    background-color: rgb(39 39 42 / 0.45) !important;
}

.bg-zinc-800\/5{
    background-color: rgb(39 39 42 / 0.05) !important;
}

.bg-zinc-800\/50{
    background-color: rgb(39 39 42 / 0.5) !important;
}

.bg-zinc-800\/55{
    background-color: rgb(39 39 42 / 0.55) !important;
}

.bg-zinc-800\/60{
    background-color: rgb(39 39 42 / 0.6) !important;
}

.bg-zinc-800\/65{
    background-color: rgb(39 39 42 / 0.65) !important;
}

.bg-zinc-800\/70{
    background-color: rgb(39 39 42 / 0.7) !important;
}

.bg-zinc-800\/75{
    background-color: rgb(39 39 42 / 0.75) !important;
}

.bg-zinc-800\/80{
    background-color: rgb(39 39 42 / 0.8) !important;
}

.bg-zinc-800\/85{
    background-color: rgb(39 39 42 / 0.85) !important;
}

.bg-zinc-800\/90{
    background-color: rgb(39 39 42 / 0.9) !important;
}

.bg-zinc-800\/95{
    background-color: rgb(39 39 42 / 0.95) !important;
}

.bg-zinc-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity)) !important;
}

.bg-zinc-900\/0{
    background-color: rgb(24 24 27 / 0) !important;
}

.bg-zinc-900\/10{
    background-color: rgb(24 24 27 / 0.1) !important;
}

.bg-zinc-900\/100{
    background-color: rgb(24 24 27 / 1) !important;
}

.bg-zinc-900\/15{
    background-color: rgb(24 24 27 / 0.15) !important;
}

.bg-zinc-900\/20{
    background-color: rgb(24 24 27 / 0.2) !important;
}

.bg-zinc-900\/25{
    background-color: rgb(24 24 27 / 0.25) !important;
}

.bg-zinc-900\/30{
    background-color: rgb(24 24 27 / 0.3) !important;
}

.bg-zinc-900\/35{
    background-color: rgb(24 24 27 / 0.35) !important;
}

.bg-zinc-900\/40{
    background-color: rgb(24 24 27 / 0.4) !important;
}

.bg-zinc-900\/45{
    background-color: rgb(24 24 27 / 0.45) !important;
}

.bg-zinc-900\/5{
    background-color: rgb(24 24 27 / 0.05) !important;
}

.bg-zinc-900\/50{
    background-color: rgb(24 24 27 / 0.5) !important;
}

.bg-zinc-900\/55{
    background-color: rgb(24 24 27 / 0.55) !important;
}

.bg-zinc-900\/60{
    background-color: rgb(24 24 27 / 0.6) !important;
}

.bg-zinc-900\/65{
    background-color: rgb(24 24 27 / 0.65) !important;
}

.bg-zinc-900\/70{
    background-color: rgb(24 24 27 / 0.7) !important;
}

.bg-zinc-900\/75{
    background-color: rgb(24 24 27 / 0.75) !important;
}

.bg-zinc-900\/80{
    background-color: rgb(24 24 27 / 0.8) !important;
}

.bg-zinc-900\/85{
    background-color: rgb(24 24 27 / 0.85) !important;
}

.bg-zinc-900\/90{
    background-color: rgb(24 24 27 / 0.9) !important;
}

.bg-zinc-900\/95{
    background-color: rgb(24 24 27 / 0.95) !important;
}

.bg-opacity-0{
    --tw-bg-opacity: 0 !important;
}

.bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
}

.bg-opacity-100{
    --tw-bg-opacity: 1 !important;
}

.bg-opacity-15{
    --tw-bg-opacity: 0.15 !important;
}

.bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
}

.bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
}

.bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
}

.bg-opacity-35{
    --tw-bg-opacity: 0.35 !important;
}

.bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
}

.bg-opacity-45{
    --tw-bg-opacity: 0.45 !important;
}

.bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
}

.bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
}

.bg-opacity-55{
    --tw-bg-opacity: 0.55 !important;
}

.bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
}

.bg-opacity-65{
    --tw-bg-opacity: 0.65 !important;
}

.bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
}

.bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
}

.bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
}

.bg-opacity-85{
    --tw-bg-opacity: 0.85 !important;
}

.bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
}

.bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
}

.bg-gradient-primary{
    background-image: linear-gradient(76.44deg, #5D810E 0%, #B2EA39 100%) !important;
}

.bg-gradient-secondary{
    background-image: linear-gradient(76.44deg, #003B71 0%, #67B6FF 100%) !important;
}

.bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.bg-none{
    background-image: none !important;
}

.from-magenta-300{
    --tw-gradient-from: #FF66FF var(--tw-gradient-from-position) !important;
    --tw-gradient-to: rgb(255 102 255 / 0) var(--tw-gradient-to-position) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-magenta-400{
    --tw-gradient-from: #FF33FF var(--tw-gradient-from-position) !important;
    --tw-gradient-to: rgb(255 51 255 / 0) var(--tw-gradient-to-position) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.via-purple-500{
    --tw-gradient-to: rgb(166 0 224 / 0)  var(--tw-gradient-to-position) !important;
    --tw-gradient-stops: var(--tw-gradient-from), #A600E0 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.via-transparent{
    --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.to-magenta-800{
    --tw-gradient-to: #750075 var(--tw-gradient-to-position) !important;
}

.to-purple-600{
    --tw-gradient-to: #8800B7 var(--tw-gradient-to-position) !important;
}

.bg-auto{
    background-size: auto !important;
}

.bg-contain{
    background-size: contain !important;
}

.bg-cover{
    background-size: cover !important;
}

.bg-fixed{
    background-attachment: fixed !important;
}

.bg-local{
    background-attachment: local !important;
}

.bg-scroll{
    background-attachment: scroll !important;
}

.bg-clip-border{
    background-clip: border-box !important;
}

.bg-clip-padding{
    background-clip: padding-box !important;
}

.bg-clip-content{
    background-clip: content-box !important;
}

.bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
}

.bg-bottom{
    background-position: bottom !important;
}

.bg-center{
    background-position: center !important;
}

.bg-left{
    background-position: left !important;
}

.bg-left-bottom{
    background-position: left bottom !important;
}

.bg-left-top{
    background-position: left top !important;
}

.bg-right{
    background-position: right !important;
}

.bg-right-bottom{
    background-position: right bottom !important;
}

.bg-right-top{
    background-position: right top !important;
}

.bg-top{
    background-position: top !important;
}

.bg-repeat{
    background-repeat: repeat !important;
}

.bg-no-repeat{
    background-repeat: no-repeat !important;
}

.bg-repeat-x{
    background-repeat: repeat-x !important;
}

.bg-repeat-y{
    background-repeat: repeat-y !important;
}

.bg-repeat-round{
    background-repeat: round !important;
}

.bg-repeat-space{
    background-repeat: space !important;
}

.bg-origin-border{
    background-origin: border-box !important;
}

.bg-origin-padding{
    background-origin: padding-box !important;
}

.bg-origin-content{
    background-origin: content-box !important;
}

.stroke-none{
    stroke: none !important;
}

.p-0{
    padding: 0px !important;
}

.p-2{
    padding: 0.5rem !important;
}

.p-3{
    padding: 0.75rem !important;
}

.p-4{
    padding: 1rem !important;
}

.p-px{
    padding: 1px !important;
}

.px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
}

.px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}

.px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
}

.py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
}

.py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
}

.py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.pb-0{
    padding-bottom: 0px !important;
}

.pb-12{
    padding-bottom: 3rem !important;
}

.pb-16{
    padding-bottom: 4rem !important;
}

.pb-2{
    padding-bottom: 0.5rem !important;
}

.pb-4{
    padding-bottom: 1rem !important;
}

.pb-8{
    padding-bottom: 2rem !important;
}

.pl-0{
    padding-left: 0px !important;
}

.pl-2{
    padding-left: 0.5rem !important;
}

.pl-3{
    padding-left: 0.75rem !important;
}

.pl-4{
    padding-left: 1rem !important;
}

.pl-6{
    padding-left: 1.5rem !important;
}

.pl-72{
    padding-left: 18rem !important;
}

.pr-0{
    padding-right: 0px !important;
}

.pr-0\.5{
    padding-right: 0.125rem !important;
}

.pr-3{
    padding-right: 0.75rem !important;
}

.pr-4{
    padding-right: 1rem !important;
}

.pr-6{
    padding-right: 1.5rem !important;
}

.pt-2{
    padding-top: 0.5rem !important;
}

.pt-4{
    padding-top: 1rem !important;
}

.pt-6{
    padding-top: 1.5rem !important;
}

.pt-8{
    padding-top: 2rem !important;
}

.text-center{
    text-align: center !important;
}

.text-2xl{
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
}

.text-3xl{
    font-size: 1.875rem !important;
    line-height: 2rem !important;
}

.text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
}

.text-\[8rem\]{
    font-size: 8rem !important;
}

.text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}

.text-lg{
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
}

.text-sm{
    font-size: .875rem !important;
    line-height: 1.125rem !important;
}

.text-xl{
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
}

.text-xs{
    font-size: .75rem !important;
    line-height: 1rem !important;
}

.font-bold{
    font-weight: 700 !important;
}

.font-extrabold{
    font-weight: 800 !important;
}

.font-medium{
    font-weight: 500 !important;
}

.font-normal{
    font-weight: 400 !important;
}

.font-semibold{
    font-weight: 600 !important;
}

.uppercase{
    text-transform: uppercase !important;
}

.lowercase{
    text-transform: lowercase !important;
}

.capitalize{
    text-transform: capitalize !important;
}

.\!text-dark{
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.text-black{
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgb(0 116 224 / var(--tw-text-opacity)) !important;
}

.text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgb(0 95 184 / var(--tw-text-opacity)) !important;
}

.text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgb(0 51 98 / var(--tw-text-opacity)) !important;
}

.text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgb(0 27 52 / var(--tw-text-opacity)) !important;
}

.text-dark{
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.text-error{
    --tw-text-opacity: 1 !important;
    color: rgb(201 59 59 / var(--tw-text-opacity)) !important;
}

.text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgb(224 224 224 / var(--tw-text-opacity)) !important;
}

.text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgb(181 181 181 / var(--tw-text-opacity)) !important;
}

.text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgb(138 138 138 / var(--tw-text-opacity)) !important;
}

.text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgb(94 94 94 / var(--tw-text-opacity)) !important;
}

.text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgb(93 129 14 / var(--tw-text-opacity)) !important;
}

.text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgb(73 101 11 / var(--tw-text-opacity)) !important;
}

.text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgb(56 78 8 / var(--tw-text-opacity)) !important;
}

.text-info{
    --tw-text-opacity: 1 !important;
    color: rgb(0 116 224 / var(--tw-text-opacity)) !important;
}

.text-light{
    --tw-text-opacity: 1 !important;
    color: rgb(252 253 253 / var(--tw-text-opacity)) !important;
}

.text-medium{
    --tw-text-opacity: 1 !important;
    color: rgb(94 94 94 / var(--tw-text-opacity)) !important;
}

.text-primary{
    --tw-text-opacity: 1 !important;
    color: rgb(93 129 14 / var(--tw-text-opacity)) !important;
}

.text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgb(179 15 15 / var(--tw-text-opacity)) !important;
}

.text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgb(155 13 13 / var(--tw-text-opacity)) !important;
}

.text-success{
    --tw-text-opacity: 1 !important;
    color: rgb(84 126 17 / var(--tw-text-opacity)) !important;
}

.text-transparent{
    color: transparent !important;
}

.text-white{
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgb(117 103 1 / var(--tw-text-opacity)) !important;
}

.underline{
    text-decoration-line: underline !important;
}

.no-underline{
    text-decoration-line: none !important;
}

.opacity-0{
    opacity: 0 !important;
}

.opacity-100{
    opacity: 1 !important;
}

.opacity-30{
    opacity: 0.3 !important;
}

.opacity-50{
    opacity: 0.5 !important;
}

.opacity-80{
    opacity: 0.8 !important;
}

.bg-blend-normal{
    background-blend-mode: normal !important;
}

.bg-blend-multiply{
    background-blend-mode: multiply !important;
}

.bg-blend-screen{
    background-blend-mode: screen !important;
}

.bg-blend-overlay{
    background-blend-mode: overlay !important;
}

.bg-blend-darken{
    background-blend-mode: darken !important;
}

.bg-blend-lighten{
    background-blend-mode: lighten !important;
}

.bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
}

.bg-blend-color-burn{
    background-blend-mode: color-burn !important;
}

.bg-blend-hard-light{
    background-blend-mode: hard-light !important;
}

.bg-blend-soft-light{
    background-blend-mode: soft-light !important;
}

.bg-blend-difference{
    background-blend-mode: difference !important;
}

.bg-blend-exclusion{
    background-blend-mode: exclusion !important;
}

.bg-blend-hue{
    background-blend-mode: hue !important;
}

.bg-blend-saturation{
    background-blend-mode: saturation !important;
}

.bg-blend-color{
    background-blend-mode: color !important;
}

.bg-blend-luminosity{
    background-blend-mode: luminosity !important;
}

.shadow{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
}

.blur{
    --tw-blur: blur(8px) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}

.transition-\[margin\]{
    transition-property: margin !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}

.transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}

.transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
}

.duration-200{
    transition-duration: 200ms !important;
}

.duration-300{
    transition-duration: 300ms !important;
}

.duration-500{
    transition-duration: 500ms !important;
}

.ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.ease-linear{
    transition-timing-function: linear !important;
}

.ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.scrollbar-hide{
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.scrollbar-hide::-webkit-scrollbar{
    display: none !important;
}

html {
    font-size: 16px;
}

.login-form .box.login-form__wrapper{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.global-alerts .alert{
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-timing-function: linear;
}

.global-alerts .alert.alert--active{
    max-height: 16rem;
    opacity: 1;
}

.global-alerts .alert.alert--dismissed{
    max-height: 0;
    opacity: 0;
}

.global-alerts .alert .alert__content{
    width: 100%;
}

.alert .alert__inner > .icon{
    height: 1.5rem;
    min-width: 1.5rem;
}

.recharts-tooltip-wrapper{
    border-style: none;
    stroke: none;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.recharts-legend-item .recharts-legend-item-text{
    margin-left: 0.25rem !important;
    font-size: .875rem !important;
    line-height: 1.125rem !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.dashboard-widget .blx-table-wrapper{
    border-style: none;
}

.gateway-health .recharts-legend-item{
    margin-bottom: 1rem !important;
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
}

.gateway-health .recharts-legend-item .recharts-legend-item-text{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.hover\:cursor-pointer:hover{
    cursor: pointer !important;
}

.hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(246 246 246 / var(--tw-bg-opacity)) !important;
}

.hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.hover\:text-dark:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(94 94 94 / var(--tw-text-opacity)) !important;
}

.hover\:text-info:hover{
    --tw-text-opacity: 1 !important;
    color: rgb(0 116 224 / var(--tw-text-opacity)) !important;
}

.hover\:underline:hover{
    text-decoration-line: underline !important;
}

.hover\:opacity-80:hover{
    opacity: 0.8 !important;
}

.focus\:border-dark:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgb(51 51 51 / var(--tw-border-opacity)) !important;
}

.focus\:shadow-default:focus{
    --tw-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #E0E0E0 !important;
    --tw-shadow-colored: 0px 2px 2px -1px var(--tw-shadow-color), 0px 0px 0px 3px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(20 20 20 / var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-medium{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(94 94 94 / var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:text-light{
    --tw-text-opacity: 1 !important;
    color: rgb(252 253 253 / var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:opacity-100{
    opacity: 1 !important;
}

.dark\:border-gray-400:is([data-theme="dark"] *){
    --tw-border-opacity: 1 !important;
    border-color: rgb(94 94 94 / var(--tw-border-opacity)) !important;
}

.dark\:bg-gray-400:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(94 94 94 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-gray-500:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-gray-800:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(28 28 28 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-white:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.dark\:text-dark:is([data-theme="dark"] *){
    --tw-text-opacity: 1 !important;
    color: rgb(51 51 51 / var(--tw-text-opacity)) !important;
}

.dark\:text-gray-100:is([data-theme="dark"] *){
    --tw-text-opacity: 1 !important;
    color: rgb(224 224 224 / var(--tw-text-opacity)) !important;
}

.dark\:text-light:is([data-theme="dark"] *){
    --tw-text-opacity: 1 !important;
    color: rgb(252 253 253 / var(--tw-text-opacity)) !important;
}

.dark\:hover\:bg-gray-300:hover:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(138 138 138 / var(--tw-bg-opacity)) !important;
}

.hover\:dark\:bg-gray-500:is([data-theme="dark"] *):hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(51 51 51 / var(--tw-bg-opacity)) !important;
}

.group:hover .dark\:group-hover\:bg-gray-300:is([data-theme="dark"] *){
    --tw-bg-opacity: 1 !important;
    background-color: rgb(138 138 138 / var(--tw-bg-opacity)) !important;
}

.group:hover .dark\:group-hover\:text-white:is([data-theme="dark"] *){
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

@media (min-width: 768px){

    .md\:mx-12{
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }

    .md\:mb-8{
        margin-bottom: 2rem !important;
    }

    .md\:inline{
        display: inline !important;
    }

    .md\:h-10{
        height: 2.5rem !important;
    }

    .md\:h-12{
        height: 3rem !important;
    }

    .md\:h-6{
        height: 1.5rem !important;
    }

    .md\:h-8{
        height: 2rem !important;
    }

    .md\:w-1\/2{
        width: 50% !important;
    }

    .md\:w-10{
        width: 2.5rem !important;
    }

    .md\:w-6{
        width: 1.5rem !important;
    }

    .md\:w-8{
        width: 2rem !important;
    }

    .md\:w-full{
        width: 100% !important;
    }

    .md\:flex-row{
        flex-direction: row !important;
    }

    .md\:gap-6{
        gap: 1.5rem !important;
    }

    .md\:space-x-6 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0 !important;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
    }

    .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
    }

    .md\:space-y-6 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
    }

    .md\:p-6{
        padding: 1.5rem !important;
    }

    .md\:p-8{
        padding: 2rem !important;
    }

    .md\:px-1{
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .md\:px-6{
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .md\:px-8{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .md\:pb-0{
        padding-bottom: 0px !important;
    }
}

@media (min-width: 1024px){

    .lg\:pointer-events-auto{
        pointer-events: auto !important;
    }

    .lg\:invisible{
        visibility: hidden !important;
    }

    .lg\:fixed{
        position: fixed !important;
    }

    .lg\:relative{
        position: relative !important;
    }

    .lg\:left-8{
        left: 2rem !important;
    }

    .lg\:top-0{
        top: 0px !important;
    }

    .lg\:mb-8{
        margin-bottom: 2rem !important;
    }

    .lg\:mr-20{
        margin-right: 5rem !important;
    }

    .lg\:mr-40{
        margin-right: 10rem !important;
    }

    .lg\:block{
        display: block !important;
    }

    .lg\:flex{
        display: flex !important;
    }

    .lg\:hidden{
        display: none !important;
    }

    .lg\:h-32{
        height: 8rem !important;
    }

    .lg\:h-full{
        height: 100% !important;
    }

    .lg\:max-h-full{
        max-height: 100% !important;
    }

    .lg\:min-h-screen{
        min-height: 100vh !important;
    }

    .lg\:w-1\/2{
        width: 50% !important;
    }

    .lg\:w-24{
        width: 6rem !important;
    }

    .lg\:w-60{
        width: 15rem !important;
    }

    .lg\:w-64{
        width: 16rem !important;
    }

    .lg\:grid-cols-2{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }

    .lg\:flex-row{
        flex-direction: row !important;
    }

    .lg\:flex-nowrap{
        flex-wrap: nowrap !important;
    }

    .lg\:items-start{
        align-items: flex-start !important;
    }

    .lg\:justify-center{
        justify-content: center !important;
    }

    .lg\:gap-6{
        gap: 1.5rem !important;
    }

    .lg\:gap-8{
        gap: 2rem !important;
    }

    .lg\:overflow-auto{
        overflow: auto !important;
    }

    .lg\:p-6{
        padding: 1.5rem !important;
    }

    .lg\:p-8{
        padding: 2rem !important;
    }

    .lg\:px-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .lg\:px-6{
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .lg\:px-8{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .lg\:pb-8{
        padding-bottom: 2rem !important;
    }

    .lg\:pl-24{
        padding-left: 6rem !important;
    }

    .lg\:pt-6{
        padding-top: 1.5rem !important;
    }

    .lg\:text-lg{
        font-size: 1.125rem !important;
        line-height: 1.25rem !important;
    }

    .lg\:opacity-100{
        opacity: 1 !important;
    }
}

@media (min-width: 1280px){

    .xl\:visible{
        visibility: visible !important;
    }

    .xl\:block{
        display: block !important;
    }

    .xl\:flex{
        display: flex !important;
    }

    .xl\:hidden{
        display: none !important;
    }

    .xl\:w-72{
        width: 18rem !important;
    }

    .xl\:w-\[calc\(100\%-1rem\)\]{
        width: calc(100% - 1rem) !important;
    }

    .xl\:w-\[calc\(33\.3\%-1rem\)\]{
        width: calc(33.3% - 1rem) !important;
    }

    .xl\:w-\[calc\(50\%-1rem\)\]{
        width: calc(50% - 1rem) !important;
    }

    .xl\:w-\[calc\(66\.6\%-1rem\)\]{
        width: calc(66.6% - 1rem) !important;
    }

    .xl\:justify-start{
        justify-content: flex-start !important;
    }

    .xl\:gap-4{
        gap: 1rem !important;
    }

    .xl\:p-4{
        padding: 1rem !important;
    }

    .xl\:py-2{
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .xl\:pl-72{
        padding-left: 18rem !important;
    }

    .xl\:text-base{
        font-size: 1rem !important;
        line-height: 1.5rem !important;
    }
}

@media (min-width: 1536px){

    .\32xl\:max-w-\[12ch\]{
        max-width: 12ch !important;
    }

    .\32xl\:max-w-\[20ch\]{
        max-width: 20ch !important;
    }

    .\32xl\:max-w-\[30ch\]{
        max-width: 30ch !important;
    }

    .\32xl\:max-w-none{
        max-width: none !important;
    }
}

